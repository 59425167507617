import React from "react";
import { Redirect } from "react-router-dom";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import RequestResetPassword from "../pages/Authentication/RequestResetPassword";
import ResetPassword from "../pages/Authentication/ResetPassword";
import MemberResetPassword from "pages/Authentication/MemberResetPassword";

// User Components
import UserDashboard from "../pages/User/Dashboard";
import UserSetting from "../pages/User/Settings";

// Admin Components
import AdminDashboard from "../pages/Admin/Dashboard";
import Users from "../pages/Admin/Users";
import UserSubscriptions from "../pages/Admin/UserSubscriptions";
import ImportFile from "../pages/Admin/ImportFile";
import Brands from "../pages/Admin/Brands";
import FilesList from "../pages/Admin/FilesList";
import RunningTasks from "../pages/Admin/RunningTasks";
import Notifications from "../pages/Admin/Notifications";
import AdminUserProfile from "../pages/Admin/user-profile";
import AdminUserView from "../pages/Admin/user-view"
import Import from "../pages/Admin/Import";
import BrandAdd from "../pages/Admin/Brand-Add";
import BrandEdit from "../pages/Admin/Brand-Edit";
import Members from "../pages/User/Members";
import FileRecords from "../pages/Admin/FileRecords";
import Templates from "../pages/Admin/Templates";
import AddTemplate from "../pages/Admin/AddTemplate";
import EditTemplate from "../pages/Admin/EditTeplate";
import NotificationsAdd from "pages/Admin/Notifications-Add";
import FileImporter from "pages/Admin/FileImport";
import EditFileImport from "pages/Admin/EditFileImport";
import ChartImport from "../pages/Admin/ChartImport";
import CMSSetting from "pages/Admin/CmsSettings";

import Dimension from "pages/Dimension";

//v2
//import Dashboardv2 from "pages/UserV2/DashboardV2";

const authProtectedRoutes = [
  { path: "/dashboard", component: UserDashboard, role: "user" },
  { path: "/insights/:tab", component: UserDashboard, role: "user" },
  { path: "/insights/", component: UserDashboard, role: "user" },
  { path: "/insights/:tab/:iname", component: UserDashboard, role: "user" },
  { path: "/settings", component: UserSetting, role: "user" },
  { path: "/members", component: Members, role: "user" },
  //v2
  //{ path: "/dashboardv2", component: Dashboardv2, role: "user", defaultState: { insightNo: 0 } },
  // //profile
  // { path: "/admin/profile", component: UserProfile, role: "admin" },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  /*{
    path: "/",
    exact: true,
    role: "admin",
    component: () => <Redirect to="/admin/dashboard" />,
  },*/
];

const publicRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: "/request-reset-password", component: RequestResetPassword },
  { path: "/reset-password", component: ResetPassword },
  { path: "/reset-password/:hash", component: ResetPassword },
  { path: "/create-password/:hash", component: MemberResetPassword },
  { path: "/admin", component: Login, role: "admin" },
  { path: "/admin/login", component: Login, role: "admin" },
  { path: "/admin/logout", component: Logout, role: "admin" },
  { path: "/dimension", component: Dimension},
];

const adminRoutes = [
  { path: "/admin/dashboard", component: AdminDashboard, role: "admin" },
  { path: "/admin/users", component: Users, role: "admin" },
  { path: "/admin/users/members/:id", component: AdminUserView, role: "admin" },
  { path: "/admin/subscriptions", component: UserSubscriptions, role: "admin" },
  { path: "/admin/import-file", component: ImportFile, role: "admin" },
  { path: "/admin/files-list", component: FilesList, role: "admin" },
  { path: "/admin/brands", component: Brands, role: "admin" },
  { path: "/admin/running-tasks", component: RunningTasks, role: "admin" },
  { path: "/admin/notifications", component: Notifications, role: "admin" },
  { path: "/admin/notifications-add", component: NotificationsAdd, role: "admin" },
  { path: "/admin/settings", component: AdminUserProfile, role: "admin" },
  { path: "/admin/user-view/:id", component: AdminUserView, role: "admin" },
  { path: "/admin/import", component: Import, role: "admin" },
  { path: "/admin/brand-add", component: BrandAdd, role: "admin" },
  { path: "/admin/brand-edit/:id", component: BrandEdit, role: "admin" },
  { path: "/admin/file-records/:id/:schemaName", component: FileRecords, role: "admin" },
  { path: "/admin/templates", component: Templates, role: "admin" },
  { path: "/admin/templates/add", component: AddTemplate, role: "admin" },
  { path: "/admin/templates/edit/:id", component: EditTemplate, role: "admin" },
  { path: "/admin/chartimport/add", component: FileImporter, role: "admin", defaultState: { TabNo: "1" } },
  { path: "/admin/chartimport", component: ChartImport, role: "admin"},
  { path: "/admin/chartimport/edit/:id", component: EditFileImport, role: "admin", defaultState: { TabNo: "1" } },
  { path: "/admin/cmssettings", component: CMSSetting, role: "admin" },
];

export { publicRoutes, authProtectedRoutes, adminRoutes };
