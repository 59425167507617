import React, { useMemo, useState, useEffect } from "react"
import {
  Modal,
  Row,
  Col,
  UncontrolledTooltip,
  Input,
  FormFeedback  
} from "reactstrap"
import { useHistory } from 'react-router-dom';
import { get, post, del } from "../../helpers/api_helper";;
import TableContainer from '../../components/Common/TableContainer';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import BlockUi from 'react-block-ui';
import swal from "sweetalert";
import { useFormik } from "formik";
import * as Yup from "yup";
const Users = () => {
  //meta title
  // document.title="Users";
    const history = useHistory();
    const [data, setdata] = useState([]);
    const [onSubmit, setonSubmit] = useState(false);
    const [modal_user, setmodal_user] = useState(false);
    const [isBusy, setBusy] = useState(true); 
    const [brandData, setbrandData] = useState([]);  
    //Toastr
    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeOut");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);
    let positionClass = "toast-top-right";
    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    //End toastr
    
    const user = JSON.parse(localStorage.getItem("authUser"));
    const fetchData = async function fetchData() {
      try{
        const response = await get("admin/notification");
        console.log(response.data);
        setdata(response.data);
        setBusy(false);
      } catch(err)         {
        setBusy(false);
      }
      try{
        const response = await get("/admin/brands");
        setbrandData(response.data);
        setBusy(false);
      } catch(err)         {
        setBusy(false);
      }

    }
    useEffect(() => {
      fetchData();
    }, []);

    const centerText = (val) => {
      return (
        <div style={{ textAlign: "center" }}>{val}</div>
      )
    }

    const removeUser = async (val) => {
      let willDelete = await swal({
        title: "Do you want to remove this Notification?",
        text: "This notification will be removed for all users.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })

      if(willDelete) {
        try {
          setBusy(true);
          let response = await del("/admin/notification/remove_notification/"+val);
          if(response.success) {
            toastr.success(response.success,"");
            fetchData();
          } else {
            toastr.error(response.error,"");
          } 
        }
        catch(e) {
          toastr.error("Something went wrong","");
          setBusy(false);
        }      
      }
    }    
    const actionRender = (cellProps) => {
      return (
        <div className="text-center">
          
          {/* <Link
            to={"/admin/brand-edit/"+cellProps.row.original.id}
          >
            <i 
              className="mdi mdi-pencil font-size-18 text-success" 
              id="edittooltip" 
              data-record={JSON.stringify(cellProps.row.original)} />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
          </Link>&nbsp;&nbsp; */}

          <a
            className="text-danger"
            onClick={() => removeUser(cellProps.row.original.id)}
          >
            <i className="mdi mdi-delete icon-delete font-size-18" id={`deletetooltip${cellProps.row.original.id}`} />
            <UncontrolledTooltip placement="top" target={`deletetooltip${cellProps.row.original.id}`}>
              Delete
            </UncontrolledTooltip>
          </a>
        </div>
      )
    }
    
    const statusRender = (cellProps) => {
        let val = cellProps.row.original.readCount;
        let totaluser = cellProps.row.original.totalUsers;

        return (
          <span style={{marginRight:"13px"}}>{val}/{totaluser}</span>
        )
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Brand',
                accessor: 'brandName',
            }, 
            {
                Header: 'Message',
                accessor: 'message',
            },
            {
              Header: 'Link To',
              accessor: 'linkto',
            },
            {
              Header: 'Created On',
              accessor:'createdAt'
            },                
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Status</div>),
                accessor: 'readCount',
                Cell: cellProps => centerText(
                                  statusRender(cellProps)),
                disableSortBy: true
            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Action</div>),
                accessor: 'action',
                Cell: cellProps => centerText(actionRender(cellProps)),
                disableSortBy: true,
                disableGlobalFilter:true,
            }
        ],
        []
    );
  const AddNew = () => {
    history.push("/admin/notifications-add");  
  }
  
  //meta title
  document.title="Notifications - Acumen";
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      message: '',
      brand:'',
      linkto:''
    },
    validationSchema: Yup.object({
      brand: Yup.string().required("Please select Brand"),
      linkto: Yup.string().required("Please select Link to"),
      message: Yup.string().required("Please enter Message").max(150),
    }),
    onSubmit: async (values) => {
      setonSubmit(true);
      let toSave = {
        "brand": values.brand,
        "linkto": values.linkto,
        "message": values.message,
      }
      try {
        let res = await post('admin/notification/add', toSave);
        validation.resetForm({});
        setonSubmit(false);
        setmodal_user(false);
        toastr.success(res.success,"");
        setdata(res.data);
      } catch(error) {
        setonSubmit(false);
        if (error.response) {
           toastr.error(error,"Request failed.");
         }
      }  
    },
  });
  return (
    <React.Fragment>
      <div className="inner-content">
      <div className="pr-page-title">
          <h2>Notification</h2>
      </div>
        <BlockUi tag="div" blocking={isBusy}>
          <TableContainer
                columns={columns}
                data={ isBusy ? [] : data}
                isGlobalFilter={true}
                isAddOptions={true}
                customPageSize={10}
                className="tdTheme"
                handleOrderClicks={AddNew}
                addLable="Add Notification"
                isBusy={isBusy}
            />
          </BlockUi>

      </div>
      <Modal
        isOpen={modal_user}
        toggle={() => {
          setmodal_user(false);
          validation.resetForm({});
        }}
        centered
      >
      <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">Add Notification
          </h5>
          <button
            onClick={() => {
              setmodal_user(false);
              validation.resetForm({});
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={validation.handleSubmit}>
          <Row>
              <Col lg={12}>
              <div className="mb-3">
                  <label htmlFor="name">Brand</label>
                   <Input type="select"  name="brand" 
                   id="brand"
                   onInput={validation.handleBlur}
                   onBlur={validation.handleBlur}
                   onChange = {(e) => {
                    validation.setFieldValue('brand', e.target.value);
                  }}
                   invalid={validation.touched.brand &&
                     validation.errors.brand
                       ? true
                       : false
                   }
                   >
                     <option  value={""}>Select Brand</option>
                     {brandData.map(item => (
                       item.status != "Inactive" ? 
                       <option key={item.id} value={item.id}>
                         {item.name}
                       </option> : null
                     ))}
                     </Input>
                     {  validation.touched.brand &&
                       validation.errors.brand ? (
                         <FormFeedback type="invalid">
                           {validation.errors.brand}
                         </FormFeedback>
                       ) : null}
                  </div>
              </Col>
              {/* <Col lg={12}>
              <div className="mb-3">
                <label htmlFor="name">To</label>
                <Input type="select" name="category" 
                   id="category"
                   onChange = {(e) => {
                    selectCategory(e)
                    validation.setFieldValue('category', e.target.value);
                    setshowHide("d-none");
                  }}
                   >
                     <option value={"1"}>All</option>
                     <option value={"2"}>Subscriber</option>
                     <option value={"3"}>Sub User</option>
                  </Input>
                </div>
              </Col> */}
              <Col lg={12}>
              <div className="mb-3">
                  <label htmlFor="name">Link to</label>
                   <Input type="select"  name="linkto" 
                   id="linkto"
                   onInput={validation.handleBlur}
                   onBlur={validation.handleBlur}
                   onChange = {(e) => {
                    validation.setFieldValue('linkto', e.target.value);
                  }}
                   invalid={validation.touched.linkto &&
                     validation.errors.linkto
                       ? true
                       : false
                   }
                   >
                     <option  value={""}>Select</option>
                     <option  value={"commerce-experience"}>Commerce Experience</option>
                     <option  value={"shipping-package"}>Shipping & Package</option>
                     <option  value={"logistics-transportation"}>Logistics & Transportation</option>
                     <option  value={"brand-marketintel"}>Return/Refund</option>
                     <option  value={"customer-service"}>Customer Service</option>
                     </Input>
                     {  validation.touched.linkto &&
                       validation.errors.linkto ? (
                         <FormFeedback type="invalid">
                           {validation.errors.linkto}
                         </FormFeedback>
                       ) : null}
                  </div>
              </Col>
              <Col lg={12}>
                <div className="mb-3">
                  <label htmlFor="name">Message</label>
                  <Input
                      type="textarea"
                      id="textarea"
                      maxLength="225"
                      rows="5"
                      name='message'
                      placeholder="Enter description"
                      value={validation.values.message || ""}
                      onChange={validation.handleChange}
                      onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.message &&
                        validation.errors.message
                          ? true
                          : false
                      }
                    />
                    {validation.touched.message &&
                      validation.errors.message ? (
                        <FormFeedback type="invalid">
                          {validation.errors.message}
                        </FormFeedback>
                      ) : null}
                </div>
              </Col>
              </Row>
              <Row>
              <Col lg={12}>
                <div className="text-end">
                  <button type="submit"tabIndex={8} disabled={onSubmit} className="btn btn-success">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>
  </React.Fragment>
  );
}

export default Users;
