import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import { CardTitle, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { post } from "helpers/api_helper";
import SingleChartSubmit from "helpers/Function/SingleChartSubmit";
import ImportContext from "helpers/Context/ImportContext";
import InputText from "pages/Admin/components/inputtext";
import SelectField from "pages/Admin/components/SelectInput";
import UniqueList from "pages/Admin/components/UniqueList";
import FormAligner from "pages/Admin/components/FormAligner";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default function Table({
  subInsights = null,
  Headers = [],
  id = "",
  save = null,
  index = null,
  clickIndicator = null,
  subInsightsData = null,
}) {
  //console.log(id);
  ////console.log(Headers);
  ////console.log(save, index);
  const chartName = "Table";
  const history = useHistory();
  const formikRef = useRef();
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [saveStatus, setSaveStatus] = useState(false);
  //console.log(ImportContextData);
  const [uniqueData, setUniqueData] = useState([]);
  const FormFields = {
    InsightDescription:"",
    retailer: "",
    legend: "",
  };
  const [FormData, setFormData] = useState(FormFields);
  ////console.log(FormData);
  const { grpby, avgof } = FormData;
  const [submited, setSubmited] = useState(false);
  const [uniqueRow, setUniqueRow] = useState(null);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  const handleInputChange = (e) => {
    //console.log(formikRef.current?.values);
  };
  const handleFormChange = (e) => {
    setImportContextData({
      ...ImportContextData,
      ["subData"]: [],
      ["SubinsightNo"]: index,
    });
    setSaveStatus(false);
    // toastr.warning(`Sub-Insight-${index + 1} unsaved successfully`);
  }
  useEffect(() => {
    if(subInsights!=null){
    FormFields["subInsghtsTitle"] = "";
     //console.log(JSON.stringify(formikRef.current?.values) == JSON.stringify(FormFields));
     //console.log(JSON.stringify(formikRef.current?.values),JSON.stringify(FormFields));
    if(JSON.stringify(formikRef.current?.values) != JSON.stringify(FormFields && subInsights != null)){
      setImportContextData({...ImportContextData, [`subinsight.${id}.data`]: formikRef.current?.values});
      if(uniqueData.length != 0){
        setImportContextData({...ImportContextData, [`subinsight.${id}.data`]: {...formikRef.current?.values, ["unique"]: uniqueData}});
      }
    }
    }
  },[formikRef.current?.values, uniqueData])
  const handleFormSubmit = (value) => {
    value["uniqueObject"] = uniqueData;
    if (subInsights == null) {
      value["chartName"] = chartName;
      let res = SingleChartSubmit(value, ImportContextData?.id);
      res
        .then((data) => {
          //console.log(data);
          if (data?.success) {
            toastr.success(data?.success, "");
            history.push("/admin/chartimport/add", { TabNo: "3" });
          } else {
            toastr.error("Not Able to add chart data", "");
          }
        })
        .catch((err) => console.log(err));
    } else {
      value["chartName"] = chartName;
      ////console.log(value);
      setImportContextData({
        ...ImportContextData,
        ["subData"]: value,
        ["SubinsightNo"]: index,
      });
      setSaveStatus(true);
      toastr.success(`Sub-Insight-${index + 1} saved successfully`);
    }
  };
  const validationSchema = {
      retailer: Yup.string()
      .required("field is required")
      .test("check required", "field is required", function (value) {
        if (value == undefined || value == "Select") {
          return false;
        } else {
          return true;
        }
      }),
  };
  if (subInsights != null) {
    validationSchema["subInsghtsTitle"] = Yup.string().required(
      "is required"
    );
    FormFields["subInsghtsTitle"] = "";
  }
  useMemo(() => {
    //////console.log(clickIndicator,"clickIndicator");
    ////console.log(clickIndicator, save);
    if (save) {
      formikRef.current?.handleSubmit();
    }
  }, [save, clickIndicator]);
  return (
    <>
      {/* {subInsights == null && (
        <CardTitle className={"h5 mb-4"}> Pie Chart </CardTitle>
      )} */}
          <CardTitle className={"h5 mb-4"}> Table </CardTitle>
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={ImportContextData.hasOwnProperty(`subinsight.${id}.data`) ? ImportContextData[`subinsight.${id}.data`] : FormFields}
          validationSchema={Yup.object(validationSchema)}
          onSubmit={(values) => {
            //values["color"] = values["color"].slice(0, uniqueData.length);
            handleFormSubmit(values);
          }}
          render={({ values, touched, ...formik }) => (
            <Form
              onChange={(e) => {
                if(saveStatus){
                  handleFormChange(e)
                }
                // if (
                //   subInsightsData != null &&
                //   subInsightsData[index].length != 0
                // ) {
                //   resetCurrentSubinsight();
                // }
              }}
            >
              {subInsights != null ? (
                <InputText
                  type="text"
                  name={"subInsghtsTitle"}
                  placeholder={"Sub-Insights Title"}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
              ) : null}
               <InputText
                  type="textarea"
                  className="description"
                  name={"InsightDescription"}
                  placeholder={"Insight Description"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
              <CardTitle className="h5 mb-4">Group By </CardTitle>
              <hr />
              <SelectField
                type="select"
                name={"retailer"}
                placeholder={"Value"}
                submited={submited}
                options={ImportContextData.Headers}
                onInput={(e) => {
                  handleInputChange(e);
                  formik.setFieldTouched(e.target.name, true);
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              />
              <InputText
                  type="textarea"
                  name={"legend"}
                  placeholder={"Legend Description"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
                
              {subInsights == null && (
                <FormAligner>
                  <button
                    className="btn btn-success col-md-3 me-2"
                    onClick={() => {
                      setSubmited(true);
                      formik.handleSubmit();
                    }}
                    type="button"
                  >
                    {subInsights == null ? "Save and Next" : "Save"}
                  </button>
                  <Link to="/admin/chartimport">Cancel</Link>
                </FormAligner>
              )}
            </Form>
          )}
        />
      </div>
    </>
  );
}

