import React from "react";

const ErrorMessage = ({Message}) => {
  return (
    <div className="two-select-boxes">
      <span className="inner-content-donut package">
        <h4>{Message}</h4>
      </span>
    </div>
  );
};

export default ErrorMessage;