import React, {
  useMemo,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import ImportContext from "helpers/Context/ImportContext";
import ImportTab from "./SubPages/importTab";
import ChartTab from "./SubPages/chartTab/chartTab";
import FilterTab from "./SubPages/filterTab/FilterTab";
import CMSTab from "./SubPages/CMSTab/CMSTab";
import Preview from "./SubPages/PreviewTab/PreviewTab";
import arrowLeft from 'assets/images/Arrow-left.svg';
import "./FileImport.css";
const FileImporter = () => {
  const [ImportData, setImportData] = useState({
    accessTabs: ["Import", "Filter", "CMS", "Chart", "Priview"],
  });
  const importValue = useMemo(() => [ImportData, setImportData], [ImportData]);
  const accessData = importValue[0];
  // console.log(accessData);
  const history = useHistory();
  const location = useLocation();
  const currentTab = location.state?.TabNo;
  //console.log(currentTab);
  const [activeTab, setactiveTab] = useState(currentTab);
  // useEffect(() => {
  //   setactiveTab(accessData.tab ? accessData.tab : "1");
  // },[accessData.tab])
  useMemo(() => {
    setactiveTab(
      location.state?.TabNo != undefined ? location.state?.TabNo : "1"
    );
  }, [location.state]);
  return (
    <ImportContext.Provider value={importValue}>
      <React.Fragment>
        <div className="inner-content">
          <div className="pr-page-title">
            <h2>Chart Import</h2>
            <h6>
                <div className="back">
                <Link to="/admin/chartimport">
                    <img src={arrowLeft} alt="arrow" />
                    Back
                  </Link>
                </div>
            </h6>
          </div>
          <div className="insights-tabs">
            <ul
              className="nav nav-pills nav-one mb-3 align-items-center"
              id="details-insights"
              role="tablist"
            >
              <li
                className={`nav-item ${
                  accessData["accessTabs"].includes("Import")
                    ? ""
                    : "no-pointer-event"
                }`}
                role="presentation"
              >
                <button
                  className={activeTab === "1" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    history.push("/admin/chartimport/add", { TabNo: "1" });
                  }}
                  id="commerce-tab"
                >
                  Import
                </button>
              </li>
              <li
                className={`nav-item ${
                  accessData["accessTabs"].includes("Chart")
                    ? ""
                    : "no-pointer-event"
                }`}
                role="presentation"
              >
                <button
                  className={activeTab == "2" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    history.push("/admin/chartimport/add", { TabNo: "2" });
                  }}
                  id="shipping-tab"
                >
                  Chart
                </button>
              </li>
              <li
                className={`nav-item ${
                  accessData["accessTabs"].includes("Filter")
                    ? ""
                    : "no-pointer-event"
                }`}
                role="presentation"
              >
                <button
                  className={activeTab === "3" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    history.push("/admin/chartimport/add", { TabNo: "3" });
                  }}
                  id="logistics-tab"
                >
                  Filter
                </button>
              </li>
              <li
                className={`nav-item ${
                  accessData["accessTabs"].includes("CMS")
                    ? ""
                    : "no-pointer-event"
                }`}
                role="presentation"
              >
                <button
                  className={activeTab === "4" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    history.push("/admin/chartimport/add", { TabNo: "4" });
                  }}
                  id="return-tab"
                >
                  CMS
                </button>
              </li>
              <li
                className={`nav-item ${
                  accessData["accessTabs"].includes("Priview")
                    ? ""
                    : "no-pointer-event"
                }`}
                role="presentation"
              >
                <button
                  className={activeTab === "5" ? "nav-link active" : "nav-link"}
                  onClick={() => {
                    history.push("/admin/chartimport/add", { TabNo: "5" });
                  }}
                  id="customer-service-tab"
                >
                  Preview
                </button>
              </li>
            </ul>
            <div style={{ display: activeTab == "1" ? "block" : "none" }}>
              <ImportTab />
            </div>
            <div style={{ display: activeTab == "2" ? "block" : "none" }}>
              <ChartTab />
            </div>
            <div style={{ display: activeTab == "3" ? "block" : "none" }}>
              <FilterTab />
            </div>
            <div style={{ display: activeTab == "4" ? "block" : "none" }}>
              <CMSTab />
            </div>
            {activeTab == "5" && (
              <div>
                <Preview />
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    </ImportContext.Provider>
  );
};

export default FileImporter;
