import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "assets/css/style.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Spinner,
  Label,
} from "reactstrap";
import { withRouter, useHistory, useParams } from "react-router-dom";

//import images
import loginLogo from "assets/images/templateImages/Acumen-logo.png";
import loginRightImage from "assets/images/templateImages/login-right-img-half-new.png";
import passwordEye from "assets/images/templateImages/eye-off.svg";
import passwordEyeOn from "assets/images/templateImages/eye_on.svg";
import loginRightIcon from "assets/images/nike.svg";
import loginRightIcon1 from "assets/images/p-2.svg";
import loginRightIcon2 from "assets/images/p-3.svg";
import loginRightIcon3 from "assets/images/p-4.svg";
import loginRightIcon4 from "assets/images/p-5.svg";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import { userResetPassword } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";
//toastr
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import forgetPassword from "store/auth/forgetpwd/reducer";

const ResetPassword = () => {
  document.title="Reset password - Acumen";
  const history = useHistory();
  const hashRes = useParams();
  const dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);
  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      password: "",
      retypePassword: "",
    },
    validationSchema: Yup.object({
      password: Yup.string().required("Please Enter Password").min(8),
      retypePassword: Yup.string()
        .required("Please Enter Confirm Password")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
    }),
    onSubmit: (values) => {
      console.log("in onsubmit formik");
      dispatch(userResetPassword([values, hashRes.hash]));
    },
  });

  const { forgetError, forgetSuccessMsg, loading } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
    loading: state.ForgetPassword.loading,
  }));

  //If success msg redirect to login after 2s
  useEffect(() => {
    if (forgetSuccessMsg) {
      toastr.success(`${forgetSuccessMsg.success}`, "");
      localStorage.setItem(
        "authUser",
        JSON.stringify(forgetSuccessMsg.payload)
      );
      history.push("/settings");
    }
  }, [forgetSuccessMsg]);
  useEffect(() => {
    if (forgetError) {
      if (forgetError.response) {
        // Request made and server responded
        //console.log(forgetError.response);
        if (forgetError.response.data.errors) {
          //if error is in input field
          validation.setErrors(forgetError.response.data.errors);
        } else {
          //if the error in not in the input field
          toastr.error(forgetError.response.data.error, "");
        }
      } else if (forgetError.request) {
        // The request was made but no response was received
        toastr.error(forgetError, "");
      } else {
        // Something happened in setting up the request that triggered an Error
        toastr.error(forgetError, "");
      }
    }
  }, [forgetError]);

  const resetSubmit = () => {
    validation.handleSubmit();
  };

  return (
    <React.Fragment>
      <div className="common-box">
        <div className="login-logo">
          <a>
            {/* <img src={loginLogo} alt="Accumen logo" /> */}
            Acumen
          </a>
        </div>
        <div className="left-content">
          <div className="log-in-details set-new-pass">
            <h3>Reset password</h3>
            <p className="subhead">
              Please choose a new password and confirm before resetting.
            </p>

            <form
              className="create-account ca-stepFour"
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              <div className="form-group">
                <label className="form-label">Choose A New Password</label>
                <Input
                  className="form-control passwordText"
                  type={showPassword ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  tabIndex={1}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.password || ""}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                <img
                  src={showPassword ? passwordEyeOn : passwordEye}
                  className={
                    validation.touched.password && validation.errors.password
                      ? "error-eye"
                      : "eye-off"
                  }
                  onClick={() => setshowPassword(showPassword ? false : true)}
                  alt="password-eye"
                />
                {/* <img src={passwordEye} className="eye-off" alt="password-eye" /> */}
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
              </div>
              {/* <span className="info">The password must contain atleast 8 characters</span> */}
              <div className="form-group">
                <label className="form-label">Confirm Password</label>
                <Input
                  className="form-control passwordText"
                  type={showConfirmPassword ? "text" : "password"}
                  placeholder="Confirm Password"
                  name="retypePassword"
                  tabIndex={2}
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.retypePassword || ""}
                  invalid={
                    validation.touched.retypePassword &&
                    validation.errors.retypePassword
                      ? true
                      : false
                  }
                />
                <img
                  src={showConfirmPassword ? passwordEyeOn : passwordEye}
                  className={
                    validation.touched.retypePassword &&
                    validation.errors.retypePassword
                      ? "error-eye"
                      : "eye-off"
                  }
                  onClick={() =>
                    setshowConfirmPassword(showConfirmPassword ? false : true)
                  }
                  alt="password-eye"
                />
                {/* <img src={passwordEye} className="eye-off" alt="password-eye" /> */}
                {validation.touched.retypePassword &&
                validation.errors.retypePassword ? (
                  <FormFeedback type="invalid">
                    {validation.errors.retypePassword}
                  </FormFeedback>
                ) : null}
              </div>
            </form>
            <div className="button-type">
              <button
                type="submit"
                className="button-with-bg"
                disabled={loading}
                onClick={() => resetSubmit()}
              >
                {loading == true
                  ? "Processing   "
                  : "Log in with reset password"}
                {loading && (
                  <Spinner
                    className="ms-2"
                    style={{ height: "1rem", width: "1rem" }}
                    color="light"
                  />
                )}
              </button>
            </div>
          </div>
        </div>
        <div className="right-content">
          {/* <img src={loginRightImage} alt="dashboard" /> */}
          <div className="content_login">
            {/* <img src={loginRightImage} alt="dashboard" /> */}
            <h4>Acumen by G & Co.</h4>
            <h5>Advance in Retail: Transformative Insights into Action with our Live Data Benchmarking Soultion.</h5>

            <ul className="login_detail mx-0 p-0">
                <li>Real-time data on the market and competition</li>
                <li>Retail focused to provide deep expertise in the industry</li>
                <li>Actionable takeaways to quickly and clearly inform relevant teams</li>
                <li>Diverse consumer demographics across generation, gender, income, and consumer preferences</li>
                <li>Custom on-demand research in 48 hours to better inform your specific needs and purposes</li>
            </ul>

            <div className="partners_section w-100 mt-auto">
                <h6>Top Retail & Consumer Brands Place Their Trust in G & Co.</h6>
                <div className="d-flex justify-content-between align-items-center">
                    <p><img src={loginRightIcon} alt=" " /></p>
                    <p><img src={loginRightIcon1} alt=" " /></p>
                    <p><img src={loginRightIcon2} alt=" " /></p>
                    <p><img src={loginRightIcon3} alt=" " /></p>
                    <p><img src={loginRightIcon4} alt=" " /></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ResetPassword);

ResetPassword.propTypes = {
  history: PropTypes.object,
};
