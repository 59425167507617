import React, { useState, useContext, useMemo, useRef } from "react";
import { Formik, Form } from "formik";
import { Card, Col, Row, CardBody, CardTitle, Alert } from "reactstrap";
import { useHistory } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import InputRadio from "../../../components/inputRadio";
import SelectField from "../../../components/SelectInput";
import InputText from "../../../components/inputtext";
import FormAligner from "../../../components/FormAligner";
import SubinsightComponent from "./SubinsightChart";

import ImportContext from "helpers/Context/ImportContext";
import { post } from "helpers/api_helper";

//import all chart form
import GroupBarChartVertical from "./chartForms/GroupBarChartVertical";
import GroupBarChartHorizontal from "./chartForms/GroupBarChartHorizontal";
import StackedBarPlot from "./chartForms/StackedBarplot";
import HeatMap from "./chartForms/HeatMap";
import Multibarchart from "./chartForms/Multibarchart";
import PieChart from "./chartForms/PieChart";
import StackedHorizontal from "./chartForms/StackedHorizontal";
import StackedVertical from "./chartForms/StackedVertical";
import RadarChart from "./chartForms/RadarChart";
import Table from "./chartForms/Table";

import "./chartTab.css";
import { Link } from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const chartTypes = {
  GroupBarV: "Group Bar Chart Vertical",
  GroupBarH: "Group Bar Chart Horizontal",
  HeatMap: "Heat Map",
  MultiBar: "Multi-bar Comparison Chart",
  Pie: "Pie Chart",
  StackedBarH: "Stacked Bar Chart Horizontal",
  StackedBarV: "Stacked Bar Chart Vertical",
  StackedBarP: "Stacked Bar Plot",
  Table: "Table",
};
var ChartComponent = "";

const ChartTab = () => {
  const chartInfos = {
    subInsights: "no",
    chartType: "",
    chartData: null,
  };
  const history = useHistory();
  const [clickIndicator, setClickIndicator] = useState(0);
  const formikRef = useRef();
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  ////console.log(ImportContextData);
  const [subInsightsData, setsubInsightsData] = useState([{ id: uuidv4() }]);
  const [SubinsightFormData, setSubinsightFormData] = useState([[]]);
  //////console.log(subInsightsData);
  const [chartFormdata, setchartFormdata] = useState(chartInfos);
  const [chartState, setChartState] = useState("");
  const [saveIndex, setsaveIndex] = useState(null);
  const [SubinsightError, setSubinsightError] = useState(null);
  const { subInsights, chartType, chartData } = chartFormdata;

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  useMemo(() => {
    formikRef.current?.resetForm();
    setchartFormdata(chartInfos);
  }, [ImportContextData?.id]);

  useMemo(() => {
    setImportContextData({
      ...ImportContextData,
      ["subinsightflag"]: subInsights,
    });
    console.log("setimport", ImportContextData);
  }, [subInsights]);

  useMemo(() => {
    ////console.log( ImportContextData.subData);
    ////console.log(ImportContextData.SubinsightNo);
    let temparray = SubinsightFormData;
    temparray[ImportContextData?.SubinsightNo] = ImportContextData?.subData;
    ////console.log(temparray, "save data");
    setSubinsightFormData(temparray);

    const containsEmptyArray = temparray.filter(
      (subArr) => subArr.length === 0
    );
    if (containsEmptyArray.length == 0) {
      setSubinsightError(null);
    }
  }, [ImportContextData.subData, ImportContextData.SubinsightNo]);
  function getValue(chartType) {
    switch (chartType) {
      case "GroupBarV":
        return <GroupBarChartVertical />;
      //break;
      case "GroupBarH":
        return <GroupBarChartHorizontal />;
      //  break;
      case "HeatMap":
        return <HeatMap />;
      //break;
      case "MultiBar":
        return <Multibarchart />;
      //break;
      case "Pie":
        return <PieChart />;
      //break;
      case "StackedBarH":
        return <StackedHorizontal />;
      // break;
      case "StackedBarV":
        return <StackedVertical />;
      case "StackedBarP":
        return <StackedBarPlot />;
      //break;
      case "Radar":
        return <RadarChart />;
      //break;
      case "Table":
        return <Table />;
      //break;
      default:
        ChartComponent = "";
        break;
    }
  }
  const formSelected = (chartType, index) => {
    let temparray = subInsightsData;
    ////console.log(component);
    ////console.log(index);
    temparray[index] = { ...temparray[index], ["chartType"]: chartType };
    ////console.log(temparray);
    setsubInsightsData([...temparray]);
    let temparraySubInsight = SubinsightFormData;
    temparraySubInsight[index] = [];
    ////console.log(temparray, "save data");
    setSubinsightFormData(temparraySubInsight);
  };
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setchartFormdata({ ...chartFormdata, [name]: value });
  };
  const AddSubInsights = () => {
    setsubInsightsData([...subInsightsData, { id: uuidv4() }]);
    setSubinsightFormData([...SubinsightFormData, []]);
  };
  const handleSubinsightSubmit = async () => {
    const containsEmptyArray = SubinsightFormData.filter(
      (subArr) => subArr.length === 0
    );
    if (containsEmptyArray.length == 0) {
      try {
        let res = await post(`/admin/insights/addChartData/`, {
          chartData: SubinsightFormData,
          id: ImportContextData.id,
          subInsight: "yes",
        });
        //console.log(res);

        toastr.success(res.success, "");
        setSubinsightError(null);
        history.push("/admin/chartimport/add", { TabNo: "3" });
      } catch (error) {
        //console.log(error);
        toastr.error("Not Able to update chart data");
      }
    } else {
      setSubinsightError("All Sub-Insight should be saved");
    }
  };
  const RemoveSubInsights = (index, id) => {
    let subInsightsDataTemp = subInsightsData;
    let SubinsightFormDataTemp = SubinsightFormData;
    if (subInsightsDataTemp.length == 1) {
      alert("There should be atleast one subinsight");
    } else {
      subInsightsDataTemp.splice(index, 1);
      SubinsightFormDataTemp.splice(index, 1);
      setsubInsightsData([...subInsightsDataTemp]);
      setSubinsightFormData([...SubinsightFormDataTemp]);
      delete ImportContextData[`subinsight.${id}.data`];
    }
  };

  return (
    <div key={1}>
      <Formik
        innerRef={formikRef}
        initialValues={{
          subInsights,
          chartType: chartState,
          chartData: [],
        }}
        validationSchema={Yup.object({
          subInsights: Yup.string().required("subInsights Name is required"),
        })}
        onSubmit={(values) => alert(JSON.stringify(values, null, 2))}
        render={({ values, touched, ...formik }) => (
          <>
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <CardTitle className="h5 mb-4">Chart</CardTitle>
                    <Form>
                      <div className="mb-4"></div>
                      <InputRadio
                        type="radio"
                        name={"subInsights"}
                        placeholder={"Sub-Insight"}
                        currentValue={subInsights}
                        onChange={handleInputChange}
                        onClick={handleInputChange}
                      />
                      {subInsights == "no" && (
                        <SelectField
                          type="select"
                          name={"chartType"}
                          placeholder={"Chart Type"}
                          //submited={submited}
                          options={chartTypes}
                          onMouseDown={(e) => {
                            handleInputChange(e);
                          }}
                          // onClick={(e) => {
                          //   handleInputChange(e);
                          //   formik.setFieldTouched(e.target.name, true);
                          //   setChartState(e.target.value)
                          // }}
                          onInput={(e) => {
                            setChartState(e.target.value);
                            formik.setFieldTouched(e.target.name, true);
                            formik.setFieldValue(e.target.name, e.target.value);
                          }}
                          value={chartState}
                          // onChange={(e)=>{
                          //   setChartState(e.target.value)
                          // }}
                        />
                      )}
                      {subInsights == "yes" && (
                        <a
                          onClick={AddSubInsights}
                          className="float class"
                          data-placement="top"
                          title="Add Sub-Insights"
                        >
                          <i className="fa fa-plus my-float"></i>
                        </a>
                      )}
                      {/* {subInsights == "yes" && (
                        <Row className="mb-3">
                          <label
                            htmlFor="Import"
                            className="col-md-2 col-form-label form-label"
                          ></label>
                          <div className="col-md-2 ">
                            <button
                              className="btn btn-success"
                              type="button"
                              onClick={AddSubInsights}
                            >
                              Add Sub-Insights
                            </button>
                          </div>
                        </Row>
                      )} */}
                      {subInsights == "no" &&
                        (chartState== "" ||
                          chartState == "Select") && (
                          <FormAligner>
                            <button
                              className="btn btn-success col-md-2 me-2"
                              disabled={true}
                              type="button"
                            >
                              Save and Next
                            </button>
                            <Link to="/admin/chartimport">Cancel</Link>
                          </FormAligner>
                        )}
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      />
        <div style={{"display": subInsights == "no" && chartState ? "block ": "none"}}>
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>{chartState ? getValue(chartState) : null}</CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <div  style={{"display": subInsights == "yes" ? "block ": "none"}}>
          {subInsightsData.map((item, index) => {
            return (
              <>
                <div key={item.id}>
                  <Row>
                    <Col lg={12}>
                      <Card>
                        <CardBody>
                          <CardTitle className="h1 mb-0">
                            <Row className="mb-0">
                              <span htmlFor="Import" className="col-sm-10">
                                {`Sub-Insight #${index + 1} `}
                              </span>

                              <span className="col-sm-1">
                                <button
                                  className="btn subInsave btn-success"
                                  onClick={() => {
                                    setsaveIndex(index);
                                    setClickIndicator(clickIndicator + 1);
                                  }}
                                  type="button"
                                >
                                   {Array.isArray(SubinsightFormData[index])
                                    ? "Save"
                                    : "Saved"}
                                </button>
                              </span>
                              <span className="col-sm-1">
                                <button
                                  className="btn subIndel btn-danger"
                                  onClick={() => {
                                    RemoveSubInsights(index, item.id);
                                  }}
                                  id={`removeSubInsight${index}`}
                                >
                                  <i className="fa fa-trash"></i>
                                </button>
                              </span>
                            </Row>
                          </CardTitle>
                          <SubinsightComponent
                            index={index}
                            save={saveIndex}
                            saveStatusData={
                              Array.isArray(SubinsightFormData[index])
                                ? false
                                : true
                            }
                            clickIndicator={clickIndicator}
                            subInsightsData={subInsightsData}
                            formSelected={formSelected}
                            component={item}
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </div>
              </>
            );
          })}
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <FormAligner>
                    {SubinsightError != null ? (
                      <Alert color="danger">{SubinsightError}</Alert>
                    ) : null}
                  </FormAligner>
                  <FormAligner>
                    <button
                      className="btn btn-success col-md-2 me-2"
                      type="button"
                      onClick={() => {
                        handleSubinsightSubmit();
                      }}
                    >
                      Save and Next
                    </button>
                    <Link to="/admin/chartimport">Cancel</Link>
                  </FormAligner>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    </div>
  );
};

export default ChartTab;
