import React, { useMemo, useState, useContext } from "react";
import ImportContext from "helpers/Context/ImportContext";
import BlockUi from "react-block-ui";
import { Row, Card, CardBody, Col, CardTitle } from "reactstrap";
import { useHistory } from "react-router-dom";
import { post, get } from "helpers/api_helper";
import PreviewDropdown from "../../../Admin/components/PreviewDropdown";
import PreviewSubinsightDropDown from "../../../Admin/components/PreviewSubinsightDropDown";
import SubinsightDropdown from "pages/Admin/components/SubinsightDropdown";
import "./Preview.css";
import MarkdownPreviews from "./MarkdownPreviews";
import StaticContent from "../StaticContent/StaticContent";
import BarplotGroup from "pages/Chart/BarplotGroup";
import DonutChart from "pages/Chart/DonutChart";
import BarplotGroupHorizontal from "pages/Chart/BarplotGroupHorizontal";
import StackedBarchart from "pages/Chart/StackedBarChart";
import ReturnRefundDetailsChart from "pages/Chart/ReturnRefundDetails";
import ClickToDoorOverView from "pages/Chart/ClickToDoorOverView";
import PostPurchaseInsights from "pages/Chart/PostPurchaseInsights";
import PaymentVisibility from "pages/Chart/PaymentVisibility";
import ReturnObjectiveInsight from "pages/Chart/ReturnObjectiveInsights";
import ReturnObjectiveVertical from "pages/Chart/ReturnObjectiveVertical";

import DonutChartContainer from "./PreviewChartComponents/DonutChartContainer";
// const fileheader = ["Participant ID",
// "Entry Date",
// "Retailer",
// "Does the retailer promote free returns anywhere on the site *outside o…",
// "Region",
// "Subdivision"];
// const listData = ["check1", "check2", "check3", "check4", "check5", "check6"];
// const SubinsightList = [
//   "Insight1",
//   "Insight2",
//   "Insight3",
//   "Insight4",
//   "Insight5",
//   "Insight6",
// ];
// const FilterData = {
//   Retailer: ["check1", "check2", "check3", "check4", "check5", "check6"],
//   PID: ["check12", "check22", "check32", "check42", "check52", "check63"],
// };

export default function Preview({ valuess }) {
  const history = useHistory();
  const [selectedFilter, setselectedFilter] = useState({});
  const [GroupByValue, setGroupByValue] = useState({ GroupBy: [] });
  const [selectedSubinsight, setselectedSubinsight] = useState(null);
  const [selectedChart, setselectedChart] = useState(null);
  const [loadingStatus, setloadingStatus] = useState(false);
  const [Busy, setBusy] = useState(false);
  //console.log(GroupByValue);
  // console.log(fileheader);
  // DB data
  const [staticData, SetstaticData] = useState(false);
  const [cmsState, setcmsState] = useState(null);
  const [colorCodeState, setcolorCodeState] = useState(null);
  const [colorValueState, setcolorValueState] = useState(null);
  const [dataState, setdataState] = useState(null);
  const [filterState, setfilterState] = useState(null);
  const [filterValueState, setfilterValueState] = useState(null);
  const [labelState, setlabelState] = useState(null);
  const [chartTypeState, setChartTypeState] = useState(null);
  const [chartLableState, setChartLableState] = useState(null);
  const [headerState, setHeaderState] = useState(null);
  const [mappedState, setMappedState] = useState(null);
  const [lableValueState, setlableValueState] = useState(null);
  const [groupByState, setGroupByState] = useState(null);
  const [symbolState, setSymbolState] = useState(null);
  const [insightError, setInsightError] = useState(false);
  // DB data END
  // chart Type selector
  function getcomponent(chart, chartdata) {
    console.log(chart, chartdata);
    //let selectedBrands = GroupByValue?.GroupBy ? GroupByValue?.GroupBy : [];
    // const filteredData = Object.keys(chartdata)
    //   .filter((brand) => selectedBrands.includes(brand))
    //   .reduce((obj, brand) => {
    //     obj[brand] = chartdata[brand];
    //     return obj;
    //   }, {});
    switch (chart) {
      case "GroupBarChartHorizontal":
        return (
          <BarplotGroupHorizontal
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "GroupBarChartVertical":
        return (
          <BarplotGroup
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "HeatMap":
        return (
          <PaymentVisibility
            HeatData={chartdata}
            colorCode={colorCodeState}
            PaymentType={colorValueState}
            paymentSymbols={symbolState?.data}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "MultiBarChart":
        return (
          <PostPurchaseInsights
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "pieChart":
        return (
          <DonutChartContainer
            chartData={chartdata}
            colorCode={colorCodeState}
            colorValue={colorValueState}
            cms={cmsState}
            lableValue={lableValueState}
            id={valuess}
          />
        );
      case "StackedHorizontal":
        return (
          <ReturnObjectiveInsight
            BarplatData={chartdata}
            colorCode={colorCodeState}
            sideBar={colorValueState}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "StackedVertical":
        return (
          <ReturnObjectiveVertical
            BarplatData={chartdata}
            colorCode={colorCodeState}
            sideBar={colorValueState}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "StackedBarPlot":
        return (
          <ClickToDoorOverView
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
            iddata={valuess}
          />
        );
      case "Table":
        return (
          <ReturnRefundDetailsChart
            BarplatData={chartdata}
            headerProp={headerState}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
            groupBy= {groupByState}
          />
        );
      default:
        return "";
    }
  }

  const appliedInsightCallback = (selectedOption, label) => {
    setselectedFilter({ ...selectedFilter, [label]: selectedOption });
  };
  const appliedGroupByvalueCallback = (selectedOption, label) => {
    //console.log(selectedOption);
    setGroupByValue({ GroupBy: selectedOption });
  };
  const appliedSubinsightCallback = (selectedOption, label) => {
    setselectedSubinsight(selectedOption);
  };
  const getPreviewData = async (importId) => {
    try {
      //64114cefa5e13be499273684
      //6413d184e25644dcceb7bfed -- GroupBarChartHorizontal
      let res = await post(`/insights/chartPreview/${valuess}`, {
        objective: selectedSubinsight != null ? `${selectedSubinsight}` : "",
        filter: selectedFilter,
      });
      console.log(res);
      const {
        cms,
        colorCode,
        colorValue,
        chartType,
        data,
        filter,
        filterValues,
        label,
        labels,
        lableValue,
        header,
        mapped,
        groupBy,
        symbol,
      } = res;
      setcmsState(cms);
      setcolorCodeState(colorCode);
      setcolorValueState(colorValue);
      setChartTypeState(chartType);
      if(chartType != 'Table'){
        let sortdata = {...data}; 
      for (let key in sortdata) {
        let sortedObj = {};
        let sortedKeys = Object.keys(sortdata[key]).sort(function(a, b) {
          return colorValue.indexOf(a) - colorValue.indexOf(b);
        });
        sortedKeys.forEach(function(k) {
          sortedObj[k] = sortdata[key][k];
        });
        sortdata[key] = sortedObj;
      }
      setdataState(sortdata);
      }
      else{
        setdataState(data);
      }
      setlabelState(label);
      setfilterState(filter);
      setfilterValueState(filterValues);
      setlableValueState(lableValue);
      setHeaderState(header);
      setMappedState(mapped);
      setGroupByState(groupBy);
      setSymbolState(symbol);
      if (labels.length != 0 && chartLableState == null) {
        setChartLableState(labels);
      }
      setloadingStatus(false);
      setInsightError(true)
      //toastr.success(res.success, "");
    } catch (error) {
      setInsightError(false)
      setloadingStatus(false);
      console.log(error);
      //toastr.error("Populate Error");
    }
  };
  useMemo(() => {
    if (valuess) {
      setloadingStatus(true);
      getPreviewData(valuess);
    }
  }, [selectedSubinsight, selectedFilter]);
  return (
    <React.Fragment>
      <div className="graph-data">
        <div className="row">
          <div className="col-12 col-md-8 col-lg-9 col-xl-10">
            <div className="graph-data-acu" style={{ minHeight: "470px" }}>
              <h3>{cmsState?.InsightTitle}</h3>
              <div className="pulse-wrapper">
                <span className="pulse-dot"></span>
                <span>{cmsState?.livePulseText ? cmsState.livePulseText : ""}</span>
              </div>
              <div className="two-select-boxes">
                <div className="dropdown-flex">
                  {filterValueState != null &&
                    headerState != null &&
                    mappedState != null &&
                    Object.keys(filterValueState).map((key, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            order: Object.keys(filterValueState).length - index,
                          }}
                        >
                          <PreviewDropdown
                            dropdownList={filterValueState[key]}
                            fileheader={headerState}
                            type={
                              mappedState?.[headerState.indexOf(key)]
                                ?.dataType == "date"
                                ? "radio"
                                : "checkbox"
                            }
                            label={key}
                            appliedInsightCallback={appliedInsightCallback}
                            // appliedInsightCallback={headerState.indexOf(key) == groupByState ? appliedGroupByvalueCallback :appliedInsightCallback}
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
              {chartLableState != null && (
                <div className="two-select-boxes sub-insight-select">
                  <div
                    className="inner-content-donut col-5"
                    style={{ padding: "0px 0px 0px 45px" }}
                  >
                    {cmsState?.SubinsightDescription
                      ? cmsState?.SubinsightDescription
                      : "Please select a subjective insight question/statement from the drop down list to the right."}
                  </div>
                  <SubinsightDropdown
                    dropdownList={
                      chartLableState != null ? chartLableState : []
                    }
                    label={"Sub-Insight"}
                    appliedInsightCallback={appliedSubinsightCallback}
                  />
                </div>
              )}

              <div className="graph-images" style={{ marginTop: "30px" }}>
                {/* {console.log(
                    dataState,
                    colorCodeState,
                    colorValueState,
                    cmsState,
                    chartTypeState
                  )} */}
                {lableValueState != null &&
                  lableValueState?.["Insight Description"] && (
                    <div className="two-select-boxes">
                      <span className="inner-content-donut package">
                        <h4>{`${lableValueState?.["Insight Description"]}`}</h4>
                      </span>
                    </div>
                  )}
                {/* {console.log(!loadingStatus ,
                  dataState != null ,
                  colorCodeState != null ,
                  colorValueState != null ,
                  cmsState != null ,
                  chartTypeState != null ,
                  groupByState != null ,
                  selectedFilter?.[groupByState]?.length)} */}
                {!loadingStatus &&
                dataState != null &&
                colorCodeState != null &&
                colorValueState != null &&
                cmsState != null &&
                chartTypeState != null &&
                groupByState != null &&
                symbolState != null &&
                lableValueState != null &&
                selectedFilter?.[groupByState]?.length ? (
                  getcomponent(chartTypeState, dataState)
                ) : (
                  <div className="two-select-boxes">
                    <span className="inner-content-donut package">
                      {insightError ? (
                        chartTypeState == null || chartTypeState == undefined ? <h4>Please select at least one sub-insight to view data.</h4>:
                        <h4>{`Please select at least one ${headerState[groupByState]} to view data.`}</h4>
                      ) : (
                        <h4>Data not available</h4>
                      )}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* {dataState != null && (
                  <button
                    className="btn btn-success col-md-2 me-2"
                    disabled={Busy}
                    style={{ "margin-left": "62px" }}
                    // onClick={() => {
                    //   setSubmited(true);
                    //   formik.validateForm().then((data) => {
                    //     console.log(data);
                    //   });
                    //   formik.handleSubmit();
                    // }}
                    onClick={() => {
                      if (valuess) {
                        changeStatus(valuess);
                      } else {
                        toastr.error("Data not available");
                      }
                    }}
                    type="button"
                  >
                    Publish
                  </button>
                )} */}
          {/* <Link to="/admin/chartimport">Cancel</Link> */}

          <div className="col-12 col-md-4 col-lg-3 col-xl-2 bg-gray">
            <div className="graph-data-insights">
              <div className="dropdown">
                <button
                  className="dropdown-toggle"
                  type="button"
                  id="data-graph"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={() => {
                    SetstaticData(!staticData);
                  }}
                >
                  <span>{staticData ? "Close" : "Data Insight"}</span>
                  <svg
                    width="40"
                    height="41"
                    viewBox="0 0 40 41"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="20" cy="20.3364" r="20" fill="#2C2C2C" />
                    <path
                      d="M18.36 25.2395V27.4595H20.58V25.2395H18.36ZM15.1 17.6395H16.8C16.8 17.1728 16.8533 16.7395 16.96 16.3395C17.0667 15.9395 17.2333 15.5928 17.46 15.2995C17.7 15.0061 17.9933 14.7728 18.34 14.5995C18.6867 14.4261 19.1 14.3395 19.58 14.3395C20.3 14.3395 20.8667 14.5461 21.28 14.9595C21.7067 15.3728 21.9467 15.9461 22 16.6795C22.0267 17.1728 21.9667 17.5928 21.82 17.9395C21.6733 18.2861 21.4733 18.6061 21.22 18.8995C20.98 19.1795 20.7133 19.4528 20.42 19.7195C20.14 19.9728 19.8667 20.2528 19.6 20.5595C19.3467 20.8661 19.1267 21.2195 18.94 21.6195C18.7667 22.0061 18.6667 22.4795 18.64 23.0395V23.9795H20.34V23.1995C20.34 22.8528 20.3867 22.5461 20.48 22.2795C20.5867 21.9995 20.72 21.7461 20.88 21.5195C21.0533 21.2795 21.24 21.0595 21.44 20.8595C21.6533 20.6461 21.8667 20.4395 22.08 20.2395C22.2933 20.0261 22.5 19.8061 22.7 19.5795C22.9133 19.3528 23.1 19.1061 23.26 18.8395C23.42 18.5728 23.5467 18.2795 23.64 17.9595C23.7467 17.6261 23.8 17.2528 23.8 16.8395C23.8 16.1995 23.6933 15.6328 23.48 15.1395C23.28 14.6461 22.9933 14.2328 22.62 13.8995C22.2467 13.5528 21.8 13.2928 21.28 13.1195C20.7733 12.9328 20.2067 12.8395 19.58 12.8395C18.8867 12.8395 18.26 12.9595 17.7 13.1995C17.14 13.4395 16.6667 13.7795 16.28 14.2195C15.8933 14.6461 15.5933 15.1528 15.38 15.7395C15.18 16.3261 15.0867 16.9595 15.1 17.6395Z"
                      fill="white"
                    />
                  </svg>
                </button>
                <ul className="dropdown-menu" aria-labelledby="data-graph">
                  <li>
                    <a className="dropdown-item" href={void 0}>
                      More details
                    </a>
                  </li>
                </ul>
              </div>
              {/* {console.log(
                "labelvalstate",
                lableValueState?.["Legend Description"]
              )} */}

              <div className="graph-line">
                {chartTypeState != "HeatMap" &&
                chartTypeState != "MultiBarChart" &&
                chartTypeState != "Table" ? (
                  <ul>
                    {colorValueState != null
                      ? colorValueState.map((value, index) => (
                          <li key={index}>
                            <span
                              className="color-blue"
                              style={{
                                background: colorCodeState[index].unique,
                              }}
                            ></span>
                            {colorValueState[index]}
                          </li>
                        ))
                      : lableValueState?.["Legend Description"]}
                  </ul>
                ) : (
                  <div className="align-center">
                    <MarkdownPreviews markdown={lableValueState?.["Legend Description"]} />
                    {chartTypeState == "HeatMap" && (
                      <div
                        className="heatbar"
                        style={{
                          background: `linear-gradient(to bottom, ${colorCodeState} 0%, #ffffff 100%)`,
                        }}
                      >
                        <p className="align-legend-text">- 100%</p>
                        <p className="align-legend-text mt-data">- 90%</p>
                        <p className="align-legend-text mt-data">- 80%</p>
                        <p className="align-legend-text mt-data">- 70%</p>
                        <p className="align-legend-text mt-data">- 60%</p>
                        <p className="align-legend-text mt-data">- 50%</p>
                        <p className="align-legend-text mt-data">- 40%</p>
                        <p className="align-legend-text mt-data">- 30%</p>
                        <p className="align-legend-text mt-data">- 20%</p>
                        <p className="align-legend-text mt-data">- 10%</p>
                        <p className="align-legend-text mt-data">- 0%</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {staticData &&  cmsState != null  ? <StaticContent cms={cmsState} ></StaticContent> : null}
        </div>
      </div>
    </React.Fragment>
  );
}
