import { takeEvery, fork, put, all, call } from "redux-saga/effects";

// Login Redux States
import { FORGET_PASSWORD, RESET_PASSWORD, MEMBER_RESET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError } from "./actions";

import * as url from "../../../helpers/url_helper";

//Include Both Helper File with needed methods
// import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postJwtReqResetPwd,
  postJwtResetPwd,
  postJwtCreatePwd
} from "../../../helpers/backend_helper";

// const fireBaseBackend = getFirebaseBackend();

//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(
        postJwtReqResetPwd,
        process.env.REACT_APP_BACKEND_URL + url.POST_REQUEST_RESET_PASSWORD,
        {
          email: user.email,
        }
      );
      console.log(response, "responseforgotsaga");
      yield put(userForgetPasswordSuccess(response.success));
    }
  } catch (error) {
    let errorMsg = "";
    if (error.response.data.error) {
      errorMsg = error.response.data.error;
    }
    console.log(error.response.data.error, "errrr");
    yield put(userForgetPasswordError(errorMsg));
  }
}

function* resetUser({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH == "jwt") {
      const response = yield call(
        postJwtResetPwd,
        process.env.REACT_APP_BACKEND_URL +
          url.POST_RESET_PASSWORD +
          "/" +
          user[1],
        user[0]
      );
      yield put(userForgetPasswordSuccess(response));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

function* resetMember({ payload: { user, history } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH == "jwt") {
      const response = yield call(
        postJwtCreatePwd,
        process.env.REACT_APP_BACKEND_URL +
          url.POST_CREATE_PASSWORD +
          "/" +
          user[1],
        user[0]
      );
      yield put(userForgetPasswordSuccess(response));
    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
  yield takeEvery(RESET_PASSWORD, resetUser);
  yield takeEvery(MEMBER_RESET_PASSWORD, resetMember);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
