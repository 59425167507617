import React, { useState, useRef, useEffect } from "react";
import { Row } from "reactstrap";
import { useField, ErrorMessage } from "formik";

const MultiCheckSelect = ({CoustomValueData,
  ...props
}) => {
  const [field, meta] = useField(props);
  const [dropdown, setDropdown] = useState(false);
  const insightDropDown = useRef();
  useOnClickOutside(insightDropDown, () => setDropdown(false));
  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }
  return (
    <React.Fragment>
      <div className="d-flex dropdown-tab-content mt-0 mb-0">
        <div className="dropdown" ref={insightDropDown} {...props}>
          <button
            className={`btn dropdown-toggle mt-0 mb-0 multiselect ${meta.touched && meta.error && ("invalid-border")}`}
            type="button"
            id="dropdownMenuButton1"
            onClick={() => {
              setDropdown(!dropdown);
            }}
          >
            {"Custom Value"}
            <i
              className={
                dropdown
                  ? "dropdown-toggle-icon rotate"
                  : "dropdown-toggle-icon"
              }
            />
          </button>
          <ul
            className={
              dropdown
                ? "multiselect-container dropdown-menu show"
                : "multiselect-container dropdown-menu"
            }
          >
            {props.children}
          </ul>
        </div>
      </div>
    </React.Fragment>
  );
};
export default MultiCheckSelect;
