import React, { useMemo, useState, useEffect } from "react"
import {
  Container,
  Form,
  Modal,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap"
import {get, del, post} from "../../helpers/api_helper";
import toastr from "toastr";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

const CMSSetting = () => {
    const [onSubmit, setonSubmit] = useState(false);
    const [data, setdata] = useState("");
    //Toastr
    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeOut");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);
    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            liveText: '',
        },
        validationSchema: Yup.object({
            liveText: Yup.string().required("The live text field is required"),
        }),
        onSubmit: async (values) => {
            setonSubmit(false);
            let toSave = {
              "id" : data,
              "text": values.liveText
            }
            try {
              let res = await post('admin/brands/addLiveText', toSave); 
              toastr.success(res.success,"");
            } catch(error) {
              setonSubmit(false);
              if (error.response) {
                 toastr.error(error,"Request failed.");
               }
            }  
          },
    })
    document.title="CMS Settings - Acumen";
    
    const fetchData = async function fetchData() {
        try{
          const response = await get("admin/brands/getLiveTextID");
          setdata(response.brandLiveText._id);
        } catch(err)         {
            console.log(err)
        }
    }
    
    useEffect(() => {
        fetchData();
    }, []);

    return(
        <React.Fragment>
            <div className="inner-content">
                <div className="pr-page-title">
                    <h2>CMS Settings</h2>
                </div>
                <form onSubmit={validation.handleSubmit} className="formadd">
                    <Row className="mb-3">
                        <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label form-label"
                        >
                        Live Text
                        </label>
                        <div className="col-md-10">
                        <Input
                            className="form-control"
                            type="text"
                            id="name"
                            name="liveText"
                            placeholder="Enter Live Text"
                            value={validation.values.liveText || ""}
                            onChange={validation.handleChange}
                            onInput={validation.handleBlur}
                            onBlur={validation.handleBlur}
                            invalid={
                            validation.touched.liveText &&
                            validation.errors.liveText
                                ? true
                                : false
                            }
                        />
                        {validation.touched.liveText &&
                            validation.errors.liveText ? (
                            <FormFeedback type="invalid">
                                {validation.errors.liveText}
                            </FormFeedback>
                            ) : null}
                        </div>
                    </Row>
                    <Row className="mt-5">
                        <label
                        htmlFor="example-text-input"
                        className="col-md-2 col-form-label form-label"
                        >
                        </label>
                        <div className="col-md-8">
                        <button 
                            type="submit" 
                            className="btn btn-success col-md-4"
                            disabled={onSubmit ? true : false}
                            >
                            {onSubmit ? "Loading..." : "Submit"}
                        </button>              
                        
                        </div>
                     </Row>
                </form>
            </div>
        </React.Fragment>
    );
}

export default CMSSetting;