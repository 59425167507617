import React, { useMemo, useState, useContext } from "react";
import ImportContext from "helpers/Context/ImportContext";
import BlockUi from "react-block-ui";
import { Row, Card, CardBody, Col, CardTitle } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { post, get, put } from "helpers/api_helper";
import PreviewDropdown from "../../../components/PreviewDropdown";
import PreviewSubinsightDropDown from "../../../components/PreviewSubinsightDropDown";
import FormAligner from "pages/Admin/components/FormAligner";
import "./Preview.css";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ClickToDoorOverView from "pages/Chart/ClickToDoorOverView";
import BarplotGroup from "pages/Chart/BarplotGroup";
import DonutChart from "pages/Chart/DonutChart";
import BarplotGroupHorizontal from "pages/Chart/BarplotGroupHorizontal";
import StackedBarchart from "pages/Chart/StackedBarChart";
import ReturnRefundDetailsChart from "pages/Chart/ReturnRefundDetails";
import PostPurchaseInsights from "pages/Chart/PostPurchaseInsights";
import PaymentVisibility from "pages/Chart/PaymentVisibility";
import ReturnObjectiveInsight from "pages/Chart/ReturnObjectiveInsights";
import ReturnObjectiveVertical from "pages/Chart/ReturnObjectiveVertical";
import DonutChartContainer from "./PreviewChartComponents/DonutChartContainer";
// const fileheader = ["Participant ID",
// "Entry Date",
// "Retailer",
// "Does the retailer promote free returns anywhere on the site *outside o…",
// "Region",
// "Subdivision"];
// const listData = ["check1", "check2", "check3", "check4", "check5", "check6"];
// const SubinsightList = [
//   "Insight1",
//   "Insight2",
//   "Insight3",
//   "Insight4",
//   "Insight5",
//   "Insight6",
// ];
// const FilterData = {
//   Retailer: ["check1", "check2", "check3", "check4", "check5", "check6"],
//   PID: ["check12", "check22", "check32", "check42", "check52", "check63"],
// };

export default function Preview() {
  const history = useHistory();
  const [selectedFilter, setselectedFilter] = useState({});
  const [GroupByValue, setGroupByValue] = useState({ GroupBy: [] });
  const [selectedSubinsight, setselectedSubinsight] = useState(null);
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [selectedChart, setselectedChart] = useState(null);
  const [loadingStatus, setloadingStatus] = useState(false);
  const [Busy, setBusy] = useState(false);
  //console.log(GroupByValue);
  // console.log(fileheader);
  // DB data
  const [cmsState, setcmsState] = useState(null);
  const [colorCodeState, setcolorCodeState] = useState(null);
  const [colorValueState, setcolorValueState] = useState(null);
  const [dataState, setdataState] = useState(null);
  const [filterState, setfilterState] = useState(null);
  const [filterValueState, setfilterValueState] = useState(null);
  const [labelState, setlabelState] = useState(null);
  const [chartTypeState, setChartTypeState] = useState(null);
  const [chartLableState, setChartLableState] = useState(null);
  const [headerState, setHeaderState] = useState(null);
  const [mappedState, setMappedState] = useState(null);
  const [lableValueState, setlableValueState] = useState(null);
  const [groupByState, setGroupByState] = useState(null);
  const [symbolState, setSymbolState] = useState(null);
  const [insightError, setInsightError] = useState(false);
  // DB data END
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr
  // chart Type selector
  function getcomponent(chart, chartdata) {
    //console.log(chart, chartdata);
    //let selectedBrands = GroupByValue?.GroupBy ? GroupByValue?.GroupBy : [];
    // const filteredData = Object.keys(chartdata)
    //   .filter((brand) => selectedBrands.includes(brand))
    //   .reduce((obj, brand) => {
    //     obj[brand] = chartdata[brand];
    //     return obj;
    //   }, {});
    switch (chart) {
      case "GroupBarChartHorizontal":
        return (
          <BarplotGroupHorizontal
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
          />
        );
      case "GroupBarChartVertical":
        return (
          <BarplotGroup
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
          />
        );
      case "HeatMap":
        return (
          <PaymentVisibility
            HeatData={chartdata}
            colorCode={colorCodeState}
            PaymentType={colorValueState}
            paymentSymbols={symbolState?.data}
            lableValue={lableValueState}
          />
        );
      case "MultiBarChart":
        return (
          <PostPurchaseInsights
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
          />
        );
      case "pieChart":
        return (
          <DonutChartContainer
            chartData={chartdata}
            colorCode={colorCodeState}
            colorValue={colorValueState}
            cms={cmsState}
            lableValue={lableValueState}
          />
        );
      case "StackedHorizontal":
        return (
          <ReturnObjectiveInsight
            BarplatData={chartdata}
            colorCode={colorCodeState}
            sideBar={colorValueState}
            lableValue={lableValueState}
          />
        );
      case "StackedVertical":
        return (
          <ReturnObjectiveVertical
            BarplatData={chartdata}
            colorCode={colorCodeState}
            sideBar={colorValueState}
            lableValue={lableValueState}
          />
        );
      case "StackedBarPlot":
        return (
          <ClickToDoorOverView
            BarplatData={chartdata}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
          />
        );
      case "Table":
        return (
          <ReturnRefundDetailsChart
            BarplatData={chartdata}
            headerProp={headerState}
            colorCode={colorCodeState}
            uniquedata={colorValueState}
            lableValue={lableValueState}
            groupBy= {groupByState}
          />
        );
      default:
        return "";
    }
  }

  const appliedInsightCallback = (selectedOption, label) => {
    setselectedFilter({ ...selectedFilter, [label]: selectedOption });
  };
  const appliedGroupByvalueCallback = (selectedOption, label) => {
    //console.log(selectedOption);
    setGroupByValue({ GroupBy: selectedOption });
  };
  const appliedSubinsightCallback = (selectedOption, label) => {
    setselectedSubinsight(selectedOption);
  };
  const changeStatus = async (val) => {
    let willChange = true;
    let msg = "Do you want to publish the insight file?";
    let subtext =
      "Once changed, user and organization members will have access to insight";

    willChange = await swal({
      title: msg,
      text: subtext,
      icon: "info",
      buttons: true,
      dangerMode: false,
    });

    if (willChange) {
      try {
        setBusy(true);
        let response = await put(
          "/admin/insights/change_status/Publish/" + val
        );
        if (response.success) {
          toastr.success(response.success, "");
          history.push("/admin/chartimport");
        } else {
          toastr.error(response.error, "");
        }
      } catch (e) {
        console.log(e);
        setBusy(false);
        toastr.error("Something went wrong", "");
      }
    }
  };
  const getPreviewData = async (importId) => {
    try {
      //64114cefa5e13be499273684
      //6413d184e25644dcceb7bfed -- GroupBarChartHorizontal
      let res = await post(
        `/admin/insights/chartPreview/${ImportContextData?.id}`,
        {
          objective: selectedSubinsight != null ? `${selectedSubinsight}` : "",
          filter: selectedFilter,
        }
      );
      console.log(res);
      const {
        cms,
        colorCode,
        colorValue,
        chartType,
        data,
        filter,
        filterValues,
        label,
        labels,
        lableValue,
        header,
        mapped,
        groupBy,
        symbol,
      } = res;
      setcmsState(cms);
      setcolorCodeState(colorCode);
      setcolorValueState(colorValue);
      setChartTypeState(chartType);
      if(chartType != 'Table'){
        let sortdata = {...data}; 
      for (let key in sortdata) {
        let sortedObj = {};
        let sortedKeys = Object.keys(sortdata[key]).sort(function(a, b) {
          return colorValue.indexOf(a) - colorValue.indexOf(b);
        });
        sortedKeys.forEach(function(k) {
          sortedObj[k] = sortdata[key][k];
        });
        sortdata[key] = sortedObj;
      }
      setdataState(sortdata);
      }
      else{
        setdataState(data);
      }
      setlabelState(label);
      setfilterState(filter);
      setfilterValueState(filterValues);
      setlableValueState(lableValue);
      setHeaderState(header);
      setMappedState(mapped);
      setGroupByState(groupBy);
      setSymbolState(symbol);
      if (labels.length != 0 && chartLableState == null) {
        setChartLableState(labels);
      }
      setloadingStatus(false);
      setInsightError(true);
      //toastr.success(res.success, "");
    } catch (error) {
      setChartTypeState(null);
      setInsightError(false)
      setloadingStatus(false);
      console.log(error);
      //toastr.error("Populate Error");
    }
  };
  useMemo(() => {
    if (ImportContextData?.id) {
      setloadingStatus(true);
      getPreviewData(ImportContextData?.id);
    }
  }, [selectedSubinsight, selectedFilter]);
  return (
    <BlockUi tag="div">
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              {/* <CardTitle className="mb-4">Preview</CardTitle> */}
              <React.Fragment>
                <h3>{cmsState?.InsightTitle}</h3>
                <div className="but-pub">
                  {dataState != null && (
                    <button
                      id="button-pre"
                      className="btn btn-success col-md-1 me-2"
                      disabled={Busy}
                      // style={{ "margin-left": "62px" }}
                      // onClick={() => {
                      //   setSubmited(true);
                      //   formik.validateForm().then((data) => {
                      //     console.log(data);
                      //   });
                      //   formik.handleSubmit();
                      // }}
                      onClick={() => {
                        if (ImportContextData?.id) {
                          changeStatus(ImportContextData?.id);
                        } else {
                          toastr.error("Data not available");
                        }
                      }}
                      type="button"
                    >
                      <i
                        className="fa fa-regular fa-file-export"
                        style={{ "font-size": "20px" }}
                      ></i>
                    </button>
                  )}
                </div>
                <div className="dropdown-flex">
                  {filterValueState != null &&
                    headerState != null &&
                    mappedState != null &&
                    Object.keys(filterValueState).map((key, index) => {
                      return (
                        <div 
                          key={index}
                          style={{
                            order: Object.keys(filterValueState).length - index,
                          }}
                        >
                          <PreviewDropdown
                            dropdownList={filterValueState[key]}
                            fileheader={headerState}
                            type={
                              mappedState?.[headerState.indexOf(key)]
                                ?.dataType == "date"
                                ? "radio"
                                : "checkbox"
                            }
                            label={key}
                            appliedInsightCallback={appliedInsightCallback}
                            // appliedInsightCallback={headerState.indexOf(key) == groupByState ? appliedGroupByvalueCallback :appliedInsightCallback}
                          />
                        </div>
                      );
                    })}
                </div>
                {chartLableState != null && (
                  <div className="two-select-boxes">
                    <div
                      className="inner-content-donut col-5"
                      style={{ padding: "0px 0px 0px 45px" }}
                    >
                      {cmsState?.SubinsightDescription
                        ? cmsState?.SubinsightDescription
                        : "Please select a subjective insight question/statement from the drop down list to the right."}
                    </div>
                    <PreviewSubinsightDropDown
                      dropdownList={
                        chartLableState != null ? chartLableState : []
                      }
                      label={"Sub-Insight"}
                      appliedInsightCallback={appliedSubinsightCallback}
                    />
                  </div>
                )}
                <div className="graph-images" style={{ marginTop: "30px" }}>
                  {/* {console.log(
                    dataState,
                    colorCodeState,
                    colorValueState,
                    cmsState,
                    chartTypeState
                  )} */}
                  {lableValueState != null &&
                    lableValueState?.["Insight Description"] && (
                      <div className="two-select-boxes">
                        <span className="inner-content-donut package">
                          <h4>
                            {`${lableValueState?.["Insight Description"]}`}
                          </h4>
                        </span>
                      </div>
                    )}

                  {!loadingStatus &&
                  dataState != null &&
                  colorCodeState != null &&
                  colorValueState != null &&
                  headerState != null &&
                  cmsState != null &&
                  chartTypeState != null &&
                  groupByState != null &&
                  lableValueState != null &&
                  symbolState != null &&
                  (selectedFilter?.[groupByState]?.length  || ImportContextData?.FileType) ? (
                    getcomponent(chartTypeState, dataState)
                  ) : (
                    <div className="two-select-boxes">
                      <span className="inner-content-donut package">
                      {insightError ? (
                        chartTypeState == null || chartTypeState == undefined ? <h4>Please select at least one sub-insight to view data.</h4>:
                        <h4>{`Please select at least one ${headerState[groupByState]} to view data.`}</h4>
                      ) : (
                        <h4>Data not available</h4>
                      )}
                      </span>
                    </div>
                  )}
                </div>
                <div></div>
                <div className="but-pub"></div>
                {/* <Link to="/admin/chartimport">Cancel</Link> */}
              </React.Fragment>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </BlockUi>
  );
}
