import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  Table,
  UncontrolledTooltip,
  Badge,
  Spinner
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useParams, useHistory } from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { deleteUser, editProfile, resetProfileFlag } from "../../store/actions";

import bellImg from "../../assets/images/bell.svg";
import userImg from "../../assets/images/user-img.png";
import downImg from "../../assets/images/down-arrow.svg";
import plusImg from "../../assets/images/plus.svg";
import editImg from "../../assets/images/edit.svg";
import LogoutModel from "components/Common/LogoutModel";

import { get, post, del } from "../../helpers/api_helper";
import UserContext from "../../helpers/AuthType/session_helper";
import swal from 'sweetalert';

const Members = () => {

  //meta title
  document.title = "Members - Acumen";
  const history = useHistory();
  const {id} = useParams();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [userSessionData,setuserSessionData] = useContext(UserContext);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_title, setmodal_title] = useState(false);
  const [modal_delete, setmodal_delete] = useState(false);
  const [modal_delete_val, setmodal_delete_val] = useState(null);
  const [userId, setuserId] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setemail] = useState('');
  const [organization, setorganization] = useState('');
  const [disabled, setdisabled] = useState(false);
  const [isBusy, setBusy] = useState(true);
  const [parent, setparent] = useState([]);
  const [child, setchild] = useState([]);
  const [onSubmit, setonSubmit] = useState(false);
  const [profileImage, setprofileImage] = useState(user.profilePic);
  const inputFile = useRef(null) 
  const [uploadedImage, setuploadedImage, stateRef] = useState("");
  const [logoutstate, setlogoutstate] = useState(false);
  const [emailSent, setemailSent] = useState(false);
  // const [allValid, setallValid] = useState(false);
  const handleCloseClick = () =>{
    setlogoutstate(false)
  }
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);


  let positionClass = "toast-top-right";

  toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  //End toastr


  const fetchData = async function fetchData() {
      const response = await get("user/get_members");
      let p = []
      let c = []
      Object.entries(response.data).map(function([key, value]) {
        value.isParent == true
          ? p = value
          : c.push(value)
      });
      setparent(p);
      setchild(c);
    }

  useEffect(() => {
      try{        
        fetchData();        
      } catch(err)  {
        setBusy(false);
      }
  }, []);

  const logout = () => {
    history.push("logout");
  };

  function add_modal() {
    setmodal_title("Add Member");
    /*setfirstName("");
    setlastName("");
    setemail("");
    setorganization("");*/
    setdisabled(false);
    setmodal_small(!modal_small);
    removeBodyCss();
  }


  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      lastName: Yup.string().required("Please enter last name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      email: Yup.string()
        .required("Please enter business email")
        .email("Must be a valid email.")
        .max(100)
        .matches(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,'Must be a valid email'),
    }),
    onSubmit: async (values) => {
      setonSubmit(true);
      try {
        let res = await post("user/add_member", values);
        // toastr.success(res.success,"");        
        setonSubmit(false);
        fetchData();
        setemailSent(true);
      } catch(error) {        
        setonSubmit(false);
        // setemailSent(false);
        if (error.response) {
           // Request made and server responded
           validation.setErrors(error.response.data.errors);
         } else if (error.request) {
           // The request was made but no response was received
           toastr.error(error,"Request failed");
         } else {
           // Something happened in setting up the request that triggered an Error
           toastr.error(error,"Request failed.");
         } 
      }
    },
  });
  
  // const handleOnChange = (event: FormEvent) => {
  //   let { touched, errors } = validation; //Look for error and touched to find the form is valid
  //   setallValid(Object.keys(touched).length == 3 && Object.keys(errors).length == 0);
  // };

  const removeUser = async (val) => {
    // let willDelete = await swal({
    //   title: "Do you want to remove this member?",
    //   text: "Once deleted, He will not be able to use this application!",
    //   icon: "warning",
    //   buttons: true,
    //   dangerMode: true,
    // })
    // if (willDelete) {
    //   let response = await del("user/remove_member/"+val);
    //   if(response.success) {
    //     toastr.success(response.success,"");
    //     fetchData();
    //   } else {
    //     toastr.error(response.error,"");
    //   }
    // }
    setmodal_delete_val(val);
    setmodal_delete(!modal_delete);
    removeBodyCss();

  }
  const deleteUser = async(val) => {
    let response = await del("user/remove_member/"+val);
      if(response.success) {
        toastr.success(response.success,"");
        setmodal_delete(false);
        fetchData();
      } else {
        toastr.error(response.error,"");
        setmodal_delete(!false);
      }
  };
  const statusRender = (value, key) => {
    let emailInvitationSent =  value['emailInvitationSent'] != "" && value['emailInvitationSent'] != undefined;
    let joinedOn = value['joinedOn'] != "" && value['joinedOn'] != undefined;
    
    let recordStatus = <p>Added User</p>
    
    if(emailInvitationSent && joinedOn && value.status == "Inactive") {
      recordStatus = <p className="pending-user">Inactive user</p>
    } else if (emailInvitationSent && !joinedOn) {
      recordStatus = <p className="pending-user">Pending user</p>
    } else if (!emailInvitationSent && !joinedOn) {
      recordStatus = <p className="pending-user">Pending user</p>
    }
    return (
      <>
      {recordStatus}
      </>
    )
  }

  const actionRender = (value, key) => {
    return (
        <a
          href={void(0)}          
          onClick={() => removeUser(value['id'])}
        >
        <span className="del">Delete</span>
          
        </a>

    )
  }

  const saveImage = async function(img) {
    const formData = new FormData();
    setonSubmit(true);
    formData.append('profilePic', img);
    formData.append('firstName', user.firstName);
    formData.append('lastName', user.lastName);
    try {
      let res = await post("user/update_profile", formData);
      setonSubmit(false);
      if (res.success) {
        Object.keys(res).map(function (key) {
          if (key != "success") {
            user[key] = res[key];
          }
        });
        localStorage.setItem("authUser", JSON.stringify(user));
        setuserSessionData(JSON.parse(window.localStorage.getItem("authUser")));
        toastr.success("Profile details updated successfully.", "");
      }
    } catch(error) {
      setonSubmit(false);
      if (error.response) {
        // Request made and server responded
        toastr.error(error, error.response.data.errors.profilePic);
      } else if (error.request) {
        // The request was made but no response was received
        toastr.error(error, "Request failed");
      } else {
        console.log(error);
        // Something happened in setting up the request that triggered an Error
        toastr.error(error, "Request failed.");
      }
    }
  }

  const onImageChange = async (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      var maxfilesize = 1024 * 1024 * 3,  // 1 Mb
          filesize    = img.size;
      let ext = img.name.split(".").pop()
      
      if(!['jpg', 'jpeg', 'png', 'gif', 'JPG', 'JPEG', 'PNG', 'GIF'].includes(ext)) {
        toastr.error("Please upload image files only", 'Error')
        event.target.value = ''
      }else if ( filesize > maxfilesize ){
        toastr.error("File size is large. Please upload image less than 3MB")
        event.target.value = ''
      }
      else {
        setuploadedImage(img);
        setprofileImage(URL.createObjectURL(img));
        await saveImage(img);
        const user = JSON.parse(localStorage.getItem("authUser"));
        setprofileImage(user.profilePic)
      }
    }
  };


  return (
    <React.Fragment>
        <div className="inner-content settings">
          <div className="pr-page-title setting">
                <h2>Manage Users</h2>              
          </div>
            <div className="update-profile2">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3 col-xl-2">
                  <div className="edit-pic">
                    <img src={profileImage} className="edit-user-img" alt="" />
                    <input 
                          tabIndex={1}
                          id="myInput"
                           type="file"
                           ref={inputFile}
                           style={{display: 'none'}}
                           onChange = {onImageChange}
                           accept="image/*"
                        />
                        {validation.touched.profilePic &&
                        validation.errors.profilePic ? (
                          toastr.error(validation.errors.profilePic, "Error")                          
                        ) : null}
                      <a href={void(0)} 
                  onClick={()=>{inputFile.current.click()}}
                  ><img src={user.profilePic.substr(-11) == "profile.png" ? plusImg : editImg } className="edit-icon" alt="" /></a>
                  </div>
                </div>
                <div className="col-12 col-md-9 col-lg-9 col-xl-10">
                  <div className="other-info text-wrap">
                    {userSessionData.subscriptionStatus == "Active" ? 
                      <p style={{margin:"0px"}}>Admin</p>
                    : "" }
                    <h4>{user.firstName} {user.lastName}</h4>
                    <p>{user.email}</p>
                    <div className="accounts-btn">
                    <button className="acc-btn" tabIndex={2} onClick={()=>setlogoutstate(true)} >
                      Log out
                    </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="manage-users-details" style={{marginTop:"30px"}}>
              <div className="row heading-details align-items-center">
                <div className="col-md-9">
                  <p>Added accounts must use same domain.</p>
                </div>
                <div className="col-md-3">
                  <button type="button" 
                    className={`appbtn button-with-bg${parent.seatCount > 0 && child.length < parent.seatCount ? "" : " disabled_bg"}`}
                    onClick={() => {
                          add_modal();
                          validation.resetForm({});
                          setemailSent(false);
                    }}
                    data-title={parent.seatCount > 0 && child.length < parent.seatCount  ? "Add member" : "Please increase seat count to add members"}
                    data-toggle="modal"
                    data-target=".bs-example-modal-sm"
                    disabled={parent.seatCount > 0 && child.length < parent.seatCount ? "": "disabled"}
                    >
                    Add a user
                  </button>
                </div>
              </div>
              <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col" width="25%">Name</th>
                        <th scope="col" width="35%">Email Address</th>
                        <th scope="col" width="20%">Account Type</th>
                        <th scope="col" className="text-center" width="20%"></th>

                      </tr>
                    </thead>
                    <tbody>
                    <tr key={999}>
                        <td>{parent.firstName} {parent.lastName} (You)</td>
                        <td>{parent.email}</td>
                        <td>
                          Main Account
                        </td>
                        <td>
                          
                        </td>
                      </tr>
                    {child.length > 0 && Object.entries(child).map(([key, value]) => (
                          <tr key={key}>
                            <td>{value.firstName} {value.lastName}</td>
                            <td>{value.email}</td>
                            <td>
                              {statusRender(value, key)}
                            </td>
                            <td className="text-center">
                              {actionRender(value, key)}
                            </td>
                          </tr>
                    ))}
                    </tbody>
                  </table>
                </div>
              <div className="seat-limit"style={{marginBottom:"40px"}}>
                <span>Available Seats for Current Plan: ({child.length} of {parent.seatCount}) In Use</span>
                <p>Wish to change current seat limit? Contact <a href="mailto:billings@acumen-data.com" className="mailto">billings@acumen-data.com</a></p>
              </div>
            </div>
        </div>
        <Modal
            isOpen={modal_small}
            toggle={() => {
              add_modal();
              validation.resetForm({});
            }}
            centered
          >
            <div style={{padding: "40px"}}>
            <div className="modal-body addPopup">
              <Form onSubmit={validation.handleSubmit}>
              <Row>
                <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="name">First Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      value={validation.values.firstName || ""}
                      onChange={validation.handleChange}
                      onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.firstName &&
                        validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.firstName &&
                      validation.errors.firstName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.firstName}
                        </FormFeedback>
                      ) : null}
                  </div>
                </Col>
                <Col lg={12}>
                  <div className="mb-3">
                    <label htmlFor="lastName">Last Name</label>
                    <Input
                      type="text"
                      className="form-control"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      value={validation.values.lastName || ""}
                      onChange={validation.handleChange}
                      onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.lastName &&
                        validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName &&
                      validation.errors.lastName ? (
                        <FormFeedback type="invalid">
                          {validation.errors.lastName}
                        </FormFeedback>
                      ) : null}
                  </div>
                </Col>
                </Row>
                <Row>
                  
                  <Col lg={12}>
                    <div className="mb-3">
                      <label htmlFor="email">Email Address</label>
                      <Input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                        value={validation.values.email || ""}
                        onChange={validation.handleChange}
                        onInput={validation.handleBlur}
                        onBlur={validation.handleBlur}
                        invalid={
                          validation.touched.email &&
                          validation.errors.email
                            ? true
                            : false
                        }
                      />
                      {validation.touched.email &&
                        validation.errors.email ? (
                          <FormFeedback type="invalid">
                            {validation.errors.email}
                          </FormFeedback>
                        ) : null}

                        {emailSent ? <p className="emailSent">Email invitation has been sent</p> : ""}
                    </div>
                  </Col>                  
                </Row>
                
                <Row>
                  {/* <Col lg={12}>
                    <div className="text-end">
                      <button 
                        type="submit" 
                        className="btn btn-success"
                        disabled={onSubmit ? true : false}
                        >
                          {onSubmit ? "Loading..." : "Submit"}
                      </button>
                    </div>
                  </Col> */}
                  {emailSent ?
                    <div className="logout-model manageuser-adduser-model" style={{ marginTop:"20px" }}>
                        <button type="button" data-bs-dismiss="modal" 
                        style={{ marginRight : "40px" }} onClick={() => setmodal_small(false)}>OK</button>
                    </div>
                    :
                  <div className="logout-model manageuser-adduser-model" style={{ marginTop:"20px" }}>
                      <button type="button" data-bs-dismiss="modal" style={{ marginRight : "40px" }} onClick={() => setmodal_small(false)}>Cancel</button>
                      <button type="submit" className={(validation.isValid && validation.dirty) ? "" : "addUserinValid"} disabled={onSubmit || !(validation.isValid && validation.dirty)} >Add a User</button>
                  </div>}
                </Row>
            </Form>
            </div>
            </div>
          </Modal>
          <Modal
      isOpen={modal_delete}
      toggle={() => {
        removeUser(modal_delete_val);
      }}
      centered
      className="setting-modal"
      id="logout_profile"
    >
    <div className="modal-body update-profile">
      <p>Are you sure you want to delete this user’s seat access?</p>
      <div className="logout-model logout-request-model">
        <button type="button" data-bs-dismiss="modal" 
        onClick={() => {
            setmodal_delete(false);
          }}
        >Cancel</button>
        <button type="button" onClick={() => {deleteUser(modal_delete_val)}} >Yes, delete user</button>
      </div>
    </div>
    </Modal>
    <LogoutModel show={logoutstate} closeClick = {handleCloseClick} ></LogoutModel>          
    </React.Fragment>
  );
};

export default withRouter(Members);
