import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";

const PostPurchaseInsights = ({
  BarplatData,
  colorCode = [],
  uniquedata = [],
  iddata,
  lableValue,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  // console.log(BarplatData);
  // console.log(lableValue, "lableValue");
  const id = "multibar" + iddata;

  try {
    const ChartColor = colorCode.map((obj) => obj.unique);
    if (Object.keys(BarplatData) == 0) {
      throw "empty data";
    }
    let color = {};
    ChartColor.map((obj, index) => {
      color[uniquedata[index]] = obj;
    });
    //console.log(color);

    useEffect(() => {
      const margin = { top: 20, right: 30, bottom: 40, left: 110 };
      const width = 1200 - margin.left - margin.right;
      const height = 800 - margin.top - margin.bottom;

      d3.selectAll(`#${id} > *`).remove();

      const svg = d3
        .select(`#${id}`)
        .append("svg")
        /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
        .attr("viewBox", `0 0 1215 800`)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // List of subgroups = header of the csv files = soil condition here
      const subgroups = [
        "group",
        "ri_cp_avg",
        "rn_ri_avg",
        "rp_rfr_avg",
        "rp_rw_avg",
        "rw_cp_avg",
      ];

      // List of groups = species here = value of the first column called group -> I show them on the X axis
      const groups = Object.keys(BarplatData).map((d) => {
        return d;
      });

      //console.log(groups);

      // Add Y axis
      const y = d3.scaleBand().domain(groups).range([0, height]).padding([0.2]);

      // Add X axis
      const x = d3.scaleBand().domain(uniquedata).range([0, width]);

      svg
        .append("g")
        .attr("transform", "translate(0,0)")
        .attr("class", "xaxis")
        .call(d3.axisTop(x).tickSize(1))
        .select("path")
        .attr("d", "M 0 0 L 0 0 L 0 0")
        .selectAll("text")
        .each(function (d) {
          var arr = d.split(" ");
          //console.log(arr);
          if (arr.length > 1) {
            d3.select(this).text("");
            if (arr != undefined) {
              for (let i = 0; i < arr.length; i++) {
                d3.select(this)
                  .append("tspan")
                  .text(arr[i])
                  .attr("dy", i ? "1.2em" : 0)
                  .attr("x", 9)
                  .attr("text-anchor", "middle")
                  .attr("class", "tspan" + i);
              }
            }
          }
        })
        .attr("dy", "1.2em");

      const xAxisGrid = d3
        .axisBottom(x)
        .tickSize(-height)
        .tickFormat("")
        .ticks(5);
      const tooltip = d3
        .select(`#${id}`)
        .append("div")
        .attr("class", "tooltip-barplot")
        .style("visibility", "hidden");
      svg
        .append("g")
        .attr("class", "multi")
        .call(d3.axisLeft(y).tickSize(0).tickPadding([8])) //.tickSizeOuter(0)
        .selectAll("text")
        .each(function (d) {
          var arr = d.split(" ");
          if (arr.length > 1) {
            d3.select(this).text("");
            if (arr != undefined) {
              for (let i = 0; i < arr.length; i++) {
                d3.select(this)
                  .append("tspan")
                  .text(arr[i])
                  .attr("dy", i ? "1.2em" : 0)
                  .attr("x", -35)
                  .attr("text-anchor", "middle")
                  .attr("class", "tspan" + i);
              }
            }
          }
        })
        .style("font-size", "14px");

      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxisGrid)
        .selectAll("line")
        .attr("stroke", "#A9A9A9")
        .attr("stroke-width", 1)
        .attr("opacity", 0);

      /*svg.append('g')
        .attr('class', 'y axis')*/

      // color palette = one color per subgroup

      const dataArray = [];

      Object.keys(BarplatData).map((d) => {
        Object.entries(BarplatData[d]).map((r) => {
          if (!["_id", "retailer"].includes(r[0])) {
            dataArray.push({ retailer: d, type: r[0], value: r[1] });
          }
        });
      });

      //console.log(dataArray);

      // Show the bars
      const d3Svg = svg
        .selectAll()
        .data(dataArray, function (d) {
          return d.retailer + ":" + d.type;
        })
        .enter();

      d3Svg
        .append("rect")
        .attr("x", function (d) {
          return x(d.type) + 30;
        })
        .attr("y", function (d) {
          return y(d.retailer) + y.bandwidth() / 2 - y.bandwidth() / 4 / 2;
        })
        .attr("width", function (d) {
          return d.value * 1.5;
        })
        .attr("height", y.bandwidth() / 4)
        .style("fill", function (d) {
          return color[d.type];
        })
        .on("mouseover", (event, d) => {
          //console.log(d3.select(this).attr("x"));
          //console.log(d);
          tooltip
            .transition()
            .delay(0)
            .duration(50)
            .style("visibility", "visible")
            .style("opacity", "1");
          //console.log(d);
          tooltip
            .html(
              `<div class="tooltipDesign">
                    <h5 class="tt-head" style="text-align: initial">
                      ${d.type}
                    </h5>
                    <ul>
                      <li style="margin:0px">
                      <p><span 
                            class="color1-dot"
                            style="background:${color[d.type]}"
                            ></span> 
                          <span class="tooltip_text">${d.retailer}</span>
                      </p>
                        <label>${d.value}${
                lableValue?.["Unit"] ? lableValue?.["Unit"] : "%"
              }</label>
                      </li>
                     </ul>
                    </div>`
            )
            .style("left", event.layerX + 10 + "px")
            .style("top", event.layerY - 100 + "px");
        })
        .on("mousemove", function (event) {
          return tooltip
            .style("left", event.layerX + 10 + "px")
            .style("top", event.layerY - 100 + "px");
        })
        .on("mouseout", () =>
          tooltip
            .transition()
            .style("visibility", "hidden")
            .style("opacity", "0")
        );

      d3Svg
        .append("text")
        .attr("class", "text")
        .attr("x", (d) => x(d.type) + 40 + d.value * 1.5)
        .attr("y", (d) => y(d.retailer) + y.bandwidth() / 2 + 5)
        .text(
          (d) =>
            parseFloat(d.value) +
            `${lableValue?.["Unit"] ? lableValue?.["Unit"] : "%"}`
        )
        .style("fill", "black")
        .style("font-size", "12px")
        .style("line-height", "14px")
        .style("text-align", "right")
        .style("color", "#000000");

      if (lableValue?.["lable x"]) {
        svg
          .append("text")
          .attr(
            "transform",
            "translate(" + width / 2 + " ," + (height + 40) + ")"
          )
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .style("font-size", "15px")
          .text(`${lableValue?.["lable x"]}`);
      }
      if (lableValue?.["lable Y"]) {
        svg
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", -margin.left)
          .attr("x", 0 - height / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .text(`${lableValue?.["lable Y"]}`);
      }
      /*svg.append("text")
        .attr("transform", "translate(" + (width / 2) + " ," + (height + margin.bottom) + ")")
        .style("text-anchor", "middle")
        .text("Average Time (Days)")   */
      //console.log(xSubgroup.bandwidth());
      //document.querySelector("#multibar > svg > g > g:nth-child(1) > path").setAttribute("d", "M 0 0 L 0 0 L 0 0");//M 0 0 L 15 0 L 1 0
      try {
        var string = document
          .querySelector("g.multi > g:nth-child(2)")
          .getAttribute("transform");
        var t = string
          .substring(string.indexOf("(") + 1, string.indexOf(")"))
          .split(",");
        var last = document
          .querySelector("g.multi > g:last-child")
          .getAttribute("transform");
        var lt = last
          .substring(last.indexOf("(") + 1, last.indexOf(")"))
          .split(",");

        document
          .querySelector("g.multi > path")
          .setAttribute("d", "M-5," + t[1] + "H0.5V" + lt[1] + "H-5");
        document
          .querySelector("g.multi > g:nth-child(2) > line")
          .setAttribute("x2", "20");
        document
          .querySelector("g.multi > g:last-child > line")
          .setAttribute("x2", "20");
      } catch (e) {
        console.error(e);
      }
    }, [BarplatData]);
  } catch (err) {
    console.error(err);
    return (
      <ErrorMessage
        Message={"Not able to generate graph for the retrieved data"}
      />
    );
  }

  return <div id={id}></div>;
};

export default PostPurchaseInsights;
