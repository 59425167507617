import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";

const BarplotGroupHorizontal = ({
  chartHeightData,
  BarplatData,
  uniquedata = [],
  colorCode = [],
  lableValue,
}) => {
  //const d3Chart = useRef();
  // console.log(colorCode);
  // console.log(BarplatData);
  // console.log(uniquedata);
  const [errorMessage, setErrorMessage] = useState(null);
  try {
    const Datavalue = [];
    const chartHeight = chartHeightData ? chartHeightData : 600;
    const firstInnerObj = Object.values(BarplatData)[0];
    const uniquebar = Object.keys(firstInnerObj);
    const myArr = [];
    const colordata = colorCode.map((obj) => obj.unique);

    for (const key in BarplatData) {
      const obj = BarplatData[key];
      for (const prop in obj) {
        Datavalue.push(obj[prop]);
      }
    }
    // console.log(uniquebar);
    // console.log(myArr);
    // const loop = Object.keys(BarplatData).map((d) => {
    //   Datavalue.push(
    //     ...[
    //       BarplatData[d]["ri_cp_avg"],
    //       BarplatData[d]["rn_ri_avg"],
    //       BarplatData[d]["rp_rfr_avg"],
    //       BarplatData[d]["rp_rw_avg"],
    //       BarplatData[d]["rw_cp_avg"],
    //     ]
    //   );
    // });
    //console.log(Datavalue);
    //console.log(BarplatData);
    const customDecimal = (e) => {
      return d3.format(".0f")(e);
    };

    //console.log(d3.min(Datavalue));

    useEffect(() => {
      const margin = { top: 20, right: 30, bottom: 65, left: 110 };
      const width = 1200 - margin.left - margin.right;
      const height = chartHeight - margin.top - margin.bottom;

      d3.selectAll("#barplotH > *").remove();

      const svg = d3
        .select("#barplotH")
        .append("svg")
        /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
        .attr("viewBox", `0 0 1200 ${chartHeight}`)
        .style("width", "100%")
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // List of subgroups = header of the csv files = soil condition here
      const subgroups = [...uniquebar];

      // List of groups = species here = value of the first column called group -> I show them on the X axis
      const groups = Object.keys(BarplatData).map((d) => {
        return d;
      });

      //console.log(groups);

      // Add Y axis
      const y = d3.scaleBand().domain(groups).range([0, height]).padding([0.2]);

      // Add X axis
      const x = d3
        .scaleLinear()
        .domain([0, Math.ceil(d3.max(Datavalue))])
        .range([0, width]);

      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(
          d3
            .axisBottom(x)
            .tickSize(1)
            .ticks(
              d3.max(Datavalue) <= 10
                ? d3.max(Datavalue)
                : d3.max(Datavalue) / 2
            )
        ) //.tickFormat(customDecimal)
        .selectAll("text")
        .attr("y", 20)
        .attr("x", 0)
        .attr("dy", ".5em")
        .style("font-size", "14px")
        .style("color", "#000000")
        .style("line-height", "14px");

      const xAxisGrid = d3
        .axisBottom(x)
        .tickSize(-height)
        .tickFormat("")
        .ticks(
          d3.max(Datavalue) <= 10 ? d3.max(Datavalue) : d3.max(Datavalue) / 2
        );

      svg
        .append("g")
        .call(d3.axisLeft(y).tickSize(0).tickPadding([10]))
        .selectAll("text")
        .each(function (d) {
          var arr = d.split(" ");
          //console.log(arr);
          if (arr.length > 1) {
            d3.select(this).text("");
            if (arr != undefined) {
              for (let i = 0; i < arr.length; i++) {
                d3.select(this)
                  .append("tspan")
                  .text(arr[i])
                  .attr("dy", i ? "1.2em" : 0)
                  .attr("x", -35)
                  .attr("text-anchor", "middle")
                  .attr("class", "tspan" + i)
                  .style("font-size", "15px")
                  .style("color", "#000000");
              }
            }
          }
        })
        .style("font-size", "15px")
        .style("color", "#000000");

      svg
        .append("g")
        .attr("class", "x axis-grid")
        .attr("transform", "translate(0," + height + ")")
        .call(xAxisGrid)
        .selectAll("line")
        .attr("stroke", "#A9A9A9")
        .attr("stroke-width", 1)
        .attr("opacity", 1);

      /*svg.append('g')
        .attr('class', 'y axis')*/

      // Another scale for subgroup position?
      const xSubgroup = d3
        .scaleBand()
        .domain(subgroups)
        .range([0, y.bandwidth()])
        .padding([0.2]);

      // color palette = one color per subgroup
      const color = d3.scaleOrdinal().domain(subgroups).range(colordata);

      //console.log(BarplatData['Saks']['oc_s_avg']);

      const tooltip = d3
        .select("#barplotH")
        .append("div")
        .attr("class", "tooltip-barplot")
        .style("visibility", "hidden");

      // Show the bars
      svg
        .append("g")
        .selectAll("g")
        // Enter in data = loop group per group
        .data(Object.keys(BarplatData))
        .enter()
        .append("g")
        .attr("transform", (d) => "translate(0," + y(d) + ")")
        .selectAll("rect")
        .data((d) =>
          subgroups.map(function (key) {
            //console.log(d);
            return {
              key: key,
              value: key == "group" ? d : BarplatData[d][key],
              parent: d,
            };
          })
        )
        .enter()
        .append("rect")
        .attr("x", 0) //
        .attr("y", (d) => xSubgroup(d.key))
        .attr("width", (d) => x(d.value)) //(d)=>{console.log(x(d.value));x(d.value)}
        .attr("height", xSubgroup.bandwidth() + xSubgroup.bandwidth() / 4.25) //
        .attr("fill", (d) => color(d.key))
        .on("mouseover", (event, d) => {
          //console.log(d3.select(this).attr("x"));
          //console.log(d)
          tooltip
            .transition()
            .delay(0)
            .duration(50)
            .ease(d3.easeLinear)
            .style("visibility", "visible")
            .style("opacity", "1");
          //console.log(d);
          const parentNode = BarplatData[d.parent];
          //console.log(d.data,"data");
          //console.log(parentNode);
          //tooltip.html(`<div class="graph-tooltip">${tooltipHtml}</div>`)
          tooltip
            .html(
              `<div class="tooltipDesign" style="pointer-events: none">
                        <h5 class="tt-head">
                          ${d.parent}
                        </h5>
                        <ul>
                        ${Object.keys(parentNode)
                          .map(function (key, index) {
                            return `<li>
                          <p><span 
                          class="color1-dot"
                          style="background:${color(key)}"
                          ></span> 
                          <span class="tooltip_text">${key}</span>
                          </p>
                          <label>${parentNode[key]} ${lableValue?.['Unit'] ? lableValue?.['Unit'] : ""}</label>
                        </li>`;
                          })
                          .join("")}
                        </ul>
                      </div>`
            )
            .style("left", event.layerX + 22 + "px")
            .style("top", event.layerY - 128 + "px");
        })
        .on("mousemove", function (event) {
          return tooltip
            .style("left", event.layerX + 22 + "px")
            .style("top", event.layerY - 128 + "px");
        })
        .on("mouseout", () =>
          tooltip
            .transition()
            .style("visibility", "hidden")
            .style("opacity", "0")
        );
      // .on('mouseout', () => tooltip.transition().duration(200).style('opacity', 0))

      if (lableValue?.["lable x"]) {
        svg
          .append("text")
          .attr(
            "transform",
            "translate(" + width / 2 + " ," + (height + 50) + ")"
          )
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .style("font-size", "15px")
          .text(`${lableValue?.["lable x"]}`);
      }
      if (lableValue?.["lable Y"]) {
        svg
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", -margin.left)
          .attr("x", 0 - height / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .text(`${lableValue?.["lable Y"]}`);
      }
      //console.log(xSubgroup.bandwidth());
    }, [BarplatData]);
  } catch (err) {
    console.log(err);
    return (
      <ErrorMessage
        Message={"Not able to generate graph for the retrieved data"}
      />
    );
  }

  return <>{errorMessage == null && <div id="barplotH"></div>}</>;
};

export default BarplotGroupHorizontal;
