import { group } from "d3";
import React, { useMemo, useState, useEffect, useRef } from "react";
import ErrorMessage from "./components/ErrorMessage";
import "./ReturnRefundDetailsTable.css"
const ReturnRefundDetailsChart = ({ BarplatData ,colorCode= [],
  uniquedata = "",
  lableValue="",
  filter = null,
  groupBy = null,
  headerProp,
  sideHeader}) => {
    const [tableData, settableData] = useState([]);
    const [header, setheader] = useState([]);
    let headerdata = [...headerProp]
    console.log(BarplatData);
    try{
      if (BarplatData.length == 0) {
        throw "empty data";
      }
      useEffect(()=>{
        let dataCopy = [...BarplatData];
        let sliceArray = [];
        let sliceval= ""
        console.log(groupBy);
        console.log(sideHeader);
        dataCopy.forEach((element, key) => {
          sliceval = dataCopy[key].splice(groupBy, 1);
          sliceArray.push(sliceval);
        });
        let topHeader = sliceArray;
        let data = BarplatData;
        let mapedArray = [];
          // // Object.keys(data).map((dt,it)=>{
          // // Object.keys(data[dt]).map((dl,li)=>{
          // //     mapedArray[headerProp[li]] == undefined ? mapedArray[headerProp[li]] = [data[dt][dl]] : mapedArray[headerProp[li]].push(data[dt][dl])
          // // })
          // // });
          data.map((data,it)=>{
            mapedArray[`${topHeader[it]}__${it}`] = data;
          });
          settableData(mapedArray);
          headerdata.splice(groupBy, 1);
          setheader(headerdata);
    },[BarplatData]);
    }
    catch(err){
      console.error(err);
      return (
        <ErrorMessage
          Message={"Not able to generate graph for the retrieved data"}
        />
      );
    }
  
  
  

  return (
    <div className="graph-table">
                            <div className="graph-table-content returnrefund">
                              <table className="table table-striped">
                                <thead>
                                  <tr>
                                    <th></th>
                                    {header.map(h => {
                                        return (
                                            <th key={h}>{h}</th>
                                        );
                                    })}
                                  </tr>
                                </thead>
                                <tbody>
                                {Object.keys(tableData).map((dt,it)=>(
                                    <tr key = {it}>
                                    <th>{dt.split("__")[0]}</th>
                                    {tableData[dt].map((dt1,it1)=>{
                                        if(dt1 == 'Yes'){
                                            return(
                                                <td className="green-badge" key={`${it1}${dt}`}> <span> Yes </span> </td>
                                            )
                                        }
                                        else if(dt1 == "No"){
                                            return(
                                                <td className="red-badge" key={`${it1}${dt}`}> <span> No </span> </td>
                                            )
                                        }
                                        else{
                                            return(
                                            <td key={`${it1}${dt}`}>{dt1}</td>
                                            )
                                        }
                                    })}
                                  </tr>
                                ))}
                                  {/* <tr>
                                    <th>Free Returns Offered</th>
                                    <td className="green-badge"> <span> Yes </span> </td>
                                    <td className="green-badge"><span> Yes </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                  </tr>
                                  <tr>
                                    <th>Shipping, Import Costs NOT Included</th>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>X</td>
                                    <td>-</td>
                                    <td>X</td>
                                  </tr>
                                  <tr>
                                    <th>Return Fee Type</th>
                                    <td>-</td>
                                    <td>-</td>
                                    <td>Shipping Import</td>
                                    <td>-</td>
                                    <td>Shipping Duties</td>
                                    <td>-</td>
                                  </tr>
                                  <tr>
                                    <th>Option to Select Carrier (must be advertised)</th>
                                    <td className="red-badge"><span> No </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                    <td className="red-badge"><span> No </span></td>
                                    <td className="red-badge"><span> No </span></td>
                                    <td className="green-badge"><span> Yes </span></td>
                                  </tr>
                                  <tr>
                                    <th>Return Cutoff Time (DAYS)</th>
                                    <td>30</td>
                                    <td>14</td>
                                    <td>15</td>
                                    <td>30 for Online Purchases, 14 for In-Store</td>
                                    <td>28</td>
                                    <td>None</td>
                                  </tr> */}
                                </tbody>
                              </table>
                            </div>
                          </div>
  );
};

export default ReturnRefundDetailsChart;
