import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get, post } from "../../helpers/api_helper";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

// Reactstrap
import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap";

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import megamenuImg from "../../assets/images/megamenu-img.png";
// import mark from '../../assets/images/mark.svg';

// import images
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";

import logo from "../../assets/images/logo.svg";
import logoLightSvg from "../../assets/images/logo-light.svg";
import searchicon from "../../assets/images/Search Icon.svg";

//i18n
import { withTranslation } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";

const Header = (props) => {
  const [search, setsearch] = useState("");
  const [searchReasultState, setsearchReasultState] = useState([]);
  const [responseData,setResponseData] = useState([])
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const fetchData = async () => {
    try {
      const res = await get("insights/searchInsights");
      console.log("res", res);
      setResponseData(res);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  },[])
  useEffect(() => {

  },[])
  function searchInsights(keyword) {
    const searchResult = [];
    if (keyword.length > 0 && responseData) {
      for (const category in responseData) {
        const insights = responseData[category].filter((insight) =>
          insight.InsightTitle.toLowerCase().includes(keyword.toLowerCase())
        );
        insights.forEach((insight) => {
          searchResult.push({
            ...insight,
            tab: category,
          });
        });
      }
      if(searchResult.length > 5){
        setsearchReasultState(searchResult.slice(0,5));
      }
      else{
      setsearchReasultState(searchResult);
      }
    }
    else{
      console.log("hi")
      setsearchReasultState([]);
    }
  }
  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }
  const statusUpdatermid = (data) => {
    props.statusUpdater(data);
  };

  const clearSearch = () => {
    setsearchReasultState([]);
    setsearch("");
  };

  return (
    <React.Fragment>
      <div className="top-content-pf">
        <div className="search-box">
          <div className="app-search2 d-none d-lg-block">
            <div className="position-relative">
              <span className="deleteicon">
                <input
                  type="text"
                  placeholder={props.t("Search")}
                  className="form-control"
                  name="search"
                  onChange={(e) => {
                    setsearch(e.target.value);
                    searchInsights(e.target.value);
                  }}
                  value={search}
                />
                {search.length > 0 ? <span onClick={clearSearch}></span> : ""}
              </span>
              {/* <span><img src= {mark}/></span> */}
              <button type="button">
                <img src={searchicon} className="search" />
              </button>
              <div>
                {/* <ul>
                <li>sample</li>
              </ul> */}
                <ul
                  className={
                    searchReasultState.length != 0
                      ? "multiselect-container dropdown-menu show"
                      : "multiselect-container dropdown-menu"
                  }
                  style={{width: "100%"}}
                >
                  <li>
                    {searchReasultState.map((item, index) => (
                      <div key={index} className="pointer" onClick={() => {
                        setsearch("")       
                       setsearchReasultState([]);
                    }}>
                        <Link to={`/insights/${item.tab}/${item.insightId}`}>
                          <li className="show">
                          <label className="ms-2 pointer">{item.InsightTitle}</label>
                          </li>
                        </Link>
                      </div>
                    ))}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="pf-notification">
          <NotificationDropdown
            statusUpdatermid={statusUpdatermid}
            status={props.status}
          />
          <ProfileMenu />
        </div>
      </div>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, leftSideBarType } =
    state.Layout;
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
