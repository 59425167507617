import React, { useState, useEffect, useContext, useMemo } from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, 
         DropdownToggle, 
         DropdownMenu, 
         Row, 
         Col,
         Offcanvas,
         OffcanvasHeader,
         OffcanvasBody,
         Button, 
        } from "reactstrap";
import UserContext from "../../../helpers/AuthType/session_helper"
import SimpleBar from "simplebar-react";
import moment from "moment";
import {get, post} from "../../../helpers/api_helper";
import {useHistory} from "react-router-dom";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";
import nlogo from '../../../assets/images/notification-logo.png';
import notifiylogo from "assets/images/bell.svg";
import greendot from "assets/images/Ellipse 13.svg";
import notifiylogoinactive from "assets/images/bell.svg";
import notificationLogo from "assets/images/New Notification Bell.svg";
import notificationactive from "assets/images/New Notification Bell with Update.svg";

//i18n
import { withTranslation } from "react-i18next";

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [isRight, setIsRight] = useState(false);
  const [userSessionData, setuserSessionData] = useContext(UserContext);
  const [notificationActiveStatus, setnotificationActiveStatus] = useState();
  //const [loadingStatus, setLoadingStatus] = useState(true);
  const [notificationData,setnotificationData] = useState(null);
  const [jsxData,setjsxData] = useState(null);
  const history = useHistory();
  const toggleRightCanvas = () => {
       setIsRight(!isRight);
  };
  const user = JSON.parse(localStorage.getItem("authUser"));
  //const notificationStatusData = localStorage.getItem("NotificationStatus")
  const fetchData = async function fetchData() {
    try {
      if(user.role != 'admin'){
        const response = await get("/notifications");
        //console.log(response.data);
        setnotificationData(response.data) 
      }
  } catch(err) {
    console.log(err);
  }
  }
  const notification = [];
  const otherdate = [];
  const [customClass,setCustomClass] = useState('')
//   const sampledata = [
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "Dior",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-19T10:42:52.161+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elementum cras at cum.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-19T11:21:30.731+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-18T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-15T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-15T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-15T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-13T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-13T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-15T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
//     {
//       brand: [{
//         logo: "logo.png",
//         name: "sample2",
//         _id: "62ff745686e13d3c8bfaf2f9"
//       }],
//       createdAt: "2022-09-15T13:59:49.924+00:00",
//       id: "632335ed3b984c786c6ee4c8",
//       message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
//       readCount: 0,
//       totalUsers: 3,
//     },
// ]
// const notificationseperator = (sampledata) => {
//   Object.keys(sampledata).map((key) => {
//     //let momentdata = moment.utc(`${sampledata[key].createdAt}`).local().startOf('seconds').fromNow()
//     //let momentdata = moment(sampledata[key].createdAt).fromNow()// converts to hours ago and days ago.
//     //console.log(momentdata);
//     let yesterdaydate = (moment().subtract(1, 'days').format("MM/DD/YYYY")).toString();
//     let date = (moment(new Date(sampledata[key].createdAt)).format("MM/DD/YYYY")).toString();
//     let todaydate = (moment(new Date()).format("MM/DD/YYYY")).toString();
//     let otherDateKey = (moment(new Date(sampledata[key].createdAt)).format("DD MMM")).toString()
//     // momentdata.indexOf('hours') !== -1 || momentdata.indexOf('seconds') !== -1 || momentdata.indexOf('minute') !== -1 &&
//       if(date == todaydate){
//         notification['Today'] == undefined ? 
//         notification['Today'] = [sampledata[key]] 
//         : notification['Today'].push(sampledata[key]);
//       }
//       else if(date == yesterdaydate){
//         notification['Yesterday'] == undefined ? 
//         notification['Yesterday'] = [sampledata[key]] 
//         : notification['Yesterday'].push(sampledata[key]);
//       }
//       else{
//         if(otherdate[otherDateKey] == undefined){
//           otherdate[otherDateKey] = [sampledata[key]];
//           notification[otherDateKey] = otherdate[otherDateKey];
//         }
//         else{
//           otherdate[otherDateKey].push(sampledata[key]);
//         }
//       }
//       // else if(momentdata.substring(0,6) === '2 days'){ if 24hours calculated
//       // }
//     });
//     // console.log(today);
//     //   console.log(yesterday);
//     //   console.log(otherDays);
// }
// notificationseperator(sampledata);

const formateconverter = (time) => {
  let timedata = time;
  console.log(timedata);
  let fewseconds = timedata.replace('a few seconds','few sec')
  let a = fewseconds.replace('a ','1 ')
  let an = a.replace('an','1')
  let sec = an.replace('seconds','sec')
  let min = sec == '1 minute' ? sec.replace('minute','min') : sec.replace('minutes','min')
  return min;
}
const notificationStatusUpdator = (notificationData) => {
  Object.keys(notificationData).map((key) => {
    if(notificationData[key].readOn == null && !notificationActiveStatus){
      setnotificationActiveStatus(true);
      //setLoadingStatus(false)
    }
  })
  //setLoadingStatus(false)
}
const tabs =  ["commerce-experience","shipping-package","market-intel","brand-health","customer-service"]
const notificationseperator = (notificationData) => {
  //console.log(notificationData)
  Object.keys(notificationData).map((key) => {
    if(notificationData[key].readOn == null && !notificationActiveStatus){
      setnotificationActiveStatus(true);
      //setLoadingStatus(false)
    }
    let yesterdaydate = (moment().subtract(1, 'days').format("MM/DD/YYYY")).toString();
    let date = (moment(new Date(notificationData[key].createdAt)).format("MM/DD/YYYY")).toString();
    let todaydate = (moment(new Date()).format("MM/DD/YYYY")).toString();
    let otherDateKey = (moment(new Date(notificationData[key].createdAt)).format("DD MMM")).toString()
    if(date == todaydate){
      notification['Today'] == undefined ? 
      notification['Today'] = [notificationData[key]] 
      : notification['Today'].push(notificationData[key]);
    }
    else if(date == yesterdaydate){
      notification['Yesterday'] == undefined ? 
      notification['Yesterday'] = [notificationData[key]] 
      : notification['Yesterday'].push(notificationData[key]);
    }
    else{
      if(otherdate[otherDateKey] == undefined){
        otherdate[otherDateKey] = [notificationData[key]];
        notification[otherDateKey] = otherdate[otherDateKey];
      }
      else{
        otherdate[otherDateKey].push(notificationData[key]);
      }
    }
    });
    //console.log(notification);
    setjsxData(notification);
    //setLoadingStatus(false);
}
const insightRedirect = async(link,notificationId) => {
  setnotificationActiveStatus(false);
  notificationStatusUpdator(notificationData)
  try{
    let res = await post('/notifications/updatenotification', {id: notificationId});
    fetchData();
  }
  catch(err){
    console.log(err);
  }
    let newData = Object.assign(userSessionData, {notificationClick : (userSessionData.notificationClick ? userSessionData.notificationClick + 1 : 1 )});
    setuserSessionData(newData);
    toggleRightCanvas()
    history.push(link);
}
useEffect(() => {
  //console.log(notificationActiveStatus);
  if(notificationData != null){
    notificationseperator(notificationData);
  }
  }, [notificationData])
  useMemo(() => {
    //console.log(props.status);
    setnotificationActiveStatus(props.status)
    fetchData();
  }, [props.status])
  useEffect(() => {
    if(isRight == false){
      if(props.statusUpdatermid){
        props.statusUpdatermid(false)
      }
    }
  }, [isRight])

  useEffect(() => {
      setCustomClass('notification-drawer custom-notify');
      let timerFunc = setTimeout(() => {
        setCustomClass('notification-drawer');
      }, 5000);
      
      return () => clearTimeout(timerFunc);
  },[isRight])

  return (
    <React.Fragment>
      <Button
          className="btn header-item noti-icon pe-2"
          id="page-header-notifications-dropdown"
          style={{background:"unset"}}
          onClick={toggleRightCanvas}
        >
          {/* <i className="bx bx-bell" /> */}
          {/* <svg width="24" height="23" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" style={{marginRight:"0px"}}>
          <path d="M15 6.6665C15 5.34042 14.4732 4.06865 13.5355 3.13097C12.5979 2.19329 11.3261 1.6665 10 1.6665C8.67392 1.6665 7.40215 2.19329 6.46447 3.13097C5.52678 4.06865 5 5.34042 5 6.6665C5 12.4998 2.5 14.1665 2.5 14.1665H17.5C17.5 14.1665 15 12.4998 15 6.6665Z" stroke="#0F0F0F" strokeWidth="1.85" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M11.4413 17.5C11.2948 17.7526 11.0845 17.9622 10.8315 18.1079C10.5785 18.2537 10.2916 18.3304 9.99965 18.3304C9.70767 18.3304 9.42081 18.2537 9.16779 18.1079C8.91478 17.9622 8.70449 17.7526 8.55798 17.5" stroke="#0F0F0F" strokeWidth="1.85" strokeLinecap="round" strokeLinejoin="round"/>
          </svg> */}
          {/* <img src={notifiylogo} alt="Logo1" style={{width:'24px'}}/> */}
          { 
          //   (!loadingStatus ? 
          //   (notificationActiveStatus ? <img src={notifiylogo} alt="Logo1" style={{width:'24px'}}/> : <img src={notifiylogoinactive} alt="Logo2" style={{width:'24px'}}/>) 
          //   : (false ? <img src={notifiylogo} alt="Logo4" style={{width:'24px',backgroundColor:"red"}}/> : <img src={notifiylogoinactive} alt="Logo3" style={{width:'24px',backgroundColor:"blue"}}/>))
           }
          {/* <span className="badge rounded-pill"><div style={{width:"1px"}}></div></span> */}
          {notificationActiveStatus ? <img src={notificationactive} alt="Logo1" style={{width:'25px'}}/>
           : <img src={notificationLogo} alt="Logo1" style={{width:'25px'}}/>}
        </Button>

        <Offcanvas
            isOpen={isRight}
            direction="end"
            toggle={toggleRightCanvas}
            className={customClass}
          >
            <OffcanvasHeader toggle={toggleRightCanvas}>
                <h2>All Notifications</h2>
            </OffcanvasHeader>
            <OffcanvasBody>
               
               <div className="offcanvas-body">
                 {/* { today.length != 0 ? <div className="date">
                    <span>Today</span>
                  </div>
                 :null} */}
                  {/* {Object.keys(today).map((key, i) => (
                        <div className="info d-flex" key={`${today[key].createdAt}${i}`}>
                        <div className="logo-info">
                          <img className="edit-user-img"
                           style={{width:"40px", height:"40px"}}
                           src={`${process.env.REACT_APP_BACKEND_URL}uploads/${today[key].brand[0].logo}`}
                           alt="Logo" />
                          <span className="img-notify"></span>
                        </div>
                        <div className="desc-info">
                          <p>
                            {today[key].message}
                          </p>
                        </div>
                        <div className="time-info">
                          <span>{moment(today[key].createdAt).fromNow()}</span>
                        </div>
                      </div>
                    ))} */}
                  {/* <div className="info d-flex">
                    <div className="logo-info">
                      <img src={nlogo} alt="Logo" />
                      <span className="img-notify"></span>
                    </div>
                    <div className="desc-info">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br /> Ut elementum cras at cum. 
                      </p>
                    </div>
                    <div className="time-info">
                      <span>3 min</span>
                    </div>
                  </div>
                  <div className="info d-flex">
                    <div className="logo-info">
                      <img src={nlogo} alt="Logo" />
                      <span className="img-notify"></span>
                    </div>
                    <div className="desc-info">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      </p>
                    </div>
                    <div className="time-info">
                      <span>1 hour</span>
                    </div>
                  </div> */}
                  {/* {yesterday.length != 0 ? 
                  <div className="date">
                    <span>Yesterday</span>
                  </div>
                  :null} */}
                  {/* {Object.keys(yesterday).map((key, i) => (
                        <div className="info d-flex" key={`${yesterday[key].createdAt}${i}`}>
                        <div className="logo-info">
                          <img className="edit-user-img"
                           style={{width:"40px", height:"40px"}}
                           src={`${process.env.REACT_APP_BACKEND_URL}uploads/${yesterday[key].brand[0].logo}`}
                           alt="Logo" />
                          <span className="img-notify"></span>
                        </div>
                        <div className="desc-info">
                          <p>
                            {yesterday[key].message}
                          </p>
                        </div>
                        <div className="time-info">
                          <span>{moment(yesterday[key].createdAt).format("HH:mm")}</span>
                        </div>
                      </div>
                    ))} */}
                  {/* <div className="info d-flex">
                    <div className="logo-info">
                      <img src={nlogo} alt="Logo" />
                      <span className="img-notify"></span>
                    </div>
                    <div className="desc-info">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br /> Ut elementum cras at cum. 
                      </p>
                    </div>
                    <div className="time-info">
                      <span>13:45</span>
                    </div>
                  </div>
                  <div className="info d-flex">
                    <div className="logo-info">
                      <img src={nlogo} alt="Logo" />
                      <span className="img-notify"></span>
                    </div>
                    <div className="desc-info">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      </p>
                    </div>
                    <div className="time-info">
                      <span>11:24</span>
                    </div>
                  </div> */}
                  {jsxData != null? (
                    <>
                       {Object.keys(jsxData).map((key, i) => (
                        <div key = {key}>
                          <div className="date">
                            <span>{key}</span>
                          </div>
                      {Object.keys(jsxData[key]).map((k, i) => (
                        <div key={`${k}${jsxData[key][k].createdAt}`}>
                          <div onClick={() => {insightRedirect(`/insights/${tabs[parseInt(jsxData[key][k].LinkTo) - 1]}`,jsxData[key][k].id)}}>
                          <div className="info d-flex" id="notification">
                          <div className="logo-info">
                            <img className="edit-user-img"
                               style={{width:"40px", height:"40px"}}
                               src={`${jsxData[key][k].brandLogo}`} alt="Logo" />
                               {jsxData[key][k].readOn != null ? null 
                               : <span className="img-notify"></span>
                               }
                          </div>
                          <div className="desc-info">
                            <p>
                            {jsxData[key][k].message}
                            </p>
                          </div>
                          <div className="time-info me-4">
                            <span>{key == 'Today' ? formateconverter((moment(jsxData[key][k].createdAt).fromNow(true))) : moment(jsxData[key][k].createdAt).format("HH:mm") }</span>
                          </div>
                        </div>
                        </div>
                        </div>
                      ))}
                          </div>
                        ))}
                        </>
                     ) : null}
                 
                  {/* <div className="date">
                    <span>23 Apr</span>
                  </div>
                  <div className="info d-flex">
                    <div className="logo-info">
                      <img src={nlogo} alt="Logo" />
                      <span className="img-notify"></span>
                    </div>
                    <div className="desc-info">
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. 
                      </p>
                    </div>
                    <div className="time-info">
                      <span>13:45</span>
                    </div> 
                  </div>*/}
                </div>


            </OffcanvasBody>
        </Offcanvas>

    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};