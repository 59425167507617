import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//i18n
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";

import { Link } from "react-router-dom";

import logo from "../../assets/images/Acumen-logo.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
import logoDark from "../../assets/images/logo-dark.png";

const Sidebar = props => {

  return (
    <React.Fragment>
      <div className="col-2 col-lg-2 ps-0 bg-gray"> 
        <div className="side-bar-menu">
          <div className="login-logo">
            <Link to="/insights/industry-leaders">
              {/* <img src={logo} alt="Accumen logo" /> */}
              Acumen
            </Link>
          </div>
          <div className="side-tab-menu">
            {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
