import React, { useEffect, useState } from "react";
import { post } from "../../helpers/api_helper";
import * as url from "../../helpers/url_helper";
import {
  Alert,
  Input,
  FormFeedback,
  TabContent,
  TabPane,
  Spinner,
  Progress,
} from "reactstrap";

// import images
import loginRightImage from "assets/images/templateImages/login-right-img-half-new.png";
import loginLogo from "assets/images/templateImages/Acumen-logo.png";
import passwordEye from "assets/images/templateImages/eye-off.svg";
import passwordEyeOn from "assets/images/templateImages/eye_on.svg";
import loginRightIcon from "assets/images/nike.svg";
import loginRightIcon1 from "assets/images/p-2.svg";
import loginRightIcon2 from "assets/images/p-3.svg";
import loginRightIcon3 from "assets/images/p-4.svg";
import loginRightIcon4 from "assets/images/p-5.svg";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

// action
import { registerUser, registerServerError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link, useHistory } from "react-router-dom";

const Register = (props) => {
  //meta title
  let history = useHistory();
  document.title = "Register - Acumen";
  const [activeTab, setactiveTab] = useState(1);
  const [passedSteps, setPassedSteps] = useState([1]);
  const [loader, setloader] = useState(false);
  //progressbar data is stored in progressData
  const [progressData, setprogressData] = useState(0);
  const [showPassword, setshowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  //validationset can be used when we dont deactivate the next button to seperately validate each Wizard
  const validationset = {
    1: Yup.object({
      firstName: Yup.string().required("Please enter first name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      lastName: Yup.string().required("Please enter last name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      email: Yup.string()
        .required("Must be a business email")
        .email("Must be a valid email.")
        .max(100)
        .matches(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,'Must be a valid email'),
        // .test("email_async_validation","Please enter business email", function (value) { // Use function
        //   let regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
        //   if(validation.values.email != "" && regex.test(validation.values.email) ){
        //     setloader(true);
        //   return post(process.env.REACT_APP_BACKEND_URL + url.CHECK_BUSINESS_EMAIL, {
        //     email: validation.values.email,
        //   }).then((res) => {
        //     setloader(false);
        //     return res.message;
        //     // return Promise.resolve(this.createError({ message: message })); // This also works
        //   })
        //   .catch((error) => {
        //     setloader(false);
        //     if (error.response.data.message) {
        //       return this.createError({ message: error.response.data.message });
        //     }
        //   });
        // }else{
        //   return "";
        // }}),
    //     .test('Unique Email','Email already in use', 
    //     function(value){return new Promise((resolve, reject) => {
    //       post(process.env.REACT_APP_BACKEND_URL + url.CHECK_BUSINESS_EMAIL, {
    //         email: validation.values.email,
    //       })
    //         .then(res => {resolve(true)}).catch((error)=>
    //         {
    //           if (error.response.data.message) {
    //             resolve(false)
    //           }
    //         })
    //     })}
    // ),
    }),
    2: Yup.object({
      password: Yup.string().required("Please enter password").min(8),
      retypePassword: Yup.string()
        .required("Please confirm password")
        .when("password", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
    }),
  };

  const checkBusinessEmail = async (emailData = validation.values.email) => {
    await validation.setFieldTouched("email");
    await validation.setFieldTouched("firstName");
    await validation.setFieldTouched("lastName");
    await validation.validateForm().then((res) => {
      let errordata = res;
      let regex = /[^\s@]+@[^\s@]+\.[^\s@]+/;
      if(emailData != "" && regex.test(emailData) && !errordata.email){setloader(true);
          post(process.env.REACT_APP_BACKEND_URL + url.CHECK_BUSINESS_EMAIL, {
          email: emailData,
        })
          .then((res) => {
            setloader(false);
            // console.log(validation.errors)
            if(validation.touched.firstName &&
              !errordata.firstName && validation.touched.lastName &&
              !errordata.lastName && validation.touched.email &&
              !errordata.email){
                toggleTab(activeTab + 1)
              }
            return "";
          })
          .catch((error) => {
            setloader(false);
            if (error.response.data.message) {
            //  let errordata = validation.errors;
            //   errordata.email = error.response.data.message;
            //validation.setErrors({email:error.response.data.message });
              validation.setFieldError("email",error.response.data.message);
            }
            else{
              dispatch(registerServerError("Somthing went wrong"));
            }
              
          });
        }
    });
  };
  const authNewUser = (
    data = {
      email: validation.values.email,
      password: validation.values.password,
    }
  ) => {
    setloader(true);
    //console.log(data);
    post(url.POST_API_LOGIN, data)
      .then((response) => {
        setloader(false);
        //console.log(response);
        let authUser = response.payload;
        authUser["accessToken"] = response.token;
        localStorage.setItem("authUser", JSON.stringify(authUser));
        history.push("/settings");
      })
      .catch((error) => {
        setloader(false);
        dispatch(registerServerError("Somthing went wrong"));
      });
  };
  //used to update the wizard tab number
  function toggleTab(tab) {
    if (activeTab !== tab) {
      var modifiedSteps = [...passedSteps, tab];
      if (tab >= 1 && tab <= 3) {
        setactiveTab(tab);
        setPassedSteps(modifiedSteps);
      }
    }
  }
  //used to set initiall progress bar
  useEffect(() => {
    setprogressData(33);
  }, []);
  //updating progressbar when activetab changes
  useEffect(() => {
    switch (activeTab) {
      case 1:
        setprogressData(33);
        break;
      case 2:
        setprogressData(66);
        break;
      case 3:
        validation.handleSubmit();
        break;
      default:
        break;
    }
  }, [activeTab]);

  const dispatch = useDispatch();

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    validateOnChange:false,
    validateOnBlur:false,
    
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      retypePassword: "",
    },
    validationSchema: validationset[activeTab],
    onSubmit: (values) => {
      //values is passed to redux(this onsubmit is executed when we use validation.handleSubmit(); )
      if(activeTab == 2)
      {
        values['timeZone'] = new window.Intl.DateTimeFormat().resolvedOptions().timeZone;
        dispatch(registerUser(values));
      }
      toggleTab(activeTab + 1);
    },
  });

  const { user, registrationError, registrationServerError, loading } =
    useSelector((state) => ({
      user: state.Account.user,
      registrationError: state.Account.registrationError,
      registrationServerError: state.Account.registrationServerError,
      loading: state.Account.loading,
    }));
  useEffect(() => {
    if (registrationError) {
      validation.setErrors(registrationError);
      setactiveTab(1);
    }
  }, [registrationError]);

  return (
    <React.Fragment>
      <div className="common-box">
        <div className="login-logo">
          <a>
          {/* <img src={loginLogo} alt="Accumen logo" /> */}
          Acumen
          </a>
        </div>
        <div className="left-content">
          <div className="log-in-details" autoFocus>
            {activeTab < 3 && (
              <div className="form-header">
                {activeTab == 1 && (<h3>Create an account</h3>)}
                {activeTab == 2 && (<h3>Create a Password</h3>)}
                {registrationServerError && registrationServerError ? (
                  <Alert color="danger">{registrationServerError}</Alert>
                ) : null}
                <Progress
                  value={progressData}
                  color="success"
                  className="progress-sm"
                ></Progress>
              </div>
            )}
            <TabContent activeTab={activeTab}>
              {/* wizard one stars */}
              <TabPane tabId={1}>
                <div className="mb-4"></div>
                <form className="create-account ca-stepOne ca-stepFour">
                  <div className="form-group">
                    <label className="form-label">First Name</label>
                    <Input
                      id="firstName"
                      name="firstName"
                      className="form-control"
                      placeholder="Your First Name"
                      type="text"
                      tabIndex={1}
                      onChange={validation.handleChange}
                      //onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      value={validation.values.firstName || ""}
                      invalid={
                        validation.touched.firstName &&
                        validation.errors.firstName
                          ? true
                          : false
                      }
                    />
                    {/* error is displayed here */}
                    {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="form-label">Last Name</label>
                    <Input
                      id="lastName"
                      name="lastName"
                      className="form-control"
                      tabIndex={2}
                      placeholder="Your Last Name"
                      type="text"
                      onChange={validation.handleChange}
                      //onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      value={validation.values.lastName || ""}
                      invalid={
                        validation.touched.lastName &&
                        validation.errors.lastName
                          ? true
                          : false
                      }
                    />
                    {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="form-label">Business Email</label>
                    <Input
                      name="email"
                      className="form-control"
                      tabIndex={3}
                      placeholder="you@company.com"
                      type="text"
                      onChange={validation.handleChange}
                      //onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>
                </form>
                <div className="button-type">
                  <button
                    className="button-with-bg"
                    type="button"
                    tabIndex={4}
                    disabled={loader}
                    onClick={() => {
                      checkBusinessEmail();
                      // await validation.validateForm();
                      // checkBusinessEmail(validation.values.email);
                      //validation.handleSubmit();
                    }}
                  >
                    {loader == true ? "Processing   " : "Next"}
                    {loader && (
                      <Spinner
                        className="ms-2"
                        style={{ height: "1rem", width: "1rem" }}
                        color="light"
                      />
                    )}
                  </button>
                </div>
              </TabPane>
              <TabPane tabId={2}>
                <div className="mb-4"></div>
                <form className="create-account ca-stepFour">
                  <div className="form-group">
                    <label className="form-label">Password</label>
                    <Input
                      name="password"
                      type={showPassword ? "text" : "password"}
                      className="password passwordText"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    <img
                      src={showPassword ? passwordEyeOn : passwordEye}
                      className={
                        validation.touched.password &&
                        validation.errors.password
                          ? "error-eye"
                          : "eye-off"
                      }
                      onClick={() =>
                        setshowPassword(showPassword ? false : true)
                      }
                      alt="password-eye"
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <label className="form-label">Confirm Password</label>
                    <Input
                      name="retypePassword"
                      type={showConfirmPassword ? "text" : "password"}
                      className="password passwordText"
                      placeholder="Enter Confirm Password"
                      onChange={validation.handleChange}
                      onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      value={validation.values.retypePassword || ""}
                      invalid={
                        validation.touched.retypePassword &&
                        validation.errors.retypePassword
                          ? true
                          : false
                      }
                    />
                    <img
                      src={showConfirmPassword ? passwordEyeOn : passwordEye}
                      className={
                        validation.touched.retypePassword &&
                        validation.errors.retypePassword
                          ? "error-eye"
                          : "eye-off"
                      }
                      onClick={() =>
                        setshowConfirmPassword(
                          showConfirmPassword ? false : true
                        )
                      }
                      alt="password-eye"
                    />
                    {validation.touched.retypePassword &&
                    validation.errors.retypePassword ? (
                      <FormFeedback type="invalid">
                        {validation.errors.retypePassword}
                      </FormFeedback>
                    ) : null}
                  </div>
                </form>
                <div className="button-type">
                  <button
                    className="button-with-bg"
                    type="button"
                    disabled={loading}
                    onClick={() => {
                      validation.handleSubmit();
                    }}
                  >
                    {loading == true ? "Processing   " : "Create account"}
                    {loading && (
                      <Spinner
                        className="ms-2"
                        style={{ height: "1rem", width: "1rem" }}
                        color="light"
                      />
                    )}
                  </button>
                  <button
                    type="submit"
                    className="button-without-bg"
                    style={{ marginTop: "15px" }}
                    onClick={() => {
                      toggleTab(activeTab - 1);
                    }}
                  >
                    Back
                  </button>
                </div>
              </TabPane>
              <TabPane tabId={3}>
                <div className="log-in-details">
                  <h3>Account was created successfully!</h3>
                  <br />
                  <br />
                  <div className="button-type">
                    <button
                      className="button-with-bg"
                      disabled={loader}
                      onClick={() => authNewUser()}
                    >
                      {loader == true ? "Processing   " : "Go to the App"}
                      {loader && (
                        <Spinner
                          className="ms-2"
                          style={{ height: "1rem", width: "1rem" }}
                          color="light"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </TabPane>
            </TabContent>
            {activeTab < 3 && (
              <div className="sign-up">
                <p>
                  Already have an account?
                  <Link to={"/"}> Log In</Link>
                </p>
              </div>
            )}
          </div>
        </div>
        <div className="right-content">
          {/* <img src={loginRightImage} alt="dashboard" /> */}
          <div className="content_login">
            {/* <img src={loginRightImage} alt="dashboard" /> */}
            <h4>Acumen by G & Co.</h4>
            <h5>Advance in Retail: Transformative Insights into Action with our Live Data Benchmarking Soultion.</h5>

            <ul className="login_detail mx-0 p-0">
                <li>Real-time data on the market and competition</li>
                <li>Retail focused to provide deep expertise in the industry</li>
                <li>Actionable takeaways to quickly and clearly inform relevant teams</li>
                <li>Diverse consumer demographics across generation, gender, income, and consumer preferences</li>
                <li>Custom on-demand research in 48 hours to better inform your specific needs and purposes</li>
            </ul>

            <div className="partners_section w-100 mt-auto">
                <h6>Top Retail & Consumer Brands Place Their Trust in G & Co.</h6>
                <div className="d-flex justify-content-between align-items-center">
                    <p><img src={loginRightIcon} alt=" " /></p>
                    <p><img src={loginRightIcon1} alt=" " /></p>
                    <p><img src={loginRightIcon2} alt=" " /></p>
                    <p><img src={loginRightIcon3} alt=" " /></p>
                    <p><img src={loginRightIcon4} alt=" " /></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Register;
