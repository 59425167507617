import React from "react";
import { Row } from "reactstrap";
const FormAligner = ({lable = "",...props}) => {
  return (
    <Row className="mb-3">
      <label htmlFor="date" className="col-md-2 col-form-label form-label">
        {lable}
      </label>
      <div className="col-md-10">
        {props.children}
      </div>
    </Row>
  );
};
export default FormAligner;
