import React, { useMemo, useState, useEffect } from "react"
import {
  Container,
  Form,
  Modal,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';

import { useHistory, Link } from 'react-router-dom';
import {get, del, post} from "../../helpers/api_helper";
import PropTypes from 'prop-types';

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import arrowLeft from 'assets/images/Arrow-left.svg';

const NotificationsAdd = () => {

    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("authUser"));

    const [onSubmit, setonSubmit] = useState(false);
    const [brandData, setbrandData] = useState([]);  
    const [validationStatus, setvalidationStatus] = useState(true);
    //Toastr
    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeOut");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);


    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    //End toastr

    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];

    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
      initialValues: {
        message: '',
        brand:'',
        linkto:''
      },
      validationSchema: Yup.object({
        brand: Yup.string().required("Please select brand"),
        linkto: Yup.string().required("Please select link to"),
        message: Yup.string().required("Please enter message").max(150,"Message must be at most 150 characters"),
      }),
      onSubmit: async (values) => {
        setonSubmit(true);
        let toSave = {
          "brand": values.brand,
          "linkto": values.linkto,
          "message": values.message,
        }
        try {
          let res = await post('admin/notification/add', toSave);
          history.push("/admin/notifications");  
          toastr.success(res.success,"");
        } catch(error) {
          setonSubmit(false);
          if (error.response) {
             toastr.error(error,"Request failed.");
           }
        }  
      },
    });


    const editorConfiguration = {
      removePlugins: ['CKFinderUploadAdapter', 
                      'CKFinder', 
                      'EasyImage', 
                      'Image', 
                      'ImageCaption', 
                      'ImageStyle', 
                      'ImageToolbar', 
                      'ImageUpload', 
                      'MediaEmbed',
                      'Link',
                      'Table',
                      'Heading',
                      'BulletedList',
                      'NumberedList',
                      'BlockQuote'
                    ],
    };
    const fetchData = async function fetchData() {
      try{
        const response = await get("/admin/brands");
        setbrandData(response.data);
      } catch(err){
      }

    }
    useEffect(() => {
      fetchData();
    }, []);
  //meta title
  document.title="Add Notification - Acumen";
  return (
    <React.Fragment>
      <div className="inner-content">
      <div className="pr-page-title">
          <h2>Add Notification</h2>
          <h6>
          <div className="back"> 
            <Link
              to="/admin/notifications"
            >
              <img 
                src={arrowLeft} alt="arrow" 
              />Back
            </Link>
            </div>
          </h6>
      </div>
        <form onSubmit={validation.handleSubmit} className="formadd">
          <Row className="mb-3">
            <label
              htmlFor="logo"
              className="col-md-2 col-form-label form-label"
            >
              Brand
            </label>
            <div className="col-md-10">
            <Input type="select"  name="brand" 
                   id="brand"
                   className="form-control"
                   onInput={validationStatus ? validation.handleBlur : null}
                   onBlur={validationStatus ? validation.handleBlur : null}
                   onChange = {(e) => {
                    validation.setFieldValue('brand', e.target.value);
                  }}
                   invalid={validation.touched.brand &&
                     validation.errors.brand
                       ? true
                       : false
                   }
                   >
                     <option  value={""}>Select brand</option>
                     {brandData.map(item => (
                       item.status != "Inactive" ? 
                       <option key={item.id} value={item.id}>
                         {item.name}
                       </option> : null
                     ))}
                     </Input>
                     {  validation.touched.brand &&
                       validation.errors.brand ? (
                         <FormFeedback type="invalid">
                           {validation.errors.brand}
                         </FormFeedback>
                       ) : null}
            </div>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Link to
            </label>
            <div className="col-md-10">
            <Input type="select"  name="linkto" 
                   id="linkto"
                   className="form-control"
                    onInput={validationStatus ? validation.handleBlur : null}
                    onBlur={validationStatus ? validation.handleBlur : null}
                   onChange = {(e) => {
                    validation.setFieldValue('linkto', e.target.value);
                  }}
                   invalid={validation.touched.linkto &&
                     validation.errors.linkto
                       ? true
                       : false
                   }
                   >
                     <option  value={""}>Select link to</option>
                     <option  value={"1"}>Commerce Experience</option>
                     <option  value={"2"}>Shipping & Package</option>
                     <option  value={"3"}>Market Intel</option>
                     <option  value={"4"}>Brand Health</option>
                     <option  value={"5"}>Customer Service</option>
                     </Input>
                     {  validation.touched.linkto &&
                       validation.errors.linkto ? (
                         <FormFeedback type="invalid">
                           {validation.errors.linkto}
                         </FormFeedback>
                       ) : null}
            </div>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Message
            </label>
            <div className="col-md-10">
            <Input
                      type="textarea"
                      id="textarea"
                      className="form-control-admin"
                      maxLength="225"
                      rows="5"
                      name='message'
                      placeholder="Enter description"
                      value={validation.values.message || ""}
                      onChange={validationStatus ? validation.handleChange : null}
                      onInput={validationStatus ? validation.handleBlur : null}
                      onBlur={validationStatus ? validation.handleBlur : null}
                      invalid={
                        validation.touched.message &&
                        validation.errors.message
                          ? true
                          : false
                      }
                    />
                    {validation.touched.message &&
                      validation.errors.message ? (
                        <FormFeedback type="invalid">
                          {validation.errors.message}
                        </FormFeedback>
                      ) : null}
            </div>
          </Row>
          <Row className="mt-5">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
            </label>
            <div className="col-md-8">
               <button 
                type="submit" 
                className="btn btn-success col-md-4"
                disabled={onSubmit ? true : false}
                >
                  {onSubmit ? "Loading..." : "Submit"}
              </button>              
              <Link
              onMouseOver={() => {
                setvalidationStatus(false);
              }}
              onMouseOut={()=>{
                setvalidationStatus(true);
              }}
                  to="/admin/notifications"
                  className="text-primary ms-2"
                >Cancel</Link>
            </div>
          </Row>
      </form>    


      </div>

  </React.Fragment>
  );
}


export default NotificationsAdd;
