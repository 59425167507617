import React, { useMemo, useState, useEffect } from "react"
import {
  Container,
  Form,
  Modal,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Input,
  FormFeedback
} from "reactstrap"

import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';

import { useHistory, Link } from 'react-router-dom';
import {get, del, put, post} from "../../helpers/api_helper";
import PropTypes from 'prop-types';
import TableContainer from '../../components/Common/TableContainer';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import swal from "sweetalert";

const Users = () => {

    const history = useHistory();
    const [data, setdata] = useState([]);
    const [modal_user, setmodal_user] = useState(false);
    const [firstName, setfirstName] = useState("");
    const [lastName, setlastName] = useState("");
    const [email, setemail] = useState("");
    const [seatCount, setSeatCount] = useState(0);
    const [password, setpassword] = useState("");
    const [organization, setorganization] = useState("");
    const [changePassword, setchangePassword] = useState(false);    
    const [isBusy, setBusy] = useState(true);
    const [onSubmit, setonSubmit] = useState(false);
    const [userId, setuserId] = useState('');
    const [memberCount, setmemberCount] = useState('0');
    const [statusLoader, setstatusLoader] = useState(false);

    //Toastr
    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeOut");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);


    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    //End toastr

    const user = JSON.parse(localStorage.getItem("authUser"));

    const fetchData = async function fetchData() {
      try{
        const response = await get("admin/users");
        setdata(response.data);
        setBusy(false);
      } catch(err)         {
        setBusy(false);
      }
    }
    useEffect(() => {
      setBusy(true);
      fetchData();
    }, []);

    const centerText = (val) => {
      return (
        <div style={{ textAlign: "center" }}>{val}</div>
      )
    }

    const wordBreak = (val) => {
      return (
        <div style={{ wordBreak: "break-all" }}>{val}</div>
      )
    }

    const removeUser = async (val) => {
      let willDelete = await swal({
        title: "Do you want to remove this user?",
        text: "User and their members will also be removed from the application!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })

      if(willDelete) {
        try{
            setBusy(true);
            let response = await del("/admin/users/remove_user/"+val);
            if(response.success) {
              toastr.success(response.success,"");
              fetchData();
            } else {
              toastr.error(response.error,"");
            } 
        }
        catch(e) {
          toastr.error("Something went wrong","");
          setBusy(false);
        }      
      }      
    }

    const changeStatus = async (val, type) => {
      let willChange = true;
      if(type == "subscription") {
        let msg = "Do you want to activate the user subscription?"
        let subtext = "Once changed, the user and their members able to access the insights";
        if(val.subscriptionStatus == "Active") {
          msg = "Do you want to inactivate the user subscription?"
          subtext = "Once changed, user and organization members will not have access to insights."
        }
        willChange = await swal({
          title: msg,
          text: subtext,
          icon: "info",
          buttons: true,
          dangerMode: false,
        })
      }

      if(willChange) {
        try{
           setBusy(true);
           let response = await put("/admin/users/change_status/"+type+"/"+val.id);
           if(response.success) {
              toastr.success(response.success,"");
              fetchData();
            } else {
              toastr.error(response.error,"");
            }
        }
        catch(e) {
          setBusy(false);
          toastr.error("Something went wrong","");
        }  
      }
    }

    const memberCountAction = (cellProps) => {
      return (
        <div className="text-center">
            <Link
              to={"/admin/users/members/"+ cellProps.row.original.id}
              className="text-primary"
            >
            {cellProps.row.original.memberCount} / {cellProps.row.original.seatCount}
              
              </Link>
        </div>
      )
    }
    const actionRender = (cellProps) => {
      return (
        <div className="text-center">
        <Link
          to={"/admin/users/members/"+ cellProps.row.original.id}
          className="text-primary"
        >
          <i 
            className="mdi mdi-eye-outline font-size-18" 
            id={`viewtooltip${cellProps.row.original.id}`} />
            <UncontrolledTooltip placement="top" target={`viewtooltip${cellProps.row.original.id}`}>
              View
            </UncontrolledTooltip>
          </Link>&nbsp;&nbsp;

          <a
            href={void(0)}
            onClick={editForm}
          >
            <i 
              className="mdi mdi-pencil font-size-18 text-success" 
              id={`edittooltip${cellProps.row.original.id}`} 
              data-record={JSON.stringify(cellProps.row.original)} />
                <UncontrolledTooltip placement="top" target={`edittooltip${cellProps.row.original.id}`} >
                  Edit
                </UncontrolledTooltip>
          </a>&nbsp;&nbsp;

          <a
            className="text-danger"
            onClick={() => removeUser(cellProps.row.original.id)}
          >
            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${cellProps.row.original.id}`} />
            <UncontrolledTooltip placement="top" target={`deletetooltip${cellProps.row.original.id}`}>
              Delete
            </UncontrolledTooltip>
          </a>
        </div>
      )
    }
    const subscriptionStatus = (cellProps) => {
        let val = cellProps.row.original.subscriptionStatus
        return (
            <Badge
              className={"font-size-12 me-2 bg-" + 
              (val === "Active" ? "success" : "danger" && val === "Inactive" ? "warning" : "danger")}
              pill
            >
              <a
                onClick={() => changeStatus(cellProps.row.original, 'subscription')}
                style={{color: "unset !important"}}
              >
                <strong>{val}</strong>
              </a>
            </Badge>
        )
    };

    const statusRender = (cellProps) => {
        let val = cellProps.row.original.status;
        
        return (
            <Badge
              className={"font-size-12 me-2 bg-" + 
              (val === "Active" ? "success" : "danger" 
                && val === "Inactive" ? "warning" : "danger")}
              pill
            >
              <a
                onClick={() => changeStatus(cellProps.row.original, 'status')}
                style={{color: "unset !important"}}
              >
                <strong>{val}</strong>
              </a>
            </Badge>
        )
    };

    const columns = useMemo(
        () => [
            {
                Header: 'Name',
                accessor: d => `${d.firstName} ${d.lastName}`,
                Cell: cellProps => (
                  cellProps.row.original.firstName+" "+
                    cellProps.row.original.lastName
                )
                
            },
            {
                Header: 'Email',
                accessor: 'email',
                Cell: cellProps => wordBreak(cellProps.row.original.email)                
            },
            {
                accessor: 'organization',
                Header: () => (
                  <div
                    style={{
                      textAlign:"left"
                    }}
                >Organization</div>),
                Cell: cellProps => cellProps.row.original.organization
            },
            {
                accessor: 'Members',
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Members count</div>),
                disableGlobalFilter:true,
                Cell: cellProps => memberCountAction(cellProps),
            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Joined On</div>),
                accessor: 'createdAt',
                Cell: cellProps => centerText(
                  cellProps.row.original.createdAt
                )
            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Subscription</div>),
                accessor: 'subscriptionStatus',
                Cell: cellProps => {
                  return centerText(subscriptionStatus(cellProps))
                },
                //disableGlobalFilter:true,
                disableSortBy: true
            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Status</div>),
                accessor: 'status',
                Cell: cellProps => centerText(statusRender(cellProps)),
                //disableGlobalFilter:true,
                disableSortBy: true

            },
            {
                Header: () => (
                  <div
                    style={{
                      width: "80px",
                      textAlign:"center"
                    }}
                >Action</div>),
                accessor: 'action',
                Cell: cellProps => centerText(actionRender(cellProps)),
                disableGlobalFilter:true,
                disableSortBy: true
            }
        ],
        []
    );

  const editForm = (e) => {
    setchangePassword(false);
    const record = JSON.parse(e.target.getAttribute("data-record"));
    
    setfirstName(record.firstName);
    setlastName(record.lastName);
    setorganization(record.organization);
    setemail(record.email);
    setpassword("");
    setSeatCount(record.seatCount);
    setuserId(record.id);
    setmemberCount(record.memberCount);

    setmodal_user(!modal_user);

  }

  const AddNew = () => {
    history.push("/admin/user-add");
  }

  useEffect(() => {
    if(changePassword === true)
    {
      validation.validateForm();
    }
    else{
      validation.setFieldError("password","");
      validation.setFieldValue("password","")
    }
  },[changePassword])

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      organization: organization,
      email: email,
      password: password,
      seatCount: seatCount
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      lastName: Yup.string().required("Please enter last name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      organization: Yup.string().required("The organization field is required"),
      password: Yup.string().min(8)
      .when("changePassword", {
        is: () => changePassword==true,
        then: Yup.string().required("The password field is required")
      }),
      seatCount: Yup.number()
      .when("memberCount", {
        is: () => memberCount <= seatCount,
        then: Yup.number().required(`The seat count should be greater than or equal to current member count: ${memberCount}`)
      })
      .min(memberCount, `The seat count should be greater than or equal to current member count: ${memberCount}`)
      .max(100, 'The seat count should be less than 100'),
    }),
    
    onSubmit: async (values) => {
      setonSubmit(true);
      const formData = new FormData();
      Object.keys(values).map(function(key) {
        if(values[key] != "") 
        {
          formData.append(key, values[key]);                          
        }
      });
      formData.set("seatCount", parseInt(values['seatCount']));
      formData.append('id',userId);

      const token = user.accessToken;
      try {
        let res = await post( 'admin/users/update_subscriber_profile', formData);
        setonSubmit(false);
        setmodal_user(false);
        toastr.success(res.success,"");
        fetchData();
      } catch(error) {
        console.log(error);
        setonSubmit(false);
        if (error.response) {
           // Request made and server responded
           console.log(error.response.data.errors);
           validation.setErrors(error.response.data.errors);
         } else if (error.request) {
           // The request was made but no response was received
           toastr.error(error,"Request failed");
         } else {
           console.log(error)
           // Something happened in setting up the request that triggered an Error
           toastr.error(error,"Request failed.");
         }
      }
    },
  });

  //meta title
  document.title="Subscribers - Acumen";
  return (
    <React.Fragment>
      <div className="inner-content">
        <div className="pr-page-title">
          <h2>Subscriber List</h2>
      </div>
        <BlockUi tag="div" blocking={isBusy}>
        <TableContainer
              columns={columns}
              data={data}
              isGlobalFilter={true}
              isAddOptions={false}
              customPageSize={10}
              className="tdTheme"
              handleOrderClicks={AddNew}
              addLable="Add User"
              isBusy={isBusy}
          />
          </BlockUi>
      </div>

       <Modal
        isOpen={modal_user}
        toggle={() => {
          setmodal_user(false);
          validation.resetForm({});
        }}
        centered
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="mySmallModalLabel">
            Edit
          </h5>
          <button
            onClick={() => {
              setmodal_user(false);
              validation.resetForm({});
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="name">First Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    id="firstName"
                    tabIndex={1}
                    placeholder="Enter First Name"
                    value={validation.values.firstName || ""}
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstName &&
                    validation.errors.firstName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.firstName}
                      </FormFeedback>
                    ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="email">Last Name</label>
                  <Input
                    type="text"
                    className="form-control"
                    id="lastName"
                    tabIndex={2}
                    placeholder="Enter Last Name"
                    value={validation.values.lastName || ""}
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.lastName &&
                      validation.errors.lastName
                        ? true
                        : false
                    }                    
                  />
                  {validation.touched.lastName &&
                    validation.errors.lastName ? (
                      <FormFeedback type="invalid">
                        {validation.errors.lastName}
                      </FormFeedback>
                    ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="email">Organization</label>
                    <Input
                      type="text"
                      className="form-control"
                      tabIndex={3}
                      id="organization"
                      placeholder="Enter Organization"
                      value={validation.values.organization || ""}
                      onChange={validation.handleChange}
                      onInput={validation.handleBlur}
                      onBlur={validation.handleBlur}
                      invalid={
                        validation.touched.organization &&
                        validation.errors.organization
                          ? true
                          : false
                      }
                    />
                    {validation.touched.organization &&
                      validation.errors.organization ? (
                        <FormFeedback type="invalid">
                          {validation.errors.organization}
                        </FormFeedback>
                      ) : null}
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <label htmlFor="email">Email</label>
                    <Input
                      type="text"
                      className="form-control"
                      tabIndex={4}
                      id="email"
                      placeholder="Enter Email"
                      value={validation.values.email}
                      onChange={validation.handleChange}
                      disabled={true}
                    />
                  </div>
                </Col>
            </Row>
            <Row>              
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="password">Password</label>
                  <Input
                    type={changePassword ? "text" : "password"}
                    className="form-control"
                    tabIndex={5}
                    id="password"
                    name="password"
                    placeholder="Enter new password"
                    value={changePassword ? validation.values.password : "12345678"}
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    disabled={changePassword ? false : true}
                    invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                    }
                  />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}  
                  <a tabIndex={6}className="link_hover"
                    onClick={()=>
                      {
                        changePassword ? 
                          setchangePassword(false) : 
                          setchangePassword(true);
                          validation.touched.password = false;
                          validation.values.password = "";
                      }
                    }
                    onKeyPress={(e)=>
                      {
                        if(e.key == "Enter"){
                          changePassword ? 
                          setchangePassword(false) : 
                          setchangePassword(true);
                          validation.touched.password = false;
                          validation.values.password = "";
                        } 
                      }
                    }
                  >{changePassword ? "Cancel": "Change Password"}</a>
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label htmlFor="seatCount">Seat count</label>
                  <Input
                    type="text"
                    className="form-control"
                    tabIndex={7}
                    id="seatCount"
                    placeholder="Enter seat count"
                    autoComplete="new-password"
                    min={memberCount}
                    value={validation.values.seatCount}
                    max={100}
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) 
                      ? true : event.preventDefault();
                    }}
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.seatCount &&
                      validation.errors.seatCount
                        ? true
                        : false
                    }
                  />
                  {validation.touched.seatCount &&
                      validation.errors.seatCount ? (
                        <FormFeedback type="invalid">
                          {validation.errors.seatCount}
                        </FormFeedback>
                      ) : null}
                </div>
              </Col>
            </Row>

            <Row>
              <Col lg={12}>
                <div className="text-end">
                  <button type="submit"tabIndex={8} className="btn btn-success">
                    Submit
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </Modal>

  </React.Fragment>
  );
}

Users.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default Users;
