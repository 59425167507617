import { call, put, takeEvery, takeLatest } from "redux-saga/effects";

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

import { postApiLogin, postAdminApiLogin } from "../../../helpers/backend_helper";

function* loginUser({ payload: { user, history } }) {
  
  try {
    var pathname = window.location.pathname.replace("/", "");
    let loginTo = postApiLogin;
    // console.log(pathname);
    if(pathname.startsWith("admin")) {
      // Login to admin
      loginTo = postAdminApiLogin;
    }
    const response = yield call(loginTo, user);
    // console.log("response: ", response);
    let authUser = response.payload;
    authUser['accessToken'] = response.token;
    localStorage.setItem("authUser", JSON.stringify(authUser));
    yield put(loginSuccess(response));
    
    if(pathname.startsWith("admin")) {
      history.push("/admin/users");
    } else {
      history.push("/insights/industry-leaders");
    }
  } catch (error) {
    let errorMsg = "Incorrect email or password"; // Default error msg  
    try{
      errorMsg = error.response.data.error;
    } catch(err) {
      errorMsg = "Something went wrong. Please contact administrator";
    }
    yield put(apiError(errorMsg));
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    var pathname = window.location.pathname.replace("/", "");
    localStorage.removeItem("authUser");
    
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }

    if(pathname.startsWith("admin")) {
      history.push("/admin");
    } else {
      history.push("/login");
    }
  } catch (error) {
    yield put(apiError(error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
