import React, {useState, useEffect} from "react"
import {
  Container,
  Row,
  Input,
  Form,
  InputGroup,
  FormFeedback
} from "reactstrap"

import { useHistory, Link } from 'react-router-dom';
import { get, post } from "../../helpers/api_helper";
import moment from "moment";

//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import BlockUi from 'react-block-ui';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import arrowLeft from 'assets/images/Arrow-left.svg';

const FilesList = () => {
  //meta title
  document.title="Import - Acumen";
  const history = useHistory();

  const [onSubmit, setonSubmit] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [categories, setCategories] = useState([]);
  const [isBusy, setBusy] = useState(true);    
  const [template, settemplate] = useState("");    
  const [templates, settemplates] = useState([]);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);


    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    //End toastr

    useEffect(() => {
      fetchData();
    }, []);

    const fetchData = async function fetchData() {
      setBusy(true);
      try {
        const response = await get("admin/files/templates");
        settemplates(response.data);
        setBusy(false);
      } catch(err) {
        console.log(err);
        setBusy(false);
      }
    }

    const selectTemplate = async function(event) {
      settemplate(event.target.value);    
    }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      importDate: '',
      insightFile: '',
      template: ""
    },
    validationSchema: Yup.object({
      // importDate: Yup.string().required("The Date is required"),
      insightFile: Yup.mixed().required("The Insight File is required")
      .test('File format',
        'Please upload excel (.xlsx) file only', 
        function(value) {   
          if (!value || value =="" || value == null) return true;
          return value.name && value.name.endsWith(".xlsx")
        })
      .test('File size',
        'File size is large. Please upload the file in less than 3MB', 
          function(value) {
            if (!value || value =="" || value == null) return true;
            return (value.size <= 1024 * 1024 * 3)
        }),
      template: Yup.string().required("Please select the template"),
    }),
    onSubmit: async (values) => {
      setonSubmit(true);

      values.importDate = moment(dateValue).format("YYYY-MM-DD");

      const formData = new FormData();
      Object.keys(values).map(function(key) {
        formData.append(key, values[key]);
      });

      console.log(formData);
      
      try {
        let res = await post('admin/files/add', formData); 
        toastr.success(res.success, "");
        history.push("/admin/import-file");  
      } catch(error) {
        setonSubmit(false);
        setBusy(false);
        if (error.response) {
           // Request made and server responded
           if(error.response.data && error.response.data.errors) {
            validation.setErrors(error.response.data.errors);
           }
         } else if (error.request) {
           // The request was made but no response was received
           toastr.error(error, "Request failed");
         } else {
           //console.log(error)
           // Something happened in setting up the request that triggered an Error
           toastr.error(error,"Request failed.");
         }
      }   

      
    },
  });


  return (
    <React.Fragment>
      <div className="inner-content">
        <div className="pr-page-title">
            <h2>Import</h2>
            <h6>
            <div className="back"> 
              <Link
                to="/admin/import-file"
                // className="text-primary"
              ><img 
              src={arrowLeft} alt="arrow" 
            />Back</Link>
            </div>
          </h6>
        </div>
        <div>
        <BlockUi tag="div" blocking={isBusy}>
          <form onSubmit={validation.handleSubmit} className="">
            <Row className="mb-3">
              <label
                htmlFor="importDate"
                className="col-md-2 col-form-label form-label"
              >
                Import Date
              </label>
              <div className="col-md-10">
               <InputGroup

               >
                <Flatpickr
                  className="form-control d-block"
                  placeholder="Import date"
                  options={{
                    altInput: true,
                    altFormat: "m/d/Y",
                    dateFormat: "m/d/Y"
                  }}
                  value={dateValue}
                  id="importDate"
                  name="importDate"
                  onChange={(event, date)=>{
                    setDateValue(date)
                  }}
                  onInput={validation.handleBlur}
                  onBlur={validation.handleBlur}
                  disabled={true}

                />
              </InputGroup>
              {
                validation.errors.importDate ? (
                  <FormFeedback type="invalid">
                    {validation.errors.importDate}
                  </FormFeedback>
                ) : null}
              </div>

            </Row>
            <Row className="mb-3">
              <label
                htmlFor="template"
                className="col-md-2 col-form-label form-label"
              >
                Insight Template
              </label>
              <div className="col-md-10">
               <Input type="select" defaultValue={template} name="template" 
                  id="template"
                  onChange={(e) => {
                     selectTemplate(e);
                     validation.setFieldValue('template', e.target.value);
                  }}
                  onInput={validation.handleBlur}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.template &&
                    validation.errors.template
                      ? true
                      : false
                  }
                  >
                    <option value="">Select template</option>
                    {templates && templates !=[] && templates.map(item => (
                      <option key={item._id} value={item._id}>
                        {item.name}
                      </option>
                    ))}
                    </Input>
                    {
                      validation.touched.template &&
                      validation.errors.template ? (
                        <FormFeedback type="invalid">
                          {validation.errors.template}
                        </FormFeedback>
                      ) : null
                    }
              </div>
            </Row>
            <Row className="mb-3">
              <label
                htmlFor="insightFile"
                className="col-md-2 col-form-label form-label"
              >
                Import File
              </label>
              <div className="col-md-10">
               <Input 
                className="form-control line-height" 
                type="file" 
                id="insightFile" 
                name="insightFile"
                onChange={event => validation.setFieldValue('insightFile', event.target.files[0])}
                onClick={event => {
                  validation.setFieldValue('insightFile', null);
                  event.target.value = null                  
                }}
                onInput={validation.handleBlur}
                // onBlur={validation.handleBlur}
                invalid={
                      validation.touched.insightFile &&
                      validation.errors.insightFile
                        ? true
                        : false
                    }
              />
              {
                validation.touched.insightFile && 
                validation.errors.insightFile ? (
                  <FormFeedback type="invalid">
                    <div
                      dangerouslySetInnerHTML={{__html: validation.errors.insightFile}}
                    />
                  </FormFeedback>
                ) : null}

              </div>
            </Row>

            <Row className="mt-4">
              <div className="col-md-2"></div>
              <div className="col-md-10">
                 <button 
                    type="submit" 
                    className="btn btn-success col-md-2"
                    disabled={onSubmit || !(validation.isValid && validation.dirty)}
                    >
                      {onSubmit ? "Loading..." : "Submit"}
                  </button>

                  &nbsp;&nbsp;
                   <Link
                      to="/admin/import-file"
                      className="text-primary"
                    >Cancel</Link>

              </div>
            </Row>
          </form>
        </BlockUi>
        </div>

      </div>
  </React.Fragment>
  );
}

export default FilesList;
