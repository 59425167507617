import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";

// //Import Scrollbar
import SimpleBar from "simplebar-react";

// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import bell from "assets/images/bell.svg";
import importicon from "assets/images/download.svg";
import settings from "assets/images/settings-new.svg";
import notifyIcon from "assets/images/New Notification Bell.svg";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");

      if (pathName.includes("/admin/users")) {
        pathName = "/admin/users";
      } else if (pathName.includes("/admin/brand")) {
        pathName = "/admin/brands";
      } else if (pathName.includes("/admin/fileimport")) {
        pathName = "/admin/fileimport";
      } else if (pathName.includes("/admin/templates")) {
        pathName = "/admin/templates";
      } else if (pathName.includes("/admin/file-records")) {
        pathName = "/admin/import-file";
      } else if (pathName.includes("/admin/notifications")) {
        pathName = "/admin/notifications";
      } else if (pathName.includes("/admin/chartimport")) {
        pathName = "/admin/chartimport";
      } else if (pathName.includes("/admin/fileimport")) {
        pathName = "/admin/chartimport";
      }


      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          //console.log(items[i]);
          break;
        }
      }

      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="tab-panel-menu nav nav-pills" id="side-menu">
            {/*<li className="menu-title">{props.t("Menu")} </li>*/}
            <li>
              <Link to="/admin/dashboard" className="tablinks">
                <i className="bx bx-home-circle"></i>
                <span>{props.t("Dashboard")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/users" className="tablinks">
                <i className="bx bx-user-circle"></i>
                <span>{props.t("Subscribers")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/brands" className="tablinks">
                <i className="bx bx-book-content"></i>
                <span>{props.t("Brands")}</span>
              </Link>
            </li>

            <li>
              <Link to="/admin/templates" className="tablinks">
                <i className="fa fa-copy"></i>
                <span>{props.t("Templates")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/import-file" className="tablinks">
                  <img src={importicon}/>
                  <span>{props.t("Import")}</span>
              </Link>              
            </li>

            <li>
              <Link
                to={{
                  pathname: "/admin/chartimport",
                }}
                className="tablinks"
              >
                {/* <i className="bx bx-import"></i> */}
                <img src={importicon} />
                <span>{props.t("Chart")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/cmssettings" className="tablinks">
                {/* <i className="bx bx-bell"></i> */}
                <img src={settings} />
                <span>{props.t("CMS Settings")}</span>
              </Link>
            </li>
            <li>
              <Link to="/admin/notifications" className="tablinks">
                {/* <i className="bx bx-bell"></i> */}
                <img src={notifyIcon} style={{width:'23px'}}/>
                <span>{props.t("Notifications")}</span>
              </Link>
            </li>
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
