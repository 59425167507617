import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";
import { createDispatchHook } from "react-redux";

const ReturnObjectiveVertical = ({
  chartHeightData,
  BarplatData,
  sideBar,
  colorCode,
  iddata,
  lableValue,
}) => {
  //const d3Chart = useRef();
  const id = "rrvertical" + iddata;
  const [errorMessage, setErrorMessage] = useState(null);
  try {
    if (Object.keys(BarplatData) == 0) {
      throw "empty data";
    }
    const ChartColor = colorCode.map((obj) => obj.unique);

    const chartData = Array.from(Object.keys(BarplatData), (x, i) => {
      return {
        group: x,
        ...BarplatData[x],
      };
    });

    const customDecimal = (e) => {
      return d3.format(".0f")(e);
    };
    const chartHeight = chartHeightData ? chartHeightData : 800;
    let totalNoBrand = Object.keys(BarplatData).length;
    let paddingBetween = 0.05;
    switch (totalNoBrand) {
      case 1:
        paddingBetween = 0.28;
        break;
      case 2:
        paddingBetween = 0.25;
        break;
      case 3:
        paddingBetween = 0.23;
        break;
      case 4:
        paddingBetween = 0.2;
        break;
      case 5:
        paddingBetween = 0.19;
        break;
      case 6:
        paddingBetween = 0.17;
        break;
      case 7:
        paddingBetween = 0.15;
        break;
    }
    useEffect(() => {
      const margin = { top: 20, right: 30, bottom: 80, left: 90 };
      const width = 2000 - margin.left - margin.right;
      const height = chartHeight - margin.top - margin.bottom;

      d3.selectAll(`#${id} > *`).remove();

      const svg = d3
        .select(`#${id}`)
        .append("svg")
        /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
        .attr("viewBox", `0 0 2000 ${chartHeight}`)
        // .style('height','365px')
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // List of subgroups = header of the csv files = soil condition here
      const subgroups = ["group", ...sideBar];

      // List of groups = species here = value of the first column called group -> I show them on the X axis
      const groups = Object.keys(BarplatData).map((d) => {
        return d;
      });

      // Add X axis
      const x = d3
        .scaleBand()
        .domain(groups)
        .range([0, width])
        .padding(paddingBetween)
        .align(1);

      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickSize(1))
        .selectAll("g")
        .attr("transform", (d) => "translate(" + (x(d) + 70) + ",0)")
        .selectAll("text")
        //.call(wrap, x.bandwidth())
        .attr("y", 20)
        .attr("x", 9)
        .attr("dy", ".35em")
        .style("font-size", "20px")
        .style("line-height", "14px");

      // Add Y axis
      const y = d3.scaleLinear().domain([0, 100]).range([height, 0]);

      const yAxisGrid = d3.axisLeft(y).tickSize(-width).tickFormat("").ticks(5);

      svg
        .append("g")
        .call(
          d3
            .axisLeft(y)
            .tickFormat(customDecimal)
            .ticks(5)
            .tickSize(0)
            .tickPadding([10])
        )
        .selectAll("g")
        .selectAll("text")
        .text((d) => {
          return d + `${lableValue?.["Unit"] ? lableValue?.["Unit"] : "%"}`;
        })
        .style("font-size", "20px");

      svg
        .append("g")
        .attr("class", "y rr-y-axis-grid axis-grid")
        .call(yAxisGrid);

      // Another scale for subgroup position?
      /*const xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.2])*/

      // color palette = one color per subgroup
      const color = d3.scaleOrdinal().domain(sideBar).range(ChartColor);

      //console.log(BarplatData['Saks']['oc_s_avg']);

      const tooltip = d3
        .select(`#${id}`)
        .append("div")
        .attr("class", "tooltip-barplot")
        .style("opacity", 0);

      const dataArray = [];

      Object.keys(BarplatData).map((d) => {
        let da = BarplatData[d];
        let col = { group: da["retailer"] };
        sideBar.map((s) => {
          col[s] = da[s];
        });
        //console.log(col);

        dataArray.push(col);
      });

      const stackedData = d3.stack().keys(subgroups)(chartData);

      /*console.log(stackedData);
      console.log(dataArray);   */

      // Show the bars
      const d3Svg = svg
        .append("g")
        .selectAll("g")
        // Enter in data = loop group per group
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", (d) => color(d.key))
        .selectAll("rect")
        //.data((d)=>subgroups.map(function(key) { return {key: key, value: key == 'group' ? d : BarplatData[d][key]}; }) )
        .data((d) => d)
        .enter();

      d3Svg
        .append("rect")
        .attr("x", (d) => x(d.data.group) + 20) //
        .attr("y", (d) => y(d[1]) - 1)
        .attr("width", x.bandwidth() > 80 ? 120 : x.bandwidth())
        .attr("height", (d) =>
          y(d[0]) - y(d[1]) != 0 ? y(d[0]) - y(d[1]) + 1 : 0
        )
        .on("mouseover", (event, d) => {
          //console.log(d3.select(this).attr("x"));
          //console.log(d)
          tooltip
            .transition()
            .delay(0)
            .duration(50)
            .ease(d3.easeLinear)
            .style("visibility", "visible")
            .style("opacity", "1");

          const parentNode = structuredClone(d.data);
          delete parentNode.group;
          //console.log(d.data,"data");
          //console.log(parentNode);
          //tooltip.html(`<div class="graph-tooltip">${tooltipHtml}</div>`)
          tooltip
            .html(
              `<div class="tooltipDesign" style="pointer-events: none">
                        <h5 class="tt-head">
                          ${d.data.group}
                        </h5>
                        <ul>
                        ${Object.keys(parentNode)
                          .map(function (key, index) {
                            return `<li>
                          <p><span 
                          class="color1-dot"
                          style="background:${color(key)}"
                          ></span> 
                          <span class="tooltip_text">${key}</span>
                          </p>
                          <label>${
                            parentNode[key]
                          }${lableValue?.["Unit"] ? lableValue?.["Unit"] : "%"}</label>
                        </li>`;
                          })
                          .join("")}
                        </ul>
                      </div>`
            )
            .style("left", event.layerX + 22 + "px")
            .style("top", event.layerY - 128 + "px");
        })
        .on("mousemove", function (event) {
          return tooltip
            .style("left", event.layerX + 22 + "px")
            .style("top", event.layerY - 128 + "px");
        })
        .on("mouseout", () =>
          tooltip
            .transition()
            .style("visibility", "hidden")
            .style("opacity", "0")
        );

      d3Svg
        .append("text")
        .attr("class", "text")
        .attr("x", (d) => {
          if (d[0] != NaN || d[1] != NaN) {
            console.log(d[1] - d[0]);
            if (parseInt(d[1] - d[0]) == 100) {
              return x(d.data.group) + 55;
            } else if (
              parseInt(d[1] - d[0]) < 100 &&
              parseInt(d[1] - d[0]) > 9
            ) {
              return x(d.data.group) + 62;
            } else {
              return x(d.data.group) + 68;
            }
          }
          // let sum = (d.data.Yes == 100 ||d.data.Yes == 0) ? 55 : 62;
          // return (x(d.data.group)+sum)
          //(width-20) will give the size inside graph by avoiding the axis width (20 will be the axis width)
        })
        .attr("y", (d) => y(d[0]) + (y(d[1]) - y(d[0])) / 2 + 3)
        .text((d) => {
          // console.log(d[1], d[0])
          if (isNaN(d[0]) || isNaN(d[1]) || d[1] - d[0] == 0) {
            return "";
          }
          return (
            parseFloat(d[1] - d[0]) +
            `${lableValue?.["Unit"] ? lableValue?.["Unit"] : "%"}`
          );
        })
        .style("fill", "white")
        .style("font-size", "20px")
        .style("line-height", "14px");

      if (lableValue?.["lable x"]) {
        svg
          .append("text")
          .attr(
            "transform",
            "translate(" + width / 2 + " ," + (height + 50) + ")"
          )
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .style("font-size", "15px")
          .text(`${lableValue?.["lable x"]}`);
      }
      if (lableValue?.["lable Y"]) {
        svg
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", -margin.left)
          .attr("x", 0 - height / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .text(`${lableValue?.["lable Y"]}`);
      }
      // console.log(xSubgroup.bandwidth());
    }, [BarplatData, sideBar, colorCode]);
  } catch (err) {
    console.log(err);
    return (
      <ErrorMessage
        Message={"Not able to generate graph for the retrieved data"}
      />
    );
  }

  return <div id={id}></div>;
};

export default ReturnObjectiveVertical;
