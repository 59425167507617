import { takeEvery, fork, put, all, call } from "redux-saga/effects";

//Account Redux states
import { REGISTER_USER } from "./actionTypes";
import {
  registerUserSuccessful,
  registerUserFailed,
  registerServerError,
} from "./actions";
import * as url from "../../../helpers/url_helper";
//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";

import { postJwtRegister } from "../../../helpers/backend_helper";

// initialize relavant method of both Auth
const fireBaseBackend = getFirebaseBackend();

// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user } }) {
  try {
    if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      const response = yield call(
        postJwtRegister,
        process.env.REACT_APP_BACKEND_URL + url.POST_API_REGISTER,
        user
      );
      yield put(registerUserSuccessful(response.success));
    }
  } catch (error) {
    console.log(error);
    if (error.response.data.errors !== undefined) {
      yield put(registerUserFailed(error.response.data.errors));
    } else {
      yield put(
        registerServerError(
          "Sorry! something went wrong, please contact our support team"
        )
      );
    }
  }
}

export function* watchUserRegister() {
  yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
  yield all([fork(watchUserRegister)]);
}

export default accountSaga;
