import React, { useState, useContext, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ImportContext from "helpers/Context/ImportContext";
import { post } from "helpers/api_helper";
import InputFile from "pages/Admin/components/inputFile";
import FormAligner from "../../../components/FormAligner";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export default function AppendTab() {
  const FormFields = {
    insightFile: "",
    replace: "",
  };
  const history = useHistory();
  const formikRef = useRef();
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr
  //console.log(FormFields);
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [importFormdata, setimportFormdata] = useState(FormFields);
  const { importDate, insightFile, mapped } = importFormdata;
  const { lableY, valueY, xaxis } = FormFields;
  const [submited, setSubmited] = useState(false);
  //console.log(FormData);
  const HandleSubmit = async (file, checkboxdata) => {
    try {
      if (file == "" || file == undefined) {
        history.push(`/admin/chartimport/edit/${ImportContextData.id}`, {
          TabNo: "3",
        });
      } else {
        const formData = new FormData();
        formData.append("insightFile", file);
        formData.append("replace", checkboxdata);
        let res = await post(
          `admin/insights/editFileImport/${ImportContextData?.id}`,
          formData
        );
        toastr.success(res.success, "");
        history.push(`/admin/chartimport/edit/${ImportContextData.id}`, {
          TabNo: "3",
        });
      }
    } catch (error) {
      //console.log(error.response?.data?.errors?.["cms.InsightTitle"]);
      toastr.error(
        error.response?.data?.errors?.["insightFile"]
          ? error.response?.data?.errors?.["insightFile"]
          : "Server Error"
      );
    }
  };

  const validationSchema = {};
  const handleFileImportChange = (e) => {
    const { name, files } = e.target;
    console.log(name, files);
    setimportFormdata({ ...importFormdata, [name]: files[0] });
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="group-bar-vertical">Append</CardTitle>
              <div>
                <Formik
                  initialValues={FormFields}
                  innerRef={formikRef}
                  validationSchema={Yup.object({
                    insightFile: Yup.mixed()
                      .test(
                        "File format",
                        "Please upload excel (.xlsx) file only",
                        function () {
                          if (!importFormdata.insightFile) return true;
                          return importFormdata.insightFile.name.endsWith(
                            ".xlsx"
                          );
                        }
                      )
                      .test(
                        "File size",
                        "File size is large. Please upload the file in less than 3MB",
                        function () {
                          if (!importFormdata.insightFile) return true;
                          return (
                            importFormdata.insightFile.size <= 1024 * 1024 * 3
                          );
                        }
                      ),
                  })}
                  //validationSchema={Yup.object(validationSchema)}
                  onSubmit={(values) => {
                    // console.log(insightFile);
                    // console.log(values);
                    HandleSubmit(insightFile, values.replace);
                  }}
                  render={({ values, touched, ...formik }) => (
                    <Form>
                      <InputFile
                        type="file"
                        name={"insightFile"}
                        placeholder={"Import File"}
                        onInput={handleFileImportChange}
                        onChange={(e) => {
                          formik.handleBlur;
                          formik.setFieldValue(e.target.name, e.target.value);
                        }}
                        onClick={(e) => {
                          formik.setFieldValue(e.target.name, "");
                        }}
                        onBlur={formik.handleBlur}
                      />
                      <FormAligner>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="replace"
                          value="true"
                          id="defaultCheck"
                          onClick={(e) => {
                            if (
                              values.replace != "true" ||
                              values.replace == ""
                            ) {
                              formik.setFieldValue(
                                e.target.name,
                                e.target.value
                              );
                            } else {
                              formik.setFieldValue(e.target.name, "false");
                            }
                          }}
                        />
                        <label htmlFor="defaultCheck1">
                          Replace old data with new data
                        </label>
                      </FormAligner>
                      <FormAligner>
                        <button
                          className="btn btn-success col-md-2 me-2"
                          onClick={() => {
                            formik.handleSubmit();
                          }}
                          type="button"
                        >
                          Save and Next
                        </button>
                        <Link to="/admin/chartimport">Cancel</Link>
                      </FormAligner>
                    </Form>
                  )}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
