import React, { useMemo, useState, useEffect }  from "react"
import {
  Container,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"

import { useHistory, Link } from 'react-router-dom';
import {get, del, put} from "../../helpers/api_helper";
import PropTypes from 'prop-types';
import TableContainer from '../../components/Common/TableContainer';
import BlockUi from 'react-block-ui';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Users = () => {
  //meta title
  document.title="File list- Acumen";
  const history = useHistory();


  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);


  let positionClass = "toast-top-right";

  toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  //End toastr

  const [data, setdata] = useState([]);
  const [isBusy, setBusy] = useState(true);
  const fetchData = async function fetchData() {
    try{
      const response = await get("admin/files");
      setdata(response.data);
      setBusy(false);
    } catch(err)         {
      setBusy(false);
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const centerText = (val) => {
      return (
        <div style={{ textAlign: "center" }}>{val}</div>
      )
  };

  const changeStatus = async (val) => {
    let willChange = true;
    let msg = "Do you want to publish the insight file?"
    let subtext = "Once changed, user and organization members will have access to insight";
    if(val.status == "Published") {
      msg = "Do you want to unpublish the insight file?"
      subtext = "Once changed, user and organization members will not have access to insight."
    }

    willChange = await swal({
      title: msg,
      text: subtext,
      icon: "info",
      buttons: true,
      dangerMode: false,
    })
  
    if(willChange) {
      try{
         setBusy(true);
         let response = await put("/admin/files/change_status/"+val.status+"/"+val.id);
         if(response.success) {
            toastr.success(response.success,"");
            fetchData();
          } else {
            toastr.error(response.error,"");
          }
      }
      catch(e) {
        console.log(e)
        setBusy(false);
        toastr.error("Something went wrong","");
      }  
    }
  }


  const removeFile = async (val) => {
    let willDelete = await swal({
      title: "Do you want to remove this file?",
      text: "This insight file will be removed permanently from the application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })

    if(willDelete) {
      try{
          setBusy(true);
          let response = await del("/admin/files/remove_file/"+val);
          if(response.success) {
            toastr.success(response.success,"");
            fetchData();
          } else {
            toastr.error(response.error,"");
          } 
      }
      catch(e) {
        toastr.error("Something went wrong","");
        setBusy(false);
      }      
    }      
  }



  const statusRender = (cellProps) => {
        let val = cellProps.row.original.status == "Published" ? "success" : "warning";
        return (
            <Badge
              className={`font-size-12 me-2 bg-${val}`}
              pill
              >
              <a
                onClick={() => changeStatus(cellProps.row.original)}
                style={{color: "unset !important"}}
              >
                <strong>{cellProps.row.original.status}</strong>
              </a>
            </Badge>
        )
    };

    const actionRender = (cellProps) => {
      return (
        <div className="text-center">
          
          <Link
            to={"/admin/file-records/"+ cellProps.row.original.id+"/"+cellProps.row.original.schemaName}
            className="text-primary"
          >
            <i 
              className="mdi mdi-eye-outline font-size-18" 
              id={`viewtooltip${cellProps.row.original.id}`} />
              <UncontrolledTooltip placement="top" target={`viewtooltip${cellProps.row.original.id}`}>
                View
              </UncontrolledTooltip>
            </Link>&nbsp;&nbsp;

          <a
            className="text-danger"
            onClick={() => removeFile(cellProps.row.original.id)}
          >
            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${cellProps.row.original.id}`} />
            <UncontrolledTooltip placement="top" target={`deletetooltip${cellProps.row.original.id}`}>
              Delete
            </UncontrolledTooltip>
          </a>
        </div>
      )
    }

  const columns = useMemo(
        () => [
            {
                Header: 'File Name',
                accessor: 'insightFile',
            },
            {
                Header: 'Import Date',
                accessor: 'importDate',
            },
            {
                Header: 'Insight',
                accessor: 'category',
            },
            {
                Header: 'Template',
                accessor: 'template',
            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Status</div>),
                accessor: 'status',
                Cell: cellProps => centerText(
                                  statusRender(cellProps)),
                disableSortBy: true

            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Action</div>),
                accessor: 'action',
                Cell: cellProps => centerText(actionRender(cellProps)),
                disableSortBy: true,
                disableGlobalFilter:true,
            },
        ],
        []
    );

  const AddNew = () => {
    history.push("/admin/import");
  }

  return (
    <React.Fragment>
      <div className="inner-content">
        <div className="pr-page-title">
          <h2>Files</h2>
        </div>
        <BlockUi tag="div" blocking={isBusy}>
         <TableContainer
              columns={columns}
              data={data}
              isGlobalFilter={true}
              isAddOptions={true}
              customPageSize={10}
              className="tdTheme"
              handleOrderClicks={AddNew}
              addLable="File Import"
          />
        </BlockUi>
      </div>
  </React.Fragment>
  );
}

export default Users;
