import PropTypes, { string } from "prop-types";
import React, { useEffect,useState } from "react";
import "assets/css/style.css";
import "assets/css/custom.css";
import { Alert, Input, FormFeedback, Spinner } from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

import loginRightImage from "assets/images/templateImages/login-right-img-half-new.png";
import loginLogo from "assets/images/templateImages/Acumen-logo.png";
import loginRightIcon from "assets/images/nike.svg";
import loginRightIcon1 from "assets/images/p-2.svg";
import loginRightIcon2 from "assets/images/p-3.svg";
import loginRightIcon3 from "assets/images/p-4.svg";
import loginRightIcon4 from "assets/images/p-5.svg";

// actions
import { loginUser, apiError } from "../../store/actions";

//eyeIcons
import passwordEye from "assets/images/templateImages/eye-off.svg";
import passwordEyeOn from "assets/images/templateImages/eye_on.svg";

const Login = (props) => {
  if(!localStorage.getItem("authUser") == false)
  {
    const user = JSON.parse(localStorage.getItem("authUser"));
    const redirectURL = (user.role == 'admin') ? '/admin/dashboard' :'/insights/industry-leaders'; 
    props.history.push(redirectURL);
  }

  const pathname = window.location.pathname.replace("/", "");
  //meta title
  document.title="Login - Acumen";
  const dispatch = useDispatch();
  const [showPassword, setshowPassword] = useState(false);
  const [serverError,setserverError] = useState(null);
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .required("Please Enter Your Email")
        .email("Must be a valid email."),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: async (values) => {
      // console.log("in onsubmit formik: ", values);
      dispatch(loginUser(values, props.history));
    },
  });
  const { error, loading } = useSelector((state) => ({
    error: state.Login.error,
    loading: state.Login.loading,
  }));

  useEffect(() => {
    if (error) {
      if(typeof error === 'string'){
        setserverError(error)
      }
      else {
        validation.setErrors(error);
      }
    }
  }, [error]);

  useEffect(() => {
    dispatch(apiError("")); // Clear api error on load
    setserverError(null);
  }, []);
  
  var forgetButton = "";
  if (pathname != "admin") {
    var forgetButton = (
      <Link to={"/request-reset-password"} className="forgot-password">
        Forgot Password?
      </Link>
    );
    var signButton = (
      <div className="sign-up">
        <p>
          Don't have an account? <Link to="/register">Sign up</Link>
        </p>
      </div>
    );
  }

  return (
    <React.Fragment>
      <div className="common-box">
        <div className="login-logo">
          <a>
            {/* <img src={loginLogo} alt="Accumen logo" /> */}
            Acumen
          </a>
        </div>
        <div className="left-content">
          <div className="log-in-details">
            <div className="form-header">
              <h3>Log in</h3>
              <h5 className="welcome-text">
                Welcome Back! Please enter your details
              </h5>
            </div>
            <form
              className="login-details ca-stepFour"
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
                return false;
              }}
            >
              {serverError ? <Alert color="danger">{serverError}</Alert> : null}
              <div className="form-group input-marging">
                <label className="form-label">Email</label>
                <Input
                  className="form-control"
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
              <div
                className="form-group"
                style={{ position: "relative", marginBottom: "60px" }}
              >
                <label className="form-label">Password</label>
                <Input
                  className="passwordText"
                  name="password"
                  value={validation.values.password || ""}
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter your password"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  invalid={
                    validation.touched.password && validation.errors.password
                      ? true
                      : false
                  }
                />
                <img
                  src={showPassword ? passwordEyeOn : passwordEye}
                  className={
                    validation.touched.password && validation.errors.password
                      ? "error-eye"
                      : "eye-off"
                  }
                  onClick={() => setshowPassword(showPassword ? false : true)}
                  alt="password-eye"
                />
                {validation.touched.password && validation.errors.password ? (
                  <FormFeedback type="invalid">
                    {validation.errors.password}
                  </FormFeedback>
                ) : null}
                {forgetButton}
              </div>
              <div className="button-type">
                <button
                  type="submit"
                  onClick={() => console.log(validation)}
                  className="button-with-bg"
                  disabled={loading}
                >
                  {loading === true ? "Processing   " : "Log in"}
                  {loading && (
                    <Spinner
                      className="ms-2"
                      style={{ height: "1rem", width: "1rem" }}
                      color="light"
                    />
                  )}
                </button>
                {signButton}
              </div>
            </form>
          </div>
        </div>
        <div className="right-content">
          <div className="content_login">
            {/* <img src={loginRightImage} alt="dashboard" /> */}
            <h4>Acumen by G & Co.</h4>
            <h5>Advance in Retail: Transformative Insights into Action with our Live Data Benchmarking Soultion.</h5>

            <ul className="login_detail mx-0 p-0">
                <li>Real-time data on the market and competition</li>
                <li>Retail focused to provide deep expertise in the industry</li>
                <li>Actionable takeaways to quickly and clearly inform relevant teams</li>
                <li>Diverse consumer demographics across generation, gender, income, and consumer preferences</li>
                <li>Custom on-demand research in 48 hours to better inform your specific needs and purposes</li>
            </ul>

            <div className="partners_section w-100 mt-auto">
                <h6>Top Retail & Consumer Brands Place Their Trust in G & Co.</h6>
                <div className="d-flex justify-content-between align-items-center">
                    <p><img src={loginRightIcon} alt=" " /></p>
                    <p><img src={loginRightIcon1} alt=" " /></p>
                    <p><img src={loginRightIcon2} alt=" " /></p>
                    <p><img src={loginRightIcon3} alt=" " /></p>
                    <p><img src={loginRightIcon4} alt=" " /></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
