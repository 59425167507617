import React, { useMemo, useState, useEffect } from "react"
import {
  Container,
  Form,
  Modal,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Spinner,  
} from "reactstrap"

import { useHistory, Link } from 'react-router-dom';
import {get, del, put} from "../../helpers/api_helper";
import PropTypes from 'prop-types';
import TableContainer from '../../components/Common/TableContainer';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import BlockUi from 'react-block-ui';
import swal from "sweetalert";

const Brands = () => {

    const history = useHistory();
    const [data, setdata] = useState([]);
    const [modal_user, setmodal_user] = useState(false);
    const [isBusy, setBusy] = useState(true);    
    
    //Toastr
    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeOut");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);


    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    //End toastr

    const user = JSON.parse(localStorage.getItem("authUser"));
    const fetchData = async function fetchData() {
      try{
        const response = await get("admin/Brands");
        setdata(response.data);
        setBusy(false);
      } catch(err)         {
        setBusy(false);
      }
    }
    useEffect(() => {
      fetchData();
    }, []);

    const centerText = (val) => {
      return (
        <div style={{ textAlign: "center" }}>{val}</div>
      )
    }

    const removeUser = async (val) => {
      let willDelete = await swal({
        title: "Do you want to remove this brand?",
        text: "Dependant, insights will also be removed.",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      })

      if(willDelete) {
        try {
          setBusy(true);
          let response = await del("/admin/brands/remove_brand/"+val);
          if(response.success) {
            toastr.success(response.success,"");
            fetchData();
          } else {
            toastr.error(response.error,"");
          } 
        }
        catch(e) {
          toastr.error("Something went wrong","");
          setBusy(false);
        }      
      }
    }

    const changeStatus = async (val) => {
      try{
         setBusy(true);
         let response = await put("/admin/brands/change_status/"+val);
         if(response.success) {
            toastr.success(response.success,"");
            fetchData();
          } else {
            toastr.error(response.error,"");
          }
      }
      catch(e) {
        setBusy(false);
        toastr.error("Something went wrong","");
      }    
      
    }

    
    const actionRender = (cellProps) => {
      return (
        <div className="text-center">
          
          <Link
            to={"/admin/brand-edit/"+cellProps.row.original.id}
          >
            <i 
              className="mdi mdi-pencil font-size-18 text-success" 
              id={`edittooltip${cellProps.row.original.id}`} 
              data-record={JSON.stringify(cellProps.row.original)} />
                <UncontrolledTooltip placement="top" target={`edittooltip${cellProps.row.original.id}`}>
                  Edit
                </UncontrolledTooltip>
          </Link>&nbsp;&nbsp;

          <a
            className="text-danger"
            onClick={() => removeUser(cellProps.row.original.id)}
          >
            <i className="mdi mdi-delete font-size-18" id={`deletetooltip${cellProps.row.original.id}`} />
            <UncontrolledTooltip placement="top" target={`deletetooltip${cellProps.row.original.id}`}>
              Delete
            </UncontrolledTooltip>
          </a>
        </div>
      )
    }
    
    const statusRender = (cellProps) => {
        let val = cellProps.row.original.status;

        return (
            <Badge
              className={"font-size-12 me-2 bg-" + 
              (val === "Active" ? "success" : "danger" 
                && val === "Inactive" ? "warning" : "danger")}
              pill
            >
              <a
                onClick={() => changeStatus(cellProps.row.original.id)}
                style={{color: "unset !important"}}
              >
                <strong>{val}</strong>
              </a>
            </Badge>
        )
    };

    const logoRender = (logo) => {
      return (
          <img 
            className="edit-user-img"
            style={{width:"40px", height:"40px"}}
            src={logo} 
          />
        );
    }


    const columns = useMemo(
        () => [
            {
                Header: 'Logo',
                Cell: cellProps => logoRender(cellProps.row.original.logo),
                disableGlobalFilter:true,
            },
            {
                Header: 'Name',
                accessor: 'name',
            }, 
            {
                Header: 'Industry',
                accessor: 'industry',
            },
            {
                Header: 'Country/Region',
                accessor: 'country',
            },
            {
              Header: 'Innovation Score',
              accessor: 'score',
            },          
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Status</div>),
                accessor: 'status',
                Cell: cellProps => centerText(
                                  statusRender(cellProps)),
                disableSortBy: true

            },
            {
                Header: () => (
                  <div
                    style={{
                      textAlign:"center"
                    }}
                >Action</div>),
                accessor: 'action',
                Cell: cellProps => centerText(actionRender(cellProps)),
                disableSortBy: true,
                disableGlobalFilter:true,
            }
        ],
        []
    );

  const editForm = (e) => {
    
    const record = JSON.parse(e.target.getAttribute("data-record"));    
    setmodal_user(!modal_user);

  }

  const AddNew = () => {
    history.push("/admin/brand-add");
  }
  
  //meta title
  document.title="Brands - Acumen";
  return (
    <React.Fragment>
      <div className="inner-content">
      <div className="pr-page-title">
          <h2>Brands List</h2>
      </div>
        <BlockUi tag="div" blocking={isBusy}>
          <TableContainer
                columns={columns}
                data={ isBusy ? [] : data}
                isGlobalFilter={true}
                isAddOptions={true}
                customPageSize={10}
                className="tdTheme"
                handleOrderClicks={AddNew}
                addLable="Add Brand"
                isBusy={isBusy}
            />
          </BlockUi>

      </div>

  </React.Fragment>
  );
}

Brands.propTypes = {
    preGlobalFilteredRows: PropTypes.any,
};

export default Brands;
