import PropTypes from "prop-types";
import React, { useEffect, useContext, useState, useCallback } from "react";

import { withRouter } from "react-router-dom";
import {
  changeLayout,
  changeSidebarTheme,
  changeSidebarThemeImage,
  changeSidebarType,
  changeTopbarTheme,
  changeLayoutWidth,
  showRightSidebarAction
} from "../../store/actions";

// Layout Related Components
import Header from "./Header";
import Sidebar from "./Sidebar";
import Footer from "./Footer";
import RightSidebar from "../CommonForBoth/RightSidebar";

//redux
import { useSelector, useDispatch } from "react-redux";

import io from "socket.io-client";
// import { socket, SocketContext } from '../../socketContext';
import UserContext from "../../helpers/AuthType/session_helper";
import { get } from "../../helpers/api_helper";

const Layout = props => {
  const dispatch = useDispatch();
  const [notificationStatus, setnotificationStatus] = useState(false);
  const {
    isPreloader,
    leftSideBarThemeImage,
    layoutWidth,
    leftSideBarType,
    topbarTheme,
    showRightSidebar,
    leftSideBarTheme,
  } = useSelector(state => ({
    isPreloader: state.Layout.isPreloader,
    leftSideBarThemeImage: state.Layout.leftSideBarThemeImage,
    leftSideBarType: state.Layout.leftSideBarType,
    layoutWidth: state.Layout.layoutWidth,
    topbarTheme: state.Layout.topbarTheme,
    showRightSidebar: state.Layout.showRightSidebar,
    leftSideBarTheme: state.Layout.leftSideBarTheme,
  }));

  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const toggleMenuCallback = () => {
    if (leftSideBarType === "default") {
      dispatch(changeSidebarType("condensed", isMobile));
    } else if (leftSideBarType === "condensed") {
      dispatch(changeSidebarType("default", isMobile));
    }
  };

  //hides right sidebar on body click
  const hideRightbar = (event) => {
    var rightbar = document.getElementById("right-bar");
    //if clicked in inside right bar, then do nothing
    if (rightbar && rightbar.contains(event.target)) {
      return;
    } else {
      //if clicked in outside of rightbar then fire action for hide rightbar
      dispatch(showRightSidebarAction(false));
    }
  };

  /*
  layout  settings
  */

  useEffect(() => {
    //init body click event fot toggle rightbar
    document.body.addEventListener("click", hideRightbar, true);

     
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(changeLayout("vertical"));
  }, [dispatch]);

  useEffect(() => {
    if (leftSideBarTheme) {
      dispatch(changeSidebarTheme(leftSideBarTheme));
    }
  }, [leftSideBarTheme, dispatch]);

  useEffect(() => {
    if (leftSideBarThemeImage) {
      dispatch(changeSidebarThemeImage(leftSideBarThemeImage));
    }
  }, [leftSideBarThemeImage, dispatch]);

  useEffect(() => {
    if (layoutWidth) {
      dispatch(changeLayoutWidth(layoutWidth));
    }
  }, [layoutWidth, dispatch]);

  useEffect(() => {
    if (leftSideBarType) {
      dispatch(changeSidebarType(leftSideBarType));
    }
  }, [leftSideBarType, dispatch]);

  useEffect(() => {
    if (topbarTheme) {
      dispatch(changeTopbarTheme(topbarTheme));
    }
  }, [topbarTheme, dispatch]);

  /* SOCKET IO */
  const user = JSON.parse(localStorage.getItem("authUser"));
  //console.log(user.profilePic.substr(-11),'userr')

  const [userSessionData, setuserSessionData] = useContext(UserContext);
  const [localData, setlocalData] = useState(JSON.parse(window.localStorage.getItem("authUser")));
  //console.log(userDatas,"set1");
  useEffect(()=>{
    setuserSessionData(JSON.parse(window.localStorage.getItem("authUser")));
  },[localData]);

  /* SOCKET STARTS*/
  useEffect(() => {
    console.log("WS: ", process.env.REACT_APP_SOCKET_IO_URL)
      if(props.location.pathname.indexOf("/admin") == -1) {
        const socket = io(process.env.REACT_APP_SOCKET_IO_URL, { 
          transports: ['polling','websocket'],
          query: `token=${localData.accessToken}`
        });

        // as soon as the component is mounted, do the following tasks:
        socket.on('connect', function(e) {
          console.log('connected')
        });
        
        // Join with email
        socket.emit('join', {email: userSessionData.email});
        
        socket.on("message", async function(data) {
          let msg = data.msg;
          //console.log(msg);
          if(msg == 'updated user details') {
            let { success, refreshed } = await get("user/refresh-token");
            let newData = Object.assign({}, localData, refreshed);
            window.localStorage.setItem("authUser", JSON.stringify(newData));
            setlocalData(newData);
          }
          if(msg == 'updated notification'){
            setnotificationStatus(data.data);
          }
        });

        socket.on('connect_error', function(err){
            console.log("Error: ", err.message);
        });
      }
      return () => {
        try {
          socket.off("disconnect", "User disconnected"); 
        } catch(err) {}
      };
    }, []);
  /* SOCKET ENDS*/
  const verticalComponent = <div id="layout-wrapper" className="row mx-0">
        {/*<Header toggleMenuCallback={toggleMenuCallback} />*/}
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="col-10 col-lg-10 p-0" style={{background:"white"}}>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade" id="insights" role="tabpanel" aria-labelledby="insights-tab">
            </div>
            <div className="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
              <Header toggleMenuCallback={toggleMenuCallback}/>
              {props.children}
            </div>
          </div>
        </div>
        {/*<Footer />*/}
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    const statusUpdater = (data) =>{
      //console.log(data);
        setnotificationStatus(data);
      }
  return (
    <React.Fragment>
      {/* <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
            <div className="chase-dot" />
          </div>
        </div>
      </div> */}
      <div id="layout-wrapper" className="row mx-0">
        {/*<Header toggleMenuCallback={toggleMenuCallback} />*/}
        <Sidebar
          theme={leftSideBarTheme}
          type={leftSideBarType}
          isMobile={isMobile}
        />
        <div className="col-10 col-lg-10 p-0" style={{background:"white"}}>
          <div className="tab-content" id="pills-tabContent">
            <div className="tab-pane fade" id="insights" role="tabpanel" aria-labelledby="insights-tab">
            </div>
            <div className="tab-pane fade show active" id="settings" role="tabpanel" aria-labelledby="settings-tab">
              <Header toggleMenuCallback={toggleMenuCallback} statusUpdater={statusUpdater}status={notificationStatus} />
              {props.children}
            </div>
          </div>
        </div>
        {/*<Footer />*/}
      </div>
      {showRightSidebar ? <RightSidebar /> : null}
    {/*  {props.location.pathname.indexOf("/admin") == -1 ? verticalComponent : 
        <SocketContext.Provider  value={socket}> 
        verticalComponent
        </SocketContext.Provider>
      }*/}
    </React.Fragment>
  );
};

Layout.propTypes = {
  changeLayoutWidth: PropTypes.func,
  changeSidebarTheme: PropTypes.func,
  changeSidebarThemeImage: PropTypes.func,
  changeSidebarType: PropTypes.func,
  changeTopbarTheme: PropTypes.func,
  children: PropTypes.object,
  isPreloader: PropTypes.any,
  layoutWidth: PropTypes.any,
  leftSideBarTheme: PropTypes.any,
  leftSideBarThemeImage: PropTypes.any,
  leftSideBarType: PropTypes.any,
  location: PropTypes.object,
  showRightSidebar: PropTypes.any,
  topbarTheme: PropTypes.any,
};

export default withRouter(Layout);
