import React from "react"
import {
  Container,
} from "reactstrap"

const FilesList = () => {
  //meta title
  // document.title="Dashboard";
  return (
    <React.Fragment>
    <div className="page-content">
      <Container fluid>
        <h4>Files List</h4>
      </Container>
    </div>
  </React.Fragment>
  );
}

export default FilesList;
