import React from "react";
import { Row, FormFeedback } from "reactstrap";
import { useField, ErrorMessage } from "formik";
import { Input } from "reactstrap";
const InputFile = ({ placeholder, disabled = "", ...Reactprops }) => {
  const [field, meta] = useField(Reactprops);
  return (
    <Row className="mb-3">
      <label htmlFor="date" className="col-md-2 col-form-label form-label">
        {placeholder}
      </label>
      <div className="col-md-10">
        <Input
          className={
            meta.touched && meta.error
              ? "input_error_border form-control line-height"
              : "form-control line-height"
          }
          type={field.type}
          name={field.name}
          disabled={disabled}
          invalid={meta.touched && meta.error
              ? true
              : false
          }
          {...Reactprops}
        />
        {meta.touched && meta.error ? (
           <FormFeedback type="invalid">
           {meta.error}
         </FormFeedback>
        ):null}
      </div>
    </Row>
  );
};
export default InputFile;
