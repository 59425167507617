import React, { useState, useEffect,useContext } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import UserContext from "../../../helpers/AuthType/session_helper";
// users
import user1 from "../../../assets/images/users/avatar-1.jpg";
import settings from "assets/images/settings.svg";
import logout from "assets/images/logout.svg";
import arrowdown from "assets/images/down-arrow.png";
import downarrow from "assets/images/expand-arrow.svg";

const ProfileMenu = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [userSessionData, setuserSessionData] = useContext(UserContext);
  const [username, setusername] = useState("Admin");
  const user = JSON.parse(localStorage.getItem("authUser"));
  const settingsUrl = user.role == "admin" ? "/admin/settings" : "/settings";
  const logoutUrl = user.role == "admin" ? "/admin/logout" : "/logout";
  //console.log(userSessionData,"topbar");
  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);
  function userImage() {
    const obj = JSON.parse(localStorage.getItem("authUser"));
    return obj.profilePic;
  }
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item ps-1"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user hw120 me-3"
            src={userSessionData.profilePic ? userSessionData.profilePic : user.profilePic }
            alt="Header Avatar"
          />
          {/* <span className="d-none d-xl-inline-block ms-2 me-1"></span> */}
          {/* <i className="mdi mdi-chevron-down d-xl-inline-block" /> */}
          <img src={downarrow} className="arrow-down" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          <DropdownItem tag={Link} to={settingsUrl}>
            {" "}
            {/* <i className="bx bx-user font-size-16 align-middle me-1" /> */}
            {/* <img src={settings} style={{paddingRight:'5%'}}/> */}
            {props.t("View profile")}{" "}
          </DropdownItem>
          {/* <DropdownItem tag="a" href="/crypto-wallet">
            <i className="bx bx-wallet font-size-16 align-middle me-1" />
            {props.t("My Wallet")}
          </DropdownItem>
          <DropdownItem tag="a" href="#">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="auth-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item">
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </Link> */}
          {/* <div className="dropdown-divider" /> */}
          {/* <DropdownItem tag={Link} to={logoutUrl}> */}
            {/* <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" /> */}
            {/* <img src={logout} style={{paddingRight:'5%'}}/>
            <span>{props.t("Logout")}</span> */}
          {/* </DropdownItem> */}
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any
};

const mapStatetoProps = state => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(ProfileMenu))
);
