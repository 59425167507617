import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";
import { object } from "prop-types";

const roundOff = new Intl.NumberFormat("en-US", {
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

function percentNormalizer(data) {
  let tosum = 0;
  for (let i in data) {
    let formatted = parseInt(roundOff.format(data[i].value));
    tosum += formatted;
  }
  if (tosum > 100) {
    for (let i in data) {
      data[i].value = data[i].value - 1;
      break;
    }
  }
  return data;
}

const DountChart = ({ map, name, colorCode, indexData, iddata = "noID", lableValue }) => {
  const myRef = useRef();
  const [errorMessage, setErrorMessage] = useState(null);
  const id = "do" + indexData + iddata;
  console.log(lableValue)
  //console.log(colorCode)
  try {
    const colordata = colorCode.map((obj) => obj.unique);
    const chartData = Array.from(Object.keys(map), (x, i) => {
      return {
        name: x,
        value: map[x],
        lable: name,
      };
    });
    //console.log(colordata);
    let data = chartData;

    percentNormalizer(data); // Call by reference and normalize if sum goes above 100

    useEffect(() => {
      myRef.current.innerHTML = "";
      const colors = colordata;

      const svgContainer = d3.select("." + id).node();

      const width = 200;
      const height = 200;
      const margin = 15;
      let radius = Math.min(width, height) / 2; //  - margin;
      // legend Position
      let legendPosition = d3
        .arc()
        .innerRadius(radius / 1.75)
        .outerRadius(radius);

      // Create SVG
      const svg = d3
        .select("." + id)
        .insert("svg")
        /*.attr("width", "100%")
                  .attr("height", "100%")*/
        .attr("viewBox", "0 0 " + width + " " + width)
        //.attr('preserveAspectRatio','xMinYMin')
        .append("g")
        .attr(
          "transform",
          "translate(" +
            Math.min(width, height) / 2 +
            "," +
            Math.min(width, height) / 2 +
            ")"
        );

      let arc = d3
        .arc()
        .innerRadius(radius / 1.75) //  This is the size of the donut hole
        .outerRadius(radius);

      let pie = d3
        .pie()
        .value((d) => d.value)
        .sort(null);
      let data_ready = pie(data);
      // Donut partition
      const tooltip = d3
        .select(`.${id}`)
        .append("div")
        .attr("class", "tooltip-barplot")
        .style("visibility", "hidden");
      svg
        .selectAll("whatever")
        .data(data_ready)
        .enter()
        .append("path")
        .on("mouseover", (event, d) => {
          //console.log(d3.select(this).attr("x"));
          console.log(d);
          tooltip
            .transition()
            .delay(0)
            .duration(50)
            .style("visibility", "visible")
            .style("opacity", "1");
          //console.log(d);
          tooltip
            .html(
              `<div class="tooltipDesign">
                    <h5 class="tt-head" style="text-align: initial">
                      ${d.data.lable}
                    </h5>
                    <ul>
                      <li style="margin:0px">
                      <p><span 
                            class="color1-dot"
                            style="background:${colors[d.index]}"
                            ></span> 
                          <span class="tooltip_text">${d.data.name}</span>
                      </p>
                        <label>${d.value}${lableValue?.['Unit'] ? lableValue?.['Unit'] : "%"}</label>
                      </li>
                     </ul>
                    </div>`
            )
            .style("left", event.layerX + 10 + "px")
            .style("top", event.layerY - 100 + "px");
        })
        .on("mousemove", function (event) {
          return tooltip
            .style("left", event.layerX + 10 + "px")
            .style("top", event.layerY - 100 + "px");
        })
        .on("mouseout", () =>
          tooltip.transition().style("visibility", "hidden").style("opacity", "0")
        )
        .transition()
        .delay((d, i) => {
          return i * 500;
        })
        .duration(500)
        .attrTween("d", (d) => {
          var i = d3.interpolate(d.startAngle, d.endAngle);
          return function (t) {
            d.endAngle = i(t);
            return arc(d);
          };
        })
        .attr("fill", (d) => colors[d.index])
        

      // Legend group and legend name
      svg
        .selectAll("mySlices")
        .data(data_ready)
        .enter()
        .append("g")
        .attr("transform", (d) => {
          let v = legendPosition.centroid(d);
          let v0 = v[0];
          if (d.data.value < 50) {
            v0 = v[0] > 0 ? v[0] - 2 : v[0] + 2;
          } else if (d.data.value > 65) {
            v0 = v[0] > 0 ? v[0] + 2 : v[0] - 2;
          }

          let v1 = v[1];
          if (d.data.value < 25) {
            v1 = v[1] > 0 ? v[1] - 2 : v[1] + 2;
          } else if (d.data.value > 80) {
            v1 = v[1] > 0 ? v[1] + 2 : v[1] - 2;
          }
          //console.log(d);
          return `translate(${v0},${v1})`;
        }) //
        .attr("class", "legend-g")
        .style("user-select", "none")
        .style("pointer-events", "none")
        .append("text")
        .text(
          (d) =>
            (d.data.value % 1 != 0
              ? roundOff.format(d.data.value)
              : d.data.value) + `${lableValue?.['Unit'] ? lableValue?.['Unit'] : "%"}`
        )
        .style("text-anchor", "middle")
        .style("font-weight", 400)
        .style("fill", "#FFFFFF")
        .style("font-size", "11px");

      //Label for value
      svg
        .selectAll(".legend-g")
        .append("text")
        .text("") //(d)=>{ return  d.data.value}
        .style("fill", "#FFFFFF")
        .style("font-size", 12)
        .style("text-anchor", "middle")
        .attr("y", 16);
    }, [map]);
  } catch (err) {
    console.log(err);
    return (
      <ErrorMessage
        Message={"Not able to generate graph for the retrived data"}
      />
    );
  }
  return (
    <>
      {errorMessage == null && (
        <div className="donutChart" style={{ width: "240px" }}>
          <div className={id} ref={myRef}></div>
          <div className="visRetailer">{name}</div>
        </div>
      )}
    </>
  );
};

export default DountChart;
