import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import BlockUi from "react-block-ui";
import ImportContext from "helpers/Context/ImportContext";
import { Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputText from "../../../components/inputtext";
import SelectField from "../../../components/SelectInput";

//import all chart form
import GroupBarChartVertical from "./chartForms/GroupBarChartVertical";
import GroupBarChartHorizontal from "./chartForms/GroupBarChartHorizontal";
import HeatMap from "./chartForms/HeatMap";
import StackedBarPlot from "./chartForms/StackedBarplot";
import Multibarchart from "./chartForms/Multibarchart";
import PieChart from "./chartForms/PieChart";
import StackedHorizontal from "./chartForms/StackedHorizontal";
import StackedVertical from "./chartForms/StackedVertical";
import RadarChart from "./chartForms/RadarChart";
import Table from "./chartForms/Table";
import FormAligner from "../../../components/FormAligner";

const chartTypes = {
  GroupBarChartVertical: "Group Bar Chart Vertical",
  GroupBarChartHorizontal: "Group Bar Chart Horizontal",
  HeatMap: "Heat Map",
  MultiBarChart: "Multi-bar Comparison Chart",
  pieChart: "Pie Chart",
  StackedHorizontal: "Stacked Bar Chart Horizontal",
  StackedVertical: "Stacked Bar Chart Vertical",
  StackedBarPlot:"Stacked Bar Plot",
  Table: "Table",
};

var ChartComponent = null;

const SubinsightComponent = ({
  index,
  save,
  formSelected,
  subInsightsData,
  component = [],
  clickIndicator,
  ...rest
}) => {
  ////console.log(save);
  ////console.log(index);

  const chartInfos = {
    chartType: "",
    chartData: null,
  };
  const subInsights = true;
  const formikRef = useRef();
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [subInsightsCount, setsubInsightsCount] = useState(1);
  const [chartFormdata, setchartFormdata] = useState(chartInfos);
  const { chartType, chartData } = chartFormdata;
  const [chartState, setChartState] = useState("");
  const [loader, setloader] = useState(false);
  function getcomponent(chart) {
  switch (chart) {
    case "GroupBarChartVertical":
       return(
        <GroupBarChartVertical
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          id={component.id}
          {...rest}
        />
      );
    case "GroupBarChartHorizontal":
      return(
        <GroupBarChartHorizontal
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          id={component.id}
          {...rest}
        />
      );
    case "HeatMap":
      return(
        <HeatMap
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          id={component.id}
          {...rest}
        />
      );
    case "MultiBarChart":
      return(
        <Multibarchart
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          id={component.id}
          {...rest}
        />
      );
    case "pieChart":
      return(
        <PieChart
          subInsights={subInsights}
          save={save == index}
          index={index}
          id={component.id}
          clickIndicator={clickIndicator}
          {...rest}
        />
      );
    case "StackedHorizontal":
      return(
        <StackedHorizontal
          subInsights={subInsights}
          save={save == index}
          index={index}
          id={component.id}
          clickIndicator={clickIndicator}
          {...rest}
        />
      );
    case "StackedVertical":
      return(
        <StackedVertical
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          id={component.id}
          {...rest}
        />
      );
      case "StackedBarPlot":
        return(
          <StackedBarPlot
            subInsights={subInsights}
            save={save == index}
            index={index}
            clickIndicator={clickIndicator}
            id={component.id}
            {...rest}
          />
        );
    case "Radar":
      return(
        <RadarChart
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          {...rest}
        />
      );
    case "Table":
      return(
        <Table
          subInsights={subInsights}
          save={save == index}
          index={index}
          clickIndicator={clickIndicator}
          id={component.id}
          {...rest}
        />
      );
    default:
      ChartComponent = "";
      break;
  }
}
  function handleChartChange(e, id){
    delete ImportContextData[`subinsight.${id}.data`];
  }
  // useMemo(() => {
  //   //console.log(chartState);
  //    formSelected(chartState, index);
  // }, [chartState]);
  useEffect(() => {
    //console.log(component);
    formikRef.current?.setFieldValue(
      "chartType",
      component.hasOwnProperty('chartType') ? component.chartType : ""
    );
    setChartState( component.hasOwnProperty('chartType') ? component.chartType : "");
  }, [component]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setchartFormdata({ ...chartFormdata, [name]: value });
  };

  const validationSchema = {
    chartType: Yup.string()
    .required("is required")
    }

   
  return (
    <BlockUi tag="div">
      <div className="mb-6"></div>
      <Formik
        innerRef={formikRef}
        initialValues={{chartType}}
        validationSchema={Yup.object(validationSchema)}
          // mapped: Yup.array().of(
          //   Yup.object().shape({
          //     dataType: Yup.string()
          //       .required("This field is required")
          //       .test(
          //         "check required",
          //         "This field is required",
          //         function (value) {
          //           if (value == undefined || value == "Select") {
          //             return false;
          //           } else {
          //             return true;
          //           }
          //         }
          //       ),
           
        
       
        onSubmit={(values) => {
          ////console.log(values);
        }}
        render={({ values, touched, ...formik }) => (
          <>
            <Form>
              <div className="mb-4"></div>
              <SelectField
                type="select"
                name={"chartType"}
                placeholder={"Chart Type"}
                //submited={submited}
                options={chartTypes}
                onInput={(e) => {
                  handleInputChange(e);
                  formik.setFieldTouched(e.target.name, true);
                  formik.setFieldValue(e.target.name, e.target.value);
                  handleChartChange(e, component.id);
                  formSelected(e.target.value, index);
                  setChartState(e.target.value)
                }}
                value={chartState}
              />
            </Form>
          </>
        )}
      />
      {/* {component.length != 0 ? component[0] : null} */}
      {/* {//console.log(component,"compoenet")} */}
      {getcomponent(chartState)}
      {/* {ChartComponent} */}
    </BlockUi>
  );
};

export default SubinsightComponent;
