import React, { useState, useMemo, useRef, useContext, useEffect } from "react";
import { CardTitle, Row } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import ImportContext from "helpers/Context/ImportContext";
import { post } from "helpers/api_helper";
import SingleChartSubmit from "helpers/Function/SingleChartSubmit";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import InputText from "pages/Admin/components/inputtext";
import SelectField from "pages/Admin/components/SelectInput";
import UniqueList from "pages/Admin/components/UniqueList";
import FormAligner from "pages/Admin/components/FormAligner";
import MultiCheckSelect from "pages/Admin/components/MultiCheckSelect";

import toastr from "toastr";
import "toastr/build/toastr.min.css";
const DefaultBarColor = ["#49CC86", "#3349FF", "#FFE500", "#FF3B30", "#AF52DE"];
export default function StackedHorizontal({
  subInsights = null,
  Headers = [],
  id = "",
  save = null,
  index = null,
  clickIndicator = null,
  subInsightsData = null,
}) {
  const chartName = "StackedHorizontal";
  const history = useHistory();
  //console.log(subInsights);
  const [uniqueData, setUniqueData] = useState([]);
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [staticFile, setStaticFile] = useState(ImportContextData?.FileType ? ImportContextData?.FileType : false);
  const [checked, setChecked] = useState({});
  const FormFields = staticFile
    ? {
        InsightDescription: "",
        lableY: "",
        valueY: "0",
        unit: "",
        lableX: "",
        uniqueselect: [],
        color: [],
      }
    : {
        InsightDescription: "",
        lableY: "",
        valueY: "",
        unit: "",
        lableX: "",
        valueX: "",
        averageOf: "",
        color: [],
      };
  const updateChecked = (e, formik) => {
    if (saveStatus) {
      handleFormChange(e);
    }
    //formik.setFieldValue(e.target.name, e.target.value);
    if (
      checked[e.target.name] != undefined
        ? checked[e.target.name].includes(e.target.value)
        : false
    ) {
      let arr = checked[e.target.name];
      arr = arr.filter((data) => data !== e.target.value);
      setChecked({ ...checked, [e.target.name]: [...arr] });
      formik.setFieldValue(e.target.name, [...arr]);
    } else {
      setChecked({
        ...checked,
        [e.target.name]: checked[e.target.name]
          ? [...checked[e.target.name], e.target.value]
          : [e.target.value],
      });
      formik.setFieldValue(
        e.target.name,
        checked[e.target.name]
          ? [...checked[e.target.name], e.target.value]
          : [e.target.value]
      );
    }
  };
  useEffect(() => {
    setUniqueData(checked?.uniqueselect ? checked?.uniqueselect : []);
    if (checked?.uniqueselect) {
      formikRef.current.setFieldValue(
        "color",
        Array.from(checked?.uniqueselect, (x, i) => {
          return {
            unique: DefaultBarColor[i] ? DefaultBarColor[i] : "#000000",
          };
        })
      );
    }
  }, [checked]);
  const formikRef = useRef();
  const [FormData, setFormData] = useState(FormFields);
  const { lableY, valueY, xaxis } = FormFields;
  const [submited, setSubmited] = useState(false);
  const [uniqueRow, setUniqueRow] = useState(null);
  const [saveStatus, setSaveStatus] = useState(false);
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  //console.log(FormData);
  const handleInputChange = (e) => {
    //console.log(formikRef.current?.values);
  };
  const handleFormChange = (e) => {
    setImportContextData({
      ...ImportContextData,
      ["subData"]: [],
      ["SubinsightNo"]: index,
    });
    setSaveStatus(false);
    // toastr.warning(`Sub-Insight-${index + 1} unsaved successfully`);
  };
  useEffect(() => {
    if (subInsights != null) {
      FormFields["subInsghtsTitle"] = "";
      //console.log(JSON.stringify(formikRef.current?.values) == JSON.stringify(FormFields));
      //console.log(JSON.stringify(formikRef.current?.values),JSON.stringify(FormFields));
      if (
        JSON.stringify(formikRef.current?.values) !=
        JSON.stringify(FormFields && subInsights != null)
      ) {
        setImportContextData({
          ...ImportContextData,
          [`subinsight.${id}.data`]: formikRef.current?.values,
        });
        if (uniqueData.length != 0) {
          setImportContextData({
            ...ImportContextData,
            [`subinsight.${id}.data`]: {
              ...formikRef.current?.values,
              ["unique"]: uniqueData,
            },
          });
        }
      }
    }
  }, [formikRef.current?.values, uniqueData]);
  const handleFormSubmit = (value) => {
    value["uniqueObject"] = uniqueData;
    if (subInsights == null) {
      value["chartName"] = chartName;
      let res = SingleChartSubmit(value, ImportContextData?.id);
      res
        .then((data) => {
          //console.log(data);
          if (data?.success) {
            toastr.success(data?.success, "");
            history.push("/admin/chartimport/add", { TabNo: "3" });
          } else {
            toastr.error("Not Able to add chart data", "");
          }
        })
        .catch((err) => console.log(err));
    } else {
      value["chartName"] = chartName;
      //console.log(value);
      setImportContextData({
        ...ImportContextData,
        ["subData"]: value,
        ["SubinsightNo"]: index,
      });
      setSaveStatus(true);
      toastr.success(`Sub-Insight-${index + 1} saved successfully`);
    }
  };
  const getUniqueData = async (unique) => {
    try {
      let res = await post(
        `admin/insights/getUniqueValue/${ImportContextData?.id}`,
        {
          value: parseInt(unique),
        }
      );
      setUniqueData(res.data);
      formikRef.current.setFieldValue(
        "color",
        Array.from(res.data, (x, i) => {
          return {
            unique: DefaultBarColor[i] ? DefaultBarColor[i] : "#000000",
          };
        })
      );
    } catch (error) {
      toastr.error("Not Able to get Unique Data");
      console.error(error.response);
    }
  };
  const validationSchema = staticFile
    ? {
        uniqueselect: Yup.array().test(
          "check required",
          "Value field is required",
          function (value, formdatas) {
            if (value == undefined || value?.length == 0) {
              return false;
            } else {
              return true;
            }
          }
        ),
        //lableX: Yup.string().required("is required"),
        unit: Yup.string().required("is required"),
      }
    : {
        //lableY: Yup.string().required("is required"),
        valueY: Yup.string()
          .required("field is required")
          .test("check required", "field is required", function (value) {
            if (value == undefined || value == "Select") {
              return false;
            } else {
              return true;
            }
          }),
        //lableX: Yup.string().required("is required"),
        unit: Yup.string().required("is required"),
        valueX: Yup.string()
          .required("field is required")
          .test("check required", "field is required", function (value) {
            if (value == undefined || value == "Select") {
              return false;
            } else {
              return true;
            }
          }),
        averageOf: Yup.string()
          .required("field is required")
          .test("check required", "field is required", function (value) {
            if (value == undefined || value == "Select") {
              return false;
            } else {
              return true;
            }
          }),
      };
  if (subInsights != null) {
    validationSchema["subInsghtsTitle"] = Yup.string().required("is required");
    FormFields["subInsghtsTitle"] = "";
  }
  useMemo(() => {
    if (uniqueRow != null && uniqueRow != "Select" && uniqueRow != "") {
      getUniqueData(uniqueRow);
      formikRef.current?.setFieldValue("color", []);
    }
  }, [uniqueRow]);
  useMemo(() => {
    ////console.log(clickIndicator,"clickIndicator");
    //console.log(clickIndicator, save);
    if (save) {
      formikRef.current?.handleSubmit();
    }
  }, [save, clickIndicator]);
  return (
    <>
      <CardTitle className="h5 mb-4">Stacked Horizontal</CardTitle>
      <div className="mb-5"></div>
      <div>
        {staticFile ? (
          <Formik
            innerRef={formikRef}
            initialValues={
              ImportContextData.hasOwnProperty(`subinsight.${id}.data`)
                ? ImportContextData[`subinsight.${id}.data`]
                : FormFields
            }
            validationSchema={Yup.object(validationSchema)}
            onSubmit={(values) => {
              values["color"] = values["color"].slice(0, uniqueData.length);
              handleFormSubmit(values);
            }}
            render={({ values, touched, ...formik }) => (
              <Form
                onChange={(e) => {
                  if (saveStatus) {
                    handleFormChange(e);
                  }
                }}
              >
                {subInsights != null ? (
                  <InputText
                    type="text"
                    name={"subInsghtsTitle"}
                    placeholder={"Sub-Insights Title"}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                ) : null}
                <InputText
                  type="textarea"
                  className="description"
                  name={"InsightDescription"}
                  placeholder={"Insight Description"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
                <InputText
                  type="text"
                  name={"unit"}
                  placeholder={"Unit"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
                <CardTitle className="h5 mb-4">Y axis </CardTitle>
                <hr />
                <InputText
                  type="text"
                  name={"lableY"}
                  placeholder={"Label Y"}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <CardTitle className="h5 mb-4">X axis </CardTitle>
                <hr />
                <InputText
                  type="text"
                  name={"lableX"}
                  placeholder={"Label X"}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <FormAligner lable="Values of">
                  <div
                    role="group"
                    aria-labelledby="checkbox-group"
                    onClick={(e) => {
                      if (e.target.name != "") {
                        updateChecked(e, formik);
                      }
                    }}
                  >
                    <MultiCheckSelect
                      name={`uniqueselect`}
                      CoustomValueData={
                        ImportContextData?.Headers
                          ? ImportContextData?.Headers
                          : []
                      }
                      onBlur={(e) => {
                        formik.setFieldTouched(`uniqueselect`, true);
                      }}
                    >
                      {ImportContextData?.Headers &&
                        (ImportContextData?.Headers
                          ? ImportContextData?.Headers
                          : []
                        ).map((key, i) => {
                          return (
                            <li className={"show"} key={i}>
                              <Field
                                type="checkbox"
                                name={`uniqueselect`}
                                value={key}
                                className="styled-checkbox dropdown-Checkbox"
                              />
                              <label htmlFor={`uniqueselect`}>{`${key}`}</label>
                            </li>
                          );
                        })}
                    </MultiCheckSelect>
                    <ErrorMessage
                      name={`uniqueselect`}
                      component="div"
                      className="error"
                    />
                  </div>
                </FormAligner>
                <UniqueList
                  uniqueRow={uniqueRow}
                  UniqueData={
                    ImportContextData[`subinsight.${id}.data`]?.unique
                      ? ImportContextData[`subinsight.${id}.data`]?.unique
                      : uniqueData
                  }
                  placeholder={"Unique Objects"}
                />
                {uniqueRow != "" && (
                  <>
                    <CardTitle className="h5 mb-4">Chart Design</CardTitle>
                    <hr />
                    <FieldArray
                      name="color"
                      render={(arrayHelpers) => {
                        const color = values.color;
                        ////console.log(formik.touched);
                        return (
                          <div>
                            {color && color.length > 0
                              ? color.map((value, index) => (
                                  <div key={index}>
                                    <InputText
                                      key={index}
                                      type="color"
                                      name={`color.${index}.unique`}
                                      placeholder={`${
                                        ImportContextData[
                                          `subinsight.${id}.data`
                                        ]?.unique
                                          ? ImportContextData[
                                              `subinsight.${id}.data`
                                            ]?.unique[index]
                                          : uniqueData[index]
                                      }`}
                                      onInput={(e) => {
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                ))
                              : null}
                          </div>
                        );
                      }}
                    />
                  </>
                )}
                {subInsights == null && (
                  <FormAligner>
                    <button
                      className="btn btn-success col-md-3 me-2"
                      onClick={() => {
                        setSubmited(true);
                        formik.handleSubmit();
                      }}
                      type="button"
                    >
                      {subInsights == null ? "Save and Next" : "Save"}
                    </button>
                    <Link to="/admin/chartimport">Cancel</Link>
                  </FormAligner>
                )}
              </Form>
            )}
          />
        ) : (
          <Formik
            innerRef={formikRef}
            initialValues={
              ImportContextData.hasOwnProperty(`subinsight.${id}.data`)
                ? ImportContextData[`subinsight.${id}.data`]
                : FormFields
            }
            validationSchema={Yup.object(validationSchema)}
            onSubmit={(values) => {
              values["color"] = values["color"].slice(0, uniqueData.length);
              handleFormSubmit(values);
            }}
            render={({ values, touched, ...formik }) => (
              <Form
                onChange={(e) => {
                  if (saveStatus) {
                    handleFormChange(e);
                  }
                }}
              >
                {subInsights != null ? (
                  <InputText
                    type="text"
                    name={"subInsghtsTitle"}
                    placeholder={"Sub-Insights Title"}
                    onChange={(e) => {
                      handleInputChange(e);
                    }}
                  />
                ) : null}
                <InputText
                  type="textarea"
                  className="description"
                  name={"InsightDescription"}
                  placeholder={"Insight Description"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
                <InputText
                  type="text"
                  name={"unit"}
                  placeholder={"Unit"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
                <CardTitle className="h5 mb-4">Y axis </CardTitle>
                <hr />
                <InputText
                  type="text"
                  name={"lableY"}
                  placeholder={"Label Y"}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <SelectField
                  type="select"
                  name={"valueY"}
                  placeholder={"Value"}
                  submited={submited}
                  options={ImportContextData?.Headers}
                  onInput={(e) => {
                    handleInputChange(e);
                    formik.setFieldTouched(e.target.name, true);
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CardTitle className="h5 mb-4">X axis </CardTitle>
                <hr />
                <InputText
                  type="text"
                  name={"lableX"}
                  placeholder={"Label X"}
                  onChange={(e) => {
                    handleInputChange(e);
                  }}
                />
                <SelectField
                  type="select"
                  name={"valueX"}
                  placeholder={"Value"}
                  submited={submited}
                  options={{
                    avg: "Average",
                    number: "Number",
                  }}
                  onInput={(e) => {
                    handleInputChange(e);
                    formik.setFieldTouched(e.target.name, true);
                    formik.setFieldValue(e.target.name, e.target.value);
                  }}
                />
                <CardTitle className="h5 mb-4">Average Of</CardTitle>
                <hr />
                <SelectField
                  type="select"
                  name={"averageOf"}
                  placeholder={"Value"}
                  submited={submited}
                  options={ImportContextData?.Headers}
                  onInput={(e) => {
                    handleInputChange(e);
                    formik.setFieldTouched(e.target.name, true);
                    formik.setFieldValue(e.target.name, e.target.value);
                    setUniqueRow(e.target.value);
                  }}
                />
                <UniqueList
                  uniqueRow={uniqueRow}
                  UniqueData={
                    ImportContextData[`subinsight.${id}.data`]?.unique
                      ? ImportContextData[`subinsight.${id}.data`]?.unique
                      : uniqueData
                  }
                  placeholder={"Unique Objects"}
                />
                {uniqueRow != "" && (
                  <>
                    <CardTitle className="h5 mb-4">Chart Design</CardTitle>
                    <hr />
                    <FieldArray
                      name="color"
                      render={(arrayHelpers) => {
                        const color = values.color;
                        ////console.log(formik.touched);
                        return (
                          <div>
                            {color && color.length > 0
                              ? color.map((value, index) => (
                                  <div key={index}>
                                    <InputText
                                      key={index}
                                      type="color"
                                      name={`color.${index}.unique`}
                                      placeholder={`${
                                        ImportContextData[
                                          `subinsight.${id}.data`
                                        ]?.unique
                                          ? ImportContextData[
                                              `subinsight.${id}.data`
                                            ]?.unique[index]
                                          : uniqueData[index]
                                      }`}
                                      onInput={(e) => {
                                        handleInputChange(e);
                                      }}
                                    />
                                  </div>
                                ))
                              : null}
                          </div>
                        );
                      }}
                    />
                  </>
                )}
                {subInsights == null && (
                  <FormAligner>
                    <button
                      className="btn btn-success col-md-3 me-2"
                      onClick={() => {
                        setSubmited(true);
                        formik.handleSubmit();
                      }}
                      type="button"
                    >
                      {subInsights == null ? "Save and Next" : "Save"}
                    </button>
                    <Link to="/admin/chartimport">Cancel</Link>
                  </FormAligner>
                )}
              </Form>
            )}
          />
        )}
      </div>
    </>
  );
}
