import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";

const RadarChartd3 = ({chartdata,dataKey}) => {
  //const d3Chart = useRef();
  var graphDisp = true;
  const NoInsightSelected = ()=> {
    return (<div className="two-select-boxes">
                  <p className="inner-content-donut" style={{fontSize:"16px"}}>
                  Data not available for this brand
                  </p>
            </div>)
  }
  const Datavalue = [];
  var cfg = {
    w:250,				//Width of the circle
    h: 250,				//Height of the circle
    margin: {top: 40, right: 40, bottom: 40, left: 40}, //The margins of the SVG
    levels: 4,				//How many levels or inner circles should there be drawn
    maxValue: 0.2, 			//What is the value that the biggest circle will represent
    labelFactor: 1.25, 	//How much farther than the radius of the outer circle should the labels be placed
    wrapWidth: 60, 		//The number of pixels after which a label needs to be given a new line
    opacityArea: 0.35, 	//The opacity of the area of the blob
    dotRadius: 4, 			//The size of the colored circles of each blog
    opacityCircles: 0, 	//The opacity of the circles of each blob
    strokeWidth: 1, 		//The width of the stroke around each blob
    roundStrokes: false,
    color: d3.scaleOrdinal(d3.schemeCategory10)	//If true the area and stroke will follow a round path (cardinal-closed)
   };
   var data_pie = [{axis:"Battery Life",value:19.5},
   {axis:"Brand",value:19.5},
   {axis:"Contract Cost",value:19.5},
   {axis:"Design And Quality",value:19.5},
   {axis:"Have Internet Connectivity",value:19.5}]
  var data = [
    [//Nokia Smartphone
      {axis:"Battery Life",value:20},
      {axis:"Brand",value:20},
      {axis:"Contract Cost",value:20},
      {axis:"Design And Quality",value:20},
      {axis:"Have Internet Connectivity",value:20},
    ]
  ];
  var chart = chartdata[0][dataKey];
  let arr = [];
  try{
    Object.keys(chart).forEach((key, index) => {
      if(key != 'Retailer' && key != 'Innovation Score'){
          arr.push({axis:key,value:(chart[key]/100)})   
      }
  });
  }
  catch(err){
    graphDisp = false;
  }
data = [arr]
  const chartHeight = 600;
  const customDecimal = (e) => {
    return d3.format('.0f')(e)
  }
  function wrap(text, width) {
    text.each(function() {
      var text = d3.select(this),
          words = text.text().split(/\s+/).reverse(),
          word,
          line = [],
          lineNumber = 0,
          lineHeight = 1.4, // ems
          y = text.attr("y"),
          x = text.attr("x"),
          dy = parseFloat(text.attr("dy")),
          tspan = text.text(null).append("tspan").attr("x", x).attr("y", y).attr("dy", dy + "em");
          
      while (word = words.pop()) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text.append("tspan").attr("x", x).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
        }
    }
  });
}

  useEffect(()=>{
    if(graphDisp){
    const colors = ['#1438F5', '#49CC86','#0F0F0F','#878787','#bcbcbc80'];
    var maxValue = Math.max(cfg.maxValue, d3.max(data, function(i){return d3.max(i.map(function(o){return o.value;}))}));
    const margin = {top: 20, right: 20, bottom: 20, left: 20};
    const width = 1200 - margin.left - margin.right;
    const height = chartHeight - margin.top - margin.bottom;
    var allAxis = (data[0].map(function(i, j){return i.axis})),	//Names of each axis
    total = allAxis.length,					//The number of different axes
    radius = Math.min(cfg.w/2, cfg.h/2), 	//Radius of the outermost circle
    Format = d3.format('%'),			 	//Percentage formatting
    angleSlice = Math.PI * 2 / total;	

    // Add R axis
    
    var rScale = d3.scaleLinear()
    .range([0, radius])
    .domain([0, maxValue]);
    d3.selectAll("#barplotstack > *").remove();
      
    var svg = d3.select("#barplotstack").append("svg")
    .attr("viewBox", `0 0 360 ${360}`)
    .attr("width",  cfg.w + cfg.margin.left + cfg.margin.right)
    .attr("height", cfg.h + cfg.margin.top + cfg.margin.bottom)
    .attr("class", "radargraphs");
    var g = svg.append("g")
			.attr("transform", "translate(" + (cfg.w/2 + cfg.margin.left) + "," + (cfg.h/2 + cfg.margin.top) + ")");
    
    
    var filter = g.append('defs').append('filter').attr('id','glow'),
    feGaussianBlur = filter.append('s').attr('stdDeviation','0').attr('result','b'),
    feMerge = filter.append('feMerge'),
    feMergeNode_1 = feMerge.append('feMergeNode').attr('in','b'),
    feMergeNode_2 = feMerge.append('feMergeNode').attr('in','g');
    
    var axisGrid = g.append("g").attr("class", "axisWrapper");
	
	//Draw the background circles
	axisGrid.selectAll(".levels")
	   .data(d3.range(1,(cfg.levels+1)).reverse())
	   .enter()
		.append("circle")
		.attr("class", "gridCircle")
		.attr("r", function(d, i){return radius/cfg.levels*d;})
		.style("fill", "#BCBCBC")
		.style("stroke", "#BCBCBC")
		.style("fill-opacity", cfg.opacityCircles)
        let arc = d3.arc()
            .innerRadius(radius+(cfg.levels*6.15))  //  This is the size of the donut hole
            .outerRadius(radius+(cfg.levels*8));              

    let pie = d3.pie()
        .value((d)=>d.value).sort(null);
    let data_ready = pie(data_pie)
    axisGrid
    .selectAll('whatever')
    .data(data_ready)
    .enter()
    .append('path')
    .transition().delay((d, i)=> { return i * 300; }).duration(300)
    .attrTween('d', (d) =>{
         var i = d3.interpolate(d.startAngle, d.endAngle);
         return function(t) {
             d.endAngle = i(t);
           return arc(d);
         }
    })  
    .attr('fill',  (d) =>  colors[d.index])
	//Text indicating at what % each level is
	axisGrid.selectAll(".axisLabel")
	   .data(d3.range(1,(cfg.levels+1)).reverse())
	   .enter().append("text")
	   .attr("class", "axisLabel")
       .attr("text-anchor", "end")
	   .attr("x", -5)
	   .attr("y", function(d){return -d*radius/cfg.levels;})
	   .attr("dy", "-1.0em")
	   .style("font-size", "10px")
	   .attr("fill", "#878787")
	   .text(function(d,i) { return Math.trunc(parseInt(Format(maxValue * d/cfg.levels))) });

	/////////////////////////////////////////////////////////
	//////////////////// Draw the axes //////////////////////
	/////////////////////////////////////////////////////////
	
	//Create the straight lines radiating outward from the center
	var axis = axisGrid.selectAll(".axis")
		.data(allAxis)
		.enter()
		.append("g")
		.attr("class", "axis");
	//Append the lines
	axis.append("line")
		.attr("x1", function(d, i){ return rScale(maxValue/cfg.levels) * Math.cos(angleSlice*i - Math.PI/2); })
		.attr("y1", function(d, i){ return rScale(maxValue/cfg.levels) * Math.sin(angleSlice*i - Math.PI/2); })
		.attr("x2", function(d, i){ return rScale(maxValue*1.2) * Math.cos(angleSlice*i - Math.PI/2); })
		.attr("y2", function(d, i){ return rScale(maxValue*1.2) * Math.sin(angleSlice*i - Math.PI/2); })
		.attr("class", "line")
		.style("stroke", "#BCBCBC")
		.style("stroke-width", "1px");

        
	//Append the labels at each axis

    /////////////////////////////////////////////////////////
	///////////// Draw the radar chart blobs ////////////////
	/////////////////////////////////////////////////////////
	
	//The radial line function
	var radarLine = d3.lineRadial()
    .curve(d3.curveLinear)
    .radius(function(d) { return rScale(d.value); })
    .angle(function(d,i) {return angleSlice*(i+.5); });
    
if(cfg.roundStrokes) {
    radarLine.curve(d3.curveCardinalClosed)
}
            
//Create a wrapper for the blobs	
var blobWrapper = g.selectAll(".radarWrapper")
    .data(data)
    .enter().append("g")
    .attr("class", "radarWrapper");
        
    
//Create the outlines	
blobWrapper.append("path")
    .attr("class", "radarStroke")
    .attr("d", function(d,i) {  let firstdata = d[0];
                                d.push(firstdata);
                                return radarLine(d); })
    .style("stroke-width", cfg.strokeWidth + "px")
    .style("stroke",'#0f0f0f')
    .style("fill", "none")
//Append the circles
blobWrapper.selectAll(".radarCircle")
    .data(function(d,i) { return d; })
    .enter().append("circle")
    .attr("class", "radarCircle")
    .attr("r", cfg.dotRadius)
    .attr("cx", function(d,i){ return rScale(d.value) * Math.cos((angleSlice*(i+.5)) - Math.PI/2); })
    .attr("cy", function(d,i){ return rScale(d.value) * Math.sin((angleSlice*(i+.5)) - Math.PI/2); })
    .style("fill", '#0f0f0f')
    .style("fill-opacity", 1);

/////////////////////////////////////////////////////////
//////// Append invisible circles for tooltip ///////////
/////////////////////////////////////////////////////////

//Wrapper for the invisible circles on top
var blobCircleWrapper = g.selectAll(".radarCircleWrapper")
    .data(data)
    .enter().append("g")
    .attr("class", "radarCircleWrapper");
    
//Append a set of invisible circles on top for the mouseover pop-up
blobCircleWrapper.selectAll(".radarInvisibleCircle")
    .data(function(d,i) { return d; })
    .enter().append("circle")
    .attr("class", "radarInvisibleCircle")
    .attr("r", cfg.dotRadius*1.5)
    .attr("cx", function(d,i){ return rScale(d.value) * Math.cos(angleSlice*i - Math.PI/2); })
    .attr("cy", function(d,i){ return rScale(d.value) * Math.sin(angleSlice*i - Math.PI/2); })
    .style("fill", "none")
    .style("pointer-events", "all")

}},[chartdata,dataKey]);
  
  

  return (
    <>
    {
    graphDisp ?
    <div id="barplotstack"></div>:
    <NoInsightSelected/>
    }
    </>
  );
};

export default RadarChartd3;
