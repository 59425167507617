import React, { Fragment,useEffect,useState,useMemo } from "react";
import PropTypes from "prop-types";
import {
  useTable,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFilters,
  useExpanded,
  usePagination,
  useResizeColumns,
  useFlexLayout,
  useBlockLayout
} from "react-table";
import { Table, Row, Col, Button, Input } from "reactstrap";
import { Filter, DefaultColumnFilter } from "./filters";
import searchicon from "../../assets/images/Search Icon.svg";

// Define a default UI for filtering
function GlobalFilter({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  gotoPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200);
  useMemo(
    () => {
      gotoPage(0)
      setGlobalFilter(value || undefined)
    }, 
    [globalFilter]
  );
  return (
    <Col sm={7} lg={7}>
      <div className="search-box me-2 mb-2 d-inline-block" style={{fontFamily: 'Helvetica Neue'}}>
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="search"
              className="form-control"
              placeholder={`Search`}
              value={value || ""}
            />
          </label>
          <button><img src={searchicon} className="searchnew" /></button>
        </div>
      </div>
      <div className="d-flex pull-right me-2 mb-2 text-center d-none" style={{fontFamily: 'Helvetica Neue'}}>
        <a className="btn btn-link text-success"><i className="fa fa-bar-chart"></i> Show Graph</a>
        <a className="btn btn-link text-warning"> <i className="fa fa-check"></i>  Publish</a>
      </div>
    </Col>
  );
}

    const TableContainer = ({
      controlledPageCount,
      columns,
      data,
      isGlobalFilter,
      isAddOptions,
      isAddUserList,
      handleOrderClicks,
      handleUserClick,
      handleCustomerClick,
      isAddCustList,
      customPageSize,
      className,
      customPageSizeOptions,
      addLable,
      sortdata = "",
      isBusy
    }) => {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        rows,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state,
        preGlobalFilteredRows,
        setGlobalFilter,
        LoadingComponent,        
        state: { pageIndex, pageSize },
        
      } = useTable(
        {
          columns,
          data,
          useResizeColumns,
          useFlexLayout,
          defaultColumn: { Filter: DefaultColumnFilter },
          sortDescFirst:true,
          initialState: { 
            pageIndex: 0, 
            pageSize: customPageSize,
            sortBy: [
              {
                id: sortdata,
                desc: false,
              },
            ],
          },
          disableSortRemove: true,
          autoResetPage: false
        },
    useGlobalFilter,
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = column => {
    return column.isSorted ? (column.isSortedDesc 
        ? <i className="fas fa-caret-down" title="Asc" style={{"color": "grey"}}></i> 
        : <i className="fas fa-caret-up" title="Desc" style={{"color": "grey"}}></i>) : "";
  };

  const onChangeInSelect = event => {
    setPageSize(Number(event.target.value));
  };

  const onChangeInInput = event => {
    const page = event.target.value ? Number(event.target.value) - 1 : 0;
    gotoPage(page);
  };

  const [indexdata, setindexdata] = useState();
  const [pageLength, setpageLength] = useState();
  useEffect(() => {
    setindexdata(pageIndex + 1);
  }, [pageIndex])
  useEffect(() => {
    setpageLength(page.length);
  }, [page.length])
  return (
    <Fragment>
      <Row className="mb-2">
        <Col sm={customPageSizeOptions ? 1 : 2} lg={2}>
          <select
            className="form-select"
            value={pageSize}
            onChange={onChangeInSelect}
            style={{"height": "41px"}}
          >
            {[10, 20, 30, 40, 50].map(pageSize => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
        </Col>
        {isGlobalFilter && (
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            gotoPage={gotoPage}
          />
        )}
        {isAddOptions && (
          <Col sm="3" lg="3">
            <div className="text-sm-end">
              <button
                type="button"
                className="appbtn button-with-bg mb-2 me-2"
                onClick={handleOrderClicks}
              >
                <i className="mdi mdi-plus me-1" />
                {addLable}
              </button>
            </div>
          </Col>
        )}
        {isAddUserList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="primary"
                className="appbtn button-with-bg mb-2 me-2"
                onClick={handleUserClick}
              >
                <i className="mdi mdi-plus-circle-outline me-1" />
                Create New User
              </Button>
            </div>
          </Col>
        )}
        {isAddCustList && (
          <Col sm="7">
            <div className="text-sm-end">
              <Button
                type="button"
                color="success"
                className="appbtn button-with-bg mb-2 me-2"
                onClick={handleCustomerClick}
              >
                <i className="mdi mdi-plus me-1" />
                New Customers
              </Button>
            </div>
          </Col>
        )}
      </Row>

      <div className="table-responsive react-table">
        <Table bordered hover {...getTableProps()} className={className}>
          <thead className="table-light table-nowrap">
            {headerGroups.map(headerGroup => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                <th
                  key={column.id}
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  {...column.getHeaderProps({
                    style: { 
                      minWidth: column.minWidth, width: column.width, maxWidth: column.maxWidth,
                      wordBreak: column.wordBreak, "whiteSpace": column.whiteSpace
                    },
                  })}
                >
                  {column.render("Header")}
                  <span>
                    {generateSortingIndicator(column)}
                  </span>
                </th>

                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {(page.length > 0 && page.map( (row, i) => {
              prepareRow(row);
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td className={className} key={cell.id} {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              );

            })) || (isBusy 
                      ? <tr><td colSpan="10">Loading...</td></tr> 
                      : <tr><td colSpan="10">No record found...</td></tr>)}

          </tbody>
        </Table>
      </div>    
      <Row className="justify-content-center align-items-center">
        <Col className="col-sm-6 col-lg-6">
            Showing {pageLength} of {data.length} entries
        </Col>
        <Col className="col-sm-2 col-6 col-lg-2">
          <div className="d-flex gap-1">
            <Button
              color="primary"
              className="btn-page"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {"<<"}
            </Button>
            <Button
              color="primary"
              className="btn-page"
              onClick={previousPage}
              disabled={!canPreviousPage}
            >
              {"<"}
            </Button>
          </div>
        </Col>
        <Col className="col-sm-2 col-lg-2 col-md-2 d-none d-md-block text-center">
          Page{" "}
          <strong>
            {indexdata} of {pageOptions.length}
          </strong>
        </Col>
{/*        <Col className="col-md-auto" style={{"display": "None"}}>
          <Input
            type="number"
            step="1"
            className="form-control-sm"
            onKeyPress={(event) => {
              return (event.charCode >= 48 && event.charCode <= 57) ? true : event.preventDefault();
            }}
            min={1}
            style={{ width: 70, fontSize: "16px"}}
            max={pageOptions.length}
            value={pageIndex + 1}
            onChange={onChangeInInput}
          />
        </Col>*/}

        <Col className="col-sm-2 col-6 col-lg-2">
          <div className="d-flex gap-1">
            <Button color="primary" className="btn-page" onClick={nextPage} disabled={!canNextPage}>
              {">"}
            </Button>
            <Button
              color="primary"
              className="btn-page"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {">>"}
            </Button>
          </div>
        </Col>
      </Row>
    </Fragment>
  );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};

export default TableContainer;
