import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";

const BarplotGroup = ({
  chartHeightData,
  BarplatData,
  uniquedata = [],
  colorCode = [],
  lableValue,
}) => {
  //const d3Chart = useRef();
  // console.log(colorCode);
  // console.log(BarplatData);
  // console.log(uniquedata);
  const [errorMessage, setErrorMessage] = useState(null);

  try{
  const Datavalue = [];
  const chartHeight = chartHeightData ? chartHeightData : 600;
  const firstInnerObj = Object.values(BarplatData)[0];
  const uniquebar = Object.keys(firstInnerObj);
  const colordata = colorCode.map(obj => obj.unique);

  for (const key in BarplatData) {
    const obj = BarplatData[key];
    for (const prop in obj) {
      Datavalue.push(obj[prop]);
    }
  }
  // console.log(uniquebar);
  // console.log(myArr);
  // const loop = Object.keys(BarplatData).map((d) => {
  //   Datavalue.push(
  //     ...[
  //       BarplatData[d]["ri_cp_avg"],
  //       BarplatData[d]["rn_ri_avg"],
  //       BarplatData[d]["rp_rfr_avg"],
  //       BarplatData[d]["rp_rw_avg"],
  //       BarplatData[d]["rw_cp_avg"],
  //     ]
  //   );
  // });
  //console.log(Datavalue);
  //console.log(BarplatData);
  const customDecimal = (e) => {
    return d3.format(".0f")(e);
  };

  useEffect(() => {
    const margin = { top: 20, right: 30, bottom: 40, left: 90 };
    const width = 1200 - margin.left - margin.right;
    const height = chartHeight - margin.top - margin.bottom;

    d3.selectAll("#barplot > *").remove();

    const svg = d3
      .select("#barplot")
      .append("svg")
      /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
      .attr("viewBox", `0 0 1200 ${chartHeight}`)
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // List of subgroups = header of the csv files = soil condition here
    const subgroups = [...uniquebar];

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = Object.keys(BarplatData).map((d) => {
      return d;
    });

    // Add X axis
    const x = d3.scaleBand().domain(groups).range([0, width]).padding(0.5);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickSize(12).tickPadding([15]))
      .selectAll("g")
      .selectAll("line")
      .attr("y1", 10)
      .attr("y2", 20)
      .attr("dy", ".35em")
      .style("color", "#0f0f0f")
      .selectAll("text")
      .attr("y", 20)
      .attr("x", 0)
      .attr("dy", ".5em")
      .style("color", "#0f0f0f");

    // Add Y axis
    const y = d3
      .scaleLinear()
      .domain([0, Math.ceil(d3.max(Datavalue))])
      .range([height, 0]);

    //console.log(Datavalue);

    const yAxisGrid = d3
      .axisLeft(y)
      .tickSize(-width)
      .tickFormat("")
      .ticks(
        d3.max(Datavalue) <= 10 ? d3.max(Datavalue) : d3.max(Datavalue) / 2
      );

    svg
      .append("g")
      .call(
        d3
          .axisLeft(y)
          .tickFormat(customDecimal)
          .ticks(
            d3.max(Datavalue) <= 10 ? d3.max(Datavalue) : d3.max(Datavalue) / 2
          )
      )
      .selectAll("line")
      .attr("stroke", "#A9A9A9")
      .attr("stroke-width", 0.1)
      .attr("opacity", 0);

    svg.append("g").attr("class", "y axis-grid").call(yAxisGrid);

    // Another scale for subgroup position?
    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.2]);

    // color palette = one color per subgroup
    const color = d3
      .scaleOrdinal()
      .domain(subgroups)
      .range([...colordata]);

    //console.log(BarplatData['Saks']['oc_s_avg']);

    const tooltip = d3
      .select("#barplot")
      .append("div")
      .attr("class", "tooltip-barplot")
      .style("visibility", "hidden");

    // Show the bars
    svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(Object.keys(BarplatData))
      .enter()
      .append("g")
      .attr("transform", (d) => "translate(" + x(d) + ",0)")
      .selectAll("rect")
      .data((d) =>
        subgroups.map(function (key) {
          return {
            key: key,
            value: key == "group" ? d : BarplatData[d][key],
            parent: d,
          };
        })
      )
      .enter()
      .append("rect")
      .attr("x", (d) => xSubgroup(d.key))
      .attr("y", (d) => y(d.value))
      .attr("width", xSubgroup.bandwidth())
      .attr("height", (d) => height - y(d.value))
      .attr("fill", (d) => color(d.key))
      .on("mouseover", (event, d) => {
        //console.log(d3.select(this).attr("x"));
        console.log(d);
        tooltip
          .transition()
          .delay(0)
          .duration(50)
          .style("visibility", "visible")
          .style("opacity", "1");
        //console.log(d);
        tooltip
          .html(
            `<div class="tooltipDesign">
                  <h5 class="tt-head">
                    ${d.parent}
                  </h5>
                  <ul>
                    <li style="margin:0px">
                    <p><span 
                          class="color1-dot"
                          style="background:${color(d.key)}"
                          ></span> 
                          <span class="tooltip_text">${d.key}</span>
                          </p>
                      <label>${d.value} ${lableValue?.['Unit'] ? lableValue?.['Unit'] : ""}</label>
                    </li>
                   </ul>
                  </div>`
          )
          .style("left", event.layerX + 10 + "px")
          .style("top", event.layerY - 100 + "px");
      })
      .on("mousemove", function (event) {
        return tooltip
          .style("left", event.layerX + 10 + "px")
          .style("top", event.layerY - 100 + "px");
      })
      .on("mouseout", () =>
        tooltip.transition().style("visibility", "hidden").style("opacity", "0")
      );

      if (lableValue?.["lable x"]) {
        svg
          .append("text")
          .attr(
            "transform",
            "translate(" + width / 2 + " ," + (height + 50) + ")"
          )
          .style("text-anchor", "middle")
          .style("color", "#000000")
          .style("font-weight", "500")
          .style("font-size", "15px")
          .text(`${lableValue?.["lable x"]}`);
      }
      if(lableValue?.["lable Y"]){
        svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", - margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("color", "#000000")
        .style("font-weight", "500")
        .text(`${lableValue?.["lable Y"]}`);
      }

    // console.log(xSubgroup.bandwidth());
  }, [BarplatData]);
  }
  catch(err){
    console.log(err);
    return (
      <ErrorMessage Message={"Not able to generate graph for the retrieved data"}/>
    );
  }
  return (
    <>
      {errorMessage == null && (<div id="barplot"></div>)}
    </>
  );
};

export default BarplotGroup;
