import React, {useState, useEffect,useMemo, useContext, useRef} from "react"
import {
  Container,
  Table
} from "reactstrap"
import Preview from "./Component/PreviewTab/PreviewTab";
import MultiBar from "./Component/PostPurchaseInsights";
import CustomerServices from "./Component/CustomerServiceInsights";

const CustomerService = ({ UnSubscription,activeTab,customData,userSessionData,NoBrandSelected,NoInsightSelected, activeInsight }) => {

	const [dropdown, setDropdown] = useState(false);
  
  const [insightSearch, setInsightSearch] = useState("");
  const [selectedInsight,setSelectedInsight] = useState([]);
  const [appliedInsight, setAppliedInsight] = useState([]);
  const [insightValue, setInsightValue] = useState([]);
  const [insightValueId, setInsightValueId] = useState([]);
  const insightDropDown = useRef();

  const insightData = ["Post-Purchase Subjective Insights","Customer Service Objective Insights"];


  function selectValue() {
    let valuesArray = [];
    let valueIdArray = [];
    for (let i = 0; i < customData.length; i++) {
      const values = customData[i].InsightTitle;
      const valId = customData[i].insightId;
      //insightValue.push({"val":values,"valId":valId});
      valuesArray.push(values);
      valueIdArray.push(valId);
    }
    let newSelectedInsight = selectedInsight.filter(elem => valueIdArray.includes(elem));
    let newAppliedInsight = appliedInsight.filter(elem => valueIdArray.includes(elem));
    setSelectedInsight(newSelectedInsight);
    setAppliedInsight(newAppliedInsight);
    setInsightValue(valuesArray);
    setInsightValue(valuesArray)
    setInsightValueId(valueIdArray)
  }
  useEffect(() =>{
    if(activeInsight){
      setSelectedInsight([activeInsight]);
      setAppliedInsight([activeInsight]);
    }
  },[activeInsight])

  useMemo(
    () => {
      selectValue()
    },
    [customData]
  );
  const insight = insightValue.map((index, i)=>{

    let display = insightSearch == "" 
                  ? (i > 6 ? "show" : "show")
                  : (index.toLowerCase().includes(insightSearch.toLowerCase())?"show":"hide");

      display = display == "hide"
              ? (selectedInsight.includes(index) ? "show" : "hide")
              : display; 

    return (<li key={i} className={display} onClick={() => (selectedInsight.length < 3
      || selectedInsight.includes(insightValueId[i]))
      ? onChangeInsight(insightValueId[i]) : null}>
          <input 
               type="checkbox" 
               value={insightValueId[i]}
               className="styled-checkbox"
               id={`dropdown-i${index.replace(/\s+/g, '')}`}
               name={"insightSearch"}
               checked={selectedInsight.includes(insightValueId[i])}
               disabled={selectedInsight.length >= 3
                          && !selectedInsight.includes(insightValueId[i]) 
                          ? true:false
                        }
               title={selectedInsight.length >= 3
                          && !selectedInsight.includes(insightValueId[i]) 
                          ? "Only 3 insights can be selected at once"
                          : ""}
               
          />
          <label 
            htmlFor={index}
          >
            {index}
          </label>
        </li>)

  });

  const onChangeInsight = (name) => {
      const newSelectedItems = selectedInsight.includes(name)
        ? selectedInsight.filter((item) => item !== name)
        : [...selectedInsight, name]; 
      setSelectedInsight(newSelectedItems);      
  };

  const insightCount = selectedInsight.length;

  useOnClickOutside(insightDropDown, () => setDropdown(false));

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }
	
	return (
		<React.Fragment>
		<div className="detailed-insights" 
			style={{display : activeTab === "5" ? 'block':'none'}}>
            <h4 style={{"margin-left": "32px"}}>Detailed Insights</h4>
              <div style={{"margin-left": "32px"}} className="d-flex dropdown-tab-content">
                <div className="dropdown" ref={insightDropDown}>
                  <button 
                    className="btn dropdown-toggle" 
                    type="button" 
                    id="dropdownMenuButton1"
                    onClick={()=>{
                      setDropdown(!dropdown);
                     
                    }} 
                    >
                    {insightCount=="0"?"No":insightCount} Insights Selected
                    {/* <i className= "dropdown-toggle-icon" /> */}
                    <i className= {dropdown ? "dropdown-toggle-icon rotate" : "dropdown-toggle-icon"} />
                  </button>
                
                  <ul 
                    className={
                      dropdown
                        ? "multiselect-container dropdown-menu show"
                        : "multiselect-container dropdown-menu"
                    }>
                    <li>
                      <input 
                        type="search" 
                        placeholder="Search insights" 
                        value={insightSearch}
                        onChange={(v)=>{
                          setInsightSearch(v.target.value)
                        }}
                      />
                    </li>
                    {insight}
                    <li className="apply-btn">
                      <button 
                        className="btn"
                        onClick={()=>{
                          setAppliedInsight(selectedInsight);
                          setDropdown(!dropdown);
                        }}
                      >Apply</button>
                    </li>
                  </ul>
                </div>
                
                <button 
                  type="reset" 
                  className="reset-filter"
                  onClick={()=>{
                   
                    setSelectedInsight([]);
                    setAppliedInsight([]);
                    
                    setInsightSearch("");
                  }}
                >Reset Filters</button>
              </div>
              { userSessionData.subscriptionStatus == "Inactive" ?
              <UnSubscription />
              :
              <div className="tab-content" id="details-insightsContent" style={{height:"auto","minHeight":"400px"}}>
                  <div className="tab-wrap tab-pane fade show active" id="commerce" role="tabpanel" aria-labelledby="commerce-tab">
                  {appliedInsight.length > 0
              ? appliedInsight.map((value, index) => (


                <div key={value}>
                  <Preview
                    valuess={appliedInsight[index]} NoBrandSelected={NoBrandSelected} useOnClickOutside={useOnClickOutside} /></div>)) : null
            }
                   {/* {
                    appliedInsight.includes(1) ?
                      <CustomerServices 
                        NoBrandSelected={NoBrandSelected}
                        useOnClickOutside={useOnClickOutside}
                      />

                      : ""
                  } */}

                  

                  {
                    appliedInsight.length == 0 ?
                      <NoInsightSelected /> :
                      "" 
                  }
	                
                  
                  {/*<ReturnObjectiveVertical NoBrandSelected={NoBrandSelected}/>*/}
              	</div>          
              
            </div>
            }
          </div>
		</React.Fragment>
	);

};

export default CustomerService;