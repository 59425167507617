import PropTypes from 'prop-types';
import React, { useState } from "react";
import TempStaticImage from "assets/images/graph-details-img.png";
const StaticContent = (cms) => {
    const [toggleAuthor,settoggleAuthor] = useState(true);
  return (
    <div>
      <div className="graph-details">
                    <div className="editor" dangerouslySetInnerHTML={{ __html: cms?.cms?.morecontent }}>
                      {/* {console.log(cms)}
                      dangerouslySetInnerHTML={{ __html: detailDesc }} */}
                  </div>
                  </div>
                  {/* <div className="share-options">
                  <ul>
                    <li>
                      <a href="javascript:void(0);">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M18 8.3374C19.6569 8.3374 21 6.99426 21 5.3374C21 3.68055 19.6569 2.3374 18 2.3374C16.3431 2.3374 15 3.68055 15 5.3374C15 6.99426 16.3431 8.3374 18 8.3374Z" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M6 15.3374C7.65685 15.3374 9 13.9943 9 12.3374C9 10.6805 7.65685 9.3374 6 9.3374C4.34315 9.3374 3 10.6805 3 12.3374C3 13.9943 4.34315 15.3374 6 15.3374Z" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M18 22.3374C19.6569 22.3374 21 20.9943 21 19.3374C21 17.6805 19.6569 16.3374 18 16.3374C16.3431 16.3374 15 17.6805 15 19.3374C15 20.9943 16.3431 22.3374 18 22.3374Z" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M8.58984 13.8474L15.4198 17.8274" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M15.4098 6.84741L8.58984 10.8274" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clipPath="url(#clip0_635_6460)">
                          <path d="M6 9.67383V2.67383H18V9.67383" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M6 18.6738H4C3.46957 18.6738 2.96086 18.4631 2.58579 18.088C2.21071 17.713 2 17.2043 2 16.6738V11.6738C2 11.1434 2.21071 10.6347 2.58579 10.2596C2.96086 9.88454 3.46957 9.67383 4 9.67383H20C20.5304 9.67383 21.0391 9.88454 21.4142 10.2596C21.7893 10.6347 22 11.1434 22 11.6738V16.6738C22 17.2043 21.7893 17.713 21.4142 18.088C21.0391 18.4631 20.5304 18.6738 20 18.6738H18" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M18 14.6738H6V22.6738H18V14.6738Z" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          </g>
                          <defs>
                          <clipPath id="clip0_635_6460">
                          <rect width="24" height="24" fill="white" transform="translate(0 0.673828)"/>
                          </clipPath>
                          </defs>
                        </svg>
                      </a>
                    </li>
                    <li>
                      <a href="javascript:void(0);">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M21 15.6738V19.6738C21 20.2043 20.7893 20.713 20.4142 21.088C20.0391 21.4631 19.5304 21.6738 19 21.6738H5C4.46957 21.6738 3.96086 21.4631 3.58579 21.088C3.21071 20.713 3 20.2043 3 19.6738V15.6738" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M7 10.6738L12 15.6738L17 10.6738" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                          <path d="M12 15.6738V3.67383" stroke="#0F0F0F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
  );
};



export default StaticContent;
