import React, { useState, useEffect, createRef, useRef, useContext, useCallback } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  Table,
} from "reactstrap";
import { post } from "../../helpers/api_helper";
import UserContext from "../../helpers/AuthType/session_helper";
import LogoutModel from "components/Common/LogoutModel";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link} from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import bellImg from "../../assets/images/bell.svg";
import userImg from "../../assets/images/user-img.png";
import downImg from "../../assets/images/down-arrow.svg";
import plusImg from "../../assets/images/plus.svg";
import editImg from "../../assets/images/edit.svg";

//eyeIcons
import passwordEye from "assets/images/templateImages/eye-off.svg";
import passwordEyeOn from "assets/images/templateImages/eye_on.svg";

import axios from 'axios';

import toastr from "toastr";
import "toastr/build/toastr.min.css";
import profile from "store/auth/profile/reducer";
// import { socket, SocketContext } from '../../socketContext';

const UserProfile = (props) => {
  //meta title
  document.title = "Settings - Acumen";

  const user = JSON.parse(localStorage.getItem("authUser"));
  //console.log(user.profilePic.substr(-11),'userr')

  const [userSessionData, setuserSessionData] = useContext(UserContext);

  const [localData, setlocalData] = useState(JSON.parse(window.localStorage.getItem("authUser")));
  const [logoutstate, setlogoutstate] = useState(false);
  const handleCloseClick = () =>{
    setlogoutstate(false)
  }
  //console.log(userDatas,"set1");
  useEffect(()=>{
    setuserSessionData(JSON.parse(window.localStorage.getItem("authUser")));
  },[localData]);
  //console.log(user);

  // /* SOCKET STARTS*/
  // const socket = useContext(SocketContext);
  
  // const handleInviteAccepted = useCallback(() => {
  //     console.log("CALLBACK")
  // }, []);  

  // const handleConnection = useCallback(() => {
  //     console.log("CALLBACK")
  // }, []);

  // useEffect(() => {
  //     // as soon as the component is mounted, do the following tasks:
  //     socket.on('connect', handleConnection);
  //     // emit USER_ONLINE event
  //     socket.emit("message", "HI"); 
  //     socket.on("dataUpdate", handleInviteAccepted); 
  //     socket.on('connect_error', function(err){
  //         console.log(err.message);
  //     });
  //     return () => {
  //       socket.off("message", "userSessionData.lastName"); 
  //     };
  //   }, [socket]);

  // /* SOCKET ENDS*/
  const [showList, setShowList] = React.useState(false);

  const [modal_demo, setmodal_demo] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_logout, setmodal_logout] = useState(false);
  const [modal_title, setmodal_title] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [changePassword, setchangePassword] = useState(false);  
  const [profileImage, setprofileImage] = useState("");
  const [uploadedImage, setuploadedImage] = useState("");
  const [onSubmit, setonSubmit] = useState(false);
  const [resetPass, setresetPass] = useState(false);
  const [resetPassText, setresetPassText] = useState('');
  const [onresetPass, setonresetPass] = useState(false);
  const [showPassword, setshowPassword] = useState(false);


  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);


  let positionClass = "toast-top-right";

  toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  //End toastr

  const inputFile = useRef(null) 
  useEffect(() => {
    if(changePassword === true)
    {
      validation.validateForm();
    }
    else{
      validation.setFieldError("password","");
      validation.setFieldValue("password","")
    }
  },[changePassword])

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
        let img = event.target.files[0];
        var maxfilesize = 1024 * 1024 * 3,  // 1 Mb
            filesize    = img.size;
        let ext = img.name.split(".").pop()
        
        if(!['jpg', 'jpeg', 'png', 'gif', 'JPG', 'JPEG', 'PNG', 'GIF'].includes(ext)) {
          toastr.error("Please upload image files only", 'Error')
          event.target.value = ''
        } else if ( filesize > maxfilesize ){
          toastr.error("File size is large. Please upload image less than 3MB")
          event.target.value = ''
        } else {
        let img = event.target.files[0];
        setuploadedImage(event.target.files[0]);
        setprofileImage(URL.createObjectURL(img));
      }
    }
  };


  function bookDemo() {
    setmodal_demo(!modal_small);
    removeBodyCss();
  }
  function add_modal() {
    setmodal_title("Add User");
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function getDomain() {
    let user = JSON.parse(window.localStorage.getItem("authUser"));
    return user.email.split("@")[1];
  }

  function userData() {
    return JSON.parse(window.localStorage.getItem("authUser"));
  }

  function edit_modal(id) {
    console.log(id);
    setmodal_title("Edit User");
    setfirstName("Mark");
    setlastName("Otto");
    setemail("@mdo");
    setpassword("12345678");

    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const ManageUsers = () => (
    <div
      id="userList"
      className="manage-users-details"
      style={{ marginTop: "30px" }}
    >
      <div className="row heading-details align-items-center">
        <div className="col-md-9">
          <p>Added accounts must use same domain.</p>
        </div>
        <div className="col-md-3">
          <button
            type="button"
            className="appbtn mb-2 me-2 btn btn-success"
            onClick={() => {
              add_modal();
            }}
            data-toggle="modal"
          >
            + Add a user
          </button>
        </div>
      </div>

      <div className="table-responsive">
        <Table className="table mb-0">
          <thead className="table-light">
            <tr>
              <th>Name</th>
              <th>Email Address</th>
              <th>Account Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                {userData().firstName} {userData().lastName} (You)
              </td>
              <td>{userData().email}</td>
              <td>Main Account</td>
              <td></td>
            </tr>
            <tr>
              <td>Jacob Thornton</td>
              <td>jacob@{getDomain()}</td>
              <td>Added User</td>
              <td>
                <Link to="">Delete</Link>
              </td>
            </tr>
            <tr>
              <td>Larry Bird</td>
              <td>larry@{getDomain()}</td>
              <td>Added User</td>
              <td>
                <Link to="">Delete</Link>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <div className="seat-limit">
        <span>Available Seats for Current Plan: (2 of 3) In Use</span>
        <p>
          Wish to change current seat limit? Contact{" "}
          <a href="mailto:billings@acumen-data.com">billings@acumen-data.com</a>
        </p>
      </div>
    </div>
  );

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      organization: user.organization,
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please Enter First Name").max(30).matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      lastName: Yup.string().required("Please Enter Last Name").max(30).matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      password: Yup.string().min(8),
    }),
    onSubmit: async (values) => {
      const formData = new FormData();
      setonSubmit(true);
      Object.keys(values).map(function(key) {
         if(values[key] != "")
         {
           formData.append(key, values[key]);
         }
      });
      // Object.keys(values).forEach((key) => {
      //   if (values[key] === "") {
      //     delete values[key];
      //   }
      // });
      uploadedImage ?
        formData.append('profilePic', uploadedImage) :
        "";
      // uploadedImage != "" ? (values.profilePic = uploadedImage) : "";
      // console.log(uploadedImage,values);
      const token = user.accessToken;
      try {
        let res = await post("user/update_profile", formData);
        setonSubmit(false);
        if (res.success) {
          Object.keys(res).map(function (key) {
            if (key != "success") {
              user[key] = res[key];
            }
          });

          localStorage.setItem("authUser", JSON.stringify(user));
          setprofileImage("");
          setlocalData({});
          toastr.success("Profile details updated successfully.", "");
        }
      } catch(error) {
        setonSubmit(false);
        if (error.response) {
          // Request made and server responded
          validation.setErrors(error.response.data.errors);
        } else if (error.request) {
          // The request was made but no response was received
          toastr.error(error, "Request failed");
        } else {
          console.log(error);
          // Something happened in setting up the request that triggered an Error
          toastr.error(error, "Request failed.");
        }
      }
    },
  });

  const { updateProfileError, updateProfileServerError, loading } = useSelector(
    (state) => ({
      updateProfileError: state.Account.updateProfileError,
      updateProfileServerError: state.Account.updateProfileServerError,
      loading: state.Account.loading,
    })
  );
  useEffect(() => {
    if (updateProfileError) {
      validation.setErrors(updateProfileError);
    }
  }, [updateProfileError]);

  useEffect(() => {
    console.log(loading);
  }, [loading]);

  const resetPassword = async() => {
    
      setresetPass(true);
      setonresetPass(true);

      try {
        let res = await post("request-reset-password", {'email':user.email});
        setonresetPass(false);
        
        if (res.success) {
          setresetPassText('<span class="success">Password reset link sent to your email</span>');
        }
      } catch(error) {
        let err = error.response.data.error
        setonresetPass(false);
        setresetPassText(`<span class="error">${err}</span>`);
      }

      setTimeout(() => {
        setresetPass(false);
      }, 3000);

  }

  return (
    <React.Fragment>
      <div className="inner-content settings">
        <div className="pr-page-title setting">
          <h2>Settings</h2>
        </div>
        <div className="update-profile2">
          <div className="row">
            <div className="col-12 col-md-3 col-lg-3 col-xl-2">
              <div className="edit-pic">
                <img src={profileImage != "" ? profileImage : userSessionData.profilePic } 
                className={`edit-user-img ${user.profilePic.endsWith('/profile.png') ? '' : 'remove-border'}`} alt="" />
                <input 
                    tabIndex={1}
                    id="myInput"
                     type="file"
                     ref={inputFile}
                     style={{display: 'none'}}
                     onChange = {onImageChange}
                     accept="image/*"
                  />
                <a href={void(0)} 
                  onClick={()=>{inputFile.current.click()}}
                  ><img src={user.profilePic.substr(-11) == "profile.png" ? plusImg : editImg } className="edit-icon" alt="" /></a>
                  
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-9 col-xl-10">
            {userSessionData.subscriptionStatus == "Active" ? 
              <p className="role">{user.role == "subscriber" ? "Admin" : "Added user"}</p>
            : "" }
            
              <div className="other-info">
                <h4>{user.firstName + " " + user.lastName}</h4>
                <p>{user.email}</p>

                <div className="accounts-btn">
                  <button className="acc-btn" tabIndex={2} onClick={()=>setlogoutstate(true)} >
                    Log out
                  </button>
                  {
                     (() => {
                        if (userSessionData.role == "subscriber") {
                          if (userSessionData.subscriptionStatus === "Active" ) {
                            return <Link 
                                 className="acc-btn text-center"
                                 role="button"
                                 to="/members"
                                 tabIndex={3}
                                >
                                 Manage Users
                                </Link>
                          } else {
                            return <button
                                className="acc-btn"
                                onClick={() => {
                                  bookDemo();
                                }}
                                tabIndex={3}
                                data-toggle="modal"
                              >
                                Manage Users
                              </button>
                          }
                        }
                     })()
                   }
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="setting"
          className="manage-users-details"
          style={{ marginTop: "50px" }}
        >
          {updateProfileError && updateProfileServerError ? (
            <Alert color="danger">{updateProfileServerError}</Alert>
          ) : null}
          <form className="profile-form user-settings-form" onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={5}>
                <div className="mb-3">
                  <label className="form-label2">First Name</label>
                  <Input
                    id="firstName"
                    type="text"
                    tabIndex={4}
                    name="firstName"
                    className="form-control"
                    autoComplete="new-password"
                    placeholder="Enter First Name"
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={5}>
                <div className="mb-3">
                  <label className="form-label2">Last Name</label>
                  <Input
                    id="lastName"
                    type="text"
                    tabIndex={5}
                    name="lastName"
                    autoComplete="new-password"
                    className="form-control"
                    placeholder="Enter Last Name"
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                    invalid={
                      validation.touched.lastName && validation.errors.lastName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={5}>
                <div className="mb-3">
                  <label className="form-label2">Organization</label>
                  <Input
                    type="text"
                    tabIndex={6}
                    className="form-control"
                    id="organization"
                    name="organization"
                    placeholder="Enter Organizationss"
                    value={validation.values.organization}
                    onChange={validation.handleChange}
                    disabled={true}                    
                  />
                </div>
              </Col>
              <Col lg={5}>
                <div className="mb-3 reset-password">
                  <div className="cancel-close-text">
                    <button 
                    type="button" 
                    onClick={resetPassword}
                    disabled={onresetPass ? true : false}
                    className={onresetPass ? "opacity-5" : ""}
                    >Reset Password</button>
                  </div>
                </div>
                {resetPass ? <span dangerouslySetInnerHTML={{ __html: resetPassText }} /> : ""}
              </Col>
            </Row>
            
            <Row>
              <div className="bill-method">
                <span>Billing Method</span>
                <p style={{marginBottom:"12px"}}>
                  Questions about billing? Contact{" "}
                  <a 
                    tabIndex={9} 
                    className="link_hover" 
                    href="mailto:billings@acumen-data.com">
                    billings@acumen-data.com
                  </a>
                </p>
              </div>
            </Row>

            <Row>
              <Col lg={3}>
                <div className="text-end">
                  <button 
                  type="submit" 
                  tabIndex={10}
                  className="button-with-bg"
                  disabled={onSubmit ? true : false}
                  >
                    {onSubmit ? "Loading..." : "Save changes"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>

        {/* {showList ? <ManageUsers /> : <EditProfile />} */}
      </div>



      <Modal
        isOpen={modal_demo}
        toggle={() => {
          bookDemo();
        }}
        centered
        className="setting-modal"
        id="manage-user-profile"
      >
        <div className="modal-body">
          <p>
            You cannot manage users if you are not subscribed to Acumen.
          </p>
          <br/>
          <p>
           Please click here to <a className="book-demo-link" href="mailto:billings@acumen-data.com">book a demo call</a> to manage
            additional users.
          </p>
          <div className="cancel-close-text cancel-close-customized">
            <button
              type="button"
              onClick={() => {
                setmodal_demo(false);
              }}
              style={{"color":"#000000"}}
            >
              Close
            </button>
            <button type="button" 
              className="book-demo-button"
              onClick={(e)=>{
                window.location.href = 'mailto:billings@acumen-data.com';
                e.preventDefault();
              }}>Book Demo Call</button>
          </div>
        </div>
      </Modal>
      <LogoutModel show={logoutstate} closeClick = {handleCloseClick} ></LogoutModel>
    </React.Fragment>
  );
};

export default withRouter(UserProfile);
