import React from "react";
import { Row, FormFeedback, Col } from "reactstrap";
import { useField, ErrorMessage } from "formik";
import { Input } from "reactstrap";
const InputRadio = ({ placeholder, disable, currentValue, ...Reactprops }) => {
  const [field, meta] = useField(Reactprops);
  //console.log(currentValue);
  return (
    <Row className="mb-3">
      <label htmlFor="date" className="col-md-2 col-form-label form-label">
        {placeholder}
      </label>
      <div className="col-md-10 mt-2">
      <input
              className="form-check-input"
              id="subinsight1"
              value="yes"
              checked={currentValue == "yes"}
              {...Reactprops}
            />
            <label className="form-check-label" htmlFor="subinsight1">
              Yes
            </label>
            <input
              className="form-check-input"
              id="subinsight2"
              value="no"
              checked={currentValue == "no"}
              {...Reactprops}
            />
            <label className="form-check-label" htmlFor="subinsight2">
              No
            </label>
        {meta.touched && meta.error ? (
           <FormFeedback type="invalid">
           {meta.error}
         </FormFeedback>
        ):null}
      </div>
    </Row>
  );
};
export default InputRadio;
