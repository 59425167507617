import React, { useState, useContext, useMemo, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import BlockUi from "react-block-ui";
import {
  Row,
  Spinner,
  Alert,
  Card,
  CardBody,
  Col,
  CardTitle,
} from "reactstrap";
import { Link } from "react-router-dom";
import { Formik, Form, FieldArray, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputText from "../../components/inputtext";
import InputFile from "../../components/inputFile";
import FormAligner from "../../components/FormAligner";
import SelectField from "../../components/SelectInput";
import ImportContext from "helpers/Context/ImportContext";

import { post } from "helpers/api_helper";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const ImportTab = () => {
  const history = useHistory();
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, "0");
  var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  var yyyy = today.getFullYear();
  const importInfos = {
    importDate: mm + "/" + dd + "/" + yyyy,
    insightFile: "",
  };
  const sampleSlectField = [
    "select1",
    "select2",
    "select3",
    "select4",
    "select5",
    "select6",
  ];
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  //console.log(ImportContextData);
  const formikRef = useRef();
  const [importFormdata, setimportFormdata] = useState(importInfos);
  const [submited, setSubmited] = useState(false);
  const { importDate, insightFile, mapped } = importFormdata;
  const [SelectFieldData, setSelectFieldData] = useState([]);
  const [warningMessage, setWarningMessage] = useState("");
  const [loader, setloader] = useState(false);
  const [filemode, setfilemode] = useState(null);
  const [ServerError, setServerError] = useState([]);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setimportFormdata({ ...importFormdata, [name]: value });
  };
  const handleFileImportChange = (e) => {
    const { name, files } = e.target;
    setimportFormdata({ ...importFormdata, [name]: files[0] });
  };
  const Populate = async (File) => {
    const formData = new FormData();
    formData.append("insightFile", File);
    //console.log(File);
    try {
      let res = await post("admin/insights/generate-map", formData);
      //console.log(res);
      const { header, info, isCalculated } = res;
      setImportContextData({ ...ImportContextData, ["Headers"]: header, ["FileType"]: isCalculated});
      setSelectFieldData(header);
      setfilemode(isCalculated);
      formikRef.current.setFieldValue(
        "mapped",
        Array.from(header, (x) => {
          return {
            dataType: "Select",
          };
        })
      );
      setWarningMessage(info);
      setServerError([]);
      toastr.success(res.success, "");
    } catch (error) {
      console.log(error);
      toastr.error("Populate Error");
    }
  };
  const SubmitForm = async (values) => {
    const { importDate, mapped } = values;
    const formData = new FormData();
    //console.log(mapped);
    formData.append("insightFile", insightFile);
    formData.append("importDate", importDate);
    formData.append("mapped", JSON.stringify(mapped));
    formData.append("calculatedData", filemode);
    try {
      let res = await post("/admin/insights/fileimport", formData);
      const { id, success } = res;
      setImportContextData({
        ...ImportContextData,
        ["id"]: id,
        ["accessTabs"]: [...ImportContextData["accessTabs"], "Chart"],
        DataType: mapped,
      });
      history.push("/admin/chartimport/add", { TabNo: "2" });
      toastr.success(success, "");
      setServerError([]);
    } catch (error) {
      //console.log(error.response);
      console.log(error.response?.data?.errors?.insightFile);
      setServerError(
        error.response?.data?.errors?.insightFile
          ? error.response.data.errors.insightFile
          : []
      );
      toastr.error("Data Import Error");
    }
  };

  useEffect(() => {
    const redirect = (e) => {
      e.preventDefault();
      e.returnValue = "";
      /*history.push("/admin/chartimport");*/
    };
    window.addEventListener("beforeunload", redirect);
    return () => {
      window.removeEventListener("beforeunload", redirect);
    };
  }, [history]);

  return (
    <BlockUi tag="div">
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle>Import</CardTitle>
              <Formik
                innerRef={formikRef}
                enableReinitialize
                initialValues={{
                  importDate,
                  insightFile,
                  mapped: [],
                }}
                validationSchema={Yup.object({
                  insightFile: Yup.mixed()
                    .required("Please upload excel (.xlsx) file")
                    .test(
                      "File format",
                      "Please upload excel (.xlsx) file only",
                      function () {
                        if (!importFormdata.insightFile) return false;
                        return (
                          importFormdata.insightFile.name &&
                          importFormdata.insightFile.name.endsWith(".xlsx")
                        );
                      }
                    )
                    .test(
                      "File size",
                      "File size is large. Please upload the file in less than 3MB",
                      function () {
                        if (!importFormdata.insightFile) return false;
                        return (
                          importFormdata.insightFile.size <= 1024 * 1024 * 3
                        );
                      }
                    ),
                  mapped: Yup.array().of(
                    Yup.object().shape({
                      dataType: Yup.string()
                        .required("field is required")
                        .test(
                          "check required",
                          "field is required",
                          function (value) {
                            if (
                              value == undefined ||
                              value == "Select" ||
                              value == "" ||
                              value == 1
                            ) {
                              return false;
                            } else {
                              return true;
                            }
                          }
                        ),
                    })
                  ),
                })}
                onSubmit={(values) => {
                  SubmitForm(values);
                }}
                render={({ values, touched, ...formik }) => (
                  <Form>
                    <div className="mb-4"></div>
                    <InputText
                      type="text"
                      disabled={true}
                      value={new Date().toJSON().slice(0, 10)}
                      name={"importDate"}
                      placeholder={"Import Date"}
                      onChange={handleInputChange}
                    />
                    <InputFile
                      type="file"
                      name={"insightFile"}
                      placeholder={"Import File"}
                      disabled={ImportContextData?.id ? true : false}
                      onInput={handleFileImportChange}
                      onChange={() => {
                        formik.handleBlur;
                        formik.resetForm();
                        setSelectFieldData([]);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {/* <FormAligner>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="replace"
                        value="true"
                        id="defaultCheck"
                        onClick={(e) => {
                          // if (
                          //   values.replace != "true" ||
                          //   values.replace == ""
                          // ) {
                          //   formik.setFieldValue(e.target.name, e.target.value);
                          // } else {
                          //   formik.setFieldValue(e.target.name, "false");
                          // }
                        }}
                      />
                      <label htmlFor="defaultCheck1">
                        Given excel file contains pre calculcated data
                      </label>
                    </FormAligner> */}
                    <FormAligner>
                      {!ImportContextData.id ? (
                        <button
                          className="btn btn-success col-md-2 me-2"
                          type="button"
                          //disabled={ImportContextData?.id ? true : false}
                          onClick={async (e) => {
                            formik.setFieldTouched("insightFile", true);
                            if (insightFile != "") {
                              Populate(insightFile);
                            }
                          }}
                        >
                          {loader == true
                            ? "Processing   "
                            : "Populate Excel Fields"}
                          {loader && (
                            <Spinner
                              className="ms-2"
                              style={{ height: "1rem", width: "1rem" }}
                              color="light"
                            />
                          )}
                        </button>
                      ) : (
                        ""
                      )}
                      {!ImportContextData.id ? (
                        <Link to="/admin/chartimport">Cancel</Link>
                      ) : (
                        ""
                      )}
                    </FormAligner>
                    {SelectFieldData.length != 0 && (
                      <div>
                        <br />
                        <h3 className="card-title">
                          <div className="mb-3" style={{ fontSize: "25px" }}>
                            Map DataType
                          </div>
                        </h3>
                        <hr />
                        {ServerError.length != 0 ? (
                          <Alert color="danger">
                            {ServerError.join(", ") + " is having invalid data"}
                          </Alert>
                        ) : null}
                        <FieldArray
                          name="mapped"
                          render={(arrayHelpers) => {
                            //console.log(formik.touched);
                            return (
                              <div>
                                {SelectFieldData &&
                                  SelectFieldData != [] &&
                                  SelectFieldData.map(function (val, key) {
                                    return (
                                      <SelectField
                                        type="select"
                                        name={`mapped.${key}.dataType`}
                                        key={key}
                                        keys={key}
                                        placeholder={val}
                                        submited={submited}
                                        disabled={
                                          ImportContextData?.id ? true : false
                                        }
                                        options={{
                                          date: "Date",
                                          number: "Number",
                                          string: "String",
                                          commaseperatedstring:
                                            "Comma Seperated String",
                                        }}
                                        defaultValue={""}
                                        onMouseDown={(e) => {
                                          handleInputChange(e);
                                        }}
                                        onClick={(e) => {
                                          handleInputChange(e);
                                          formik.setFieldTouched(
                                            e.target.name,
                                            true
                                          );
                                        }}
                                      />
                                    );
                                  })}
                              </div>
                            );
                          }}
                        />
                        <FormAligner>
                          <button
                            className="btn btn-success col-md-2 me-2"
                            disabled={ImportContextData?.id ? true : false}
                            onClick={() => {
                              formik.handleSubmit();
                            }}
                            type="button"
                          >
                            Save and Next
                          </button>
                          <Link to="/admin/chartimport">Cancel</Link>
                        </FormAligner>
                      </div>
                    )}
                  </Form>
                )}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    </BlockUi>
  );
};

export default ImportTab;
