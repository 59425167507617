import React from "react";
import { Row, FormFeedback } from "reactstrap";
import { useField, ErrorMessage } from "formik";
import { Input } from "reactstrap";
const UniqueList = ({uniqueRow, placeholder, disable, UniqueData, ...Reactprops }) => {
  return (
    <Row className="mb-3">
      <label htmlFor="date" className="col-md-2 col-form-label form-label">
        {placeholder}
      </label>
      {uniqueRow != "" && (
      <div className="col-md-10">
        <p className="mt-2">
          {UniqueData.join(", ")}
        </p>
      </div>
      )}
    </Row>
  );
};
export default UniqueList;
