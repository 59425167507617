import Preview from "./Component/PreviewTab/PreviewTab";
import React, { useState, useEffect, useMemo, useParams, useContext, useRef } from "react"
import {
  Container,
  Table
} from "reactstrap"
import { getInvoiceDetail } from "store/actions";


import OrderType from "./Component/OrderType";
import PaymentVisibility from "./Component/PaymentVisibility";


const CommerceExperience = ({ UnSubscription, activeTab, commerceData, userSessionData, NoBrandSelected, NoInsightSelected, activeInsight}) => {

  const [dropdown, setDropdown] = useState(false);
  const [insightSearch, setInsightSearch] = useState("");
  const [selectedInsight, setSelectedInsight] = useState([]);
  const [appliedInsight, setAppliedInsight] = useState([]);
  const [insightValue, setInsightValue] = useState([]);
  const [insightValueId, setInsightValueId] = useState([]);
  const [valuess, setvalues] = useState([]);
  const insightDropDown = useRef();


  // const insightData = ["Payment Method Availability & Visibility","Order Type"];



  function selectValue() {
    let valuesArray = [];
    let valueIdArray = [];
    for (let i = 0; i < commerceData.length; i++) {
      const values = commerceData[i].InsightTitle;
      const valId = commerceData[i].insightId;
      //insightValue.push({"val":values,"valId":valId});
      
      valuesArray.push(values);
      valueIdArray.push(valId);
    }
    let newSelectedInsight = selectedInsight.filter(elem => valueIdArray.includes(elem));
    let newAppliedInsight = appliedInsight.filter(elem => valueIdArray.includes(elem));
    setSelectedInsight(newSelectedInsight);
    setAppliedInsight(newAppliedInsight);
    setInsightValue(valuesArray);
    setInsightValueId(valueIdArray);
  }
  useEffect(() =>{
    if(activeInsight){
      setSelectedInsight([activeInsight]);
      setAppliedInsight([activeInsight]);
    }
  },[activeInsight])

  useMemo(
    () => {
      selectValue()
    },
    [commerceData]
  );

  function getId() {

   for (let i = 0; i < selectedInsight.length; i++) {

     const appval = selectedInsight[i];

      const seletinsightval = insightValueId[appval];
      
      valuess.push(seletinsightval);
     
      console.log('finalvalues', valuess);
     
    }

 }



  const insight = insightValue.map((index, i) => {

    let display = insightSearch == ""
      ? (i > 6 ? "show" : "show")
      : (index.toLowerCase().includes(insightSearch.toLowerCase()) ? "show" : "hide");

    display = display == "hide"
      ? (selectedInsight.includes(index) ? "show" : "hide")
      : display;

    return (<li key={i} className={display} onClick={() => (selectedInsight.length < 3
      || selectedInsight.includes(insightValueId[i]))
      ? onChangeInsight(insightValueId[i]) : null}>
      <input
        type="checkbox"
        value={insightValueId[i]}
        className="styled-checkbox"
        id={`dropdown-${index.replace(/\s+/g, '')}`}
        name={"insightSearch"}
        checked={selectedInsight.includes(insightValueId[i])}
        disabled={selectedInsight.length >= 3
          && !selectedInsight.includes(insightValueId[i])
          ? true : false
        }
        title={selectedInsight.length >= 3
          && !selectedInsight.includes(insightValueId[i])
          ? "Only 3 insights can be selected at once"
          : ""}
      />
      <label
        htmlFor={index.replace(/\s+/g, '')}
      >
        {index}
      </label>
    </li>)


  });

  const onChangeInsight = (name) => {
    console.log(name);
    const newSelectedItems = selectedInsight.includes(name)
      ? selectedInsight.filter((item) => item !== name)
      : [...selectedInsight, name];
    setSelectedInsight(newSelectedItems);
 };

  const insightCount = selectedInsight.length;

  useEffect(() => {
    setvalues([])
  }, [selectedInsight])

  useOnClickOutside(insightDropDown, () => setDropdown(false));

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }
  // useEffect(() => {
  //   console.log(selectedInsight);
  // },[selectedInsight]);

  return (
    <React.Fragment>
      <div className="detailed-insights"
        style={{ display: activeTab === "1" ? 'block' : 'none' }}>
        <h4 style={{"margin-left": "32px"}}>Detailed Insights</h4>
        <div style={{"margin-left": "32px"}} className="d-flex dropdown-tab-content">
          <div className="dropdown" ref={insightDropDown}>
            <button
              className="btn dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              onClick={() => {
                setDropdown(!dropdown);
              }}
             

            >
              {insightCount == "0" ? "No" : insightCount} Insights Selected
              {/* <i className= "dropdown-toggle-icon" /> */}
              <i className={dropdown ? "dropdown-toggle-icon rotate" : "dropdown-toggle-icon"} />
            </button>

            <ul
              className={
                dropdown
                  ? "multiselect-container dropdown-menu show"
                  : "multiselect-container dropdown-menu"
              }>
              <li>
                <input
                  type="search"
                  placeholder="Search insights"
                  value={insightSearch}
                  onChange={(v) => {
                    setInsightSearch(v.target.value)
                  }}

                />
              </li>
              {insight}

              <li className="apply-btn">

                <button
                  className="btn"

                  onClick={() => {
                    setAppliedInsight(selectedInsight);
                    setDropdown(!dropdown);

                  }}

                >Apply


                </button>

              </li>


            </ul>
          </div>

          <button
            type="reset"
            className="reset-filter"
            onClick={() => {
              console.log("reset filter");
              setSelectedInsight([]);
              setAppliedInsight([]);
              setInsightSearch("");
              setvalues([]);
            }}
          >Reset Filters</button>
        </div>

        <div className="tab-content" id="details-insightsContent" style={{ height: "auto", "minHeight": "400px" }}>
          <div className="tab-wrap tab-pane fade show active" id="commerce" role="tabpanel" aria-labelledby="commerce-tab">
            {appliedInsight.length > 0
              ? appliedInsight.map((value, index) => (


                <div key={value}>
                  <Preview
                    valuess={appliedInsight[index]} NoBrandSelected={NoBrandSelected} useOnClickOutside={useOnClickOutside} /></div>)) : null
            }
            {/* {
                appliedInsight.includes(1) ?
                  <OrderType NoBrandSelected={NoBrandSelected} useOnClickOutside={useOnClickOutside} />
                  : ""
              } */}

              {
                appliedInsight.length == 0 ?
                  <NoInsightSelected /> :
                  ""
              }




            {/*<ClickToDoor brandDropDown={brandDropDown} brandList={brandList}/>*/}



            {/*<div className="graph-details">
                  <div className="row">
                    <div className="col-12">
                      <h4>
                        Retailers are making huge investments into click-to-door speeds in  2022, the fashion industry can return to growth as changing category landscapes.
                      </h4>
                    </div>
                  </div>
                  <div className="author-section">
                    <div className="row">
                      <div className="col-12 col-md-7 col-lg-8">
                        <div className="author-img-details">
                          <img src="assets/images/graph-details-img.png" alt="author-img-details" />
                          <em>Gucci Photoshoot 2020</em>
                        </div>
                      </div>
                      <div className="col-12 col-md-5 col-lg-4">
                        <div className="accordion" id="author-acco">
                          <div className="accordion-item">
                            <h2 className="accordion-header" id="aboutAuthor">
                              <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#authorOne" aria-expanded="true" aria-controls="authorOne">
                                About the Authors
                              </button>
                            </h2>
                            <div id="authorOne" className="accordion-collapse collapse show" aria-labelledby="aboutAuthor" data-bs-parent="#author-acco">
                              <div className="accordion-body">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh nunc morbi arcu scelerisque sed. At mauris lacus nulla tincidunt vel posuere eu purus gravida. 
                              </div>
                            </div>
                          </div>
                        </div>  
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        <div className="author-para">
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh nunc morbi arcu scelerisque sed. At mauris lacus nulla tincidunt vel posuere eu purus gravida. Faucibus rhoncus purus nibh proin quis pellentesque scelerisque amet. Nunc duis magna aenean nunc amet, posuere. Sapien nunc facilisis ac nullam phasellus condimentum feugiat in donec. Interdum sem at sollicitudin sed laoreet nunc sit. Consequat amet volutpat urna, et, elementum habitant gravida nunc. 
                          </p>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nibh nunc morbi arcu scelerisque sed. At mauris lacus nulla tincidunt vel posuere eu purus gravida. Faucibus rhoncus purus nibh proin quis pellentesque scelerisque amet. Nunc duis magna aenean nunc amet, posuere. Sapien nunc facilisis ac nullam phasellus condimentum feugiat in donec. Interdum sem at sollicitudin sed laoreet nunc sit. Consequat amet volutpat urna, et, elementum habitant gravida nunc. 
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>*/}
          </div>

          {/*<div className="tab-pane fade" id="shipping" role="tabpanel" aria-labelledby="shipping-tab">...</div>
              <div className="tab-pane fade" id="logistics" role="tabpanel" aria-labelledby="logistics-tab">...</div>
              <div className="tab-pane fade" id="return" role="tabpanel" aria-labelledby="return-tab">...</div>
              <div className="tab-pane fade" id="customer-service" role="tabpanel" aria-labelledby="customer-service-tab">...</div>*/}

          {/*<div className="share-options">
                <ul>
                  <li>
                    <a href="javascript:void(0);">
                      
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      ss
                    </a>
                  </li>
                  <li>
                    <a href="javascript:void(0);">
                      ss
                    </a>
                  </li>
                </ul>
              </div>*/}
        </div>

      </div>
    </React.Fragment>
  );

};

export default CommerceExperience;