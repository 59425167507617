import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Container, Table, Input } from "reactstrap";
import io from "socket.io-client";
import { get } from "../../helpers/api_helper";
import { useHistory } from "react-router-dom";
import lockImg from "assets/images/icons-lock.png";
import { useParams } from "react-router";
import UserContext from "../../helpers/AuthType/session_helper";
//import ClickToDoor from "./ClickToDoor";
import CommerceExperience from "./CommerceExperience";
import ShippingPackage from "./ShippingPackage";
import ReturnRefundBreakDown from "./ReturnRefundBreakDown";
import CustomerService from "./CustomerService";
import LogisticsTransportation from "./LogisticsTransportation";

const InsightsTabContent = (props) => {
  let tab = "1";
  let paramdata = useParams().tab;
  let insightName = useParams().iname;
  switch (paramdata) {
    case "commerce-experience":
      tab = "1";
      break;
    case "shipping-package":
      tab = "2";
      break;
    case "market-intel":
      tab = "3";
      break;
    case "brand-health":
      tab = "4";
      break;
    case "customer-service":
      tab = "5";
      break;
    default:
      tab = "1";
      break;
  }

  const [activeTab, setactiveTab] = useState(tab > 5 ? "1" : tab);
  const [activeInsight, setactiveInsight] = useState(null);
  const [userSessionData, setuserSessionData] = useContext(UserContext);
  const [commerceData, setCommerceData] = useState([]);
  const [shippingData, setShippingData] = useState([]);
  const [logisticData, setLogisticData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [customData, setcustomData] = useState([]);
  const [responseData, setResponseData] = useState(null);
  const myRef = useRef(null);
  const contentRef = useRef(null);
  const history = useHistory();

  const fetchData = async () => {
    try {
      const res = await get("insights/getInsights");
      console.log("res", res);
      setResponseData(res);
      setCommerceData(res["Commerce Experience"]);
      setShippingData(res["Shipping and Package"]);
      setLogisticData(res["LogisticsTransportation"]);
      setBrandData(res["Brand and market"]);
      setcustomData(res["Customer Service"]);
    } catch (err) {
      console.log(err);
    }
  };
  const [localData, setlocalData] = useState(
    JSON.parse(window.localStorage.getItem("authUser"))
  );

  useMemo(() => {
    if (responseData == null) {
      fetchData();
    }
    const socket = io(process.env.REACT_APP_SOCKET_IO_URL, {
      transports: ["polling", "websocket"],
      query: `token=${localData.accessToken}`,
    });

    // as soon as the component is mounted, do the following tasks:
    socket.on("connect", function (e) {
      setuserSessionData({ ...userSessionData, socketData: socket });
    });

    // Join with email
    socket.emit("join", { email: userSessionData.email });

    socket.on("message", async function (data) {
      let msg = data.msg;
      console.log(msg);
      if (msg == "updated insight") {
        fetchData();
      }
    });

    socket.on("connect_error", function (err) {});
    return () => {
      try {
        socket.off("disconnect", "User disconnected");
      } catch (err) {}
    };
  }, []);
  const UnSubscription = () => {
    return (
      <div className="not-subscribed-box">
        <div className="lockIcon">
          <img src={lockImg} alt="lock-icon" />
        </div>
        <h4 className="unsubscriptiontitle">
          You are not subscribed to Acumen.
          <br />
          <br />
          Please click here to{" "}
          <a href="mailto:billings@acumen-data.com">book a demo call</a> to
          unlock insights.
        </h4>
      </div>
    );
  };

  const NoBrandSelected = () => {
    return (
      <div className="two-select-boxes">
        <p className="inner-content-donut nobrandsSelect">
          Not enough brands were selected.
          <br />
          Please select at least one brand to view data.
        </p>
      </div>
    );
  };

  const NoInsightSelected = () => {
    return (
      <div className="two-select-boxes">
        <p className="inner-content-donut" style={{ fontSize: "16px" }}>
          Please select at least one Insight to view data.
        </p>
      </div>
    );
  };
  useEffect(() => {
    setactiveTab(tab > 5 ? "1" : tab);
  }, [useParams()]);
  useEffect(() => {
    setactiveInsight(insightName)
    myRef.current.scrollIntoView();
  },[useParams().iname])
  // useEffect(() => {
  //   if (paramdata && paramdata != "industry-leaders") {
  //     myRef.current.scrollIntoView();
  //   }
  // }, [activeTab]);
  useEffect(() => {
    if (paramdata && paramdata != "industry-leaders") {
      myRef.current.scrollIntoView();
    }
  }, [userSessionData.notificationClick]);

  const isInViewport1 = useIsInViewport(contentRef);
  function useIsInViewport(ref) {
    const [isIntersecting, setIsIntersecting] = useState(false);
    const observer = useMemo(
      () =>
        new IntersectionObserver(([entry]) =>
          setIsIntersecting(entry.isIntersecting)
        ),
      []
    );

    useEffect(() => {
      observer.observe(ref.current);

      return () => {
        observer.disconnect();
      };
    }, [ref, observer]);

    return isIntersecting;
  }

  return (
    <React.Fragment>
      <div className="insights-tabs" ref={myRef}>
        <ul
          className="nav nav-pills mb-3 align-items-center"
          id="details-insights"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={activeTab === "1" ? "nav-link active" : "nav-link"}
              onClick={() => {
                history.push("/insights/commerce-experience");
                myRef.current.scrollIntoView();
              }}
              id="commerce-tab"
            >
              Commerce Experience
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={activeTab === "2" ? "nav-link active" : "nav-link"}
              onClick={() => {
                history.push("/insights/shipping-package");
                myRef.current.scrollIntoView();
              }}
              id="shipping-tab"
            >
              Shipping & Package
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={activeTab === "3" ? "nav-link active" : "nav-link"}
              onClick={() => {
                history.push("/insights/market-intel");
                myRef.current.scrollIntoView();
              }}
              id="logistics-tab"
            >
              Market Intel
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={activeTab === "4" ? "nav-link active" : "nav-link"}
              onClick={() => {
                history.push("/insights/brand-health");
                myRef.current.scrollIntoView();
              }}
              id="return-tab"
            >
              Brand Health
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={activeTab === "5" ? "nav-link active" : "nav-link"}
              onClick={() => {
                history.push("/insights/customer-service");
                myRef.current.scrollIntoView();
              }}
              id="customer-service-tab"
            >
              Customer Service
            </button>
          </li>
        </ul>
      </div>
      <div ref={contentRef}>
        {activeTab === "1" ? (
          <CommerceExperience
            UnSubscription={UnSubscription}
            activeTab={activeTab}
            userSessionData={userSessionData}
            commerceData={commerceData}
            NoBrandSelected={NoBrandSelected}
            NoInsightSelected={NoInsightSelected}
            activeInsight={activeInsight}
          />
        ) : (
          ""
        )}

        {activeTab === "2" ? (
          <ShippingPackage
            UnSubscription={UnSubscription}
            activeTab={activeTab}
            shippingData={shippingData}
            userSessionData={userSessionData}
            NoBrandSelected={NoBrandSelected}
            NoInsightSelected={NoInsightSelected}
            activeInsight={activeInsight}
          />
        ) : (
          ""
        )}
        {activeTab === "3" ? (
          <LogisticsTransportation
            UnSubscription={UnSubscription}
            activeTab={activeTab}
            logisticData={logisticData}
            userSessionData={userSessionData}
            NoBrandSelected={NoBrandSelected}
            NoInsightSelected={NoInsightSelected}
            activeInsight={activeInsight}

          />
        ) : (
          ""
        )}
        {activeTab === "4" ? (
          <ReturnRefundBreakDown
            UnSubscription={UnSubscription}
            activeTab={activeTab}
            brandData={brandData}
            userSessionData={userSessionData}
            NoBrandSelected={NoBrandSelected}
            NoInsightSelected={NoInsightSelected}
            activeInsight={activeInsight}

          />
        ) : (
          ""
        )}

        {activeTab === "5" ? (
          <CustomerService
            UnSubscription={UnSubscription}
            activeTab={activeTab}
            customData={customData}
            userSessionData={userSessionData}
            NoBrandSelected={NoBrandSelected}
            NoInsightSelected={NoInsightSelected}
            activeInsight={activeInsight}

          />
        ) : (
          ""
        )}
      </div>
      {!isInViewport1 && (
        <div
          className="scroll-down"
          onClick={() => {
            myRef.current.scrollIntoView();
          }}
        >
          {" "}
          Scroll Down for Insights{" "}
        </div>
      )}
    </React.Fragment>
  );
};

export default InsightsTabContent;
