import React from "react";
import { Row, FormFeedback } from "reactstrap";
import { useField, ErrorMessage } from "formik";
import { Input } from "reactstrap";
const InputText = ({ placeholder, rowClass = null, disabled = false, ...Reactprops }) => {
  const [field, meta] = useField(Reactprops);
  //console.log(field);
  //console.log(meta,field.name);
    return (
    <Row className={rowClass != null ? rowClass : "mb-3"}>
      <label htmlFor="date" className="col-md-2 col-form-label form-label">
        {placeholder}
      </label>
      <div className="col-md-10">
        <Input
          className={
            meta.touched && meta.error
              ? "input_error_border form-control"
              : "form-control"
          }
          disabled={disabled}
          name={field.name}
          invalid={meta.touched && meta.error
              ? true
              : false
          }
          {...Reactprops}
          {...field}
        />
        {meta.touched && meta.error ? (
           <FormFeedback type="invalid">
            {`${placeholder} ${meta.error}`}
         </FormFeedback>
        ):null}
      </div>
    </Row>
  );
};
export default InputText;
