import React, { useMemo, useState, useEffect } from "react"
import {
  Container,
  Form,
  Modal,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Badge,
  Spinner,
  Input,
  FormFeedback,
} from "reactstrap"

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';

import { useHistory, Link } from 'react-router-dom';
import {get, del, post} from "../../helpers/api_helper";
import PropTypes from 'prop-types';

import toastr from "toastr";
import "toastr/build/toastr.min.css";

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import arrowLeft from 'assets/images/Arrow-left.svg';

const BrandAdd = () => {

    const history = useHistory();
    const user = JSON.parse(localStorage.getItem("authUser"));

    const [onSubmit, setonSubmit] = useState(false);
    const [logoImage, setlogoImage] = useState("");
    const [description, setdescription] = useState("");

    //Toastr
    const [showEasing, setshowEasing] = useState("swing");
    const [hideEasing, sethideEasing] = useState("linear");
    const [showMethod, setshowMethod] = useState("fadeIn");
    const [hideMethod, sethideMethod] = useState("fadeOut");
    const [showDuration, setshowDuration] = useState(300);
    const [hideDuration, sethideDuration] = useState(1000);
    const [timeOut, settimeOut] = useState(5000);
    const [extendedTimeOut, setextendedTimeOut] = useState(1000);


    let positionClass = "toast-top-right";

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: true,
        progressBar: true,
        preventDuplicates: true,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
      };
    //End toastr

    const SUPPORTED_FORMATS = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    let patternTwoDigisAfterComma = /^\d+(\.\d{0,2})?$/;
    const validation = useFormik({
      // enableReinitialize : use this flag when initial values needs to be changed
      enableReinitialize: true,
      initialValues: {
        logo: '',
        name: '',
        industry: '',
        description: '',
        'country': '',
        score:''
      },
      validationSchema: Yup.object({
        name: Yup.string().required("The name field is required").max(30),
        industry: Yup.string().required("The industry field is required").max(30).matches(/^[A-Za-z0-9 ]+$/,'special characters are not allowed'),
        country: Yup.string().required("The country field is required").max(30).matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
        score: Yup.number().required("The innovation score field is required")
        .nullable(true)
        .min(0, 'The innovation score should be greater than or equal to 0')
        .max(100, 'The innovation score should be less than 100'),
        logo: Yup.mixed()
        .test('File size',
          'File size is large. Please upload image less than 3MB', (value) => !value || (value.size <= 1024 * 1024 * 3))
        .test('File format',
          'Please upload image files only', (value) => !value || (value && SUPPORTED_FORMATS.includes(value.type))),
      }),

      onSubmit: async (values) => {
        setonSubmit(true);

        const formData = new FormData();

        Object.keys(values).map(function(key) {
          if(values[key] != "")
          {
            formData.append(key,values[key]);
          }
        });

        description != ""
          ? formData.append('description',description)
          : "";

        logoImage != ""
          ? formData.append('logo',logoImage)
          : "";

        values['score'] == 0
          ? formData.append('score',0)
          : ""

        const token = user.accessToken;
        try {
          let res = await post('admin/brands/add', formData); 
          toastr.success(res.success,"");
          history.push("/admin/brands");  
        } catch(error) {
          setonSubmit(false);
          if (error.response) {
             // Request made and server responded
             console.log(error.response.data.errors);
             validation.setErrors(error.response.data.errors);
           } else if (error.request) {
             // The request was made but no response was received
             toastr.error(error,"Request failed");
           } else {
             //console.log(error)
             // Something happened in setting up the request that triggered an Error
             toastr.error(error,"Request failed.");
           }
        }
      },
    });


    const editorConfiguration = {
      removePlugins: ['CKFinderUploadAdapter', 
                      'CKFinder', 
                      'EasyImage', 
                      'Image', 
                      'ImageCaption', 
                      'ImageStyle', 
                      'ImageToolbar', 
                      'ImageUpload', 
                      'MediaEmbed',
                      'Link',
                      'Table',
                      'Heading',
                      'BulletedList',
                      'NumberedList',
                      'BlockQuote'
                    ],
    };

    
  //meta title
  document.title="Add Brand - Acumen";
  return (
    <React.Fragment>
      <div className="inner-content">
      <div className="pr-page-title">
          <h2>Add Brand</h2>
          <h6>
          <div className="back"> 
            <Link
              to="/admin/brands"
            >
              <img 
                src={arrowLeft} alt="arrow" 
              />Back
            </Link>
            </div>
          </h6>
      </div>
        <form onSubmit={validation.handleSubmit} className="formadd">
          <Row className="mb-3">
            <label
              htmlFor="logo"
              className="col-md-2 col-form-label form-label"
            >
              Logo
            </label>
            <div className="col-md-10">
              <Input 
                className="form-control line-height" 
                type="file" 
                id="logo" 
                name="logo"
                onChange={event => validation.setFieldValue('logo', event.target.files[0])}
                onInput={validation.handleBlur}
                onBlur={validation.handleBlur}
                invalid={
                  validation.errors.logo
                    ? true
                    : false
                }
              />
              {
                validation.errors.logo ? (
                  <FormFeedback type="invalid">
                    {validation.errors.logo}
                  </FormFeedback>
                ) : null}
            </div>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Name
            </label>
            <div className="col-md-10">
              <Input
                className="form-control"
                type="text"
                id="name"
                name="name"
                placeholder="Enter Name"
                value={validation.values.name || ""}
                onChange={validation.handleChange}
                onInput={validation.handleBlur}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.name &&
                  validation.errors.name
                    ? true
                    : false
                }
              />
              {validation.touched.name &&
                validation.errors.name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.name}
                  </FormFeedback>
                ) : null}
            </div>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Industry
            </label>
            <div className="col-md-10">
              <Input
                className="form-control"
                type="text"
                id="industry"
                name="industry"
                placeholder="Enter Industry"
                value={validation.values.industry || ""}
                onChange={validation.handleChange}
                onInput={validation.handleBlur}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.industry &&
                  validation.errors.industry
                    ? true
                    : false
                }
              />
              {validation.touched.industry &&
                validation.errors.industry ? (
                  <FormFeedback type="invalid">
                    {validation.errors.industry}
                  </FormFeedback>
                ) : null}
            </div>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Country/Region
            </label>
            <div className="col-md-10">
              <Input
                className="form-control"
                type="text"
                id="country"
                name="country"
                placeholder="Enter Country/Region"
                value={validation.values.country || ""}
                onChange={validation.handleChange}
                onInput={validation.handleBlur}
                onBlur={validation.handleBlur}
                invalid={
                  validation.touched.country &&
                  validation.errors.country
                    ? true
                    : false
                }
              />
              {validation.touched.country &&
                validation.errors.country ? (
                  <FormFeedback type="invalid">
                    {validation.errors.country}
                  </FormFeedback>
                ) : null}
            </div>
          </Row>
          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Innovation Score
            </label>
            <div className="col-md-10">
                  <Input
                    type="text"
                    className="form-control"
                    id="score"
                    placeholder="Enter innovation score"
                    name="score"
                    autoComplete="new-password"
                    value={validation.values.score}
                    onKeyPress={(event) => {
                      return (event.charCode >= 48 && event.charCode <= 57) 
                      ? true : event.preventDefault();
                    }}
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.score &&
                      validation.errors.score
                        ? true
                        : false
                    }
                  />
              {validation.touched.score &&
                validation.errors.score ? (
                  <FormFeedback type="invalid">
                    {validation.errors.score}
                  </FormFeedback>
                ) : null}
            </div>
          </Row>

          <Row className="mb-3">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
              Description
            </label>
            <div className="col-md-10">
              <CKEditor
                editor={ClassicEditor}
                config={ editorConfiguration }
                data=""
                id="description"
                name="description"
                onReady={editor => {
                  // You can store the "editor" and use when it is needed.
                  console.log('Editor is ready to use!', editor);
                }}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  setdescription(data);
                }}

              />
            </div>
          </Row>

          <Row className="mt-5">
            <label
              htmlFor="example-text-input"
              className="col-md-2 col-form-label form-label"
            >
            </label>
            <div className="col-md-8">
               <button 
                type="submit" 
                className="btn btn-success col-md-4"
                disabled={onSubmit ? true : false}
                >
                  {onSubmit ? "Loading..." : "Submit"}
              </button>              
              <Link
                  to="/admin/brands"
                  className="text-primary ms-2"
                >Cancel</Link>
            </div>
          </Row>
      </form>    


      </div>

  </React.Fragment>
  );
}


export default BrandAdd;
