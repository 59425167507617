import React, {useState, useEffect, useContext, useRef} from "react"
import {
  Container,
  Table
} from "reactstrap"

import {get, post} from "../../../helpers/api_helper";
import ReturnObjectiveInsights from "../../Chart/ReturnObjectiveInsights";
import StaticContent from "./StaticContent/StaticContent";

const OrderType = ({NoBrandSelected, useOnClickOutside}) => {

	const [brandDropDown, setBrandDropdown] = useState(false);
  	const [brandSearch, setBrandSearch] = useState("");
  	
  	const [selectedBrands, setSelectedBrands] = useState([]);
  	const [visibleData, setvisibleData] = useState({});  	
  	const [retailers, setRetailers] = useState([]);
  	const selectedBrandCount = selectedBrands.length;
  	const [appliedBrands, setAppliedBrands] = useState([]);
    const [filterDropDown, setFilterDropdown] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState("14");
    const [appliedFilter, setAppliedFilter] = useState("14");
    const [questDropDown, setQuestDropDown] = useState(false);
    const [questions, setQuestions] = useState([]);
    const [questSelected, setQuestSelected] = useState("No Sub-Insights Selected");
    const [questChanged, setQuestChanged] = useState("");
    const [sideBar, setSideBar] = useState([]);
    const [colorCodes, setcolorCodes] = useState([]);
    const [selectedColors, setselectedColors] = useState([]);
    const [Busy, setBusy] = useState(true);
    const [staticData,SetstaticData] = useState(false)

    const brandFilter = useRef();
    const dayFilter = useRef();

    const fetchAvgShippingTimes = async function () {
	    setBusy(true);
	    try {
	      const response = await get("insights/OrderTypes?filter="+appliedFilter);
	      if(selectedBrands.length == 0) {
		  	   let brands = response.retailers.length > 7 
                     ? response.retailers.slice(0,7) : response.retailers;
  		  }
        setSideBar(response.sidebar);
	      setRetailers(response.retailers);
	      setvisibleData(response.data);
        setcolorCodes(response.colorCodes)
        setselectedColors(response.selectedColors)
	      setBusy(false);
	    } catch(err) {
	      /*console.log(err);
	      setBusy(false);*/
        setBusy(false);
	    }
	}

	useEffect(() => {
	    fetchAvgShippingTimes();
	  }, [appliedFilter, questChanged]);

  /*useEffect(() => {
      fetchAvgShippingTimes();
    }, [questSelected]);
*/
	const onChangeBrand = (name) => {
	    const newSelectedItems = selectedBrands.includes(name)
	      ? selectedBrands.filter((item) => item !== name)
	      : [...selectedBrands, name];
	    setSelectedBrands(newSelectedItems);
	    
	};

  	const brandList = retailers.map((index, i)=> {
        
  		let display = brandSearch == "" 
                  ? (i > 6 ? "show" : "show")
                  : (index.toLowerCase().includes(brandSearch.toLowerCase())?"show":"hide");

    	display = display == "hide"
              ? (selectedBrands.includes(index) ? "show" : "hide")
              : display; 

      return (<li key={index} className={display} onClick={() => (selectedBrands.length < 7 
        || selectedBrands.includes(index) )
        ? onChangeBrand(index): null }>
          <input 
               type="checkbox" 
               value={index}
               className="styled-checkbox"
               id={index.replace(/\s+/g, '')}
               name={"brandSearch"}
               checked={selectedBrands.includes(index)}
               disabled={selectedBrands.length >= 7 
                          && !selectedBrands.includes(index) 
                          ? true:false
                        }
               title={selectedBrands.length >= 7 
                          && !selectedBrands.includes(index) 
                          ? "Only 7 brands can be selected at once"
                          : ""}
          />
          <label 
            htmlFor={index.replace(/\s+/g, '')}
          >
            {index}
          </label>
        </li>)

	});
  	/*console.log(visibleData);
  	console.log(selectedBar)*/
  const filterData = {"14":"14 Days Average","28":"28 Days Average","84":"3 Months Average"};

  const filter = Object.keys(filterData).map((index, i)=>{

    return (<li key={index}>
          <input 
               type="radio" 
               value={index}
               className="styled-radio"
               name={"filterSearch1"}
               onClick={() => onChangeFilter(index)}
               checked={selectedFilter == index ? true : false}
          />
          <label 
            htmlFor={filterData[index]}
          >
            {filterData[index]}
          </label>
        </li>)

  });

  const onChangeFilter = (name)=> {
    setSelectedFilter(name);
  }

  const questionList = questions.map((index, i)=>{
    
    return (<li key={i} onClick={()=>{
      setQuestSelected(index);
      setQuestDropDown(!questDropDown);
      setQuestChanged(index);
    }} >
              <a 
                href={void(0)}
              >
                <label>
                  {index}
                </label>
              </a>  
                
            </li>)
  });

  useOnClickOutside(brandFilter, () => setBrandDropdown(false));
  useOnClickOutside(dayFilter, () => setFilterDropdown(false));

  const selectedBar = Object.assign({},...appliedBrands.map((d)=>{
    return {[d]: visibleData[d]}
  }))

  console.log(selectedBar);
  console.log(Object.keys(visibleData).length);

	return (
		<React.Fragment>
		<div className="graph-data">
          <div className="row">
            <div className="col-12 col-md-8 col-lg-9 col-xl-10">
              <div className="graph-data-acu" style={{minHeight:"400px"}}>
                <h3>Order Type</h3>
                <div className="two-select-boxes dropdown-tab-content" style={{marginBottom:"20px"}}>
                  <div className="dropdown" ref={brandFilter}>
                    <button 
                      className="btn dropdown-toggle" 
                      type="button" 
                      id="dropdownMenuButton2" 
                      onClick={()=>{
                        //console.log(brandDropDown)
                        setBrandDropdown(!brandDropDown);
                      }}
                      >
                        {selectedBrandCount == "0"?"0":selectedBrandCount} Brands Selected
                        <i className = { 
                          brandDropDown ? 
                            "dropdown-toggle-icon rotate" : 
                            "dropdown-toggle-icon"
                          } 
                        />
                    </button>
                  
                    <ul className={
                        brandDropDown
                          ? "multiselect-container dropdown-menu show"
                          : "multiselect-container dropdown-menu"
                    }>                             
                      <li>
                        <input 
                          type="search" 
                          placeholder="Search Brands"
                          style={{borderRadius:"0px"}} 
                          onKeyUp={(v)=>{
                            setBrandSearch(v.target.value)
                          }}
                          onChange={(v) => setBrandSearch(v.target.value)}
                        />
                      </li>
                      
                      <li className="brandScroll">
                        {brandList}
                      </li>     
                                           
                      <li className="apply-btn">
                        <button 
                        	className="btn"
                        	onClick={()=>{
		                		setAppliedBrands(selectedBrands);
		                		setBrandDropdown(!brandDropDown);
		                	}}
                        	>Apply</button>
                      </li>
                    </ul>
                  </div>
                  <div className="dropdown" ref={dayFilter}>
                    <button 
                      className="btn dropdown-toggle" 
                      type="button" 
                      id="dropdownMenuButton3" 
                      onClick={()=>{
                        setFilterDropdown(!filterDropDown);
                      }}
                      >
                      {filterData[appliedFilter]}
                      <i className = { 
                          filterDropDown ? 
                            "dropdown-toggle-icon rotate" : 
                            "dropdown-toggle-icon"
                          } 
                        />
                    </button>
                  
                    <ul 
                      className={
                        filterDropDown ?
                        "multiselect-container dropdown-menu show" :
                        "multiselect-container dropdown-menu"
                      }
                    >
                      {filter}
                      <li className="apply-btn">
                        <button 
                          className="btn"
                          onClick={()=>{
                            setAppliedFilter(selectedFilter);
                            setFilterDropdown(!filterDropDown);
                            //fetchAvgShippingTimes();
                          }}
                        >Apply</button>
                      </li>
                    </ul>
                  </div>
                </div>
                {
                  Object.keys(selectedBar).length > 0 ?
                  <>
                      <div className="graph-images" style={{marginTop:"30px"}}>
                        {
                            Object.keys(selectedBar).length > 0 
                              && Object.keys(visibleData).length > 0
                              && typeof Object.values(selectedBar)[0] != 'undefined'
                              ? <ReturnObjectiveInsights 
                                BarplatData={selectedBar} 
                                sideBar={sideBar}
                                colorCode={selectedColors}
                                />
                              : ""
                        }
                      </div>
                  </>
                  : <NoBrandSelected /> //NoBrandSelected
                }
               
              </div>
            </div>
            <div className="col-12 col-md-4 col-lg-3 col-xl-2 bg-gray">
              <div className="graph-data-insights">
                <div className="dropdown">
                  <button className="dropdown-toggle" type="button" id="data-graph" data-bs-toggle="dropdown" aria-expanded="false" onClick={() => {SetstaticData(!staticData)}}>
                    <span>{staticData ? "Close":"Data Insight"}</span> 
                    <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="20" cy="20.3364" r="20" fill="#2C2C2C"/>
                      <path d="M18.36 25.2395V27.4595H20.58V25.2395H18.36ZM15.1 17.6395H16.8C16.8 17.1728 16.8533 16.7395 16.96 16.3395C17.0667 15.9395 17.2333 15.5928 17.46 15.2995C17.7 15.0061 17.9933 14.7728 18.34 14.5995C18.6867 14.4261 19.1 14.3395 19.58 14.3395C20.3 14.3395 20.8667 14.5461 21.28 14.9595C21.7067 15.3728 21.9467 15.9461 22 16.6795C22.0267 17.1728 21.9667 17.5928 21.82 17.9395C21.6733 18.2861 21.4733 18.6061 21.22 18.8995C20.98 19.1795 20.7133 19.4528 20.42 19.7195C20.14 19.9728 19.8667 20.2528 19.6 20.5595C19.3467 20.8661 19.1267 21.2195 18.94 21.6195C18.7667 22.0061 18.6667 22.4795 18.64 23.0395V23.9795H20.34V23.1995C20.34 22.8528 20.3867 22.5461 20.48 22.2795C20.5867 21.9995 20.72 21.7461 20.88 21.5195C21.0533 21.2795 21.24 21.0595 21.44 20.8595C21.6533 20.6461 21.8667 20.4395 22.08 20.2395C22.2933 20.0261 22.5 19.8061 22.7 19.5795C22.9133 19.3528 23.1 19.1061 23.26 18.8395C23.42 18.5728 23.5467 18.2795 23.64 17.9595C23.7467 17.6261 23.8 17.2528 23.8 16.8395C23.8 16.1995 23.6933 15.6328 23.48 15.1395C23.28 14.6461 22.9933 14.2328 22.62 13.8995C22.2467 13.5528 21.8 13.2928 21.28 13.1195C20.7733 12.9328 20.2067 12.8395 19.58 12.8395C18.8867 12.8395 18.26 12.9595 17.7 13.1995C17.14 13.4395 16.6667 13.7795 16.28 14.2195C15.8933 14.6461 15.5933 15.1528 15.38 15.7395C15.18 16.3261 15.0867 16.9595 15.1 17.6395Z" fill="white"/>
                    </svg>
                  </button>
                  <ul className="dropdown-menu" aria-labelledby="data-graph">
                    <li><a className="dropdown-item" href={void(0)}>More details</a></li>
                  </ul>
                </div>

                <div className="graph-line">
                {appliedBrands.length > 0 ?
                 <ul>
                     {
                          selectedColors && selectedColors.map(function(item, i){
                            return <li key={i}><span style={{background:'#3349ff',backgroundColor:`${item}`}}
                                ></span>{sideBar[i]}</li>
                          })
                       }
                  </ul>
                  : "" }
                </div>
              </div>
            </div>
            {
							staticData ? <StaticContent></StaticContent> : null
						}
          </div>    
        </div>	
		</React.Fragment>
	);

};

export default OrderType;