import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";


import Tick from "../../assets/images/Tick.png";
import TickWht from "../../assets/images/Tick-wht.png";
import BlueTick from "../../assets/images/bluetick.png";
import Dash from "../../assets/images/Dash.png";

const PaymentVisibility = ({
  chartHeightData,
  HeatData,
  PaymentType,
  paymentSymbols,
  colorCode,
  iddata,
  lableValue,
}) => {
  //const d3Chart = useRef();
  // console.log(paymentSymbols);
  //console.log(HeatData, PaymentType, paymentSymbols, colorCode);
  const [errorMessage, setErrorMessage] = useState(null);
  const id = "heatmap" + iddata;
  try{
  const Datavalue = [];
  const chartHeight = chartHeightData ? chartHeightData : 600;
  if(Object.keys(HeatData) == 0){
    throw("empty data")
  }
  const customDecimal = (e) => {
    return d3.format(".0f")(e);
  };
  let totalNoBrand = Object.keys(HeatData).length;
  let totalpaymethod;
  if(totalNoBrand){
    totalpaymethod = Object.keys(HeatData[Object.keys(HeatData)[0]]).length
  }
  let paddingBetween;
  let graphSize;
  // switch(totalNoBrand) {
  //   case 1:
  //     paddingBetween = -5.6
  //     graphSize = 200
  //     break;
  //   case 2:
  //     paddingBetween = -0.6
  //     graphSize = 280
  //     break;
  //   case 3:
  //     paddingBetween = -0.6
  //     graphSize = 360
  //     break;
  //   case 4:
  //     paddingBetween = -0.6
  //     graphSize = 440
  //     break;
  //   case 5:
  //     paddingBetween = -0.6
  //     graphSize = 520
  //     break;
  //   case 6:
  //     paddingBetween = -0.6
  //     graphSize = 600
  //     break;
  //   case 7:
  //     paddingBetween = -0.6
  //     graphSize = 680
  //     break;
  // }

  function wrap(text, width) {
    text.each(function () {
      var text = d3.select(this),
        //words = text.text().split(/\s+/).reverse(),
        words = text
          .text()
          .match(/.{1,5}/g)
          .reverse(),
        word,
        line = [],
        lineNumber = 0,
        lineHeight = 1.1, // ems
        y = text.attr("y"),
        dy = parseFloat(text.attr("dy")) / 2,
        tspan = text.text(null); //.append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");

      //console.log(words);

      while ((word = words.pop())) {
        line.push(word);
        tspan.text(line.join(" "));
        if (tspan.node().getComputedTextLength() > width) {
          line.pop();
          tspan.text(line.join(" "));
          line = [word];
          tspan = text
            .append("tspan")
            .attr("x", 0)
            .attr("y", y)
            .attr("dy", ++lineNumber * lineHeight + dy + "em")
            .text(word);
        }
      }
    });
  }


  useEffect(() => {
    const margin = { top: 40, right: 30, bottom: 60, left: 110 };
    const width = 1200 - margin.left - margin.right;
    const height = 120 + totalNoBrand * 80 - margin.top - margin.bottom;

    d3.selectAll(`#${id} > *`).remove();

    const svg = d3
      .select(`#${id}`)
      .append("svg")
      /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
      .attr("viewBox", `0 0 ${(totalpaymethod ? totalpaymethod : 15) * 85} ${50 + totalNoBrand * 80}`)
      // .style('height','426px')
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // List of subgroups = header of the csv files = soil condition here
    const subgroups = ["group", "oc_s_avg", "s_d_avg"];

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = Object.keys(HeatData).map((d) => {
      return d;
    });

    // Add X axis
    const x = d3
      .scaleBand()
      .domain(PaymentType)
      .range([0, 76* (PaymentType.length)])
      .padding([0.01]);

    svg
      .append("g")
      .attr("transform", "translate(0,0)")
      .attr("class", "font13")
      .call(d3.axisTop(x).tickSize(0).tickPadding([20]))
      .selectAll("text")
      .attr("class", "font13")
      // .style("font-size","11px")
      .style("color", "#000000")
      .style("line-height", "13.12px");

    // Add Y axis
    const y = d3
      .scaleBand()
      .domain(groups)
      .range([height, 0])
      .paddingInner(0.0)
      .paddingOuter(0.2)
      .align([0.1]);

    svg
      .append("g")
      .call(d3.axisLeft(y).tickSize(0).tickPadding([8]))
      .selectAll("text")
      .each(function (d) {
        var arr = d.split(" ");
        //console.log(arr);
        if (arr.length > 1) {
          d3.select(this).text("");
          if (arr != undefined) {
            for (let i = 0; i < arr.length; i++) {
              d3.select(this)
                .append("tspan")
                .text(arr[i])
                .attr("dy", i ? "1.1em" : arr.length == 3 ? -17 : -12)
                .attr("x", -37)
                .attr("text-anchor", "middle")
                .attr("class", "tspan" + i);
            }
          }
        }
      })
      .attr("x", -16)
      .attr("dy", "-7");

    // color palette = one color per subgroup
    const color = d3
      .scaleLinear()
      .range(["white", colorCode ? colorCode : "#0234FF"])
      .domain([1, 100]);

    //console.log(HeatData['Saks']['oc_s_avg']);

    const tooltip = d3
      .select(`#${id}`)
      .append("div")
      .attr("class", "tooltip-barplot")
      .style("visibility", "hidden");

    const dataArray = [];

    Object.keys(HeatData).map((d) => {
      Object.entries(HeatData[d]).map((r) => {
        if (!["_id", "retailer"].includes(r[0])) {
          dataArray.push({ retailer: d, type: r[0], value: r[1] });
        }
      });
    });

    //console.log(dataArray);

    const d3Svg = svg
      .selectAll()
      .data(dataArray, function (d) {
        return d.retailer + ":" + d.type;
      })
      .enter();

    d3Svg
      .append("rect")
      .attr("x", function (d) {
        return x(d.type) + x.bandwidth() / 3 - 25;
      })
      .attr("y", function (d) {
        return y(d.retailer) + y.bandwidth() / 2 - 52;
      })
      .attr("width", 75.64364025695932)
      .attr("height", 77.67475035663338)
      .style("fill", function (d) {
        return color(d.value);
      })
      .on("mouseover", (event, d) => {
        tooltip
          .transition()
          .delay(0)
          .duration(0)
          .style("visibility", "visible")
          .style("opacity", "1");
        tooltip
          .html(
            `
          <div class="tooltipDesign">
            <h5 class="tt-head">
            ${d.retailer}
            </h5>
            <ul>
              <li style="margin:0px; margin:0px 0px 10px 0px">
                <img src="${
                  paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "—"
                    ? Dash
                    : paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "✓"
                    ? BlueTick
                    : ""
                }"${
              paymentSymbols?.[d.retailer]?.[0][d.type] == "—"
                ? 'style="width:12px;height:1px;margin-right:5px"'
                : paymentSymbols?.[d.retailer]?.[0][d.type] == "✓"
                ? 'style="width:10px;height:10px;margin-right:5px;"'
                : ""
            }>
                </img>
                <p class="typetooltip">${d.type} ${paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "✓" ? "Method readily visible" : "Method not readily visible"}</p>
              </li>
              <li style="opacity:.6" ${
                !(paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "")
                  ? 'class = "ms-3"'
                  : ""
              }>
                <p class="valtooltip">${
                  d.value
                }% of people indicated  this option was available</p>
              </li>
              
             </ul>
            </div>
            `
          )
          .style("left", event.layerX + 10 + "px")
          .style("top", event.layerY - 133 + "px");
        //console.log(d3.select(this).attr("x"));
      })
      .on("mousemove", function (event) {
        return tooltip
          .style("left", event.layerX + 10 + "px")
          .style("top", event.layerY - 133 + "px");
      })
      .on("mouseout", () => {
        tooltip
          .transition()
          .delay(0)
          .duration(0)
          .style("visibility", "hidden")
          .style("opacity", "0");
      });

    d3Svg
      .append("image")
      .attr("xlink:href", (d) =>
        paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "—"
          ? Dash
          : paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "✓"
          ? d.value > 79
            ? TickWht
            : Tick
          : ""
      ) // d.value > 79 ? TickWht: (d.value==0?"":Tick)
      .attr("x", function (d) {
        return x(d.type) + x.bandwidth() / 3 + 2;
      })
      .attr("y", function (d) {
        return y(d.retailer) + y.bandwidth() / 2 - 22;
      })
      .attr("width", "20")
      .attr("height", "20")
      .style("fill", "red")
      .attr("class", "paymenttick")
      .on("mouseover", (event, d) => {
        tooltip
          .transition()
          .delay(100)
          .duration(100)
          .ease(d3.easeLinear)
          .style("visibility", "visible")
          .style("opacity", "1");
        //console.log(d3.select(this).attr("x"));
        tooltip
          .html(
            `
                  <div class="tooltipDesign">
                    <h5 class="tt-head">
                    ${d.retailer}
                    </h5>
                    <ul>
                      <li style="margin:0px; margin:0px 0px 10px 0px">
                        <img src="${
                          paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "—"
                            ? Dash
                            : paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "✓"
                            ? BlueTick
                            : ""
                        }"${
              paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "—"
                ? 'style="width:12px;height:1px;margin-right:5px"'
                : paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "✓"
                ? 'style="width:10px;height:10px;margin-right:5px;"'
                : ""
            }>
                        </img>
                        <p class="typetooltip">${
                          d.type
                        } Method readily visible</p>
                      </li>
                      <li style="opacity:.6" ${
                        !(paymentSymbols?.[d.retailer]?.[0]?.[d.type] == "")
                          ? 'class = "ms-3"'
                          : ""
                      }>
                        <p class="valtooltip">${
                          d.value
                        }% of people indicated  this option was available</p>
                      </li>
                      
                     </ul>
                    </div>
                    `
          )
          .style("left", event.layerX + 10 + "px")
          .style("top", event.layerY - 28 + "px");
      })
      .on("mousemove", function (event) {
        return tooltip
          .style("left", event.layerX + 10 + "px")
          .style("top", event.layerY - 28 + "px");
      })
      .on("mouseout", () =>
        tooltip.transition().style("visibility", "hidden").style("opacity", "0")
      );

    if (lableValue?.["lable x"]) {
      svg
        .append("text")
        .attr(
          "transform",
          "translate(" + width / 2 + " ," + (height - margin.bottom + 50) + ")"
        )
        .style("text-anchor", "middle")
        .style("color", "#000000")
        .style("font-weight", "500")
        .style("font-size", "15px")
        .text(`${lableValue?.["lable x"]}`);
    }
    if (lableValue?.["lable Y"]) {
      svg
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", -margin.left)
        .attr("x", 0 - height / 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("color", "#000000")
        .style("font-weight", "500")
        .text(`${lableValue?.["lable Y"]}`);
    }
    // console.log(dataArray);
  }, [HeatData, PaymentType, paymentSymbols]);
}
catch(err){
  console.log(err);
  return (
    <ErrorMessage Message={"Not able to generate graph for the retrieved data"}/>
  );
}

  return <div id={id}></div>;
};

export default PaymentVisibility;
