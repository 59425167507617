import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
  Table,
  UncontrolledTooltip,
  Badge,
  Spinner
} from "reactstrap";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link, useParams, useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";

import bellImg from "../../assets/images/bell.svg";
import userImg from "../../assets/images/user-img.png";
import downImg from "../../assets/images/down-arrow.svg";
import editImg from "../../assets/images/edit.svg";

import { get, post, put, del} from "../../helpers/api_helper";
import axios from 'axios';
import swal from "sweetalert";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import arrowLeft from 'assets/images/Arrow-left.svg';

const AdminUserProfile = () => {

  //meta title
  document.title = "Members - Acumen";
  const history = useHistory();
  const {id} = useParams();
  const user = JSON.parse(localStorage.getItem("authUser"));

  const [modal_small, setmodal_small] = useState(false);
  const [modal_title, setmodal_title] = useState(false);
  const [userId, setuserId] = useState('');
  const [firstName, setfirstName] = useState('');
  const [lastName, setlastName] = useState('');
  const [email, setemail] = useState('');
  const [organization, setorganization] = useState('');
  const [disabled, setdisabled] = useState(false);
  const [isBusy, setBusy] = useState(true);
  const [parent, setparent] = useState([]);
  const [child, setchild] = useState([]);
  const [onSubmit, setonSubmit] = useState(false);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);


  let positionClass = "toast-top-right";

  toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  //End toastr

  const fetchData = async function fetchData() {
      const response = await get("admin/users/get_members/"+id);          
      let p = []
      let c = []
      Object.entries(response.data).map(function([key, value]) {
        value.isParent == true
          ? p = value
          : c.push(value)
      });

      setparent(p);
      setchild(c);          
      /*setdata(response.data);
      setBusy(false);*/
    }

  useEffect(() => {
      try{        
        fetchData();
      } catch(err)  {
        setBusy(false);
      }
  }, []);

  function add_modal() {
    setmodal_title("Add User");
    setfirstName("");
    setlastName("");
    setemail("");
    setuserId("");
    setorganization("");
    setdisabled(false);
    setmodal_small(!modal_small);
    removeBodyCss();
  }

  const edit_modal = (e) => {
    const record = child[e.target.getAttribute("data-record")];
    setmodal_title("Edit User");
    setuserId(record.id);
    setfirstName(record.firstName);
    setlastName(record.lastName);
    setemail(record.email);
    setorganization(record.organization); 
    setdisabled(true);

    setmodal_small(!modal_small);
    removeBodyCss();
  }

  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      firstName: firstName,
      lastName: lastName,
      organization: organization,
      email: email,
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      lastName: Yup.string().required("Please enter last name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      email: Yup.string()
        .required("Please enter business email")
        .email("Must be a valid email.")
        .max(100)
        .matches(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,'Must be a valid email'),
      
    }),
    onSubmit: async (values) => {
      setonSubmit(true);
      const formData = new FormData();
      Object.keys(values).map(function(key) {
        formData.append(key, values[key]);                          
      });

      formData.append("parent", parent['id'])      

      let url = 'admin/users/update_profile'
      if(userId=="") {
        url = 'admin/users/add_member';
      } else {
        formData.append("id", userId);
      }
      const token = user.accessToken;
      try {
        let res = await post(url, formData)
        setmodal_small(false);
        fetchData();
        toastr.success(res.success,"");
        validation.resetForm();
        setonSubmit(false);
      } catch(error) {
        setonSubmit(false);
        if (error.response) {
           // Request made and server responded
           validation.setErrors(error.response.data.errors);
         } else if (error.request) {
           // The request was made but no response was received
           toastr.error(error,"Request failed");
         } else {
           console.log(error)
           // Something happened in setting up the request that triggered an Error
           toastr.error(error,"Request failed.");
         }   
      }
    },
  });

  const removeUser = async (val) => {
    let willDelete = await swal({
      title: "Do you want to remove this member?",
      text: "Once deleted, He will not be able to use this application!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    if(willDelete) {
      let response = await del("/admin/users/remove_user/"+val);
      if(response.success) {
        toastr.success(response.success,"");
        fetchData();
      } else {
        toastr.error(response.error,"");
      }      
    }
  }

  const changeStatus = async (val, type) => {
    let willChange = true;
    if(type == "subscription") {
      let msg = "Do you want to activate the user subscription?"
      let subtext = "Once changed, the user and their members able to access the insights";
      if(val['subscriptionStatus'] == "Active") {
        msg = "Do you want to inactivate the user subscription?"
        subtext = "Once changed, user and organization members will not have access to insights."
      }
      willChange = await swal({
        title: msg,
        text: subtext,
        icon: "info",
        buttons: true,
        dangerMode: false,
      })
    }

    if(willChange) {
      try{
         setBusy(true);
         let response = await put("/admin/users/change_status/"+type+"/"+val['id']);
         if(response.success) {
            toastr.success(response.success,"");
            setonSubmit(false);
            fetchData();
          } else {
            toastr.error(response.error,"");
          }
      }
      catch(e) {
        setBusy(false);
        toastr.error("Something went wrong","");
      }  
    }
  }

  const statusRender = (value, key) => {
    let emailInvitationSent =  value['emailInvitationSent'] != "" && value['emailInvitationSent'] != undefined;
    let joinedOn = value['joinedOn'] != "" && value['joinedOn'] != undefined;
    
    let recordStatus = <a><Badge
          className={"font-size-12 me-2 bg-success"}
          onClick={() => changeStatus(value, "status")}
          pill
        ><strong>Active</strong></Badge></a>
    
    if(emailInvitationSent && joinedOn && value.status == "Inactive") {
      recordStatus = <a><Badge
          className={"font-size-12 me-2 bg-warning"}
          onClick={() => changeStatus(value, "status")}
          pill
        ><strong>Inactive</strong></Badge></a>
    }
    else if (emailInvitationSent && !joinedOn) {
      recordStatus = <Badge
          className={"font-size-12 me-2 bg-warning"}
          pill
        ><strong>Pending Invitation</strong></Badge>
    } else if (!emailInvitationSent && !joinedOn) {
      recordStatus = <Badge
          className={"font-size-12 me-2 bg-warning"}
          pill
        ><strong>Pending Invitation</strong></Badge>
    } else if(emailInvitationSent && joinedOn && value.status == "Inactive") {
      recordStatus = <a><Badge
          className={"font-size-12 me-2 bg-warning"}
          onClick={() => changeStatus(value, "status")}
          pill
        ><strong>Inactive</strong></Badge></a>
    } 
    return (
      <div className="text-center">
        {recordStatus}
      </div>
    )
  }

  const actionRender = (value, key) => {
    return (
      <div className="text-center">
        <a 
          href={void(0)}
          onClick={edit_modal}
          data-record={key}
          >
          <i 
            data-record={key}
            className="mdi mdi-pencil font-size-18 text-success" 
            id="edittooltip" 
            />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
        </a>&nbsp;&nbsp;

        <a
          className="text-danger"
          onClick={() => removeUser(value['id'])}
        >
          <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
          <UncontrolledTooltip placement="top" target="deletetooltip">
            Delete
          </UncontrolledTooltip>
        </a>
      </div>
    )
  }

  return (
    <React.Fragment>
        <div className="inner-content">
        <div className="pr-page-title">
              <h2>View Members</h2>              
              <h6>
              <div className="back"> 
                <Link
                  to="/admin/users/"
                >
                  <img 
                    src={arrowLeft} alt="arrow" 
                  />Back
                </Link>
                </div>
              </h6>
        </div>
            <div className="update-profile2">
              <div className="row">
                <div className="col-12 col-md-3 col-lg-3 col-xl-2">
                  <div className="edit-pic">
                    <img src={parent.profilePic} className="edit-user-img" alt="" />
                    {/*<img src={editImg} className="edit-icon" alt="" />*/}
                  </div>
                </div>
                <div className="col-12 col-md-9 col-lg-9 col-xl-10" style={{marginTop: "2%"}}>
                  <div className="other-info text-wrap">
                    <h4>{parent.firstName} {parent.lastName}</h4>
                    <p>{parent.email}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="manage-users-details" style={{marginTop:"30px"}}>
              <div className="row heading-details align-items-center">
                <div className="col-md-9">
                  <p>Added accounts must use same domain.</p>
                </div>
                <div className="col-md-3 text-sm-end">
                  <button type="button" 
                    className={`appbtn button-with-bg${parent.seatCount > 0 && child.length < parent.seatCount ? "" : " disabled_bg"}`}
                    onClick={() => {
                          add_modal();
                    }}
                    data-title={parent.seatCount > 0 && child.length < parent.seatCount 
                    && parent.subscriptionStatus == "Active" 
                    && parent.status == "Active"
                     ? "Add member" : "Please increase seat count to add members"}
                    data-toggle="modal"
                    data-target=".bs-example-modal-sm"
                    disabled={parent.seatCount > 0 && child.length < parent.seatCount 
                    && parent.subscriptionStatus == "Active" 
                    && parent.status == "Active"
                    ? "": "disabled" }
                    >
                    Add member
                  </button>
                </div>
              </div>

              <div className="table-responsive">
                    <Table className="table mb-0">
                      <thead className="table-light">
                        <tr>
                          <th width="15%">First Name</th>
                          <th width="15%">Last Name</th>
                          <th width="15%">Organization</th>
                          <th width="25%">Email</th>
                          <th width="15%" className="text-center">Status</th>
                          <th width="10%" className="text-center">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                      
                      {child.length > 0 && Object.entries(child).map(([key, value]) => (
                            <tr key={key}>
                              <td>{value.firstName}</td>
                              <td>{value.lastName}</td>
                              <td>{value.organization}</td>
                              <td>{value.email}</td>
                              <td className="text-center">
                                {statusRender(value, key)}
                              </td>
                              <td className="text-center">
                                {actionRender(value, key)}
                              </td>
                            </tr>
                      )) || <tr><td colSpan="5">No record found</td></tr>}
                      </tbody>
                    </Table>
                  </div>

              
              <div className="seat-limit">
                <span>Available Seats for Current Plan: ({child.length} of {parent.seatCount}) In Use</span>
                </div>
            </div>
        </div>
                  <Modal
                      isOpen={modal_small}
                      toggle={() => {
                        add_modal();
                        validation.resetForm({});
                      }}
                      centered
                    >
                      <div className="modal-header">
                        <h5
                          className="modal-title mt-0"
                          id="mySmallModalLabel"
                        >
                          {modal_title}
                        </h5>
                        <button
                          onClick={() => {
                            setmodal_small(false);
                            validation.resetForm({});
                          }}
                          type="button"
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={validation.handleSubmit}>
                        <Row>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label htmlFor="name">First Name</label>
                              <Input
                                type="text"
                                className="form-control"
                                id="firstName"
                                placeholder="Enter First Name"
                                value={validation.values.firstName || ""}
                                onChange={validation.handleChange}
                                onInput={validation.handleBlur}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.firstName &&
                                  validation.errors.firstName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.firstName &&
                                validation.errors.firstName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.firstName}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Col>
                          <Col lg={6}>
                            <div className="mb-3">
                              <label htmlFor="email">Last Name</label>
                              <Input
                                type="text"
                                className="form-control"
                                id="lastName"
                                placeholder="Enter Last Name"
                                value={validation.values.lastName || ""}
                                onChange={validation.handleChange}
                                onInput={validation.handleBlur}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.lastName &&
                                  validation.errors.lastName
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.lastName &&
                                validation.errors.lastName ? (
                                  <FormFeedback type="invalid">
                                    {validation.errors.lastName}
                                  </FormFeedback>
                                ) : null}
                            </div>
                          </Col>
                          </Row>
                          <Row>
                            {userId ? <Col lg={6}>
                              <div className="mb-3">
                                <label htmlFor="Organization">Organization</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="organization"
                                  placeholder="Enter Organization"
                                  value={validation.values.organization || ""}
                                  onChange={validation.handleChange}
                                  onInput={validation.handleBlur}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.organization &&
                                    validation.errors.organization
                                      ? true
                                      : false
                                  }
                                />
                                {validation.touched.organization &&
                                  validation.errors.organization ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.organization}
                                    </FormFeedback>
                                  ) : null}
                              </div>
                            </Col> : null}

                            <Col lg={6}>
                              <div className="mb-3">
                                <label htmlFor="email">Email</label>
                                <Input
                                  type="text"
                                  className="form-control"
                                  id="email"
                                  name="email"
                                  placeholder="Enter Email"
                                  value={validation.values.email || ""}
                                  onChange={validation.handleChange}
                                  onInput={validation.handleBlur}
                                  onBlur={validation.handleBlur}
                                  invalid={
                                    validation.touched.email &&
                                    validation.errors.email
                                      ? true
                                      : false
                                  }
                                  disabled={disabled}
                                />

                                {validation.touched.email &&
                                  validation.errors.email ? (
                                    <FormFeedback type="invalid">
                                      {validation.errors.email}
                                    </FormFeedback>
                                  ) : null}
                              </div>
                            </Col>                           
                          </Row>
                          
                          <Row>
                            <Col lg={12}>
                              <div className="text-end">
                                <button 
                                  type="submit" 
                                  className="btn btn-success"
                                  disabled={onSubmit ? true : false}
                                  >
                                    {onSubmit ? "Loading..." : "Submit"}
                                </button>
                              </div>
                            </Col>
                          </Row>
                      </form>
                      </div>
                    </Modal>
          
    </React.Fragment>
  );
};

export default withRouter(AdminUserProfile);
