import React from "react"
import PropTypes from "prop-types"
import { Route, Redirect, useLocation } from "react-router-dom"


let user = JSON.parse(localStorage.getItem("authUser"));

const Authmiddleware = ({
  component: Component,
  layout: Layout,
  isAuthProtected,
  location,
  defaultState,
  ...rest
}) => { 
  const LocationData = useLocation();
  const currentState = LocationData.state;
  if(currentState == undefined) {
    location.state = defaultState;
  }
  return(
  <Route
    {...rest}
    render={props => {
      user = JSON.parse(localStorage.getItem("authUser"));
      if (isAuthProtected && !localStorage.getItem("authUser")) {
        let roleLogin = "/login";
        if(props.location.pathname.includes('/admin/'))
        {
          roleLogin = "/admin";
        }
        return (
          <Redirect
            to={{ pathname: roleLogin  , state: { from: props.location } }}
          />
        )
      }
      else if(user !== null && user.role != rest.extra)
      {
         if(rest.extra == "admin")
         {
            return (
              <Redirect
                to="/admin/dashboard"
              />
            )
         }

         if(rest.extra == "subscriber")
         {
            return (
              <Redirect
                to={{ pathname: "/dashboardv2", state: { insightNo: 0 } }}
              />
            )
         }

      }

      if(isAuthProtected && user !== null && user.role != "admin" 
            && props.location.pathname == "/members") {
        if((user.role === "member" || user.subscriptionStatus != "Active")) {
          return (<Redirect to={{ pathname: "/dashboardv2", state: { insightNo: 0 } }}/>)
        }
      }

      return (
        <Layout>
          <Component {...props} />
        </Layout>
      )
    }}
  />
)}

Authmiddleware.propTypes = {
  isAuthProtected: PropTypes.bool,
  component: PropTypes.any,
  location: PropTypes.object,
  layout: PropTypes.any, 
}

export default Authmiddleware;
