import React, { useState, useEffect, useContext, useRef } from "react";

const PreviewSubinsightDropDown = ({
  dropdownList,
  appliedInsightCallback,
  label,
}) => {
  let activeTab = 1;
  const [dropdown, setDropdown] = useState(false);

  const [insightSearch, setInsightSearch] = useState("");
  const [selectedInsight, setSelectedInsight] = useState([]);
  const [appliedInsight, setAppliedInsight] = useState([]);

  const insightDropDown = useRef();

  const insightData = dropdownList;

  const insight = insightData.map((index, i) => {
    let display =
      insightSearch == ""
        ? i > 6
          ? "show"
          : "show"
        : index.toLowerCase().includes(insightSearch.toLowerCase())
        ? "show"
        : "hide";

    display =
      display == "hide"
        ? selectedInsight.includes(index)
          ? "show"
          : "hide"
        : display;

    return (
      <li
        key={i}
        className={display}
        onClick={() =>{
            console.log(i)
            setSelectedInsight(i)
        }}
      >
        <input
          type="radio"
          value={i}
          className="styled-checkbox"
          id={index.replace(/\s+/g, "")}
          name={"insightSearch"}
        />
        <label htmlFor={index.replace(/\s+/g, "")}>{index}</label>
      </li>
    );
  });

  const onChangeInsight = (name) => {
    const newSelectedItems = selectedInsight.includes(name)
      ? selectedInsight.filter((item) => item !== name)
      : [...selectedInsight, name];
    setSelectedInsight(newSelectedItems);
  };

  const insightCount = selectedInsight.length;

  useEffect(() => {
    console.log(selectedInsight);
  }, [selectedInsight]);

  useOnClickOutside(insightDropDown, () => setDropdown(false));

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  return (
    <React.Fragment>
      <div
        className="two-select-boxes dropdown-tab-content col-7"
        style={{ margin: "0px" }}
      >
        <div className="questions dropdown col-11" ref={insightDropDown}>
          <button
            className="btn dropdown-toggle"
            type="button"
            id="dropdownMenuButton1"
            onClick={() => {
              setDropdown(!dropdown);
            }}
            style={{
              marginTop: "10px",
              width: "100%",
              whiteSpace: "pre-wrap",
            }}
          >
            {selectedInsight.length == 0  ? `Select ${label}` : dropdownList[selectedInsight]}
            {/* <i className= "dropdown-toggle-icon" /> */}
            <i
              className={
                dropdown
                  ? "dropdown-toggle-icon rotate"
                  : "dropdown-toggle-icon"
              }
            />
          </button>
          <ul
            className={
              dropdown
                ? "multiselect-container dropdown-menu show"
                : "multiselect-container dropdown-menu"
            }
          >
            {/* <li>
              <input
                type="search"
                placeholder="Search insights"
                value={insightSearch}
                onChange={(v) => {
                  setInsightSearch(v.target.value);
                }}
              />
            </li> */}
            <li  style={{ width: "0px",height:"0px",padding:"0px 0px 0px 0px"}}></li>
            <li className="brandScroll" style={{paddingLeft: "0px",paddingRight: "0px",
            marginRight:"0px"}}> 
            {insight}
            </li>
            <li className="apply-btn">
              <button
                className="btn"
                onClick={() => {
                  setAppliedInsight(selectedInsight);
                  setDropdown(!dropdown);
                  appliedInsightCallback(selectedInsight, label);
                }}
              >
                Apply
              </button>
            </li>
          </ul>
        </div>

        {/* <button 
                  type="reset" 
                  className="reset-filter"
                  onClick={()=>{
                    setSelectedInsight([]);
                    setAppliedInsight([]);
                    setInsightSearch("");
                  }}
                >Reset Filters</button> */}
      </div>
    </React.Fragment>
  );
};
export default PreviewSubinsightDropDown;
