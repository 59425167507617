import React from 'react';
import { post } from 'helpers/api_helper';
export default async function SingleChartSubmit(values, id) {
    try {
        let res = await post(`/admin/insights/addChartData/`, {"chartData": [values], "id": id, subInsight: "no"});
        return  res;
      } catch (error) {
        console.log(error);
        return error;
      }
}