import React, {useState, useEffect, useRef} from "react"
import {
  Container,
  Row,
  Input,
  Form,
  InputGroup,
  FormFeedback,
  Col,
  Card,
  CardBody,
  CardTitle,
  Label,
  Button,
  TabContent,
  TabPane,
  Spinner,
  Alert
} from "reactstrap"

import { useHistory, Link } from 'react-router-dom';
import { get, post } from "../../helpers/api_helper";
import moment from "moment";
import BlockUi from 'react-block-ui';
import arrowLeft from 'assets/images/Arrow-left.svg';
//Import Flatepicker
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Select from "react-select";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const EditTemplate = (props) => {
  //meta title
  document.title="Edit Template - Acumen";
  const history = useHistory();

  const [onSubmit, setonSubmit] = useState(false);
  const [dateValue, setDateValue] = useState(new Date());
  const [categories, setCategories] = useState([]);
  const [isBusy, setBusy] = useState(true);    
  const [category, setcategory] = useState(null);    
  const [showHide, setshowHide] = useState("");
  const [insightFile, setinsightFile] = useState("");    
  const [name, setname] = useState("");    
  const [excelFields, setexcelFields] = useState(null);
  const [mapFields, setmapFields] = useState([]);
  const [loader, setloader] = useState(false);
  const [templateData, settemplateData] = useState("");
  const [mappedError,setMappedError] = useState(null);
  const [initialSelectData, setinitialSelectData] = useState([]);
  const myRef = useRef(null);

  let {id} = props.match.params;
  //progressbar data is stored in progressData
  const [progressData, setprogressData] = useState(0);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);


  let positionClass = "toast-top-right";
  const sampleFiles = {
    "62fccefbd2ea10cd6412d231": process.env.REACT_APP_BACKEND_URL + "samples/Visibility of Returns Template & Test Data.xlsx",
    "62fdd118aee76a90afc8efa3": process.env.REACT_APP_BACKEND_URL + "samples/Return_Refund Template & Test Data.xlsx",
    "62fdd118aee76a90afc8efa5": process.env.REACT_APP_BACKEND_URL + "samples/Click-to-Door Template & Test Datas.xlsx", 
    "631057b09c96fe4210f91e55": process.env.REACT_APP_BACKEND_URL + "samples/Return_Refund Objective Insights - Combined.xlsx",
    "6315a21f090fe80418370bf8": process.env.REACT_APP_BACKEND_URL + "samples/Return Objective Insights (vertical).xlsx", 
    "6315a41716f521f92d5bdbfe": process.env.REACT_APP_BACKEND_URL + "samples/Return Objective Insights - Vertical Stacked.xlsx", 
    "6315a45d00b571aeb556966e": process.env.REACT_APP_BACKEND_URL + "samples/Return Objective Insights - Horizontal Stacked.xlsx",  
    "6315a45d00b571aeb556967e": process.env.REACT_APP_BACKEND_URL + "samples/Payment Method Visibility.xlsx",
    "632069649219c99600f44a44": process.env.REACT_APP_BACKEND_URL + "samples/Payment Avail. Symbol Table.xlsx",
    "6322ce7f45214aea425be853": process.env.REACT_APP_BACKEND_URL + "samples/Order Types.xlsx",
    "6322d6792cb91b0da47d3021": process.env.REACT_APP_BACKEND_URL + "samples/Return - Refund Details.xlsx",
    "6322ea201f9b1cb28b8a3847": process.env.REACT_APP_BACKEND_URL + "samples/Package Details.xlsx",
    "6323137102b61abfe2e062bc": process.env.REACT_APP_BACKEND_URL + "samples/Customer Service Objective.xlsx",
    "63231a3aaa564004543cf0cd": process.env.REACT_APP_BACKEND_URL + "samples/Post Purchse Subjective Insights combined.xlsx",
    "63245ced25f7c119982a201a": process.env.REACT_APP_BACKEND_URL + "samples/Shipping Carrier.xlsx",
    "632abc1bc08b23505d37fdc5": process.env.REACT_APP_BACKEND_URL + "samples/SMS Notification.xlsx",
    "63c7dae57ebe81f3d433a37e": process.env.REACT_APP_BACKEND_URL + "samples/Radar.xlsx",
}
  toastr.options = {
      positionClass: positionClass,
      timeOut: timeOut,
      extendedTimeOut: extendedTimeOut,
      closeButton: true,
      progressBar: true,
      preventDuplicates: true,
      showEasing: showEasing,
      hideEasing: hideEasing,
      showMethod: showMethod,
      hideMethod: hideMethod,
      showDuration: showDuration,
      hideDuration: hideDuration
    };
  //End toastr
    const updateInitialvalue = async function updateInitialvalue(key,data){
      //console.log(key);
      const some_array = [...initialSelectData]
      some_array[key] = data
      setinitialSelectData(some_array);
    }

  const fetchData = async function fetchData() {
    setBusy(true);
    try {
      const response = await get("admin/templates/categories");
      setCategories([response.data[13]]);
      setBusy(false);
    } catch(err) {
      //console.log(err);
      setBusy(false);
    }
    setBusy(true);
    try {
      const response = await get(`/admin/templates/get_template/${id}`);
      settemplateData(response.data);
      console.log(response.data);
      let mappedData = Object.values(response.data.mapped);
      let graphFields = Object.keys(response.data.mapped);
      setinitialSelectData(mappedData);
      setmapFields(graphFields);
      setBusy(false);
    } catch(err) {
      //console.log(err);
      setBusy(false);
    }
  }

  const selectCategory = async function(event) {
    setcategory(event.target.value);    
  }

  const populateFields = async function(f) {
    let { insightFile, category, name } = validation.values
    if(insightFile && category && name && !validation.errors.insightFile) {
      const formData = new FormData();
      formData.append("category", category);
      formData.append("insightFile", insightFile);
      formData.append("name", name);
      formData.append("id", templateData._id);
      try {
        setBusy(true);
        let res = await post('admin/templates/generate-map', formData);
        toastr.success(res.success,"");
        if(res.header) {
          setexcelFields(res.header);
          setmapFields(categories.filter((item) => item._id == category)[0]['columns']);
          setshowHide("");
        } else {
          setshowHide("d-none");
        }
        setBusy(false);
      } catch(error) {
        setonSubmit(false);
        setBusy(false);
        if (error.response) {
           // Request made and server responded
           if(error.response.data && error.response.data.errors) {
            validation.setErrors(error.response.data.errors);
           } else {
            validation.setErrors({"insightFile": error.response.data});
           }
         } else if (error.request) {
           // The request was made but no response was received
           toastr.error(error,"Request failed");
         } else {
           //console.log(error)
           // Something happened in setting up the request that triggered an Error
           toastr.error(error,"Request failed.");
         }
      }
    } else {
      setshowHide("d-none");
    }
  }

  const onFileChange = async (event) => {
    if (event.target.files && event.target.files[0]) {

      let importFile = event.target.files[0];
      var maxfilesize = 1024 * 1024 * 3,  // 1 Mb
          filesize    = importFile.size;
      let ext = importFile.name.split(".").pop()
      
      if(!importFile.name.endsWith(".xlsx")) {
        // toastr.error("Please upload excel files only", 'Error')
      } else if ( filesize > maxfilesize ){
        // toastr.error("File size is large. Please upload image less than 3MB")
      }
      else {
        console.log(importFile)
        // await populateFields(importFile);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if(mappedError != null){
      myRef.current.scrollIntoView()
    }
  }, [mappedError]);

  const SUPPORTED_FORMATS = ['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,
    initialValues: {
      name: templateData.insightCategory,
      category: templateData.category,
      insightFile: '',
    },
    validationSchema: Yup.object({
      //importDate: Yup.number().required("The Date is required"),
      category: Yup.string().required("Please select category"),
      name: Yup.string().required("The template name is required"),
      mapped: Yup.mixed(),
      insightFile: showHide ? Yup.mixed()
      .test('File format',
        'Please upload excel (.xlsx) file only', 
        function(value) {   
          if (!value) return false;
          return value.name && value.name.endsWith(".xlsx")
        })
      .test('File size',
        'File size is large. Please upload the file in less than 3MB', 
          function(value) {
            if (!value) return false;
            return (value.size <= 1024 * 1024 * 3)
        }): null,
    }),
    onSubmit: async (values) => {
      setonSubmit(true);
      //console.log(initialSelectData);
      //console.log(mapFields);
      let toSave = {
        "name": values.name,
        "category": values.category,
        "mapped": excelFields ? values.mapped : initialSelectData,
        "graphFields": mapFields,
        "id":id
      }
      if(values.insightFile){
        toSave["insightFile"] = values.insightFile.name;
      }
      console.log(toSave);
      try {
        //console.log(formData);
        let res = await post('admin/templates/update', toSave);
        toastr.success(res.success,"");
        history.push("/admin/templates");  
      } catch(error) {
        setonSubmit(false);
        if (error.response) {
           // Request made and server responded
           //console.log(error.response.data.errors);
           validation.setErrors(error.response.data.errors);
           setMappedError(error.response.data.errors)
           if(error.response.data.errors.mapped)
           {
             setMappedError(error.response.data.errors.mapped)
           }
         } else if (error.request) {
           // The request was made but no response was received
           toastr.error(error,"Request failed");
         } else {
           //console.log(error)
           // Something happened in setting up the request that triggered an Error
           toastr.error(error,"Request failed.");
         }
      }   
    },
  });

  return (
    <React.Fragment>
      <div className="inner-content">
        <div className="pr-page-title">
            <h2>Edit Template</h2>
            <h6>
            <div className="back"> 
            <Link
              to="/admin/templates"
            >
              <img 
                src={arrowLeft} alt="arrow" 
              />Back
            </Link>
            </div>
          </h6>
        </div>
        <div>
        <BlockUi tag="div" blocking={isBusy}>
              <form onSubmit={validation.handleSubmit}>
                <div className="mb-4"></div>
                <Row className="mb-3">
                  <label
                    htmlFor="date"
                    className="col-md-2 col-form-label form-label"
                  >
                    Template name
                  </label>
                  <div className="col-md-10">
                   <Input
                     type="text"
                     className="form-control"
                     id="name"
                     name="name"
                     placeholder="Name"
                     value={validation.values.name || ""}
                     onChange={validation.handleChange}
                     onInput={validation.handleBlur}
                     onBlur={validation.handleBlur}
                     invalid={
                       validation.touched.name &&
                       validation.errors.name
                         ? true
                         : false
                     }
                   />
                   {validation.touched.name &&
                     validation.errors.name ? (
                       <FormFeedback type="invalid">
                         {validation.errors.name}
                       </FormFeedback>
                     ) : null}

                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="date"
                    className="col-md-2 col-form-label form-label"
                  >
                    Insight Category
                  </label>
                  <div className="col-md-10">
                    {validation.values.category ? <select name="category" 
                   id="category"
                   defaultValue={validation.values.category}
                   onChange={(e) => {
                      selectCategory(e);
                      setshowHide("d-none");
                      validation.setFieldValue('category', e.target.value);
                   }}     
                   >
                     <option value={""} disabled>Select insight</option>
                     {categories.map(item => (
                       <option key={item._id} value={item._id}>
                         {item.name}
                       </option>
                     ))}
                     </select>
                    : null
                    }
                     {  validation.touched.category &&
                       validation.errors.category ? (
                         <FormFeedback type="invalid">
                           {validation.errors.category}
                         </FormFeedback>
                       ) : null}
                  </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="Import"
                    className="col-md-2 col-form-label form-label"
                  >
                    Import File
                  </label>
                  <div className="col-md-10">
                   <Input 
                    className="form-control line-height" 
                    type="file" 
                    id="insightFile" 
                    name="insightFile"
                    onChange={(event) => {
                      setshowHide("d-none");
                      validation.setFieldValue('insightFile', event.target.files[0]);
                    }}
                    onInput={validation.handleChange}
                    onBlur={validation.handleBlur}
                    invalid={validation.touched.insightFile &&
                      validation.errors.insightFile
                        ? true
                        : false
                    }
                  />
                  {validation.touched.insightFile &&
                    validation.errors.insightFile ? (
                      <FormFeedback type="invalid">
                        {validation.errors.insightFile}
                      </FormFeedback>
                    ) : null}{validation.values.category ?
                      (<small className="form-text text-muted"><a href={sampleFiles[validation.values.category]}>Download</a> sample file</small>)
                    :null}
                   </div>
                </Row>
                <Row className="mb-3">
                  <label
                    htmlFor="Import"
                    className="col-md-2 col-form-label form-label"
                  >
                  </label>
                  <div className="col-md-10">
                  <button
                      className="btn btn-success col-sm-3 float-start"
                      type="button"
                      disabled={loader}
                      onClick={async (e) => {
                        validation.setFieldTouched("insightFile", true );
                        validation.setFieldTouched("category", true );
                        validation.setFieldTouched("name", true );
                        validation.validateForm();
                        await populateFields(e);
                      }}
                    >
                      {loader == true ? "Processing   " : "Populate Excel Fields"}
                      {loader && (
                        <Spinner
                          className="ms-2"
                          style={{ height: "1rem", width: "1rem" }}
                          color="light"
                        />
                      )}
                    </button>
                   </div>
                </Row>
                  <div className={`card ${showHide}`}>
                  <div className="col-md-4" ref={myRef}>
                  {mappedError ? <Alert  color="danger">{mappedError}</Alert> : null}
                  </div>
                    <CardBody>
                    <h3 className="card-title">
                        <div className="mb-3" style={{fontSize: "25px"}}>
                          Map Fields
                        </div >
                      </h3>
                      <Row className="mb-3">
                        <label
                          htmlFor="Import"
                          className="col-md-2 col-form-label form-label"
                        >
                          <strong>Graph Fields</strong>
                        </label>
                        <div className="col-md-10">
                          <label
                            htmlFor="Import"
                            className="col-md-2 col-form-label form-label"
                          >
                          <strong>File fields</strong>
                          
                          </label>
                        </div>
                      </Row>
                      {mapFields && mapFields != [] && mapFields.map(function(val, key){
                          return (
                            <Row className="mb-3" key={key}>
                              <label
                                className="col-md-2 col-form-label form-label"
                                htmlFor={key}
                              >
                                {val}
                              </label>
                              <div className="col-md-10">
                                {excelFields ? (
                                  <select
                                    className=""
                                    name={`mapped[]`}
                                    id={`category_${key}`}
                                    onChange={(event) => {
                                      setMappedError(null);
                                      validation.setFieldValue(
                                        `mapped[${key}]`,
                                        event.target.value
                                      );
                                    }}
                                  >
                                    <option>
                                      Select
                                    </option>
                                    {excelFields &&
                                      excelFields != null &&
                                      excelFields.map(function (v, k) {
                                        return (
                                          <option key={k} value={v}>
                                            {v}
                                          </option>
                                        );
                                      })}
                                    {!excelFields &&
                                      templateData.mapped &&
                                      templateData.mapped != [] &&
                                      Object.values(templateData.mapped).map(
                                        (v, k) => {
                                          return (
                                            <option key={k} value={v}>
                                              {v}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                ) : (
                                  <select
                                    className=""
                                    name={`mapped[]`}
                                    id={`category_${key}`}
                                    onChange={(event) => {
                                      setMappedError(null);
                                      updateInitialvalue(key,event.target.value)
                                    }}
                                    defaultValue={
                                      !excelFields
                                        ? initialSelectData[key]
                                        : "Select"
                                    }
                                  >
                                    <option disabled >
                                      Select
                                    </option>
                                    {excelFields &&
                                      excelFields != null &&
                                      excelFields.map(function (v, k) {
                                        return (
                                          <option key={k} value={v}>
                                            {v}
                                          </option>
                                        );
                                      })}
                                    {!excelFields &&
                                      templateData.mapped &&
                                      templateData.mapped != [] &&
                                      Object.values(templateData.mapped).map(
                                        (v, k) => {
                                          return (
                                            <option key={k} value={v}>
                                              {v}
                                            </option>
                                          );
                                        }
                                      )}
                                  </select>
                                )}
                              </div>
                            </Row>
                          );
                      })}
                    </CardBody>
                  </div>
                
                <Row className={`card mt-4 ${showHide}`}>
                   <div className="col-md-4"></div>
                   <div className="col-md-8 text-center">
                       <button 
                        type="submit" 
                        className="btn btn-success col-md-2"
                        disabled={onSubmit || mappedError}
                        >
                      {onSubmit ? "Loading..." : "Submit"}
                      </button>

                      &nbsp;&nbsp;
                       <Link
                          to="/admin/templates"
                          className="mb-2"
                        >Cancel</Link>

                  </div>
                </Row>
              </form>
          </BlockUi>
        </div>

      </div>
  </React.Fragment>
  );
}

export default EditTemplate;
