import React, { useEffect, useMemo, useState } from "react";
import DonutChart from "pages/Chart/DonutChart";
import ErrorMessage from "pages/Chart/components/ErrorMessage";

export default function DonutChartContainer({
  chartData,
  colorCode,
  colorValue,
  cms,
  lableValue,
  id,
}) {
  const [chartDataState, setchartDataState] = useState(null);

  try {
    if (Object.keys(chartData) == 0) {
      throw "empty data";
    }
    useEffect(() => {
      //console.log(chartData);
      setchartDataState(chartData);
    }, [chartData]);
  } catch (e) {
    console.error(e);
    return (
      <ErrorMessage
        Message={"Not able to generate graph for the retrieved data"}
      />
    );
  }

  return (
    <>
      <div className="graph-images donut">
        {chartDataState != null
          ? Object.entries(chartDataState).map((key, index) => {
              //console.log(chartDataState[key[0]], index);
              //console.log(key[0]);
              //console.log(colorCode);
              return (
                <div key={index}>
                  <DonutChart
                    iddata={id}
                    indexData={index}
                    name={key[0]}
                    map={chartDataState[key[0]]}
                    lableValue={lableValue}
                    colorCode={colorCode}
                  />
                </div>
              );
            })
          : null}
      </div>
    </>
  );
}
