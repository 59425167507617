import React ,{useState, useEffect, useContext, useRef} from "react";
import BlockUi from 'react-block-ui';
import { useHistory, Link } from 'react-router-dom';

import {get} from "../../helpers/api_helper";
import InsightsTabContent from './InsightsTabContent';
import arrowLeft from 'assets/images/left-arrow-new.svg';
import graphImg from 'assets/images/graphTwo.png';
import UserContext from "../../helpers/AuthType/session_helper";
import RadarChartd3 from "pages/Chart/RadarChart";
import { useParams } from "react-router";

const IndustryLeader = () => {
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [userSessionData, setuserSessionData] = useContext(UserContext);
  const [data, setdata] = useState([]);
  const [livePulse, setLivePulse] = useState('');
  const [isBusy, setBusy] = useState(true);
  const [rank, setRank] = useState(0);
  const [detailPage, setDetailPage] = useState(false);
  const [detailTitle, setDetailTitle] = useState('');
  const [detailDesc, setDetailDesc] = useState('');
  const [InnovationScoreData, setInovationScoreData] = useState([{}]);
  const [selectedTable, setSelectedTable] = useState()
  const IndustryLeaderRef = useRef(null);
  let paramdata = useParams().tab;
  let count = 0;
  const fetchData = async function fetchData() {
      try{
        const response = await get("brands");
        setdata(response.data);
        setLivePulse(response.brandLiveText);
        setBusy(false);
      } catch(err)         {
        setBusy(false);
      }
      try{
        const response = await get("insights/IndustryLeaders");
        setInovationScoreData(response.data);
        console.log(response.data);
        setBusy(false);
      } catch(err)         {
        setBusy(false);
      }
    }
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    if (paramdata && paramdata == "industry-leaders") {
      IndustryLeaderRef.current.scrollIntoView();
    }
  }, [useParams().tab]);
  const NoInsightSelected = ()=> {
    return (<div className="two-select-boxes">
                  <p className="inner-content-donut" style={{fontSize:"16px"}}>
                  Data not available for this brand
                  </p>
            </div>)
  }
  const DecriptionPage = (
    <React.Fragment>
           <div className="leader-content">
            <div className="leader-selected-details1">
            <div className="row">
              <div className="col-12">
                <div className="back">
                  
                  <a 
                    href={void(0)}
                    onClick={()=>{
                      setDetailPage(false);
                    }}
                    style={{color:'#0f0f0f'}}
                    >
                    <img 
                      src={arrowLeft} alt="arrow" 
                      />Back
                    </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6">
                <h5>{detailTitle}</h5>
                <span dangerouslySetInnerHTML={{ __html: detailDesc }} className="content"/>
              </div>
              <div className="col-12 col-md-6">
                <div className="right-side-graph">
                <div className="graph-wrapper-div">
                  <div className="graph-title">
                    <h2 className="innovation">Innovation Score</h2>
                    <h4 className="innovation">{typeof(InnovationScoreData[0][selectedTable]) != 'undefined' ? InnovationScoreData[0][selectedTable]['Innovation Score']:'-'} <span>Score</span></h4>
                  </div>
                  {
                   <RadarChartd3 chartdata={InnovationScoreData} dataKey={selectedTable}/>   
			            }     
                  <ul>
                    <li>Commerce Experience</li>
                    <li>Shipping & Package</li>
                    <li>Market Intel</li>
                    <li>Brand Health</li>
                    <li>Customer Service</li>
                  </ul>
                </div>
                  {/* <img src={graphImg} alt="graph" /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      <InsightsTabContent /> 
      </React.Fragment>
  );

  const Table = (
    <React.Fragment>
        <div className="leader-content">
            <h3>Industry Leaders</h3>
            <div className="pulse-wrapper">
              <span className="pulse-dot"></span>
              <span>{livePulse}</span>
            </div>
              <div className="table-content">
              <BlockUi tag="div" blocking={isBusy}> 
                <table>
                  <thead>
                    <tr>
                      <th>Rank</th>
                      <th>logo</th>
                      <th>Name</th>
                      <th>Innovation Score</th>
                      <th>Country/Region</th>
                      <th>Industry</th>
                      <th></th>
                    </tr>  
                  </thead>
                  <tbody style={{fontSize: "1rem", 
                                fontWeight: "400", 
                                lineHeight: "1.5", 
                                color: "#212529"}}>
                  {(data.length > 0 && data.map((row,i) => {
                      count = count + 1;
                      return(
                        <tr key={i}>
                          <td>{i+1}</td>
                          <td>
                            <img 
                              className="edit-user-img"
                              style={{width:"40px", height:"40px"}}
                              src={row.logo} 
                            />
                          </td>
                          <td>{row.name}</td>
                          <td>{row.score}</td>
                          <td>{row.country}</td>
                          <td>{row.industry}</td>
                          <td>
                            <button 
                              type="submit"
                              onClick={()=>{
                                setSelectedTable(row.name)
                                setDetailPage(true);
                                setDetailTitle(row.name);
                                setDetailDesc(row.description);
                              }}
                            >Learn More</button>
                            </td>
                        </tr>)
                  })) || <tr><td colSpan="7">No record found.</td></tr>} 
                  </tbody>             
                </table>
              </BlockUi>
              </div>
            </div>
            {
              count == data.length ? <InsightsTabContent />  : null
            }
        </React.Fragment>
  );
    
    return(
      <React.Fragment>
        <div className="inner-content">
        <div className="pr-page-title" ref={IndustryLeaderRef}>
            <h2 className="mb-0">Insights</h2>
        </div>
        {
          detailPage
            ? DecriptionPage         
            : Table
        }

        </div>   
     </React.Fragment>
    );
}

export default IndustryLeader;