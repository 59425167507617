import React, { useState, useEffect, useRef, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Button,
  Label,
  Input,
  FormFeedback,
  Form,
  Modal,
} from "reactstrap";

import axios from "axios";
import UserContext from "../../helpers/AuthType/session_helper";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, useHistory } from "react-router-dom";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import avatar from "../../assets/images/users/avatar-1.jpg";
// actions
import { editProfile, resetProfileFlag } from "../../store/actions";
//Images
import plusImg from "../../assets/images/plus.svg";
import editImg from "../../assets/images/edit.svg";
import { set } from "lodash-es";
//eyeIcons
import passwordEye from "assets/images/templateImages/eye-off.svg";
import passwordEyeOn from "assets/images/templateImages/eye_on.svg";
//toastr
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const AdminUserProfile = () => {
  //meta title
  document.title="Settings - Acumen";
  const history = useHistory();
  const user = JSON.parse(localStorage.getItem("authUser"));
  const [userSessionData, setuserSessionData] = useContext(UserContext);
  const inputFile = useRef(null);
  const dispatch = useDispatch();
  const [modal_demo, setmodal_demo] = useState(false);
  const [modal_small, setmodal_small] = useState(false);
  const [modal_title, setmodal_title] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [changePassword, setchangePassword] = useState(false);
  const [profileImage, setprofileImage] = useState("");
  const [uploadedImage, setuploadedImage] = useState("");
  const [onSubmit, setonSubmit] = useState(false);
  const [showPassword, setshowPassword] = useState(false);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);
  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  const { error, success } = useSelector((state) => ({
    error: state.Profile.error,
    success: state.Profile.success,
  }));
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
        var maxfilesize = 1024 * 1024 * 3,  // 1 Mb
            filesize    = img.size;
        let ext = img.name.split(".").pop()
      if(!['jpg', 'jpeg', 'png', 'JPG', 'JPEG', 'PNG'].includes(ext)) {
        toastr.error("Please upload image files only", 'Error')
        event.target.value = ''
      } else if ( filesize > maxfilesize ){
        toastr.error("File size is large. Please upload image less than 3MB")
        event.target.value = ''
      } else {
      let img = event.target.files[0];
      setuploadedImage(event.target.files[0]);
      setprofileImage(URL.createObjectURL(img));
    }
    }
  };
  const logout = () => {
    history.push("/admin/logout");
  };
  const { updateProfileError, updateProfileServerError, loading } = useSelector(
    (state) => ({
      updateProfileError: state.Account.updateProfileError,
      updateProfileServerError: state.Account.updateProfileServerError,
      loading: state.Account.loading,
    })
  );
  
  useEffect(() => {
    if (updateProfileError) {
      validation.setErrors(updateProfileError);
    }
  }, [updateProfileError]);
  
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      password: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("Please enter first name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      lastName: Yup.string().required("Please enter last name").max(30) .matches(/^[A-Za-z ]*$/, 'special characters and numbers are not allowed'),
      email: Yup.string().required("Please enter email").email("Must be a valid email."),
      password: Yup.string().min(8)
      .when("changePassword", {
        is: () => changePassword==true,
        then: Yup.string().required("The password field is required")
      }),
    }),
    onSubmit: (values) => {
      const formData = new FormData();

      Object.keys(values).map(function (key) {
        if (values[key] != "") {
          formData.append(key, values[key]);
        }
      });
      uploadedImage ? formData.append("profilePic", uploadedImage) : "";

      const token = user.accessToken;
      setonSubmit(true);
      axios
        .post(
          process.env.REACT_APP_BACKEND_URL + "admin/settings/update_profile",
          formData,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
            /*transformRequest: (data) => {
                return data
            },*/
          }
        )
        .then((response) => {
          setonSubmit(false);
          let res = response.data;

          if (res.success) {
            Object.keys(res).map(function (key) {
              if (key != "success") {
                user[key] = res[key];
              }
            });

            localStorage.setItem("authUser", JSON.stringify(user));
            setuserSessionData(JSON.parse(window.localStorage.getItem("authUser")));
            setprofileImage("");
            toastr.success("Records updated successfully.", "");
            setfirstName(user.firstName);
            setlastName(user.lastName);
            setemail(user.email);
            setprofileImage(user.profilePic);
          }
        })
        .catch((error) => {
          setonSubmit(false);
          if (error.response) {
            // Request made and server responded
            validation.setErrors(error.response.data.errors);
          } else if (error.request) {
            // The request was made but no response was received
            toastr.error(error, "");
          } else {
            // Something happened in setting up the request that triggered an Error
            toastr.error(error, "");
          }
        });
    },
  });
  useEffect (() => {
    if(changePassword == false){
      setshowPassword(false);
    }},[changePassword]);
  return (
    <React.Fragment>
      <div className="inner-content">
      <div className="pr-page-title">
        <h2>Settings</h2>
      </div>
        <div className="update-profile2">
          <div className="row">
            <div className="col-12 col-md-3 col-lg-3 col-xl-2">
              <div className="edit-pic">
                <img
                  src={profileImage != "" ? profileImage : userSessionData ? userSessionData.profilePic : user.profilePic}
                  className="edit-user-img"
                  alt=""
                />
                <input
                  tabIndex={1}
                  id="myInput"
                  type="file"
                  ref={inputFile}
                  style={{ display: "none" }}
                  onChange={onImageChange}
                  accept="image/*"
                />
                <a href={void(0)} 
                  onClick={()=>{inputFile.current.click()}}
                  ><img src={userSessionData ? userSessionData.profilePic.substr(-11) == "profile.png" ? plusImg : editImg : user.profilePic.substr(-11) == "profile.png" ? plusImg : editImg } className="edit-icon" alt="" /></a>
              </div>
            </div>
            <div className="col-12 col-md-9 col-lg-9 col-xl-10">
              <div className="other-info">
                <h4>
                  {(firstName ? firstName : user.firstName) +
                    " " +
                    (lastName ? lastName : user.lastName)}
                </h4>
                <p>{user.email}</p>
                <div className="accounts-btn">
                  <button className="acc-btn" tabIndex={2} onClick={logout}>
                    Log out
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="setting"
          className="manage-users-details"
          style={{ marginTop: "30px" }}
        >
          {updateProfileError && updateProfileServerError ? (
            <Alert color="danger">{updateProfileServerError}</Alert>
          ) : null}
          <form className="update-profile profile-form admin-settings-form" onSubmit={validation.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <label className="form-label">First Name</label>
                  <Input
                    id="firstName"
                    type="text"
                    tabIndex={3}
                    name="firstName"
                    className="form-control"
                    autoComplete="new-password"
                    placeholder="Enter First Name"
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    value={validation.values.firstName || ""}
                    invalid={
                      validation.touched.firstName &&
                      validation.errors.firstName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.firstName &&
                  validation.errors.firstName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.firstName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label className="form-label">Last Name</label>
                  <Input
                    id="lastName"
                    type="text"
                    tabIndex={4}
                    name="lastName"
                    autoComplete="new-password"
                    className="form-control"
                    placeholder="Enter Last Name"
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    value={validation.values.lastName || ""}
                    invalid={
                      validation.touched.lastName && validation.errors.lastName
                        ? true
                        : false
                    }
                  />
                  {validation.touched.lastName && validation.errors.lastName ? (
                    <FormFeedback type="invalid">
                      {validation.errors.lastName}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <label className="form-label">Email</label>
                  <Input
                    type="text"
                    className="form-control"
                    tabIndex={5}
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    value={validation.values.email}
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                    disabled={false}
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </Col>
              <Col lg={6}>
                <div className="mb-3">
                  <label className="form-label">Password</label>
                  <Input
                    type={showPassword ? "text" : "password"}
                    className="form-control"
                    tabIndex={6}
                    id="password"
                    name="password"
                    placeholder="Enter new Password"
                    value={
                      changePassword ? validation.values.password : "12345678"
                    }
                    onChange={validation.handleChange}
                    onInput={validation.handleBlur}
                    onBlur={validation.handleBlur}
                    disabled={changePassword ? false : true}
                    invalid={
                      validation.touched.password && validation.errors.password
                        ? true
                        : false
                      }
                  />
                  <img
                  src={showPassword ? passwordEyeOn : passwordEye}
                  className={
                    validation.touched.password && validation.errors.password
                      ? "error-eye"
                      : "eye-off"
                  }
                  pointerEvents = "none"
                  onClick={() => {
                    if(changePassword == true){
                      setshowPassword(showPassword ? false : true)
                    }
                    }}
                  alt="password-eye"
                />
                  {validation.touched.password && validation.errors.password ? (
                    <FormFeedback type="invalid">
                      {validation.errors.password}
                    </FormFeedback>
                  ) : null}  
                  <span className="info">
                    <a
                      tabIndex={7}
                      className="link_hover"
                      onClick={() => {
                        changePassword
                          ? setchangePassword(false)
                          : setchangePassword(true);
                          validation.touched.password = false;
                          validation.values.password = "";
                      }}
                      onKeyPress={(e)=>
                        {
                          if(e.key == "Enter"){
                            changePassword ? 
                            setchangePassword(false) : 
                            setchangePassword(true);
                            validation.touched.password = false;
                            validation.values.password = "";
                          } 
                        }
                      }
                    >
                      {changePassword ? "Cancel" : "Change Password"}
                    </a>
                  </span>
                </div>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col lg={3}>
                <div className="text-end">
                  <button
                    type="submit"
                    className="btn btn-success"
                    disabled={onSubmit ? true : false}
                  >
                    {onSubmit ? "Loading..." : "Submit"}
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>

        {/* {showList ? <ManageUsers /> : <EditProfile />} */}
      </div>

      

      
    </React.Fragment>
  );
};

export default withRouter(AdminUserProfile);
