import { del, get, post, put } from "./api_helper";
import * as url from "./url_helper";

// Gets the logged in user data from local session
const getLoggedInUser = () => {
  const user = localStorage.getItem("authUser");
  if (user) return JSON.parse(user);
  return null;
};

//is user is logged in
const isUserAuthenticated = () => {
  return getLoggedInUser() !== null;
};

const postApiLogin = (data) => post(url.POST_API_LOGIN, data);

const postAdminApiLogin = (data) => post(url.POST_ADMIN_API_LOGIN, data);

const postJwtRegister = (url, data) => post(url, data);

const postJwtReqResetPwd = (url,data) => post(url, data);

const postJwtResetPwd = (url, data) => post(url, data);

const postJwtCreatePwd = (url, data) => post(url, data);

export {
  getLoggedInUser,
  isUserAuthenticated,
  postApiLogin,
  postAdminApiLogin,
  postJwtRegister,
  postJwtReqResetPwd,
  postJwtResetPwd,
  postJwtCreatePwd
};
