import React from "react";
import { Row, FormFeedback } from "reactstrap";
import { useField, ErrorMessage, Field } from "formik";
import { Input } from "reactstrap";
const SelectField = ({
  keys,
  placeholder,
  defaultValue,
  submited,
  arrayLength=0,
  options = [],
  multiple = false,
  isDeleted = false,
  arrayHelpers,
  disabled = false,
  index,
  ...Selectrest  
}) => {
  //console.log("options", options);
  const [field, meta] = useField(Selectrest);
  //console.log(meta,field.name);
 
  return (
    <Row className="mb-3" key={keys}>
      <label htmlFor="date" className="col-md-2 col-form-label form-label">
        {placeholder}
      </label>
      <div className={isDeleted == "true" ? "col-md-8":"col-md-10"}>
        <Input
          type="select"
          className={'mb-0'}
          {...field} 
          {...Selectrest}
          disabled={disabled}
          invalid={
            (meta.touched && meta.error) || (meta.error && submited)
              ? true
              : false
          }
        >
          <option value="">Select</option>
          { Array.isArray(options) ? options.map((val,key) => {
            return (
              <option key={key} value={key}>
                {val}
              </option>
            );
          }): Object.keys(options).map((key) => {
            return (
              <option key={key} value={key}>
                {options[key]}
              </option>
            );
          })}
        </Input>
        {(meta.touched && meta.error) || (meta.error && submited) ? (
          <div type="invalid" className="invalid-feedback">
            {`${placeholder} ${meta.error}`}
          </div>
        ) : null}
        
      </div>
      {
          isDeleted == "true" && <div className="col-md-2">
                                    <button
                                      className="btn btn-danger mt-0"
                                      type="button"
                                      onClick={() => {
                                        console.log(arrayHelpers)
                                      
                                        if(arrayLength==1)
                                        {
                                          alert("There should be atleast one Filter")
                                        }else{
                                          arrayHelpers.remove(index)
                                        }
                                      }}  // remove a friend from the list
                                    >
                                      <i className="fa fa-trash"></i>
                                    </button>
                                  </div>
        }
    </Row>
  );
};
export default SelectField;
