import React from "react"
import {
  Container,
} from "reactstrap"

const AdminDashboard = () => {
  //meta title
  // document.title="Dashboard";
  return (
    <React.Fragment>
      <div className="inner-content">
      <div className="pr-page-title">
          <h2>Dashboard</h2>
      </div>
      </div>   
  </React.Fragment>
  );
}

export default AdminDashboard;
