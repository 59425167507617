import React, { useMemo, useState, useEffect }  from "react"
import {
  Container,
  Badge,
  UncontrolledTooltip,
} from "reactstrap"

import { useHistory, Link } from 'react-router-dom';
import {get, del, put} from "../../helpers/api_helper";
import PropTypes from 'prop-types';
import TableContainer from '../../components/Common/TableContainer';
import BlockUi from 'react-block-ui';
import arrowLeft from 'assets/images/Arrow-left.svg';

const FileRecords = (props) => {
  //meta title
  document.title="File records - Acumen";
  const history = useHistory();
  let {id, schemaName} = props.match.params;
  const [data, setdata] = useState([]);
  const [isBusy, setBusy] = useState(true);
  const [isFetched, setisFetched] = useState(false);
  const [tableHeader, settableHeader] = useState([{
      Header: 'Records',
      accessor: 'file',
      id: 'col0',
      maxWidth: 70,
      minWidth: 50,
      width: 60,
  }]);
  const [insightPageName, setinsightPageName] = useState("");
  const [insightFileName, setinsightFileName] = useState("");

  let tableData = [];
  const fetchData = async function fetchData() {
    setBusy(true);
    try {
      const { data, tableHeader, insightName, file } = await get("admin/files/records/"+id+"/"+schemaName);
      settableHeader(tableHeader);
      setdata(data);
      setinsightPageName(insightName)
      setinsightFileName(file)
      setBusy(false);
      setisFetched(true)
      
    } catch(err) {
      setisFetched(true)
      setBusy(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  
  return (
    <React.Fragment>
      <div className="inner-content">
        <div className="pr-page-title">
          <h2>File records </h2>
            <h6>
            <div className="back"> 
            <Link
              to="/admin/import-file"
            >
              <img 
                src={arrowLeft} alt="arrow" 
              />Back
            </Link>
            </div>
          </h6>
        </div>
        <BlockUi tag="div" blocking={isBusy}>
          <h4 className="mb-3">{insightPageName} - {insightFileName}</h4>

          <TableContainer
               columns={tableHeader}
               data={data}
               isGlobalFilter={true}
               customPageSize={20}
               className="tdTheme"
               isBusy={isBusy}
           />
        </BlockUi>
      </div>
  </React.Fragment>
  );
}

export default FileRecords;
