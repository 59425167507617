import React, { useState, useMemo, useContext, useRef, useEffect } from "react";
import { Row, CardTitle } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import SingleChartSubmit from "helpers/Function/SingleChartSubmit";
import ImportContext from "helpers/Context/ImportContext";
import InputText from "pages/Admin/components/inputtext";
import SelectField from "pages/Admin/components/SelectInput";
import FormAligner from "pages/Admin/components/FormAligner";
import { Link } from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const DefaultBarColor = ["#3349ff", "#fd9502", "#49cc86", "#007343", "#004998"];
export default function GroupBarChartHorizontal({
  subInsights = null,
  save = null,
  index = null,
  id = "",
  clickIndicator = null,
  subInsightsData = null,
  defaultChartData = null,
  saveStatusData = false,
  resetSubinsightFormData,
}) {
  const chartName = "GroupBarChartHorizontal";
  const history = useHistory();
  const FormFields =
    defaultChartData != null
      ? defaultChartData
      : {
          lableX: "",
          valueX: "",
          grpby: "",
          yaxis: [
            {
              bar: "",
              label: "",
              color: DefaultBarColor[0],
            },
          ],
        };
  const formikRef = useRef();
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [uniqueData, setUniqueData] = useState([]);
  const [FormData, setFormData] = useState(FormFields);
  const { lableX, valueX, yaxis } = FormFields;
  const [submited, setSubmited] = useState(false);
  const [saveStatus, setSaveStatus] = useState(saveStatusData);
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  const handleInputChange = (e) => {
    //console.log(formikRef.current?.values);
  };

  const handleFormChange = (e) => {
    setImportContextData({
      ...ImportContextData,
      ["subData"]: [],
      ["SubinsightNo"]: index,
    });
    setSaveStatus(false);
    // toastr.warning(`Sub-Insight-${index + 1} unsaved successfully`);
  };
  useEffect(() => {
    if (subInsights != null) {
      FormFields["subInsghtsTitle"] = "";
      //console.log(JSON.stringify(formikRef.current?.values) == JSON.stringify(FormFields));
      //console.log(JSON.stringify(formikRef.current?.values),JSON.stringify(FormFields));
      if (
        JSON.stringify(formikRef.current?.values) != JSON.stringify(FormFields)
      ) {
        setImportContextData({
          ...ImportContextData,
          [`subinsight.${id}.data`]: formikRef.current?.values,
        });
        if (uniqueData.length != 0) {
          setImportContextData({
            ...ImportContextData,
            [`subinsight.${id}.data`]: {
              ...formikRef.current?.values,
              ["unique"]: uniqueData,
            },
          });
        }
      }
    }
  }, [formikRef.current?.values, uniqueData]);
  const handleFormSubmit = (value) => {
    if (subInsights == null) {
      value["chartName"] = chartName;
      let res = SingleChartSubmit(value, ImportContextData?.id);
      res
        .then((data) => {
          //console.log(data);
          if (data?.success) {
            toastr.success(data?.success, "");
            resetSubinsightFormData();
            history.push(`/admin/chartimport/edit/${ImportContextData?.id}`, {
              TabNo: "4",
            });
          } else {
            toastr.error("Not Able to add chart data", "");
          }
        })
        .catch((err) => console.log(err));
    } else {
      value["chartName"] = chartName;
      //console.log(value);
      setImportContextData({
        ...ImportContextData,
        ["subData"]: value,
        ["SubinsightNo"]: index,
      });
      setSaveStatus(true);
      toastr.success(`Sub-Insight-${index + 1} saved successfully`);
    }
  };
  const validationSchema = {
    //lableX: Yup.string().required("is required"),
    valueX: Yup.string()
      .required("field is required")
      .test("check required", "field is required", function (value) {
        if (value == undefined || value == "Select") {
          return false;
        } else {
          return true;
        }
      }),
    unit: Yup.string().required("is required"),
    grpby: Yup.string()
      .required("field is required")
      .test("check required", "field is required", function (value) {
        if (value == undefined || value == "Select") {
          return false;
        } else {
          return true;
        }
      }),
    yaxis: Yup.array().of(
      Yup.object().shape({
        bar: Yup.string()
          .required("field is required")
          .test("check required", "field is required", function (value) {
            if (value == undefined || value == "Select") {
              return false;
            } else {
              return true;
            }
          }),
        label: Yup.string().required("is required"),
      })
    ),
  };
  if (subInsights != null) {
    validationSchema["subInsghtsTitle"] = Yup.string().required("is required");
    FormFields["subInsghtsTitle"] = "";
  }

  useMemo(() => {
    //////console.log(clickIndicator,"clickIndicator");
    //console.log(clickIndicator,save)
    if (save) {
      formikRef.current?.handleSubmit();
    }
  }, [save, clickIndicator]);
  return (
    <>
      <CardTitle className="h5 mb-4">Group Bar Chart Horizontal</CardTitle>
      <div className="mb-5"></div>
      <div>
        <Formik
          innerRef={formikRef}
          initialValues={
            ImportContextData.hasOwnProperty(`subinsight.${id}.data`)
              ? ImportContextData[`subinsight.${id}.data`]
              : FormFields
          }
          validationSchema={Yup.object(validationSchema)}
          onSubmit={(values) => {
            handleFormSubmit(values);
            //console.log(values);
          }}
          render={({ values, touched, ...formik }) => (
            <Form
              onChange={(e) => {
                if (saveStatus) {
                  handleFormChange(e);
                }
              }}
            >
              {subInsights != null ? (
                <InputText
                  type="text"
                  name={"subInsghtsTitle"}
                  placeholder={"Sub-Insights Title"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
              ) : null}
              <InputText
                type="textarea"
                className="description"
                name={"InsightDescription"}
                placeholder={"Insight Description"}
                onInput={(e) => {
                  handleInputChange(e);
                }}
              />
              <InputText
                  type="text"
                  name={"unit"}
                  placeholder={"Unit"}
                  onInput={(e) => {
                    handleInputChange(e);
                  }}
                />
              <CardTitle className="h5 mb-4">Group By </CardTitle>
              <hr />
              <SelectField
                type="select"
                name="grpby"
                placeholder={"Group By"}
                //submited={submited}
                options={ImportContextData?.Headers}
                onInput={(e) => {
                  handleInputChange(e);
                  formik.setFieldTouched(e.target.name, true);
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              />
             <CardTitle className="h5 mb-4">X axis </CardTitle>
              <hr />
              <InputText type="text" name={"lableX"} placeholder={"Label X"} />
              <SelectField
                type="select"
                name={"valueX"}
                placeholder={"Value"}
                submited={submited}
                options={{
                  avg: "Average",
                  number: "Number",
                }}
                onInput={(e) => {
                  handleInputChange(e);
                  formik.setFieldTouched(e.target.name, true);
                  formik.setFieldValue(e.target.name, e.target.value);
                }}
              ></SelectField>
              <CardTitle className="h5 mb-4">X axis </CardTitle>
              <hr />
              <FieldArray
                name="yaxis"
                render={(arrayHelpers) => {
                  const yaxis = values.yaxis;
                  return (
                    <div>
                      {yaxis && yaxis.length > 0
                        ? yaxis.map((user, index) => (
                            <div key={index}>
                              <SelectField
                                type="select"
                                name={`yaxis.${index}.bar`}
                                placeholder={`Bar ${index + 1}`}
                                //submited={submited}
                                options={ImportContextData?.Headers}
                                onInput={(e) => {
                                  handleInputChange(e);
                                  formik.setFieldTouched(e.target.name, true);
                                  formik.setFieldValue(e.target.name, e.target.value);
                                }}
                                isDeleted={index == 0 ? "false" : "true"}
                                arrayHelpers={arrayHelpers}
                                index={index}
                              />
                              <InputText
                                type="text"
                                name={`yaxis.${index}.label`}
                                placeholder={`Bar ${index + 1} Label`}
                              />
                              <InputText
                                type="color"
                                name={`yaxis.${index}.color`}
                                placeholder={`Bar ${index + 1} Color`}
                                onInput={(e) => {
                                  handleInputChange(e);
                                }}
                              />
                              <Row className="mb-3">
                                <label
                                  htmlFor="Import"
                                  className="col-md-2 col-form-label form-label"
                                ></label>
                                {yaxis.length === index + 1 && (
                                  <div className="col-md-1">
                                    <button
                                      className="btn btn-success"
                                      type="button"
                                      onClick={() =>
                                        arrayHelpers.push({
                                          bar: "",
                                          label: "",
                                          color: DefaultBarColor[index]
                                            ? DefaultBarColor[index]
                                            : "#000000",
                                        })
                                      } // insert an empty string at a position
                                    >
                                      Add Bar
                                    </button>
                                  </div>
                                )}
                              </Row>
                            </div>
                          ))
                        : null}
                      {/* <FormAligner>
                        <button
                          className="btn btn-success col-md-2"
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              bar: "",
                              label: "",
                              color: "#000000",
                            })
                          } // insert an empty string at a position
                        >
                          Add a Bar
                        </button>
                      </FormAligner> */}
                      {subInsights == null && (
                        <FormAligner>
                          <button
                            className="btn btn-success col-md-3 me-2"
                            onClick={() => {
                              setSubmited(true);
                              formik.handleSubmit();
                            }}
                            type="button"
                          >
                            {subInsights == null ? "Save and Next" : "Save"}
                          </button>
                          <Link to="/admin/chartimport">Cancel</Link>
                        </FormAligner>
                      )}
                    </div>
                  );
                }}
              />
            </Form>
          )}
        />
      </div>
    </>
  );
}
