import React, { useState, useContext, useRef } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import ImportContext from "helpers/Context/ImportContext";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { post } from "helpers/api_helper";
import InputText from "pages/Admin/components/inputtext";
import SelectField from "pages/Admin/components/SelectInput";
import FormAligner from "../../../components/FormAligner";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const SampleData = [["Subinsight1"], ["Subinsight2"]];

export default function CMSTab() {
  const FormFields = {
    Tab: "",
    InsightTitle: "",
    livePulseText: "",
    InsightDescription: "",
    SubinsightDescription: "",
    description: "",
    // DisplayMessage: "",
  };
  const history = useHistory();
  const formikRef = useRef();
  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);
  const [description,setdescription] = useState("");

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr
  //console.log(FormFields);
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [FormDatas, setFormDatas] = useState(FormFields);
  const { lableY, valueY, xaxis } = FormFields;
  const [submited, setSubmited] = useState(false);
  //console.log(FormDatas);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...FormDatas, [name]: value });
  };
  const handleCmsSubmit = async (values) => {
    console.log(values);
    values["morecontent"] = description;
    try {
      let res = await post("admin/insights/addCMS", {
        cms: values,
        id: ImportContextData?.id,
      });
      //console.log(res);
      toastr.success(res.success, "");
      history.push("/admin/chartimport/add", { TabNo: "5" });
    } catch (error) {
      //console.log(error.response?.data?.errors?.["cms.InsightTitle"]);
      toastr.error("CMS Server Error");
      if(error.response?.data?.errors?.["cms.InsightTitle"]){
        formikRef.current.setFieldError("InsightTitle",error.response?.data?.errors?.["cms.InsightTitle"]) }
      }
  };
  const editorConfiguration = {
    removePlugins: [
      "CKFinderUploadAdapter",
      "CKFinder",
      "EasyImage",
      "MediaEmbed",
      "Link",
      "Table",
      "BulletedList",
      "NumberedList",
      "BlockQuote",
    ],
    extraPlugins: [uploadPlugin],
    image: {
      upload:{
        types: ['png', 'jpeg']
      }      
    }
  };

  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const desFile = new FormData();
          loader.file.then(async (file) => {
            desFile.append("image", file);  
            let res = await post("admin/insights/cmsFileUpload", desFile); 
            resolve({
                default: res?.data ? res.data : ''
            });     
          });
        });
      }
     
    };
  }
  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  const validationSchema = {
    Tab: Yup.string().required("is required"),
    livePulseText: Yup.string().required("is required"),
    InsightTitle: Yup.string().required(
      "is required"
    ),
    // InsightDescription: Yup.string().required(
    //   "is required"
    // ),
    // DisplayMessage: Yup.string().required(
    //   "is required"
    // ),
    // SubinsightDescription: Yup.string().required(
    //   "Description is required"
    // ),
  }
  if (ImportContextData.subinsightflag != "no") {
    validationSchema["SubinsightDescription"] = Yup.string().required(
      "is required"
    );
    FormFields["SubinsightDescription"] = "Please select a sub-insight from the drop down list to the right";
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <Card>
            <CardBody>
              <CardTitle className="group-bar-vertical">CMS</CardTitle>
              <div>
                <Formik
                  initialValues={FormFields}
                  innerRef={formikRef}
                  // validationSchema={Yup.object({
                  //   Tab: Yup.string().required("Tab is required"),
                  //   InsightTitle: Yup.string().required(
                  //     "Insight Title is required"
                  //   ),
                  //   InsightDescription: Yup.string().required(
                  //     " Insight Description is required"
                  //   ),
                  //   DisplayMessage: Yup.string().required(
                  //     "Display Message is required"
                  //   ),
                  //   SubinsightDescription: Yup.string().required(
                  //     "Description is required"
                  //   ),
                  // })}
                  validationSchema={Yup.object(validationSchema)}
                  onSubmit={(values) => handleCmsSubmit(values)}
                  render={({ values, touched, ...formik }) => (
                    <Form>
                      <SelectField
                        type="select"
                        name={"Tab"}
                        placeholder={"Tab"}
                        submited={submited}
                        options={{
                          "1": "Commerce Experience",
                          "2": "Shipping & Package",
                          "3": "Market Intel",
                          "4": "Brand Health",
                          "5": "Customer Service",
                        }}
                        onInput={(e) => {
                          handleInputChange(e);
                          formik.setFieldTouched(e.target.name, true);
                          formik.setFieldValue(
                            e.target.name,
                            e.target.value
                          );
                        }}
                      />
                      <InputText
                        type="text"
                        name={"InsightTitle"}
                        placeholder={"Insight Title"}
                      />
                      <InputText
                        type="text"
                        name={"livePulseText"}
                        placeholder={"Live Pulse Text"}
                      />
                      {
                      // <InputText
                      //   type="textarea"
                      //   name={"InsightDescription"}
                      //   placeholder={"Insight Description"}
                      // />
                      }
                   {/* {ImportContextData.subinsightflag === "yes"&&(
                    <>
                      <InputText
                        type="textarea"
                        name={`SubinsightDescription`}
                        placeholder={`Sub-Insight Label`}
                        rowClass="mb-1"
                      />
                      <FormAligner>
                        <p className="mb-0">
                          (Sub-insight Label will display If Sub-insight
                          is "Yes")
                        </p>
                      </FormAligner>
                      </>)} */}
                      {/* <FieldArray
                name="subInsightCMS"
                render={(arrayHelpers) => {
                  //console.log(formik.touched);
                  return (
                    <div>
                      {SampleData &&
                        SampleData != [] &&
                        SampleData.map(function (val, key) {
                          return <div key={key}></div>;
                        })}
                    </div>
                  );
                }}
              /> */}
                      {/*<InputText
                        type="textarea"
                        name={"DisplayMessage"}
                        placeholder={"Display Message"}
                      />
                      <FormAligner>
                        <p>(Display message when there is no selection)</p>
            </FormAligner>*/}
                      <FormAligner lable={"More Content"}>
                        <CKEditor
                          editor={ClassicEditor}
                          config={editorConfiguration}
                          data=""
                          id="description"
                          name="description"
                          onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log("Editor is ready to use!", editor);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            setdescription(data);
                          }}
                        />
                      </FormAligner>
                      <FormAligner>
                        <button
                          className="btn btn-success col-md-2 me-2"
                          onClick={() => {
                            setSubmited(true);
                            formik.validateForm().then((data) => {
                              console.log(data);
                            });
                            formik.handleSubmit();
                          }}
                          type="button"
                        >
                          Submit
                        </button>
                        <Link to="/admin/chartimport">Cancel</Link>
                      </FormAligner>
                    </Form>
                  )}
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}
