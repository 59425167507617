import React, {
  useMemo,
  useState,
  useEffect,
  createContext,
  useContext,
} from "react";
import { useLocation, useHistory, useParams, Link } from "react-router-dom";
import { post, get } from "helpers/api_helper";
import ImportContext from "helpers/Context/ImportContext";
import ImportTab from "./SubPages/importTab";
import ChartTab from "./SubPages/chartTab/chartTab";
import FilterTab from "./SubPages/filterTab/FilterTab";
import CMSTab from "./SubPages/CMSTab/CMSTab";
import Preview from "./SubPages/PreviewTab/PreviewTab";
import arrowLeft from "assets/images/Arrow-left.svg";
import "./FileImport.css";
import AppendTab from "./SubPages/appendTab/AppendTab";
const EditFileImport = () => {
  const [ImportData, setImportData] = useState({
    accessTabs: ["Import", "Append", "Filter", "CMS", "Chart", "Priview"],
  });
  const { id } = useParams();
  const [importIntialData, setImportIntialData] = useState(null);
  const [chartIntialData, setChartIntialData] = useState(null);
  const [filterInitialData, setFilterInitialData] = useState(null);
  const [CMSInitialData, setCMSInitialData] = useState(null);
  const [HeaderData, setHeaderData] = useState(null);
  const importValue = useMemo(() => [ImportData, setImportData], [ImportData]);
  const accessData = importValue[0];
  // console.log(accessData);
  const history = useHistory();
  const location = useLocation();
  const currentTab = location.state?.TabNo;
  //console.log(currentTab);
  const [activeTab, setactiveTab] = useState(currentTab);
  // useEffect(() => {
  //   setactiveTab(accessData.tab ? accessData.tab : "1");
  // },[accessData.tab])
  const titleChange = (title) => {
    setHeaderData(title ? title : "Chart Import");
  }
  useMemo(async () => {
    setactiveTab(
      location.state?.TabNo != undefined ? location.state?.TabNo : "1"
    );
    if (location.pathname.includes("edit")) {
      try {
        if (importIntialData == null) {
          let res = await get(`/admin/insights/fileDetails/${`${id}`}`);
          //console.log(res);
          const {
            importDate,
            insightName,
            mapping,
            header,
            subInsight,
            chart,
            filter,
            cms,
            fileType,
          } = res.data;
          setImportData({
            ...ImportData,
            ["Headers"]: header,
            ["id"]: id,
            ["DataType"]: mapping,
            ["subinsightflag"]: subInsight,
            ["FileType"]: fileType,
          });
          setImportIntialData({
            importDate: importDate,
            mapped: mapping,
            insightFile: insightName,
            header: header,
          });
          setChartIntialData({ subInsights: subInsight, chartData: chart });
          setFilterInitialData({ filterData: filter });
          setCMSInitialData({ CMSData: cms });
          setHeaderData(cms?.InsightTitle ? cms?.InsightTitle : "Chart Import");
        }
      } catch (error) {
        console.log(error);
      }
    }
  }, [location.state]);
  return (
    <ImportContext.Provider value={importValue}>
      {importIntialData != null &&
        chartIntialData != null &&
        filterInitialData != null &&
        HeaderData != null &&
        CMSInitialData != null && (
          <React.Fragment>
            <div className="inner-content">
              <div className="pr-page-title">
                <h2>
                  {HeaderData}
                </h2>
                <h6>
                  <div className="back">
                    <Link to="/admin/chartimport">
                      <img src={arrowLeft} alt="arrow" />
                      Back
                    </Link>
                  </div>
                </h6>
              </div>
              <div className="insights-tabs">
                <ul
                  className="nav nav-pills nav-one mb-3 align-items-center"
                  id="details-insights"
                  role="tablist"
                >
                  <li
                    className={`nav-item ${
                      accessData["accessTabs"].includes("Import")
                        ? ""
                        : "no-pointer-event"
                    }`}
                    role="presentation"
                  >
                    <button
                      className={
                        activeTab === "1" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        history.push(`/admin/chartimport/edit/${id}`, {
                          TabNo: "1",
                        });
                      }}
                      id="commerce-tab"
                    >
                      Import
                    </button>
                  </li>
                  <li
                    className={`nav-item ${
                      accessData["accessTabs"].includes("Append")
                        ? ""
                        : "no-pointer-event"
                    }`}
                    role="presentation"
                  >
                    <button
                      className={
                        activeTab === "2" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        history.push(`/admin/chartimport/edit/${id}`, {
                          TabNo: "2",
                        });
                      }}
                      id="return-tab"
                    >
                      Append
                    </button>
                  </li>
                  <li
                    className={`nav-item ${
                      accessData["accessTabs"].includes("Chart")
                        ? ""
                        : "no-pointer-event"
                    }`}
                    role="presentation"
                  >
                    <button
                      className={
                        activeTab == "3" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        history.push(`/admin/chartimport/edit/${id}`, {
                          TabNo: "3",
                        });
                      }}
                      id="shipping-tab"
                    >
                      Chart
                    </button>
                  </li>
                  <li
                    className={`nav-item ${
                      accessData["accessTabs"].includes("Filter")
                        ? ""
                        : "no-pointer-event"
                    }`}
                    role="presentation"
                  >
                    <button
                      className={
                        activeTab === "4" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        history.push(`/admin/chartimport/edit/${id}`, {
                          TabNo: "4",
                        });
                      }}
                      id="logistics-tab"
                    >
                      Filter
                    </button>
                  </li>
                  <li
                    className={`nav-item ${
                      accessData["accessTabs"].includes("CMS")
                        ? ""
                        : "no-pointer-event"
                    }`}
                    role="presentation"
                  >
                    <button
                      className={
                        activeTab === "5" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        history.push(`/admin/chartimport/edit/${id}`, {
                          TabNo: "5",
                        });
                      }}
                      id="return-tab"
                    >
                      CMS
                    </button>
                  </li>
                  <li
                    className={`nav-item ${
                      accessData["accessTabs"].includes("Priview")
                        ? ""
                        : "no-pointer-event"
                    }`}
                    role="presentation"
                  >
                    <button
                      className={
                        activeTab === "6" ? "nav-link active" : "nav-link"
                      }
                      onClick={() => {
                        history.push(`/admin/chartimport/edit/${id}`, {
                          TabNo: "6",
                        });
                      }}
                      id="customer-service-tab"
                    >
                      Preview
                    </button>
                  </li>
                </ul>
                <div style={{ display: activeTab == "1" ? "block" : "none" }}>
                  <ImportTab ImportIntialData={importIntialData} />
                </div>
                <div style={{ display: activeTab == "2" ? "block" : "none" }}>
                  <AppendTab />
                </div>
                <div style={{ display: activeTab == "3" ? "block" : "none" }}>
                  <ChartTab ChartIntialData={chartIntialData} />
                </div>
                <div style={{ display: activeTab == "4" ? "block" : "none" }}>
                  <FilterTab filterInitialData={filterInitialData} />
                </div>
                <div style={{ display: activeTab == "5" ? "block" : "none" }}>
                  <CMSTab titleChange = {titleChange} CMSInitialData={CMSInitialData} />
                </div>
                {activeTab == "6" && (
                  <div>
                    <Preview />
                  </div>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
    </ImportContext.Provider>
  );
};

export default EditFileImport;
