import React, { useState, useEffect, useContext, useRef } from "react";

const PreviewDropdown = ({
  dropdownList,
  appliedInsightCallback,
  label,
  fileheader,
  type,
}) => {
  let activeTab = 1;
  const [dropdown, setDropdown] = useState(false);
  // console.log(dropdownList);
  const [insightSearch, setInsightSearch] = useState("");
  const [selectedInsight, setSelectedInsight] = useState([]);
  const [selectedRawdata, setSelectedRawdata] = useState([]);
  const [appliedInsight, setAppliedInsight] = useState([]);

  const insightDropDown = useRef();

  const tempInsightData = [...dropdownList];
  const insightData = tempInsightData.map(num => num.toString());
  const insight = insightData.map((index, i) => {
    let display =
      insightSearch == ""
        ? i > 6
          ? "show"
          : "show"
        : index.toString().toLowerCase().includes(insightSearch.toLowerCase())
        ? "show"
        : "hide";

    display =
      display == "hide"
        ? selectedInsight.includes(dropdownList[index])
          ? "show"
          : "hide"
        : display;
    if (type == "checkbox") {
      return (
        <li
          key={i}
          className={display}
          onClick={() =>
            selectedInsight.length < 8 ||
            selectedInsight.includes(insightData[i])
             // ? onChangeInsight(i)
              ? onChangeInsight(i)
              : null
          }
        >
          <input
            type={type}
            value={i.toString()}
            className="styled-checkbox"
            id={index}
            name={"insightSearch"}
           // checked={selectedInsight.includes(dropdownList[i])}
            checked={selectedInsight.includes(index.toString())}
            disabled={
              selectedInsight.length >= 8 &&
              !selectedInsight.includes(insightData[i])
                ? true
                : false
            }
            title={
              selectedInsight.length >= 8 &&
              !selectedInsight.includes(insightData[i])
                ? "Only 8 insights can be selected at once"
                : ""
            }
          />
          <label htmlFor={index}>{index}</label>
        </li>
      );
    } else {
      return (
        <li
          key={i}
          className={display}
          onClick={() => {
            onChangeInsightRadio(i);
          }}
        >
          <input
            type={type}
            value={i}
            className="styled-checkbox"
            id={index}
            name={"insightSearch"}
            checked={selectedInsight.includes(insightData[i])}
          />
          <label htmlFor={index}>{index}</label>
        </li>
      );
    }
  });

  // const onChangeInsight = (name) => {
  //   const newSelectedItems = selectedInsight.includes(dropdownList[name])
  //     ? selectedInsight.filter((item) => item !== dropdownList[name])
  //     : [...selectedInsight, dropdownList[name]];
  //   setSelectedInsight(newSelectedItems);
  // };

  const onChangeInsight = (i) => {
    const newSelectedItems = selectedInsight.includes(tempInsightData[i].toString())
      ? selectedInsight.filter((item) => item !== tempInsightData[i].toString())
      : [...selectedInsight, tempInsightData[i].toString()];
    const newSelectedRawItems = selectedRawdata.includes(dropdownList[i])
      ? selectedRawdata.filter((item) => item !== dropdownList[i])
      : [...selectedRawdata, dropdownList[i]];
    setSelectedInsight(newSelectedItems);
    setSelectedRawdata(newSelectedRawItems);
  };
  const onChangeInsightRadio = (name) => {
    selectedInsight.includes(insightData[name])
      ? setSelectedInsight([])
      : setSelectedInsight([insightData[name]]);
      selectedRawdata.includes(insightData[name])
      ? setSelectedRawdata([])
      : setSelectedRawdata([insightData[name]]);
  };

  const insightCount = selectedInsight.length;

  useOnClickOutside(insightDropDown, () => setDropdown(false));

  function useOnClickOutside(ref, handler) {
    useEffect(
      () => {
        const listener = (event) => {
          // Do nothing if clicking ref's element or descendent elements
          if (!ref.current || ref.current.contains(event.target)) {
            return;
          }
          handler(event);
        };
        document.addEventListener("mousedown", listener);
        document.addEventListener("touchstart", listener);
        return () => {
          document.removeEventListener("mousedown", listener);
          document.removeEventListener("touchstart", listener);
        };
      },
      // Add ref and handler to effect dependencies
      // It's worth noting that because passed in handler is a new ...
      // ... function on every render that will cause this effect ...
      // ... callback/cleanup to run every render. It's not a big deal ...
      // ... but to optimize you can wrap handler in useCallback before ...
      // ... passing it into this hook.
      [ref, handler]
    );
  }

  return (
    <React.Fragment>
      <div className="detailed-insights">
        <div className="d-flex dropdown-tab-content">
          <div className="dropdown" ref={insightDropDown}>
            <button
              className="btn dropdown-toggle PreviewDropdown"
              type="button"
              id="dropdownMenuButton1"
              onClick={() => {
                setDropdown(!dropdown);
              }}
            >
              {insightCount == "0" ? "No" : insightCount} {label == "Entry Date" ? "Time Frame" : label} Selected
              {/* <i className= "dropdown-toggle-icon" /> */}
              <i
                className={
                  dropdown
                    ? "dropdown-toggle-icon rotate"
                    : "dropdown-toggle-icon"
                }
              />
            </button>

            <ul
              className={
                dropdown
                  ? "multiselect-container dropdown-menu show"
                  : "multiselect-container dropdown-menu"
              }
            >
              <li>
                <input
                  type="search"
                  placeholder={`Search ${label == "Entry Date" ? "Time Frame" : label}`}
                  value={insightSearch}
                  onChange={(v) => {
                    setInsightSearch(v.target.value);
                  }}
                />
              </li>
              {/* <li
                className={""}
                onClick={() =>{
                  setSelectedInsight([]);
                }}
              >
                <input
                  type={type}
                  value={""}
                  className="styled-checkbox"
                  id={"All"}
                  name={"insightSearch"}
                  checked={selectedInsight.length == 0}
                />
                <label htmlFor={"All"}>{"All"}</label>
              </li> */}
              <li className="brandScroll">{insight}</li>
              <li className="apply-btn">
                <button
                  className="btn"
                  onClick={() => {
                    setAppliedInsight(selectedInsight);
                    setDropdown(!dropdown);
                    appliedInsightCallback(
                      selectedRawdata,
                      fileheader.indexOf(label)
                    );
                  }}
                >
                  Apply
                </button>
              </li>
            </ul>
          </div>

          {/* <button 
                  type="reset" 
                  className="reset-filter"
                  onClick={()=>{
                    setSelectedInsight([]);
                    setAppliedInsight([]);
                    setInsightSearch("");
                  }}
                >Reset Filters</button> */}
        </div>
      </div>
    </React.Fragment>
  );
};
export default PreviewDropdown;
