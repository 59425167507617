import PropTypes from 'prop-types'
import React, {useState, useEffect} from "react"
import { Col, Modal, ModalBody, Row } from "reactstrap"
import { Link, useHistory } from "react-router-dom";

const LogoutModel = ({show, closeClick}) => {
    
    const history = useHistory();
    const [modal_logout_sidebar, setmodal_logout_sidebar] = useState(false);
    useEffect(()=>{
        if(show == true){
            setmodal_logout_sidebar(show);
        }
    },[show]);
    useEffect(()=>{
        if(modal_logout_sidebar == false){
            closeClick();
        }
    },[modal_logout_sidebar]);
    function removeBodyCssSidebar() {
        document.body.classList.add("no_padding");
    }
    const logout_sidebar = () => {
        history.push("/logout");
      };  
    function logoutModelSidebar() {
        setmodal_logout_sidebar(!modal_logout_sidebar);
        removeBodyCssSidebar();
      }
  return (
    <Modal
      isOpen={modal_logout_sidebar}
      toggle={() => {
        logoutModelSidebar();
      }}
      centered
      className="setting-modal"
      id="logout_profile"
    >
    <div className="modal-body update-profile">
      <p>Are you sure you want to log out?</p>
      <div className="logout-model logout-request-model">
        <button type="button" data-bs-dismiss="modal" 
        onClick={() => {
            setmodal_logout_sidebar(false);
          }}
        >Cancel</button>
        <button type="button" onClick={logout_sidebar}>Yes, log me out</button>
      </div>
    </div>
    </Modal>

  )
}

LogoutModel.propTypes = {
  onCloseClick: PropTypes.func,
  onDeleteClick: PropTypes.func,
  show: PropTypes.any
}

export default LogoutModel
