import React, {useState, useEffect, useContext} from "react"
import {
  Container,
  Table
} from "reactstrap"

import { useHistory, Link } from 'react-router-dom';
import {get} from "../../helpers/api_helper";
import BlockUi from 'react-block-ui';

import arrowLeft from 'assets/images/Arrow-left.svg';
import graphImg from 'assets/images/graphTwo.png';
import UserContext from "../../helpers/AuthType/session_helper";
import InsightsTabContent from './InsightsTabContent';
import IndustryLeader from "./IndustryLeaderTable";

const Dashboard = () => {

  //meta title
  document.title="Insights - Acumen";

  return (
    <IndustryLeader />
  );
}

export default Dashboard;
