import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";

const ClickToDoorOverView = ({
  chartHeightData,
  BarplatData,
  uniquedata = [],
  colorCode = [],
  lableValue,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  try {
    //console.log(uniquedata);
    //const d3Chart = useRef();
    if (Object.keys(BarplatData) == 0) {
      throw "empty data";
    }
    const Datavalue = [];
    const chartHeight = chartHeightData ? chartHeightData : 600;
    // const loop = Object.keys(BarplatData).map((d)=>{
    //   Datavalue.push(...[BarplatData[d]['oc_s_avg']+BarplatData[d]['s_d_avg']])
    // });
    const firstInnerObj = Object.values(BarplatData)[0];
    const uniquebar = Object.keys(firstInnerObj);
    const colordata = colorCode.map((obj) => obj.unique);
    const chartData = Array.from(Object.keys(BarplatData), (x, i) => {
      return {
        group: x,
        ...BarplatData[x],
      };
    });
    for (const key in BarplatData) {
      const obj = BarplatData[key];
      let sum = 0;
      Object.keys(obj).forEach((key) => {
        sum += obj[key];
      });
      Datavalue.push(sum);
    }

    const customDecimal = (e) => {
      return d3.format(".0f")(e);
    };

    useEffect(() => {
      const margin = { top: 20, right: 30, bottom: 40, left: 90 };
      const width = 1200 - margin.left - margin.right;
      const height = chartHeight - margin.top - margin.bottom;

      d3.selectAll("#barplotstack > *").remove();

      const svg = d3
        .select("#barplotstack")
        .append("svg")
        /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
        .attr("viewBox", `0 0 1200 ${chartHeight}`)
        // .style('width','855px')
        // .style('height','426px')
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // List of subgroups = header of the csv files = soil condition here
      const subgroups = ["group", ...uniquebar];

      // List of groups = species here = value of the first column called group -> I show them on the X axis
      const groups = Object.keys(BarplatData).map((d) => {
        return d;
      });

      // Add X axis
      const x = d3.scaleBand().domain(groups).range([0, width]).padding([0.2]);

      svg
        .append("g")
        .attr("transform", "translate(0," + height + ")")
        .call(d3.axisBottom(x).tickSize(12).tickPadding([15]))
        .selectAll("g")
        .attr("transform", (d) => "translate(" + (x(d) + 35) + ",0)")
        .selectAll("line")
        .attr("y1", 10)
        .attr("y2", 20)
        .attr("dy", ".35em")
        .style("color", "#0f0f0f")
        .selectAll("text")
        .attr("y", 20)
        .attr("x", 9)
        .attr("dy", ".35em");

      // Add Y axis
      const y = d3
        .scaleLinear()
        .domain([0, Math.ceil(d3.max(Datavalue))])
        .range([height, 0]);
      //console.log(d3.max(Datavalue));
      const yAxisGrid = d3
        .axisLeft(y)
        .tickSize(-width)
        .tickFormat("")
        .ticks(
          d3.max(Datavalue) <= 10
            ? Math.ceil(d3.max(Datavalue))
            : Math.ceil(d3.max(Datavalue) / 2)
        );

      svg
        .append("g")
        .call(
          d3
            .axisLeft(y)
            .tickFormat(customDecimal)
            .ticks(
              d3.max(Datavalue) <= 10
                ? Math.ceil(d3.max(Datavalue))
                : Math.ceil(d3.max(Datavalue) / 2)
            )
        )
        .selectAll("line")
        .attr("stroke", "#A9A9A9")
        .attr("stroke-width", 0.1)
        .attr("opacity", 0);

      svg.append("g").attr("class", "y axis-grid").call(yAxisGrid);

      // Another scale for subgroup position?
      /*const xSubgroup = d3.scaleBand()
      .domain(subgroups)
      .range([0, x.bandwidth()])
      .padding([0.2])*/

      // color palette = one color per subgroup
      const color = d3
        .scaleOrdinal()
        .domain(uniquedata)
        .range([...colordata]);
      //.range(['#3349FF','#3349FF','#49cc86'])

      //console.log(BarplatData['Saks']['oc_s_avg']);

      const tooltip = d3
        .select("#barplotstack")
        .append("div")
        .attr("class", "tooltip-barplot")
        .style("visibility", "hidden");

      const dataArray = [];

      Object.keys(BarplatData).map((d) => {
        let da = BarplatData[d];
        dataArray.push({
          group: da["retailer"],
          oc_s_avg: "" + da["oc_s_avg"],
          s_d_avg: "" + da["s_d_avg"],
        });
      });

      const stackedData = d3.stack().keys(subgroups)(chartData);
      console.log(chartData);
      console.log(stackedData);
      // console.log(dataArray);*/

      // Show the bars
      svg
        .append("g")
        .selectAll("g")
        // Enter in data = loop group per group
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", (d) => color(d.key))
        .attr("data-group", (d) => d.key)
        .selectAll("rect")
        //.data((d)=>subgroups.map(function(key) { return {key: key, value: key == 'group' ? d : BarplatData[d][key]}; }) )
        .data((d, i) => d)
        .enter()
        .append("rect")
        .attr("x", (d) => x(d.data.group))
        .attr("y", (d) => y(d[1]))
        .attr("width", x.bandwidth() > 80 ? 80 : x.bandwidth())
        .attr("height", (d) => y(d[0]) - y(d[1]))
        .on("mouseover", (event, d) => {
          //console.log(d3.select(this).attr("x"));
          tooltip
            .transition()
            .delay(0)
            .duration(50)
            .ease(d3.easeLinear)
            .style("visibility", "visible")
            .style("opacity", "1");

          // console.log(d);
          //   let toolText = "";

          //   toolText = (d[0] == 0)
          //                 ? uniquedata[0]
          //                 : uniquedata[1];

          //   let toolColor = (d[0] == 0)
          //                 ? '#3349FF'
          //                 : '#49CC86';
          //console.log(d);

          tooltip
            .html(
              `
                <div class="tooltipDesign">
                  <h5 class="tt-head">
                  ${d.data.group}
                  </h5>
                  <ul>
                    <li style="margin:0px">
                    <p>
                      <span 
                        class="color1-dot"
                        style="background:${color(
                          event.target.parentNode["__data__"].key
                        )}"
                        ></span> 
                      <span class="tooltip_text">${event.target.parentNode["__data__"].key}</span>
                      </p>
                      <label>${parseFloat(d[1] - d[0]).toFixed(2)} ${lableValue?.['Unit'] ? lableValue?.['Unit'] : ""}</label>
                    </li>
                   </ul>
                  </div>
                  `
            )
            .style("left", event.layerX + 10 + "px")
            .style("top", event.layerY - 100 + "px");
        })
        .on("mousemove", function (event) {
          return tooltip
            .style("left", event.layerX + 10 + "px")
            .style("top", event.layerY - 100 + "px");
        })
        .on("mouseout", () =>
          tooltip
            .transition()
            .style("visibility", "hidden")
            .style("opacity", "0")
        );
      if (lableValue?.["lable Y"]) {
        svg
          .append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 30 - margin.left)
          .attr("x", 0 - height / 2)
          .attr("dy", "1em")
          .style("text-anchor", "middle")
          .style("font-weight", "500")
          .style("font-size", "14px")
          .style("color", "#000000")
          .text(`${lableValue?.["lable Y"]}`);
      }
      // console.log(xSubgroup.bandwidth());
    }, [BarplatData]);
  } catch (err) {
    console.log(err);
    return (
      <ErrorMessage Message={"Not able to generate graph for the retrieved data"}/>
    );
  }
  return (
    <>
      {errorMessage == null && (<div id="barplotstack"></div>)}
    </>
  );
};

export default ClickToDoorOverView;
