import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import "assets/css/style.css";
import {
  Row,
  Col,
  CardBody,
  Card,
  Alert,
  Container,
  Form,
  Input,
  FormFeedback,
  Spinner,
  Label,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";
import { withRouter, Link, useHistory } from "react-router-dom";

//import Images
import loginLogo from "assets/images/templateImages/Acumen-logo.png";
import loginRightImage from "assets/images/templateImages/login-right-img-half-new.png";
import loginRightIcon from "assets/images/nike.svg";
import loginRightIcon1 from "assets/images/p-2.svg";
import loginRightIcon2 from "assets/images/p-3.svg";
import loginRightIcon3 from "assets/images/p-4.svg";
import loginRightIcon4 from "assets/images/p-5.svg";

// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

// actions
import {
  userForgetPassword,
  userForgetPasswordError,
} from "../../store/actions";

const RequestResetPassword = () => {
  document.title = "Reset password - Acumen"
  const [buttonActive, setButtonActive] = useState(false);
  const [serverError,setserverError] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "", //"admin@themesbrand.com" ||
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Must be a valid email")
        .required("Please enter email"),
    }),
    onSubmit: (values) => {
      console.log("in onsubmit formik");
      dispatch(userForgetPassword(values));
    },
  });

  const { error, forgetError, forgetSuccessMsg, loading } = useSelector(
    (state) => ({
      forgetError: state.ForgetPassword.forgetError,
      forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
      loading: state.ForgetPassword.loading,
    })
  );

  useEffect(() => {
    if (forgetError) {
      if(typeof forgetError === 'string'){
        console.log(forgetError,'err')
        setserverError(forgetError)
      }
      else{
        validation.setErrors(forgetError);
      }
    }
  }, [forgetError]);

  useEffect(()=>{
    console.log(serverError,'servererrr')
  },[serverError])

  //If success msg redirect to login after 3s
  useEffect(() => {
    if (forgetSuccessMsg) {
      setButtonActive(true);
      setTimeout(function () {
        window.location.replace("/login");
      }, 5000);
    }
  }, [forgetSuccessMsg]);

  useEffect(() => {
    dispatch(userForgetPasswordError(""));
  }, []);

  useEffect(() => {
    setserverError("")
  },[])

  const backSubmit = () => {
    history.push("/login");
  };

  return (
    <React.Fragment>
      <div className="common-box">
        <div className="login-logo">
          <a>
            {/* <img src={loginLogo} alt="Accumen logo" /> */}
            Acumen
          </a>
        </div>
        <div className="left-content">
          <div className="log-in-details">
            <div className="form-header">
              <h3>Reset password</h3>
              <h5 className="welcome-text">
                Enter the email address associated with your account, and we’ll
                send you a link to reset your password.
              </h5>
              {serverError ? <Alert color="danger">{serverError}</Alert> : null}
            </div>
            <form
              className="login-details"
              onSubmit={(e) => {
                e.preventDefault();
                return false;
              }}
            >
              
              {forgetSuccessMsg ? (
                <div className="form-group input-marging">
                  <label className="form-label darkFont">Email Address</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter your email address"
                    disabled={true}
                  />
                </div>
              ) : (
                <div className="form-group input-marging">
                  <label className="form-label darkFont">Email Address</label>
                  <Input
                    className="form-control"
                    type="text"
                    placeholder="Enter your email address"
                    name="email"
                    tabIndex={1}
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    onInput={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              )}
              {buttonActive == false ? (
                <div className="button-type">
                  <button
                    type="submit"
                    disabled={ loading || validation.touched.email && !validation.errors.email ? false : true}
                    onClick={() => {validation.handleSubmit(),setserverError("")}}
                    className="button-with-bg"
                  >
                    {loading == true ? "Processing   " : "Reset Password"}
                    {loading && (
                      <Spinner
                        className="ms-2"
                        style={{ height: "1rem", width: "1rem" }}
                        color="light"
                      />
                    )}
                  </button>
                  <button
                    //   type="submit"
                    onClick={() => backSubmit()}
                    className="button-without-bg"
                    style={{ marginTop: "30px" }}
                  >
                    Back
                  </button>
                </div>
              ) : (
                <span className="password-note" style={{ color: "#348E5E", fontSize:'16px'}}>
                  {forgetSuccessMsg}
                </span>
              )}
            </form>
          </div>
        </div>
        <div className="right-content">
          {/* <img src={loginRightImage} alt="dashboard" /> */}
          <div className="content_login">
            {/* <img src={loginRightImage} alt="dashboard" /> */}
            <h4>Acumen by G & Co.</h4>
            <h5>Advance in Retail: Transformative Insights into Action with our Live Data Benchmarking Soultion.</h5>

            <ul className="login_detail mx-0 p-0">
                <li>Real-time data on the market and competition</li>
                <li>Retail focused to provide deep expertise in the industry</li>
                <li>Actionable takeaways to quickly and clearly inform relevant teams</li>
                <li>Diverse consumer demographics across generation, gender, income, and consumer preferences</li>
                <li>Custom on-demand research in 48 hours to better inform your specific needs and purposes</li>
            </ul>

            <div className="partners_section w-100 mt-auto">
                <h6>Top Retail & Consumer Brands Place Their Trust in G & Co.</h6>
                <div className="d-flex justify-content-between align-items-center">
                    <p><img src={loginRightIcon} alt=" " /></p>
                    <p><img src={loginRightIcon1} alt=" " /></p>
                    <p><img src={loginRightIcon2} alt=" " /></p>
                    <p><img src={loginRightIcon3} alt=" " /></p>
                    <p><img src={loginRightIcon4} alt=" " /></p>
                </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default withRouter(RequestResetPassword);

RequestResetPassword.propTypes = {
  history: PropTypes.object,
};
