import React, { useState, useContext } from "react";
import { Row, Col, Card, CardBody, CardTitle } from "reactstrap";
import { Formik, Field, Form, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import { post } from "helpers/api_helper";

import ImportContext from "helpers/Context/ImportContext";
import SelectField from "../../../components/SelectInput";
import MultiCheckSelect from "../../../components/MultiCheckSelect";
import FormAligner from "../../../components/FormAligner";

import "./filter.css";
import { Link } from "react-router-dom";

import toastr from "toastr";
import "toastr/build/toastr.min.css";

const CoustomValueData = {
  Monthwise: "Month wise",
  Yearlywise: "Yearly wise",
  "Last 14 days,Last 28 days,Last 3 months": "14days, 28days, 3months",
};
export default function FilterForm({filterInitialData}) {
  const FormFields = Object.keys(filterInitialData?.filterData).length ? filterInitialData?.filterData : {
    filter: [
      {
        Colname: "",
        Customvalue: "",
      },
    ],
  };
  const history = useHistory();
  const [ImportContextData, setImportContextData] = useContext(ImportContext);
  const [checked, setChecked] = useState({});
  const [FormData, setFormData] = useState(FormFields);
  const { lableY, valueY, filter } = FormFields;
  const [submited, setSubmited] = useState(false);

  //Toastr
  const [showEasing, setshowEasing] = useState("swing");
  const [hideEasing, sethideEasing] = useState("linear");
  const [showMethod, setshowMethod] = useState("fadeIn");
  const [hideMethod, sethideMethod] = useState("fadeOut");
  const [showDuration, setshowDuration] = useState(300);
  const [hideDuration, sethideDuration] = useState(1000);
  const [timeOut, settimeOut] = useState(5000);
  const [extendedTimeOut, setextendedTimeOut] = useState(1000);

  let positionClass = "toast-top-right";

  toastr.options = {
    positionClass: positionClass,
    timeOut: timeOut,
    extendedTimeOut: extendedTimeOut,
    closeButton: true,
    progressBar: true,
    preventDuplicates: true,
    showEasing: showEasing,
    hideEasing: hideEasing,
    showMethod: showMethod,
    hideMethod: hideMethod,
    showDuration: showDuration,
    hideDuration: hideDuration,
  };
  //End toastr

  //console.log(FormData);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...FormData, [name]: value });
  };
  const updateChecked = (e, formik) => {
    formik.setFieldValue(e.target.name, e.target.value);
    // if (
    //   checked[e.target.name] != undefined
    //     ? checked[e.target.name].includes(e.target.value)
    //     : false
    // ) {
    //   let arr = checked[e.target.name];
    //   arr = arr.filter((data) => data !== e.target.value);
    //   setChecked({ ...checked, [e.target.name]: [...arr] });
    //   formik.setFieldValue(e.target.name, [...arr]);
    // } else {
    //   setChecked({
    //     ...checked,
    //     [e.target.name]: checked[e.target.name]
    //       ? [...checked[e.target.name], e.target.value]
    //       : [e.target.value],
    //   });
    //   formik.setFieldValue(
    //     e.target.name,
    //     checked[e.target.name]
    //       ? [...checked[e.target.name], e.target.value]
    //       : [e.target.value]
    //   );
    // }
  };
  const handleFilterSubmit = async (values) => {
    console.log(values);
    try {
      let res = await post(`admin/insights/addFilter`, {
        filter: values,
        id: ImportContextData?.id,
      });
      toastr.success(res?.success, "");
      history.push(`/admin/chartimport/edit/${ImportContextData.id}`, { TabNo: "5" });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        <Formik
          initialValues={FormFields}
          validationSchema={Yup.object({
            filter: Yup.array().of(
              Yup.object().shape({
                Colname: Yup.string()
                  .required("field is required")
                  .test(
                    "check required",
                    "field is required",
                    function (value) {
                      if (value == undefined || value == "Select") {
                        return false;
                      } else {
                        return true;
                      }
                    }
                  )
                  .test(
                    "check Same data",
                    "field should not be same as selected before",
                    function (value, formdatas) {
                      if (value != undefined) {
                        const selectdata = formdatas.from[1].value.filter.map(
                          (obj) => {
                            if (typeof obj.Colname !== "undefined") {
                              return obj.Colname;
                            }
                          }
                        );
                        let occurrences = selectdata.filter((e) => e === value);
                        if (occurrences.length > 1) {
                          return false;
                        }
                        else{
                          return true;
                        }
                      }
                    }
                  ),
                Customvalue: Yup.string()
                .test(
                  "check required",
                  "Custom value field is required",
                  function (value, formdatas) {
                    console.log(value);
                    if (
                      ImportContextData?.DataType[formdatas.parent.Colname]
                        ?.dataType == "date" &&
                      value == undefined
                    ) {
                      return false;
                    } else {
                      return true;
                    }
                  }
                ),
              })
            ),
          })}
          onSubmit={(values) => handleFilterSubmit(values)}
          render={({ values, touched, ...formik }) => (
            <Row>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Form>
                      <FieldArray
                        name="filter"
                        render={(arrayHelpers) => {
                          const filter = values.filter;
                          // console.log("arrayHelpers");
                          // console.log(arrayHelpers);
                          // console.log("touched");
                          // console.log(touched);
                          return (
                            <div>
                              {filter && filter.length > 0
                                ? filter.map((value, index) => (
                                    <div key={index}>
                                      <CardTitle>{`Filter #${
                                        index + 1
                                      }`}</CardTitle>
                                      <hr />
                                      <SelectField
                                        type="select"
                                        name={`filter.${index}.Colname`}
                                        placeholder={`Col Name`}
                                        //submited={submited}
                                        arrayLength={filter.length}
                                        options={ImportContextData?.Headers}
                                        onInput={(e) => {
                                          handleInputChange(e);
                                          formik.setFieldTouched(
                                            e.target.name,
                                            true
                                          );
                                          formik.setFieldValue(
                                            e.target.name,
                                            e.target.value
                                          );
                                          formik.setFieldValue(
                                            `filter.${index}.Customvalue`,''
                                          );
                                        }}
                                        isDeleted={"true"}
                                        arrayHelpers={arrayHelpers}
                                        index={index}
                                      />
                                      {value.Colname != "" &&
                                        ImportContextData?.DataType[
                                          value.Colname
                                        ]?.dataType === "date" && (
                                          <>
                                            <FormAligner lable="Custom Value">
                                              <div
                                                role="group"
                                                aria-labelledby="checkbox-group"
                                                onClick={(e) => {
                                                  if (e.target.name != "") {
                                                    updateChecked(e, formik);
                                                  }
                                                }}
                                              >
                                                <MultiCheckSelect
                                                  name={`filter.${index}.Customvalue`}
                                                  CoustomValueData={CoustomValueData}
                                                  onBlur={(e) => {
                                                    formik.setFieldTouched(
                                                      `filterCustomvalue`,
                                                      true
                                                    );
                                                  }}
                                                >
                                                  {Object.keys(
                                                    CoustomValueData
                                                  ).map((key, i) => {
                                                    return (
                                                      <li
                                                        className={"show"}
                                                        key={i}
                                                      >
                                                        <Field
                                                          type="radio"
                                                          name={`filter.${index}.Customvalue`}
                                                          value={key}
                                                          className="styled-checkbox dropdown-Checkbox"
                                                        />
                                                        <label
                                                          htmlFor={`filter.${index}.Customvalue`}
                                                        >
                                                          {`${CoustomValueData[key]}`}
                                                        </label>
                                                      </li>
                                                    );
                                                  })}
                                                </MultiCheckSelect>
                                                <ErrorMessage
                                                  name={`filter.${index}.Customvalue`}
                                                  component="div"
                                                  className="error"
                                                />
                                              </div>
                                            </FormAligner>
                                          </>
                                        )}
                                      <Row className="mb-3">
                                        <label
                                          htmlFor="Import"
                                          className="col-md-2 col-form-label form-label"
                                        ></label>
                                      </Row>
                                    </div>
                                  ))
                                : null}
                              <a
                                onClick={() =>
                                  arrayHelpers.push({
                                    Colname: "",
                                    Customvalue: "",
                                  })
                                }
                                className="float class"
                                data-placement="top"
                                title="Add Filters"
                              >
                                <i className="fa fa-plus my-float"></i>
                              </a>
                              <FormAligner>
                                <button
                                  className="btn btn-success col-md-2 me-2"
                                  onClick={() => {
                                    setSubmited(true);
                                    formik.validateForm().then((data) => {
                                      console.log(data);
                                    });
                                    formik.handleSubmit();
                                  }}
                                  type="button"
                                >
                                  Save and Next
                                </button>
                                <Link to="/admin/chartimport">Cancel</Link>
                              </FormAligner>
                            </div>
                          );
                        }}
                      />
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        />
      </div>
    </>
  );
}
