import axios from "axios";
import { messages } from "common/data";
import { history } from "App";
//apply base url for axios
const API_URL = process.env.REACT_APP_BACKEND_URL;
const axiosApi = axios.create({
  baseURL: API_URL,
});

let getToken = function() {
  let accessToken = "Bearer XXX";

  try{
    accessToken =
      "Bearer "+ JSON.parse(window.localStorage.getItem("authUser"))['accessToken']
  } catch(err) {}
  return accessToken;
}


axiosApi.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if(error.request.status == 401)
    {
      history.push("/login");
      console.error("EXPIRED TOKEN!");
      location.reload();
    }
    console.log(error.request);
    return Promise.reject(error);
  }
);

export async function get(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getToken();
  return await axiosApi.get(url, { ...config }).then(response => response.data);
}

export async function post(url, data, config = {} ) {
  axiosApi.defaults.headers.common["Authorization"] = getToken();
    return axiosApi
    .post(url, (data.constructor.name == "FormData" ? data : {...data}), { ...config })
    .then(response => response.data);
}

export async function put(url, data, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getToken();
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then(response => response.data);
}

export async function del(url, config = {}) {
  axiosApi.defaults.headers.common["Authorization"] = getToken();
  return await axiosApi
    .delete(url, { ...config })
    .then(response => response.data);
}
