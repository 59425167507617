import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
// //Import Scrollbar
import SimpleBar from "simplebar-react";
import {
  Modal,
} from "reactstrap";
import LogoutModel from "components/Common/LogoutModel";
// MetisMenu
import MetisMenu from "metismenujs";
import { withRouter } from "react-router-dom";
import { Link, useHistory } from "react-router-dom";
import insights from "assets/images/insight.svg";
import settings from "assets/images/settings-new.svg";
import logout from "assets/images/logout-new.svg";

//i18n
import { withTranslation } from "react-i18next";

const SidebarContent = (props) => {
  const ref = useRef();
  const [logoutstate, setlogoutstate] = useState(false);
  const handleCloseClick = () =>{
    setlogoutstate(false)
  }
  // function removeBodyCssSidebar() {
  //   document.body.classList.add("no_padding");
  // }
  // const logout_sidebar = () => {
  //   history.push("logout");
  // };
  // function logoutModelSidebar() {
  //   setmodal_logout_sidebar(!modal_logout_sidebar);
  //   removeBodyCssSidebar();
  // }
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously
  useEffect(() => {
    let pathName = props.location.pathname;

    const initMenu = () => {
      new MetisMenu("#side-menu");
      let matchingMenuItem = null;
      const ul = document.getElementById("side-menu");
      const items = ul.getElementsByTagName("a");
      if(pathName.includes('/members'))
      {
        pathName = '/settings';
      }

      if(pathName.includes('/insights/industry-leaders'))
      {
        pathName = '/insights/industry-leaders';
      }

      if(pathName.includes('/insights'))
      {
        pathName = '/insights/industry-leaders';
      }
      
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i];
          break;
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem);
      }
    };
    initMenu();
  }, [props.location.pathname]);

  useEffect(() => {
    ref.current.recalculate();
  });

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop;
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300;
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  }

  return (
    <React.Fragment>
      <SimpleBar className="h-100" ref={ref}>
        <div id="sidebar-menu">
          <ul className="tab-panel-menu nav nav-pills" id="side-menu">
            {/*<li className="menu-title">{props.t("Menu")} </li>*/}
            <li>
                <Link to="/insights/industry-leaders" >
                  {/* <i className="bx bx-home-circle"></i> */}
                  <img src={insights} />
                  <span>{props.t("Insights")}</span>
                </Link>
            </li>
            
            <li>
                <Link to="/settings">
                  {/* <i className="bx bx-cog"></i> */}
                  <img src={settings} />
                  <span>{props.t("Settings")}</span>
                </Link>
            </li>

            <li onClick={()=>setlogoutstate(true)}>
              <a>
                {/* <i className="bx bx-log-in"></i> */}
                <img src={logout} />
                <span>{props.t("Logout")}</span>
              </a>
            </li>
          </ul>
        </div>
      </SimpleBar>
      {/* <Modal
        isOpen={modal_logout_sidebar}
        toggle={() => {
          logoutModelSidebar();
        }}
        centered
        className="setting-modal"
        id="manage-user-profile"
      >
        <div className="modal-body">
            <p>Are you sure you want to log out?</p>
            <div className="logout-model">
                <button type="button" onClick={() => {
                setmodal_logout_sidebar(false);
              }} data-bs-dismiss="modal">Cancel</button>
                <button type="button"onClick={logout_sidebar}>Yes, log me out</button>
            </div>
        </div>
      </Modal> */}
      <LogoutModel show={logoutstate} closeClick = {handleCloseClick} ></LogoutModel>
    </React.Fragment>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
};

export default withRouter(withTranslation()(SidebarContent));
