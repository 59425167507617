import React, { useMemo, useState, useEffect, useRef } from "react";
import * as d3 from "d3";
import ErrorMessage from "./components/ErrorMessage";
import { group } from "d3";

const ReturnObjectiveInsight = ({
  chartHeightData,
  BarplatData,
  sideBar,
  colorCode,
  iddata,
  lableValue,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const id = "ReturnObjectiveInsight" + iddata;

 try{
  if(Object.keys(BarplatData) == 0){
    throw("empty data")
  }
  const customFormat = (e) => {
    return e + `${lableValue?.['Unit'] ? lableValue?.['Unit'] : "%"}`;
  };
  //console.log(BarplatData);

  const ChartColor = colorCode.map((obj) => obj.unique);
  //console.log(ChartColor);
  const chartData = Array.from(Object.keys(BarplatData), (x, i) => {
    return {
      group: x,
      ...BarplatData[x],
    };
  });
  //console.log(chartData);
  const chartHeight = chartHeightData ? chartHeightData : 800;
  let totalNoBrand = Object.keys(BarplatData).length;
  // let paddingBetween;
  // switch(totalNoBrand) {
  //   case 1:
  //     paddingBetween = 0
  //     break;
  //   case 2:
  //     paddingBetween = 2
  //     break;
  //   case 3:
  //     paddingBetween = -.75
  //     break;
  //   case 4:
  //     paddingBetween = -.10
  //     break;
  //   case 5:
  //     paddingBetween = .25
  //     break;
  //   case 6:
  //     paddingBetween = .65
  //     break;
  //   case 7:
  //     paddingBetween = 1
  //     break;
  // }
  console.log(id, "SHidddddd");
  useEffect(() => {
    const margin = { top: 20, right: 30, bottom: 130, left: 110 };
    const width = 1670 - margin.left - margin.right;
    const height = 120 + totalNoBrand * 80 - margin.top - margin.bottom; //(Object.keys(BarplatData).length*100)

    d3.selectAll(`#${id} > *`).remove();

    const svg = d3
      .select(`#${id}`)
      .append("svg")
      /*.attr("width", width + margin.left + margin.right)
      .attr("height", height + margin.top + margin.bottom)*/
      .attr("viewBox", `0 0 1670 ${50 + totalNoBrand * 80}`)
      // .style('height','420px')
      .append("g")
      .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // List of subgroups = header of the csv files = soil condition here
    const subgroups = ["group", ...sideBar];

    // List of groups = species here = value of the first column called group -> I show them on the X axis
    const groups = Object.keys(BarplatData).map((d) => {
      return d;
    });

    console.log(groups);

    // Add Y axis
    const y = d3
      .scaleBand()
      .domain(groups)
      .range([0, height])
      .paddingInner(0.1)
      .paddingOuter(0.1)
      .align([0.45]);

    // Add X axis
    const x = d3
      .scaleLinear()
      .domain([0, 100]) //d3.max(Datavalue)
      .range([0, width - 20]);

    svg
      .append("g")
      .attr("transform", "translate(0," + height + ")")
      .call(d3.axisBottom(x).tickFormat(customFormat).ticks(5).tickSize(0))
      .selectAll("text")
      .attr("y", 20)
      .attr("x", 9)
      .style("fill", "black")
      .attr("dy", ".35em")
      .style("font-size", "20px");

    const xAxisGrid = d3
      .axisBottom(x)
      .tickSize(totalNoBrand == 1 ? -height - 30 : -height - 15)
      .tickFormat("")
      .ticks(5);

    svg
      .append("g")
      .call(d3.axisLeft(y).tickSize(0).tickPadding([8]))
      .selectAll("g")
      .attr("transform", (d) => "translate(0," + (y(d) + 25) + ")")
      .selectAll("text")
      .each(function (d) {
        var arr = d.split(" ");
        // console.log(arr);
        if (arr.length > 1) {
          d3.select(this).text("");
          if (arr != undefined) {
            for (let i = 0; i < arr.length; i++) {
              d3.select(this)
                .append("tspan")
                .text(arr[i])
                .attr(
                  "dy",
                  i
                    ? arr.length == 3
                      ? "1em"
                      : "1.2em"
                    : arr.length == 3
                    ? -12
                    : 0
                )
                .attr("x", -47)
                .attr("text-anchor", "middle")
                .style("font-size", "20px")
                .attr("class", "tspan" + i);
            }
          }
        }
      })
      .attr("dy", totalNoBrand == 1 ? "-0.2em" : "0.32em")
      .attr("x", (d) => {
        var arr = d.split(" ");
        return arr.length > 1 ? -8 : -6;
      })
      .style("font-size", "20px")
      .style("line-height", "14px");

    svg
      .append("g")
      .attr("class", "x axis-grid")
      .attr("transform", "translate(0," + height + ")")
      .call(xAxisGrid)
      .selectAll("line")
      .attr("stroke", "#CCCCCC")
      .attr("stroke-width", "3.4375px")
      .attr("opacity", 1);

    /*svg.append('g')
        .attr('class', 'y axis')*/

    // Another scale for subgroup position?
    const xSubgroup = d3
      .scaleBand()
      .domain(subgroups)
      .range([0, y.bandwidth()])
      .padding([0.2]);

    // color palette = one color per subgroup
    const color = d3.scaleOrdinal().domain(sideBar).range(ChartColor);

    //console.log(BarplatData['Saks']['oc_s_avg']);

    const tooltip = d3
      .select(`#${id}`)
      .append("div")
      .attr("class", "tooltip-barplot")
      .style("opacity", 0);

    const dataArray = [];
    // console.log(sideBar);
    Object.keys(BarplatData).map((d) => {
      let da = BarplatData[d];
      let col = { group: da["retailer"] };
      sideBar.map((s) => {
        col[s] = da[s] ? da[s] : 0;
      });
      col = Object.fromEntries(
        Object.entries(col).filter(([key, value]) => value != 0)
      );
      dataArray.push(col);
    });
    //console.log(dataArray)
    //console.log(chartData);
    //console.log(groups);
    const stackedData = d3.stack().keys(subgroups)(chartData);
    //console.log(stackedData)

    // Show the bars

    const d3Svg = svg
      .append("g")
      .selectAll("g")
      // Enter in data = loop group per group
      .data(stackedData)
      .enter()
      .append("g")
      .attr("fill", (d) => color(d.key))
      .selectAll("rect")
      .data((d) => {
        return d;
      })
      .enter();

    d3Svg
      .append("rect")
      .attr("x", (d) => x(d[0])) //
      .attr("y", (d) => {
        return totalNoBrand == 1 ? y(d.data.group) - 10 : y(d.data.group);
      })
      .attr("width", (d) => x(d[1]) - x(d[0])) //(d)=>{console.log(x(d.value));x(d.value)}
      .attr("height", 50)
      .on("mouseover", (event, d) => {
        //console.log(d3.select(this).attr("x"));
        //console.log(d)
        tooltip
          .transition()
          .delay(0)
          .duration(50)
          .ease(d3.easeLinear)
          .style("visibility", "visible")
          .style("opacity", "1");

        const parentNode = structuredClone(d.data);
        delete parentNode.group;
        //console.log(d.data,"data");
        //console.log(parentNode);
        //tooltip.html(`<div class="graph-tooltip">${tooltipHtml}</div>`)
        tooltip
          .html(
            `<div class="tooltipDesign" style="pointer-events: none">
                          <h5 class="tt-head">
                            ${d.data.group}
                          </h5>
                          <ul>
                          ${Object.keys(parentNode)
                            .map(function (key, index) {
                              return `<li>
                            <p><span 
                            class="color1-dot"
                            style="background:${color(key)}"
                            ></span> 
                            <span class="tooltip_text">${key}</span>
                            </p>
                            <label>${parentNode[key]}${lableValue?.['Unit'] ? lableValue?.['Unit'] : "%"}</label>
                          </li>`;
                            })
                            .join("")}
                          </ul>
                        </div>`
          )
          .style("left", event.layerX + 22 + "px")
          .style("top", event.layerY - 128 + "px");
      })
      .on("mousemove", function (event) {
        return tooltip
          .style("left", event.layerX + 22 + "px")
          .style("top", event.layerY - 128 + "px");
      })
      .on("mouseout", () =>
        tooltip.transition().style("visibility", "hidden").style("opacity", "0")
      );

    d3Svg
      .append("text")
      .attr("class", "text")
      .style("pointer-events", "none")
      .attr("class", "infotext")
      .attr("x", (d) => {
        //(width-20) will give the size inside graph by avoiding the axis width (20 will be the axis width)
        if (parseInt(x(d[1]) - x(d[0])) < (width - 20) / 5 / 2) {
          return x(d[0]) + (x(d[1]) - x(d[0])) / 2 - 13;
        } else if (parseInt(x(d[1]) - x(d[0])) == width - 20) {
          return x(d[0]) + (x(d[1]) - x(d[0])) / 2 - 18;
        } else {
          return x(d[0]) + (x(d[1]) - x(d[0])) / 2 - 17;
        }
        // if(parseInt(x(d[1]) - x(d[0])) <= ((width-20)/5)/2){
        //   return(x(d[0])+(x(d[1]) - x(d[0]))/3)
        // }
        // else if(parseInt(x(d[1]) - x(d[0])) <= ((width-20)/5)){
        //   //console.log(width);
        //   return(x(d[0])+(x(d[1]) - x(d[0]))/2.5)
        // }
        // else if(parseInt(x(d[1]) - x(d[0])) <= ((width-20)/5)*2){
        //   //console.log(width/4);
        //   return(x(d[0])+(x(d[1]) - x(d[0]))/2.3)
        // }
        // else{
        //   return(x(d[0])+(x(d[1]) - x(d[0]))/2.12)
        // }
      })
      .attr("y", (d) => {
        return totalNoBrand == 1 ? y(d.data.group) + 21 : y(d.data.group) + 31;
      })
      .text((d) => {
        // console.log(d[1], d[0])
        if (isNaN(d[0]) || isNaN(d[1])) {
          return "";
        }
        return parseFloat(d[1] - d[0]) + `${lableValue?.['Unit'] ? lableValue?.['Unit'] : "%"}`;
      })
      .style("font-size", "20px")
      .style("fill", "white");
    if (lableValue?.["lable x"]) {
      svg
        .append("text")
        .attr(
          "transform",
          "translate(" + width / 2 + " ," + (height + 50) + ")"
        )
        .style("text-anchor", "middle")
        .style("color", "#000000")
        .style("font-weight", "500")
        .style("font-size", "15px")
        .text(`${lableValue?.["lable x"]}`);
    }
    if(lableValue?.["lable Y"]){
      svg
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("y", - margin.left)
      .attr("x", 0 - height / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("color", "#000000")
      .style("font-weight", "500")
      .text(`${lableValue?.["lable Y"]}`);
    }

    //console.log(xSubgroup.bandwidth());
  }, [BarplatData, sideBar, colorCode]);
}
catch(err){
  console.log(err);
  return (
    <ErrorMessage Message={"Not able to generate graph for the retrieved data"}/>
  );
}

  return <div id={id}></div>;
};

export default ReturnObjectiveInsight;
