import PropTypes from 'prop-types';
import React from "react";
import { createContext, useState, useContext, useMemo, useEffect } from 'react';
import { createBrowserHistory } from "history";
import { Switch, BrowserRouter as Router } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { authProtectedRoutes, publicRoutes, adminRoutes } from "./routes";

// Import all middleware
import Authmiddleware from "./routes/route";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import AdminVerticalLayout from "./components/AdminVerticalLayout/";
// import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";
// Import css
import "./assets/css/custom.css"; 

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper";
import UserContext from './helpers/AuthType/session_helper';
import fakeBackend from "./helpers/AuthType/fakeBackend"
import {
  isMobile, isTablet,
  isDesktop
} from 'react-device-detect';
// const UserContext = React.createContext({
//   userDatas: '',
//   setUserDatas: () => {},
// });

// Activating fake backend
fakeBackend()

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// };

// init firebase backend
// initFirebaseBackend(firebaseConfig);
export const history = createBrowserHistory();
const App = props => {
  const [WindowSize, setWindowSize] = useState((getWindowSize()).innerWidth);
  const [userSessionData, setuserSessionData] = useState(JSON.parse(window.localStorage.getItem("authUser")) ? JSON.parse(window.localStorage.getItem("authUser")) : "");
  const value = useMemo(
    () => ([ userSessionData, setuserSessionData ]), 
    [userSessionData]
  );
  useEffect( () => {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTabletManual = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
    // console.log(WindowSize);
    // console.log(isTabletManual, 'isTabletManual');
    // console.log(isDesktop, 'Isdesktop');
    // console.log(!isMobile, 'isModile with not');
    // console.log(isTablet, 'isTablet');
    // console.log(WindowSize < 766.98, 'screensize');
    // if (isMobile && !isTablet) {
    //     document.body.classList.add('blurreffect-mobile');
    // }
    // else if(isDesktop && WindowSize < 766.98 && !isMobile){
    //   document.body.classList.add('blurreffect');
    // }
    if(WindowSize <= 1154){
          //console.log(WindowSize);
          document.body.classList.add('blurreffect');
    }
    else{
      document.body.classList.remove('blurreffect');
      //document.body.classList.remove('blurreffect-mobile');
    }
  },[isTablet,isMobile,WindowSize])
  useEffect(() => {
    function handleWindowResize() {
      setWindowSize((getWindowSize()).innerWidth);
    }

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);
  function getWindowSize() {
    const {innerWidth, innerHeight} = window;
    return {innerWidth, innerHeight};
  }
  return (
    <React.Fragment>
      <UserContext.Provider value={value}>
      <Router history = {history}>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={VerticalLayout}
              component={route.component}
              defaultState = {route.defaultState ? route.defaultState : null}
              key={idx}
              isAuthProtected={true}
              exact
              extra="user"
            />
          ))}

          {adminRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={AdminVerticalLayout}
              component={route.component}
              defaultState = {route.defaultState ? route.defaultState : null}
              key={idx}
              isAuthProtected={true}
              exact
              extra="admin"
            />
          ))}
        </Switch>
      </Router>
      </UserContext.Provider>
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any
};

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
